import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';

export type ColumnFilterCheckboxType = {
  id: FilterTypes;
  title: string;
  dateOption?: FilterDateOptions;
  order: number;
}

@Component({
  selector: 'app-column-filter-modal',
  templateUrl: './column-filter-modal.component.html',
  styleUrl: './column-filter-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnFilterModalComponent {
  @Output() selectedColumnsChosen = new EventEmitter<ColumnFilterCheckboxType[]>();

  public readonly T = T;
  protected columns: ColumnFilterCheckboxType[] = [];
  protected defaultColumns: ColumnFilterCheckboxType[] = [];

  // When this component is reused, please pass the below property through the initial state obj.
  // The initial implementation is in the PIR table, where the Title column is the only default column that should always be included.
  protected defaultColumnFilterType: FilterTypes = FilterTypes.Title;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly bsModalService: BsModalService,
  ) {}

  public closeModal(): void {
    this.bsModalRef.hide();
    this.bsModalService._hideBackdrop();
  }

  public onChecked(isChecked: boolean, column: ColumnFilterCheckboxType): void {
    if (!isChecked) {
      if (!column.dateOption) {
        this.columns = this.columns.filter(c => c.id !== column.id);
        return;
      }

      this.columns = this.columns.filter(c => c.dateOption !== column.dateOption);
      return;
    }

    this.columns = [...this.columns, column];
  }

  public isChecked(column: ColumnFilterCheckboxType): boolean {
    if (!column.dateOption) {
      return Boolean(this.columns.find(c => c.id === column.id));
    }

    return Boolean(this.columns.find(c => c.id === column.id && c.dateOption === column.dateOption));
  }

  public onCheckAll(): void {
    this.columns = [...this.defaultColumns];
  }

  public onApply(): void {
    this.selectedColumnsChosen.emit(this.columns);
    this.closeModal();
  }
}
