<div class="card" [ngClass]="{'selected-card': isTheCardSelected, 'normal-cursor': isWithinAddRiskModal}">
  @if(objectType === objectTypes.Risk){
    <div class="card-body">
      <h5 class="card-title risk d-flex align-items-center">
        <span class="material-symbols-outlined me-2">warning</span>
        <span class="d-inline-block overflow-hidden" [app-tooltip]="{ message: suggestedItem.title.length > 40 ? suggestedItem.title : '', position: ['top'] }">
          {{ suggestedItem.title | textTruncate: 40 }}
        </span>
      </h5>
      <div class="d-flex align-items-center justify-content-between mb-3">
          <app-details-dropdown
            [filterType]="filterTypes.Risk_Likelihood"
            [canEdit]="false"
            [canEditMessage]="''"
            [statusBars]="true"
            [appliedFilters]="suggestedItem?.filters">
          </app-details-dropdown>

          <app-details-dropdown
            [filterType]="filterTypes.Risk_Impact"
            [canEdit]="false"
            [canEditMessage]="''"
            [statusBars]="true"
            [appliedFilters]="suggestedItem?.filters">
          </app-details-dropdown>
      </div>
      <button *ngIf="isWithinAddRiskModal" class="use-risk-button" (click)="prefillForm(suggestedItem)">{{T.common.use_risk | translate}}</button>
      <div *ngIf="showSelectButton" class="card-btn" (click)="onCardSelected($event)">{{ isTheCardSelected ? (T.common.remove_risk | translate) : (T.common.use_risk | translate) }}</div>
    </div>
  } @else if (objectType === objectTypes.Indicator) {
  <div class="card-body">
    <h5 class="card-title d-flex align-items-center">
      <span class="material-symbols-outlined me-2" [ngClass]="iconColor">{{ icon }}</span>
      <span class="d-inline-block truncate-two-lines" [app-tooltip]="{ message: suggestedItem.title ?? 'N/A' }">
        {{ suggestedItem.title }}
      </span>
    </h5>
    <div class="d-flex align-items-center justify-content-between mb-1">
      <app-details-dropdown
        [ngClass]="{ 'truncate-category-text': suggestedItem?.sustainabilityScope > 0 }"
        [filterType]="filterTypes.Indicator_Category"
        [canEdit]="false"
        [canEditMessage]="''"
        [label]="'Category'"
        [statusBars]="false"
        [appliedFilters]="filters"
      >
      </app-details-dropdown>
      <ng-container *ngIf="suggestedItem.sustainabilityScope > 0">
        <app-details-dropdown
          class="w-35"
          [filterType]="filterTypes.Sustainability_Scope"
          [canEdit]="false"
          [canEditMessage]="''"
          [label]="'Scope'"
          [statusBars]="false"
          [appliedFilters]="filters"
        >
        </app-details-dropdown>
      </ng-container>
    </div>

    <div class="use-item-bottom">
      <div *ngIf="updateRegion" class="show-region"><sup>{{'CO'}}<sub>{{'2'}}</sub></sup>&nbsp;&nbsp;{{ 'Region: '}}{{updateRegion}}</div>
      <div *ngIf="isTheCardSelected" class="card-btn" (click)="onRemoveSelectedCard($event)">{{ 'Remove indicator' }}</div>
    </div>

  </div>
  }
</div>
