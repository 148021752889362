<div class="d-flex justify-content-between align-items-center">
  <div class="d-flex align-items-center">
    <ng-container *ngFor="let quickFilterDropdownItem of quickFilterDropdowns">
      <app-select
        class="me-2"
        ngDropdown
        #quickFilterDropdown="ngDropdown"
        [closeUponSelection]="false"
        [templateRef]="quickFilterContent"
        [value]="selectedValues(quickFilterDropdownItem.name)"
        [label]="quickFilterDropdownItem.label"
        [templateOutletContext]="{filterDropdown: quickFilterDropdownItem, dropdownRef: quickFilterDropdown}"
        [isActive]="selectedFilters[quickFilterDropdownItem.name]?.length > 0"
        [isFocused]="quickFilterDropdown.visible && selectedFilters[quickFilterDropdownItem.name]?.length > 0"
        ></app-select>
    </ng-container>
  </div>

  @if(showClearAllBtn) {
    <div class="clear-all-btn" (click)="clearAllFilters()">
      <span class="material-symbols-outlined me-1">close</span>
      <span> {{ T.common.clear_all | translate }} </span>
    </div>
  }
</div>

<ng-template #quickFilterContent let-filterDropdown="filterDropdown" let-dropdownRef="dropdownRef">
  <div class="quick-filter-container">
    <div class="quick-filter-body custom-scrollbar">
      <app-dropdown-option
        *ngFor="let filterOption of filterDropdown.filterOptions"
        [value]="filterOption"
        [useCheckbox]="true"
        [selected]="isTheFilterSelected(filterOption, filterDropdown.name)"
        (selectionChange)="onFilterSelected($event, filterDropdown.name)">
        {{filterOption.value}}
      </app-dropdown-option>
    </div>
    <div class="quick-filter-footer">
      <app-button-rectangle
        (clicked)="onApply(); dropdownRef.onEscape()"
        [text]="(T.common.apply | translate)"
        [buttonTheme]="'white'">
      </app-button-rectangle>
    </div>
  </div>
</ng-template>
