import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { Colors } from '../../shared/enums/colors';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { RAGBreakdown } from '../../shared/models/rag/ragBreakdown';
import { IndicatorOrganisationSortOptions } from '../enums/indicatorOorganisationTrackingSortOptions';
import { IndicatorTargetBadgeTypes } from '../enums/indicatorTargetBadgeTypes';
import { IndicatorTrackingDateTypes } from '../enums/indicatorTrackingDateTypes';
import { IndicatorTrendRAGs } from '../enums/indicatorTrendRAGs';
import { IndicatorTrendStatuses } from '../enums/indicatorTrendStatuses';
import { IndicatorUpdateListOptions } from '../enums/indicatorUpdateListOptions';
import { IndicatorUpdatesSortOptions } from '../enums/indicatorUpdatesSortOptions';
import { IndicatorUpdateTypes } from '../enums/indicatorUpdateTypes';
import { IndicatorTemplateOrganisationListViewModel } from '../../accountHub/viewModels/sustainability/indicator-templates/indicatorTemplateOrganisationListViewModel';
import { IndicatorUpdateCardViewModel } from '../viewModels/indicatorUpdateCardViewModel';
import { Pillars } from '../enums/pillars';
import { IndicatorIconPillarViewModel } from '../viewModels/indicatorIconPillarViewModel';

export class SustainabilityUtilities {
  public static GetIndicatorTrendStatusMaterialIcon(status: IndicatorTrendStatuses) {
    switch (status) {
      case IndicatorTrendStatuses.None:
        return 'arrow_circle_right';
      case IndicatorTrendStatuses.Trending_Up:
        return 'trending_up';
      case IndicatorTrendStatuses.Trending_Down:
        return 'trending_down';
      case IndicatorTrendStatuses.Pass:
        return 'check';
      case IndicatorTrendStatuses.Fail:
        return 'close';
      default:
        return 'error_outline';
    }
  }

  public static GetIndicatorTargetBadgeMaterialIcon(badgeType: IndicatorTargetBadgeTypes) {
    switch (badgeType) {
      case IndicatorTargetBadgeTypes.Gold:
      case IndicatorTargetBadgeTypes.Silver:
      case IndicatorTargetBadgeTypes.Bronze:
        return `stars`;
      default:
        return `track_changes`;
    }
  }

  public static getRAGColourHexFromValue(ragStatus: IndicatorTrendRAGs) {
    switch (ragStatus) {
      case IndicatorTrendRAGs.Grey:
        return Colors.Grey;
      case IndicatorTrendRAGs.Red:
        return Colors.Red;
      case IndicatorTrendRAGs.Amber:
        return Colors.Amber;
      case IndicatorTrendRAGs.Green:
        return Colors.Green;
      case IndicatorTrendRAGs.Blue:
        return Colors.Blue;
      default:
        return Colors.Grey;
    }
  }

  public static getTrendRagText(trendRag: IndicatorTrendRAGs, translateService: TranslateService) {
    switch (trendRag) {
      case IndicatorTrendRAGs.Grey:
        return translateService.instant(T.common.rag_description.not_started);
      case IndicatorTrendRAGs.Red:
        return translateService.instant(T.common.rag_description.slipped);
      case IndicatorTrendRAGs.Amber:
        return translateService.instant(T.common.rag_description.slipping);
      case IndicatorTrendRAGs.Green:
        return translateService.instant(T.common.rag_description.on_track);
      case IndicatorTrendRAGs.Blue:
        return translateService.instant(T.common.rag_description.complete);
    }
  }

  public static getTrackingDateText(trackingDateType: IndicatorTrackingDateTypes, translateService: TranslateService) {
    switch (trackingDateType) {
      case IndicatorTrackingDateTypes.Days:
        return translateService.instant(T.common.day.many);
      case IndicatorTrackingDateTypes.Weeks:
        return translateService.instant(T.common.week.many);
      case IndicatorTrackingDateTypes.Months:
        return translateService.instant(T.common.month.many);
      case IndicatorTrackingDateTypes.Years:
        return translateService.instant(T.common.year.many);
    }
  }

  public static getRAGColourHexFromPercentegeStatus(percentege: number): string {
    if (percentege < 40) {
      return Colors.Red;
    }

    if (percentege >= 40 && percentege <= 69) {
      return Colors.Amber;
    }

    if (percentege >= 70) {
      return Colors.Green;
    }
  }

  public static getIndicatorTargetBadgeHexColour(badge: IndicatorTargetBadgeTypes) {
    switch (badge) {
      case IndicatorTargetBadgeTypes.Gold:
        return Colors.Gold;
      case IndicatorTargetBadgeTypes.Silver:
        return Colors.Silver;
      case IndicatorTargetBadgeTypes.Bronze:
        return Colors.Bronze;
      default:
        return Colors.Black;
    }
  }

  public static GetFormattedValue (
    updateType: IndicatorUpdateTypes,
    value: number,
    abbreviation: string,
    translatedPass = 'Pass',
    translatedFail = 'Fail'
  ): string {
    if (!abbreviation) abbreviation = '';

    if (value === null || value === undefined) return '';

    if (updateType === IndicatorUpdateTypes.Pass_Or_Fail) {
      return value === 1 ? translatedPass : translatedFail;
    } else {
      return `${value}${abbreviation}`;
    }
  }

  public static getTotalRAGBreakdownBar(rags: IndicatorTrendRAGs[]): RAGBreakdown[] {
    const list = [
      {
        ragStatus: IndicatorTrendRAGs.Grey,
        colour: SustainabilityUtilities.getRAGColourHexFromValue(IndicatorTrendRAGs.Grey),
        count: rags.filter((r) => r === IndicatorTrendRAGs.Grey).length,
      },
      {
        ragStatus: IndicatorTrendRAGs.Red,
        colour: SustainabilityUtilities.getRAGColourHexFromValue(IndicatorTrendRAGs.Red),
        count: rags.filter((r) => r === IndicatorTrendRAGs.Red).length,
      },
      {
        ragStatus: IndicatorTrendRAGs.Amber,
        colour: SustainabilityUtilities.getRAGColourHexFromValue(IndicatorTrendRAGs.Amber),
        count: rags.filter((r) => r === IndicatorTrendRAGs.Amber).length,
      },
      {
        ragStatus: IndicatorTrendRAGs.Green,
        colour: SustainabilityUtilities.getRAGColourHexFromValue(IndicatorTrendRAGs.Green),
        count: rags.filter((r) => r === IndicatorTrendRAGs.Green).length,
      },
      {
        ragStatus: IndicatorTrendRAGs.Blue,
        colour: SustainabilityUtilities.getRAGColourHexFromValue(IndicatorTrendRAGs.Blue),
        count: rags.filter((r) => r === IndicatorTrendRAGs.Blue).length,
      },
    ];

    return list;
  }

  public static sortUpdatesList(
    selectedDropdownOption: { id: number; value: string },
    items: IndicatorUpdateCardViewModel[],
    prop = ''
  ): IndicatorUpdateCardViewModel[] {
    let sortedItems = items;

    if (!selectedDropdownOption) {
      return items;
    }

    switch (selectedDropdownOption.id) {
      case IndicatorUpdatesSortOptions.Due_Most_Recent:
      case IndicatorUpdatesSortOptions.Due_Latest:
      case IndicatorUpdatesSortOptions.Updated_Recent:
        sortedItems = this.sortByDate(items, true, prop);
        break;
      case IndicatorUpdatesSortOptions.Due_Oldest:
      case IndicatorUpdatesSortOptions.Due_Earliest:
      case IndicatorUpdatesSortOptions.Updated_Oldest:
        sortedItems = this.sortByDate(items, false, prop);
        break;
    }

    return sortedItems.slice();
  }

  public static sortIndicatorOrganisationTrackingList(
    selectedDropdownOption: { id: number; value: string },
    items: any[],
    prop = 'title'
  ): any[] {
    let sortedItems = items;

    if (!selectedDropdownOption) {
      return items;
    }

    switch (selectedDropdownOption.id) {
      case IndicatorOrganisationSortOptions.Title_A_Z:
        sortedItems = this.sortByTitle(items, false, prop);
        break;
      case IndicatorOrganisationSortOptions.Title_Z_A:
        sortedItems = this.sortByTitle(items, true, prop);
        break;
      case IndicatorOrganisationSortOptions.Trend_Status_Red_Grey:
        sortedItems = this.sortByTrendStatus(items, true);
        break;
      case IndicatorOrganisationSortOptions.Trend_Status_Green_Grey:
        sortedItems = this.sortByTrendStatus(items, false);
        break;
    }

    return sortedItems.slice();
  }

  private static sortByTitle(items: any[], descending: boolean = false, prop): any[] {
    return items
      .sort((a, b) => {
        if (descending) {
          return b[prop].localeCompare(a[prop]);
        } else {
          return a[prop].localeCompare(b[prop]);
        }
      })
      .slice();
  }

  private static sortByTrendStatus(items: any[], descending: boolean = false): any[] {
    return items
      .sort((a, b) => {
        if (descending) {
          return a.trendRag - b.trendRag;
        } else {
          return b.trendRag - a.trendRag;
        }
      })
      .slice()
      .filter(this.isntZero)
      .concat(items.filter(this.isZero));
  }

  private static isntZero(element: IndicatorTemplateOrganisationListViewModel) {
    return element.trendRag > 0;
  }

  private static isZero(element: IndicatorTemplateOrganisationListViewModel) {
    return element.trendRag == 0;
  }

  private static sortByDate(
    items: IndicatorUpdateCardViewModel[],
    descending: boolean = false,
    prop
  ): IndicatorUpdateCardViewModel[] {
    return items
      .sort((a, b) => {
        const aTime = new Date(a[prop]).getTime();
        const bTime = new Date(b[prop]).getTime();

        if (descending) {
          return bTime - aTime;
        } else {
          return aTime - bTime;
        }
      })
      .slice();
  }

  public static getIconBasedOnPillar(pillar: Pillars): IndicatorIconPillarViewModel {
    const pillarIconModel: IndicatorIconPillarViewModel = new IndicatorIconPillarViewModel();

    switch (pillar) {
      case Pillars.Environmental:
        pillarIconModel.icon = 'grass';
        pillarIconModel.iconColor = 'text-success';
        break;
      case Pillars.Social:
        pillarIconModel.icon = 'emoji_people';
        pillarIconModel.iconColor = 'text-primary';
        break;
      case Pillars.Governance:
        pillarIconModel.icon = 'account_balance';
        pillarIconModel.iconColor = 'text-danger';
        break;
    }

    return pillarIconModel;
  }

  public static getIndicatorTemplateUpdateCount(
    trackingDurationDateValue: number,
    trackingDurationDateType: IndicatorTrackingDateTypes,
    trackingDateValue: number,
    trackingDateType: IndicatorTrackingDateTypes
  ): number {
    const totalDuration: number = this.getValueInDays(trackingDurationDateValue, trackingDurationDateType);

    const totalDays: number = this.getValueInDays(trackingDateValue, trackingDateType);

    const updateCount: number = totalDuration === 0 || totalDays === 0 ? 0 : totalDuration / totalDays;

    return Math.floor(updateCount);
  }

  private static getValueInDays(value: number, dateType: IndicatorTrackingDateTypes): number {
    switch (dateType) {
      case IndicatorTrackingDateTypes.Days:
        return value * 1;
      case IndicatorTrackingDateTypes.Weeks:
        return value * 7;
      case IndicatorTrackingDateTypes.Months:
        return value * 30;
      case IndicatorTrackingDateTypes.Years:
        return value * 365;
      default:
        return value * 1;
    }
  }


  public static getEmissionsPieChartWidgetTitleLabel(title: string, co2eTotal: number){
    const decimalValue = co2eTotal < 0.1 ? co2eTotal.toFixed(7) : co2eTotal.toFixed(1);
    return `${title} - ${decimalValue}t CO2e`
  }

  public static getEmissionsPieChartWidgetDecimal(co2eTotal: number) : number{
    return co2eTotal < 0.1 ? Number(co2eTotal.toFixed(7)) : Number(co2eTotal.toFixed(1));
  }


  public static getUpdatesAccordionSectionText(
    listOption: IndicatorUpdateListOptions,
    objectType: ObjectTypes,
    translateService: TranslateService,
    localisedOrganisation: string
  ) {
    let helperText: string = '';
    switch (listOption) {
      case IndicatorUpdateListOptions.My_Updates_Due:
        helperText = translateService.instant(T.sustainability.indicators_you_are_assigned);
        break;
      case IndicatorUpdateListOptions.All_Updates_Due:
        helperText = translateService.instant(T.sustainability.indicators_for_indicator_group);
        break;
      case IndicatorUpdateListOptions.Due_Next_30_days:
        helperText = translateService.instant(T.sustainability.indicators_updates_due_next_30_days);
        break;
      case IndicatorUpdateListOptions.Recent_Updates:
        helperText = translateService.instant(T.sustainability.recent_updates_for_organisation_indicators, {
          localisedOrganisation,
        });
        break;
      default:
        helperText = translateService.instant(T.sustainability.review_and_add_other_notes_for_organisation, {
          localisedOrganisation,
        });
        break;
    }

    return helperText;
  }

  public static getIndicatorSvgIcon(trendStatus: IndicatorTrendStatuses, ragStatus: IndicatorTrendRAGs, size: number = 24) {
    const color = SustainabilityUtilities.getRAGColourHexFromValue(ragStatus);
    switch (trendStatus) {
      case IndicatorTrendStatuses.Trending_Down:
        return `<svg width="${size}px" height="${size}px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="ui_custom-icons-RAG-statuses" transform="translate(-992.000000, -173.000000)">
                            <g id="icon_indicator_amber_down" transform="translate(992.000000, 173.000000)">
                                <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                                <rect id="Rectangle-Copy-87" fill="${color}" x="4" y="4" width="40" height="40" rx="6"></rect>
                                <g id="trending_down_black_24dp" transform="translate(8.000000, 8.000000)">
                                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                                    <polygon id="Path" fill="#FFFFFF" fill-rule="nonzero" points="21.3333333 24 24.3866667 20.9466667 17.88 14.44 12.5466667 19.7733333 2.66666667 9.88 4.54666667 8 12.5466667 16 17.88 10.6666667 26.28 19.0533333 29.3333333 16 29.3333333 24"></polygon>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>`;
      case IndicatorTrendStatuses.Trending_Up:
        return `<svg width="${size}px" height="${size}px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="ui_custom-icons-RAG-statuses" transform="translate(-1072.000000, -172.000000)">
                        <g id="icon_indicator_amber_up" transform="translate(1072.000000, 172.000000)">
                            <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                            <rect id="Rectangle-Copy-87" fill="${color}" x="4" y="4" width="40" height="40" rx="6"></rect>
                            <g id="trending_down_black_24dp" transform="translate(24.000000, 24.000000) scale(1, -1) translate(-24.000000, -24.000000) translate(8.000000, 8.000000)">
                                <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                                <polygon id="Path" fill="#FFFFFF" fill-rule="nonzero" points="21.3333333 24 24.3866667 20.9466667 17.88 14.44 12.5466667 19.7733333 2.66666667 9.88 4.54666667 8 12.5466667 16 17.88 10.6666667 26.28 19.0533333 29.3333333 16 29.3333333 24"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
                </svg>`;
      case IndicatorTrendStatuses.Fail:
        return `<svg width="${size}px" height="${size}px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="ui_custom-icons-RAG-statuses" transform="translate(-1152.000000, -102.000000)">
                            <g id="icon_indicator_red_fail" transform="translate(1152.000000, 102.000000)">
                                <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                                <rect id="Rectangle-Copy-87" fill="${color}" x="4" y="4" width="40" height="40" rx="6"></rect>
                                <g id="close_black_24dp" transform="translate(8.000000, 8.000000)">
                                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                                    <polygon id="Path" fill="#FFFFFF" fill-rule="nonzero" points="25.3333333 8.54666667 23.4533333 6.66666667 16 14.12 8.54666667 6.66666667 6.66666667 8.54666667 14.12 16 6.66666667 23.4533333 8.54666667 25.3333333 16 17.88 23.4533333 25.3333333 25.3333333 23.4533333 17.88 16"></polygon>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>`;
      case IndicatorTrendStatuses.Pass:
        return `<svg width="${size}px" height="${size}px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="ui_custom-icons-RAG-statuses" transform="translate(-1152.000000, -242.000000)">
                            <g id="icon_indicator_green_pass" transform="translate(1152.000000, 242.000000)">
                                <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                                <rect id="Rectangle-Copy-87" fill="${color}" x="4" y="4" width="40" height="40" rx="6"></rect>
                                <g id="task_alt_black_24dp" transform="translate(8.000000, 8.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                                    <path d="M29.3333333,6.90666667 L14.12,22.1333333 L8.46666667,16.48 L10.3466667,14.6 L14.12,18.3733333 L27.4533333,5.04 L29.3333333,6.90666667 Z M26.3866667,13.6266667 C26.56,14.3866667 26.6666667,15.1866667 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.1066667 10.1066667,5.33333333 16,5.33333333 C18.1066667,5.33333333 20.0533333,5.94666667 21.7066667,7 L23.6266667,5.08 C21.4666667,3.56 18.84,2.66666667 16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,14.4133333 29.04,12.8933333 28.5333333,11.48 L26.3866667,13.6266667 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>`;
      default:
        return `<svg width="${size}px" height="${size}px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="ui_custom-icons-RAG-statuses" transform="translate(-992.000000, -313.000000)">
                        <g id="icon_indicator_blue" transform="translate(992.000000, 313.000000)">
                            <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                            <rect id="Rectangle-Copy-87" fill="${color}" x="4" y="4" width="40" height="40" rx="6"></rect>
                            <g id="track_changes_black_24dp" transform="translate(8.000000, 8.000000)">
                                <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                                <path d="M25.4266667,6.57333333 L23.5466667,8.45333333 C25.4666667,10.3866667 26.6666667,13.0533333 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.56 9.4,6.08 14.6666667,5.42666667 L14.6666667,8.12 C10.88,8.76 8,12.04 8,16 C8,20.4133333 11.5866667,24 16,24 C20.4133333,24 24,20.4133333 24,16 C24,13.7866667 23.1066667,11.7866667 21.6533333,10.3466667 L19.7733333,12.2266667 C20.7333333,13.2 21.3333333,14.5333333 21.3333333,16 C21.3333333,18.9466667 18.9466667,21.3333333 16,21.3333333 C13.0533333,21.3333333 10.6666667,18.9466667 10.6666667,16 C10.6666667,13.52 12.3733333,11.4533333 14.6666667,10.8533333 L14.6666667,13.7066667 C13.8666667,14.1733333 13.3333333,15.0133333 13.3333333,16 C13.3333333,17.4666667 14.5333333,18.6666667 16,18.6666667 C17.4666667,18.6666667 18.6666667,17.4666667 18.6666667,16 C18.6666667,15.0133333 18.1333333,14.16 17.3333333,13.7066667 L17.3333333,2.66666667 L16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,12.32 27.84,8.98666667 25.4266667,6.57333333 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>`;
    }
  }
}
