<app-modal [fullScreen]="true" style="height: 100%; max-height: unset;">
  <app-modal-header
    style="z-index: 50"
    [title]="T.common.suggested_risks | translate"
    [showDelete]="false"
    (closed)="closeModal()">
  </app-modal-header>

  <div *ngIf="currentStep === 1" class="top-navigation-container">
    <div class="top-back-icon" (click)="decreaseStep()">
      <span class="material-symbols-outlined chevron-icon">chevron_left</span>
    </div>
    <div class="top-step-text">{{ T.common.back | translate }}</div>
  </div>

  <app-threaded-beads [beads]="beads" [showBubbles]="false"></app-threaded-beads>

  <app-modal-body style="flex: 1; margin: auto; overflow: hidden">
    <header class="suggested-items-modal-body-header">
      <h3 class="suggested-items-modal-body-title">
        {{ currentStep === 0 ? (T.common.suggested_risks | translate) : (T.common.update_risk_details | translate) }}
      </h3>
      <div class="suggested-items-info-text">
        {{ currentStep === 0 ?
          (T.common.suggested_risks_text | translate)
          : (T.common.update_risk_details_text | translate) }}
      </div>
    </header>
    <section [ngClass]="['criteria-container', currentStep === 1 ? 'fit-filters' : '']">
      @if (currentStep === 0) {
        <div class="criteria-box">
          <span class="criteria-title">{{ T.common.venue_type | translate }}</span>
          <span class="criteria-value">{{ accountVenue ? accountVenue : T.common.none | translate }}</span>
        </div>
        <div class="criteria-box">
          <span class="criteria-title">{{ T.common.capacity | translate }}</span>
          <span class="criteria-value">{{ account.capacity > 0 ? account.capacity : T.common.none | translate }}</span>
        </div>
        <div class="criteria-box">
          <span class="criteria-title"> {{ T.common.location.one | translate }} </span>
          <span class="criteria-value">{{ venueCountry ? venueCountry : T.common.none | translate }}</span>
        </div>
      } @else if (currentStep === 1) {
        <div class="criteria-box">
          <app-details-lozenge
            class="w-100"
            [heading]="T.common.impact_type_text.many | translate"
            [filters]="bulkFilters"
            [filterType]="filterTypes.Risk_Impact_Type"
            [showOverflowingLozenges]="true"
            [canEdit]="true"
            [accordionLozenge]="false"
            (onFiltersUpdated)="updateFiltersInBulk($event, filterTypes.Risk_Impact_Type)">
          </app-details-lozenge>
        </div>
        <div class="criteria-box">
          <app-details-lozenge
            class="w-100"
            [heading]="T.defaultLocalizations.event_type.many | translate"
            [filters]="bulkFilters"
            [filterType]="filterTypes.Event_Type"
            [showOverflowingLozenges]="true"
            [canEdit]="true"
            [accordionLozenge]="false"
            (onFiltersUpdated)="updateFiltersInBulk($event, filterTypes.Event_Type)">
          </app-details-lozenge>
        </div>
        <div class="criteria-box">
          <app-details-lozenge
            class="w-100"
            [heading]="T.defaultLocalizations.owner.many | translate"
            [filters]="bulkFilters"
            [filterType]="filterTypes.Owner"
            [showOverflowingLozenges]="true"
            [canEdit]="true"
            [accordionLozenge]="false"
            (onFiltersUpdated)="updateFiltersInBulk($event, filterTypes.Owner)">
          </app-details-lozenge>
        </div>
        <div class="criteria-box">
          <app-details-lozenge
            class="w-100"
            [heading]="T.defaultLocalizations.department.many | translate"
            [filters]="bulkFilters"
            [filterType]="filterTypes.Department"
            [showOverflowingLozenges]="true"
            [canEdit]="true"
            [accordionLozenge]="false"
            (onFiltersUpdated)="updateFiltersInBulk($event, filterTypes.Department)">
          </app-details-lozenge>
        </div>
      }
    </section>
    <section class="filters-container" *ngIf="currentStep === 0">
      <app-list-search-input-fixed
        class="me-2"
        [background]="'var(--wt-neutral-extra-light)'"
        [placeholder]="T.common.search_item | translate: {item: 'Risk'}"
        [value]="searchText"
        (inputChanged)="onListSearch($event)"
      ></app-list-search-input-fixed>

      <app-suggested-risks-quick-filters
        (filtersApplied)="filterItems($event)"
        (clearFilters)="resetFilters()">
      </app-suggested-risks-quick-filters>

      <app-button-transparent
        [icon]="'search'"
        [text]="T.common.search | translate"
        (clicked)="searchRisks()">
      </app-button-transparent>
    </section>
    @if (currentStep === 0) {
      <section>
        <div class="suggested-items-options-header">
          <h4 class="suggested-items-options-title">
            {{ T.common.select_relevant_risks | translate }}
          </h4>
        </div>
        @if(!loading){
          <div class="suggested-items-cards-container modal-dialog-scrollable custom-scrollbar">
            @for (item of filteredSuggestedRisks; track item) {
              <app-suggested-card
                [objectType]="objectTypes.Risk"
                [suggestedItem]="item"
                [isTheCardSelected]="isSelected(item.id) || openedCard?.id === item.id"
                (cardSelected)="selectRisk(item)"
                (click)="openSelectedRisk(item)">
              </app-suggested-card>
            }
          </div>
        } @else if(loading) {
          <app-loading></app-loading>
        }
      </section>
    } @else if (currentStep === 1) {
      <section class="summary-table">
        <app-responsive-table
        [objects]="selectedRisks"
        [tableHeaders]="tableHeaders"
        [selectableObjects]="false"
        [adoptParentHeight]="false"
        [wholeRowSelection]="false"
        [useSearch]="false"
        [useInfinityScroll]="false"
        [objectsPerPage]="10"
        ></app-responsive-table>
      </section>
    }

  </app-modal-body>
  <app-modal-footer>
    <footer class="suggested-items-modal-footer">
      <div class="suggested-items-modal-footer-text" [innerHTML]="selectedRisksFooterText"></div>
      <app-button-rectangle
        [icon]="footerBtnIcon[currentStep]"
        [text]="footerBtnText"
        size="large"
        [disabled]="!(selectedRisks.length > 0) || loading"
        (clicked)="increaseStep()">
      </app-button-rectangle>
    </footer>
  </app-modal-footer>
</app-modal>

<ng-template #ratingFieldTemplate let-object="object">
  <span class="rating-field material-symbols-outlined ms-2 default-cursor">warning</span>
</ng-template>

<ng-template #titleFieldTemplate let-object="object">
  <app-simple-editable-field
    [input]="object.title"
    [canEdit]="true"
    [fontSize]="14"
    (onUpdate)="updateTitle($event, object)"
  ></app-simple-editable-field>
</ng-template>

<ng-template #impactTypeFieldTemplate let-object="object">
  <app-details-lozenge
    class="w-100"
    [filters]="object.filters"
    [filterType]="filterTypes.Risk_Impact_Type"
    [canEdit]="true"
    [accordionLozenge]="true"
    (onFiltersUpdated)="updateFilters($event, object)">
  </app-details-lozenge>
</ng-template>

<ng-template #eventTypeFieldTemplate let-object="object">
  <app-details-lozenge
    class="w-100"
    [filters]="object.filters"
    [filterType]="filterTypes.Event_Type"
    [canEdit]="true"
    [accordionLozenge]="true"
    (onFiltersUpdated)="updateFilters($event, object)"
    [singleSelect]="true">
  </app-details-lozenge>
</ng-template>

<ng-template #ownersFieldTemplate let-object="object">
  <app-details-lozenge
    class="w-100"
    [filters]="object.filters"
    [filterType]="filterTypes.Owner"
    [canEdit]="true"
    [accordionLozenge]="true"
    (onFiltersUpdated)="updateFilters($event, object)">
  </app-details-lozenge>
</ng-template>

<ng-template #departmentFieldTemplate let-object="object">
  <app-details-lozenge
    class="w-100"
    [filters]="object.filters"
    [filterType]="filterTypes.Department"
    [canEdit]="true"
    [accordionLozenge]="true"
    (onFiltersUpdated)="updateFilters($event, object)">
  </app-details-lozenge>
</ng-template>

<ng-template #riskActionsTemplate let-object="object">
  @if(object.riskActions && object.riskActions.length > 0) {
  <span class="default-cursor">
    {{ object.riskActions.length === 1 ? (T.common.count_of_actions.one | translate: { count : object.riskActions.length}) : (T.common.count_of_actions.many | translate: { count : object.riskActions.length}) }}
  </span>
  } @else { <span class="default-cursor">{{ T.common.count_of_actions.none | translate }}</span> }
</ng-template>
