import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { map, Subscription } from 'rxjs';
import { SuggestedRisksService } from 'src/app/modules/risk/services/suggested-risks.service';
import { TranslateService } from '@ngx-translate/core';
import { RelevanceNames } from 'src/app/modules/shared/enums/relevanceEnums';
import { DropdownOptionChange } from 'src/app/modules/shared/components/dropdown/dropdown-option/dropdown-option.component';
import { SuggestedRiskAppliedFilters, SuggestedRiskQuickFilterNames } from '../../../enums/suggestedRiskFilters';
import { DropdownOption, QuickFilterDropdown } from '../../../enums/suggestedRiskFilterDropdown.type';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';

@Component({
  selector: 'app-suggested-risks-quick-filters',
  templateUrl: './suggested-risks-quick-filters.component.html',
  styleUrl: './suggested-risks-quick-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestedRisksQuickFiltersComponent implements OnInit, OnDestroy {
  @Output() filtersApplied = new EventEmitter<SuggestedRiskAppliedFilters>();
  @Output() clearFilters = new EventEmitter();

  public readonly T = T;
  protected subscriptions = new Subscription();

  protected quickFilterDropdowns: QuickFilterDropdown[] = [];

  protected selectedFilters: SuggestedRiskAppliedFilters;

  protected suggestedRiskCategoryOptions: DropdownOption[];
  protected riskRelevanceOptions: DropdownOption[];
  protected riskImpactTypeOptions: DropdownOption[];
  impactTypeFilters: FilterViewModel[] = [];
  suggestedRiskQuickFilterDropdowns: QuickFilterDropdown[];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly allowedFiltersService: AllowedFiltersService,
    protected readonly suggestedRisksService: SuggestedRisksService,
    protected readonly translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.initQuickFilters();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setFilterDropdownOptions(): void {
    this.suggestedRiskQuickFilterDropdowns = [
      {
        filterOptions: this.riskImpactTypeOptions,
        label: this.translateService.instant(T.defaultLocalizations.risk_impact_type.one),
        selectedFilter: this.selectedFilters?.impactRange,
        name: SuggestedRiskQuickFilterNames.RiskImpactTypes
      }
    ]
  }

  get showClearAllBtn(): boolean {
    return this.selectedFilters && (
      this.selectedFilters.impactRange.length > 0 ||
      this.selectedFilters.riskCategory.length > 0 ||
      this.selectedFilters.relevance.length > 0 ||
      this.selectedFilters.riskImpactTypes.length > 0
    )
  }

  protected initQuickFilters(): void {
    this.riskImpactTypeOptions = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Risk_Impact_Type).map(filter => ({
      id: filter.filterValue,
      value: filter.filterText
    } as DropdownOption));

    this.riskRelevanceOptions = [
      { id: 1, value: RelevanceNames.Low },
      { id: 2, value: RelevanceNames.Medium },
      { id: 3, value: RelevanceNames.High },
    ];

    this.selectedFilters = {
      impactRange: [],
      riskCategory: [],
      relevance: [],
      riskImpactTypes: [],
      likelihoodRange: [],
      keywords: [],
      limit: 8,
      venueType: [],
      eventType: []
    };

    this.setFilterDropdownOptions();

    this.quickFilterDropdowns = this.suggestedRiskQuickFilterDropdowns;
  }

  protected onFilterSelected(dropdownOptionChange: DropdownOptionChange, filterName: string) {
    //this will be used in the future
    if (dropdownOptionChange.isSelected) {
      this.selectedFilters[filterName].push(dropdownOptionChange.value)
    } else {
      this.selectedFilters[filterName] = this.selectedFilters[filterName].filter(selectedFilter => selectedFilter.id !== dropdownOptionChange.value.id)
    }

    this.changeDetectorRef.detectChanges();
  }

  protected isTheFilterSelected(option: DropdownOption, filterName: string): boolean {
    return this.selectedFilters[filterName].some(selectedFilter => selectedFilter.id === option.id);
  }

  protected onApply(): void {
    this.filtersApplied.emit(this.selectedFilters);
  }

  protected clearAllFilters(): void {
    this.selectedFilters = {
      impactRange: [],
      riskCategory: [],
      relevance: [],
      riskImpactTypes: [],
      likelihoodRange: [],
      keywords: [],
      limit: 8,
      venueType: [],
      eventType: []
    };

    this.clearFilters.emit();
  }

  protected selectedValues(filterName: string) {
    return this.selectedFilters[filterName].length > 0 ? this.selectedFilters[filterName].map((filter) => filter.value).join(', ') : '';
  }

  protected loadRiskCategoryFilterOptions(): void {
    this.subscriptions.add(
      this.suggestedRisksService.getCategoryFilter({})
        .pipe(map(categories => {

          return categories.map(category => ({
            id: category.value,
            value: category.text
          } as DropdownOption))
        }))
        .subscribe(res => {
          this.suggestedRiskCategoryOptions = res;

          this.quickFilterDropdowns.forEach(quickFilterDropdown => {
            if (quickFilterDropdown.name === SuggestedRiskQuickFilterNames.Category) {
              quickFilterDropdown.filterOptions = this.suggestedRiskCategoryOptions;
            }
          })
          this.changeDetectorRef.detectChanges();
        })
    )

  }
}
