import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { T } from 'src/assets/i18n/translation-keys';
import { RiskProfileService } from '../../../services/risk-profile.service';
import { Subscription } from 'rxjs';
import { RiskProfileViewModel } from '../../../models/RiskProfileViewModel';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { RiskProfileStatusTypes } from '../../../enums/RiskProfileStatusTypes';
import { Router } from '@angular/router';
import { OperationTypes } from 'src/app/modules/shared/enums/operationTypes';

@Component({
  selector: 'app-risk-profile-add-modal',
  templateUrl: './risk-profile-add-modal.component.html',
  styleUrl: './risk-profile-add-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RiskProfileAddModalComponent implements OnInit, OnDestroy{
  public readonly T = T;
  public filterTypes = FilterTypes;
  public filters: FilterViewModel[] = [];
  protected allowedFilters: FilterViewModel[] = [];
  public editableFieldTypes = EditableFieldTypes;
  public employee: Employee;
  public loading = false;
  protected newRiskProfile: RiskProfileViewModel = {} as RiskProfileViewModel;

  public preselectedEventId: number;
  public preselectedEventTitle: string;

  protected subscriptions = new Subscription();

  constructor(
    protected bsModalRef: BsModalRef,
    protected changeDetectorRef: ChangeDetectorRef,
    protected readonly translateService: TranslateService,
    protected readonly bsModalService: BsModalService,
    protected readonly router: Router,
    private readonly alertService: AlertService,
    private authenticationService: AuthenticationService,
    private allowedFiltersService: AllowedFiltersService,
    protected riskProfileService: RiskProfileService
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();


    if(this.preselectedEventId) {
      const preselectedEventFilter = FilterUtilities.GenerateFilter(FilterTypes.Event, this.preselectedEventId, this.preselectedEventTitle);
      this.filters.push(preselectedEventFilter);
    }

    this.initAllowedFilters();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get isRequireFilterAdded(): boolean {
    return this.filters.some(f => f.filterType === this.filterTypes.Event);
  }

  updateDescription(event: string) {
    this.newRiskProfile.description = event;
  }

  initAllowedFilters() {
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
  }

  closeModal(): void {
    this.bsModalRef.hide();
  }

  handleFilterChange(changedFilters: FilterViewModel[]) {
    this.filters = [...changedFilters];
  }

  onAdd(): void {
    if(this.loading) return;
    if(!this.isRequireFilterAdded) return;
    this.loading = true;
    this.newRiskProfile.filters = this.filters;

    const riskProfileStatusFilter = FilterUtilities.GenerateFilter(FilterTypes.Risk_Profile_Status, RiskProfileStatusTypes.Open);
    this.newRiskProfile.filters.push(riskProfileStatusFilter);

    if(this.preselectedEventId) {
      this.newRiskProfile.title = this.preselectedEventTitle + ': Risk Profile';
    } else {
      this.newRiskProfile.title = this.filters.find(f => f.filterType === this.filterTypes.Event).filterText + ': Risk Profile';
    }

    this.newRiskProfile.eventId = this.getFilterValue(this.filterTypes.Event);
    this.newRiskProfile.ownerId = this.getFilterValue(this.filterTypes.Owner);
    this.newRiskProfile.privacyStatus = this.getFilterValue(this.filterTypes.Risk_Privacy_Status);
    this.newRiskProfile.status = RiskProfileStatusTypes.Open;

    if(!this.newRiskProfile.description) this.newRiskProfile.description = '';

    this.subscriptions.add(
      this.riskProfileService.add(this.newRiskProfile).subscribe((res) => {
        void this.alertService.success(
          this.translateService.instant(T.common.risk_profile_added_success)
        );
        this.closeModal();

        const id = +res.returnModel.id;
        this.allowedFiltersService.refreshFilters(
          OperationTypes.Create,
          [{ id: id, title: res.returnModel.title, relatedObjectId: 0 }],
          FilterTypes.Risk_Profile,
          true
        );
        this.loading = false;
        void this.router.navigate([`v2/risk/risk-profiles/profiles/${id}`]);
      })
    )
  }

  getFilterValue(filterType: FilterTypes): number {
    const filter: FilterViewModel = this.filters?.find((f) => f.filterType === filterType);
    if(filterType === FilterTypes.Owner)
      return filter ? filter.filterValue as number : null;
    return filter ? filter.filterValue as number : 0;
  }
}
