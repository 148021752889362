<app-modal>
  <app-modal-header [title]="isBaseline ? (T.common.baseline_rating | translate) : (T.common.risk_rating | translate)" (closed)="onClose()"></app-modal-header>
  <app-modal-body>
    <div class="rag-matrix-container">
      <div class="txt-rotate">{{ localisedLikelihood }}</div>
      <div class="matrix-container">
        <div class="bottom-descripiton">{{ localisedImpact }}</div>
        <div class="bottom-descripiton">
          <div *ngFor="let impact of impacts" class="cell" [class.selected-impact]="isSelectedImpact(impact.key)">
            {{ impact.key }}
          </div>
        </div>
        <div class="d-flex" *ngFor="let likelihood of likelihoods">
          <div class="side-numbers" [class.selected-likelihood]="isSelectedLikelihood(likelihood.key)">{{ likelihood.key === riskLikelihoods.Issue ? (T.defaultLocalizations.issue.one | translate) : likelihood.key }}</div>
          <div
            *ngFor="let impact of impacts"
            [style.background-color]="getColour(likelihood.key, impact.key)"
            [class.selected]="isSelected(likelihood.key, impact.key)"
            (click)="onSelectLikelihoodAndImpact(likelihood.key, impact.key)"
            class="rag-cell"
          >
          </div>
        </div>
      </div>
    </div>
    <div class="dividing-line"></div>
    <div class="matrix-info">
      <div class="label-column">
        <div>{{ localisedLikelihood }}</div>
        <div>{{ localisedImpact }}</div>
      </div>
      <div class="value-column">
        <div>{{ currentlySelectedLikelihood }}</div>
        <div>{{ currentlySelectedImpact }}</div>
      </div>
      <div class="localised-value">
        <div>{{ getLocalisedCurrentLikelihood() }}</div>
        <div>{{ getLocalisedCurrentImpact() }}</div>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <app-button-rectangle
    [text]="T.common.cancel | translate"
    [size]="'large'"
    [buttonTheme]="'white'"
    (clicked)="onClose()"
    ></app-button-rectangle>
    <app-button-rectangle
    [text]="T.common.apply | translate"
    [size]="'large'"
    (clicked)="onSave()"></app-button-rectangle>
  </app-modal-footer>
</app-modal>
