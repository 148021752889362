<div
  #responsiveTable
  class="responsive-table-wrapper"
  [style.height]="adoptParentHeight ? '100%' : ''"
  (resized)="onResized()"
>
  <div *ngIf="useSearch" [ngClass]="['responsive-table-search', showTableRowsCount ? 'flex-row justify-content-between' : 'flex-column align-items-end']">
    <div *ngIf="showTableRowsCount" class="count-results">
      {{ tableRowCount === 1 ? (T.common.count_results.one | translate: { count: tableRowCount }) : (T.common.count_results.many | translate: { count: tableRowCount }) }}
    </div>

    <div class="responsive-table-search-input">
      <input
        #input
        type="text"
        [placeholder]="T.common.search | translate"
        (input)="onSearch(input.value)"
        [style.padding-right]="input.value ? '38px' : ''"
      />

      <div *ngIf="input.value" class="responsive-table-search-input-clear" (click)="onClear(input)">
        <div class="responsive-flex-wrapper">
          <i class="material-symbols-outlined">close</i>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!mobile">
    <div class="responsive-table" (scroll)="onTableScroll($event)">
      <div #fixedTableHeaders class="responsive-table-fixed-headers">
        <div
          class="responsive-table-header"
          [class.responsive-table-header-sorting]="tableHeaderEqualsCurrentlySortedHeader(tableHeader)"
          *ngFor="let tableHeader of tableHeaders; let i = index; trackBy: headersTrackByFn"
          (click)="tableHeaderIsSortable(tableHeader) ? sort(tableHeader, i) : undefined"
          [app-tooltip]="{ message: tableHeader.headerTooltipMessage }"
        >
          {{ tableHeader.title }}

          <div
            class="responsive-flex-wrapper"
            *ngIf="tableHeaderIsSortable(tableHeader) && tableHeaderEqualsCurrentlySortedHeader(tableHeader)"
          >
            <i class="material-symbols-outlined">{{
              !ascendingByTableHeaderIndex[i] ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
            }}</i>
          </div>
        </div>
        <div class="responsive-table-header" *ngIf="selectableObjects">
          <app-checkbox [isChecked]="allSelected" (checked)="onSelectUnselectAll($event)"></app-checkbox>
        </div>
      </div>

      <table *ngIf="!useInfinityScroll">
        <tr
          #trTableHeaders
          class="responsive-table-headers"
          [class.responsive-table-headers-selectable]="selectableObjects"
        >
          <th *ngIf="selectableObjects && checkBoxesOnTheLeft" class="checkbox-left">
            <app-checkbox [isChecked]="allSelected" (checked)="onSelectUnselectAll($event)"></app-checkbox>
          </th>

          <th
            *ngFor="let tableHeader of tableHeaders; let i = index; trackBy: headersTrackByFn"
            (click)="tableHeaderIsSortable(tableHeader) ? sort(tableHeader, i) : onTableHeaderClick($event)"
            ngDropdown
            [closeUponSelection]="true"
            [templateRef]="tableHeader.headerTemplate"
            [app-tooltip]="{ message: tableHeader.headerTooltipMessage }"
            [class.header-not-clickable]="!tableHeaderIsSortable(tableHeader)"
            [ngStyle]="tableHeader.style"
          >
            <div
              class="responsive-table-header"
              [class.responsive-table-header-sorting]="tableHeaderEqualsCurrentlySortedHeader(tableHeader)"
              [id]="tableHeader.emitClick ? tableHeader.property : null"
            >
              {{ tableHeader.title }}

              <div *ngIf="tableHeader.titleIcon" class="responsive-flex-wrapper">
                <i class="material-symbols-outlined ms-1">{{ tableHeader.titleIcon }}</i>
              </div>

              <i *ngIf="tableHeader.isFilterApplied" class="material-symbols-outlined filter-icon">filter_alt</i>

              <div
                class="responsive-flex-wrapper"
                *ngIf="
                  tableHeaderIsSortable(tableHeader) && tableHeaderEqualsCurrentlySortedHeader(tableHeader)
                "
              >
                <i class="material-symbols-outlined">{{
                  !ascendingByTableHeaderIndex[i] ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
                }}</i>
              </div>
            </div>
          </th>

          <th *ngIf="selectableObjects && !checkBoxesOnTheLeft">
            <app-checkbox [isChecked]="allSelected" (checked)="onSelectUnselectAll($event)"></app-checkbox>
          </th>
        </tr>

        <tr
          *ngFor="let object of getPaginatedObjects(); trackBy: rowsTrackByFn"
          [ngClass]="{'is-not-seen': isAPublicIncidentReport && !object?.isSeen, 'is-seen': isAPublicIncidentReport && object?.isSeen}"
          (click)="wholeRowSelection ? onSelectUnselectSingle(object) : onObjectClicked(object)"
        >
          <td *ngIf="selectableObjects && checkBoxesOnTheLeft" class="checkbox-left">
            <app-checkbox
              [isChecked]="objectIsSelected(object)"
              (checked)="onSelectUnselectSingle(object)"
              (click)="$event.stopPropagation()"
            ></app-checkbox>
          </td>

          <ng-container *ngFor="let tableHeader of tableHeaders; trackBy: headersTrackByFn">
            <ng-container *ngIf="tableHeader.dataType !== dataType.Template">
              <td [innerHTML]="getTableValue(tableHeader, object) | safeHtml" [ngStyle]="tableHeader.style"></td>
            </ng-container>
            <ng-container *ngIf="tableHeader.dataType === dataType.Template">
              <td [ngStyle]="tableHeader.style">
                <ng-template
                  [ngTemplateOutlet]="tableHeader.template"
                  [ngTemplateOutletContext]="{ object: object }"
                ></ng-template>
              </td>
            </ng-container>
          </ng-container>

          <td *ngIf="selectableObjects && !checkBoxesOnTheLeft">
            <app-checkbox
              [isChecked]="objectIsSelected(object)"
              (checked)="onSelectUnselectSingle(object)"
              (click)="$event.stopPropagation()"
            ></app-checkbox>
          </td>
        </tr>
      </table>

      <cdk-virtual-scroll-viewport
        class="custom-scrollbar"
        #virtualSscrollViewport
        *ngIf="useInfinityScroll"
        [itemSize]="52"
      >
        <table>
          <tr
            #trTableHeaders
            class="responsive-table-headers"
            [class.responsive-table-headers-selectable]="selectableObjects"
          >
            <th
              *ngFor="let tableHeader of tableHeaders; let i = index; trackBy: headersTrackByFn"
              (click)="tableHeaderIsSortable(tableHeader) ? sort(tableHeader, i) : undefined"
              ngDropdown
              [closeUponSelection]="true"
              [templateRef]="tableHeader.headerTemplate"
              [app-tooltip]="{ message: tableHeader.headerTooltipMessage }"
            >
              <div
                class="responsive-table-header"
                [class.responsive-table-header-sorting]="tableHeaderEqualsCurrentlySortedHeader(tableHeader)"
              >
                {{ tableHeader.title }}
                <i *ngIf="tableHeader.isFilterApplied" class="material-symbols-outlined filter-icon">filter_alt</i>
                <div
                  class="responsive-flex-wrapper"
                  *ngIf="
                    tableHeaderIsSortable(tableHeader) && tableHeaderEqualsCurrentlySortedHeader(tableHeader)
                  "
                >
                  <i class="material-symbols-outlined">{{
                    !ascendingByTableHeaderIndex[i] ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
                  }}</i>
                </div>
              </div>
            </th>
            <th *ngIf="selectableObjects">
              <app-checkbox [isChecked]="allSelected" (checked)="onSelectUnselectAll($event)"></app-checkbox>
            </th>
          </tr>
          <tr
            *cdkVirtualFor="let object of filteredObjects"
            (click)="wholeRowSelection ? onSelectUnselectSingle(object) : onObjectClicked(object)"
          >
            <ng-container *ngFor="let tableHeader of tableHeaders; trackBy: headersTrackByFn">
              <ng-container *ngIf="tableHeader.dataType !== dataType.Template">
                <td [innerHTML]="getTableValue(tableHeader, object) | safeHtml" [ngStyle]="tableHeader.style"></td>
              </ng-container>
              <ng-container *ngIf="tableHeader.dataType === dataType.Template">
                <td [ngStyle]="tableHeader.style">
                  <ng-template
                    [ngTemplateOutlet]="tableHeader.template"
                    [ngTemplateOutletContext]="{ object: object }"
                  ></ng-template>
                </td>
              </ng-container>
            </ng-container>

            <td *ngIf="selectableObjects">
              <app-checkbox
                [isChecked]="objectIsSelected(object)"
                (checked)="onSelectUnselectSingle(object)"
                (click)="$event.stopPropagation()"
              ></app-checkbox>
            </td>
          </tr>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>

  <ng-container *ngIf="mobile">
    <swiper (sliderMove)="onSliderMove()">
      <div
        class="responsive-table-mobile"
        *ngFor="let tableHeader of tableHeaders; let i = index; trackBy: headersTrackByFn"
      >
        <div
          #mobileHeader
          class="responsive-table-mobile-row responsive-table-mobile-row-header"
          [class.responsive-table-mobile-row-header-sorting]="tableHeaderEqualsCurrentlySortedHeader(tableHeader)"
          [style.padding-right]="'calc(10px + ' + paddingRight + 'px)'"
          (click)="tableHeaderIsSortable(tableHeader) ? sort(tableHeader, i) : undefined"
          ngDropdown
          [closeUponSelection]="true"
          [templateRef]="tableHeader.headerTemplate"
        >
          <div class="responsive-table-mobile-row-content">
            {{ tableHeader.title }}
            <i *ngIf="tableHeader.isFilterApplied" class="material-symbols-outlined filter-icon">filter_alt</i>
            <div
              class="responsive-flex-wrapper"
              *ngIf="tableHeaderIsSortable(tableHeader) && tableHeaderEqualsCurrentlySortedHeader(tableHeader)"
            >
              <i class="material-symbols-outlined">{{
                !ascendingByTableHeaderIndex[i] ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
              }}</i>
            </div>
          </div>
          <div class="responsive-table-mobile-row-checkbox" *ngIf="selectableObjects">
            <app-checkbox
              [isChecked]="allSelected"
              (checked)="onSelectUnselectAll($event)"
              (click)="$event.stopPropagation()"
            ></app-checkbox>
          </div>
        </div>

        <div #mobileRowsContainer class="responsive-table-mobile-rows">
          <div
            #mobileRow
            class="responsive-table-mobile-row responsive-table-mobile-row-colored"
            *ngFor="let object of getPaginatedObjects(); trackBy: rowsTrackByFn"
            (click)="wholeRowSelection ? onMobileObjectRowClick($event, object) : onObjectClicked(object)"
          >
            <ng-container *ngIf="tableHeader.dataType !== dataType.Template">
              <div
                class="responsive-table-mobile-row-content"
                [innerHTML]="getTableValue(tableHeader, object) | safeHtml"
                [ngStyle]="tableHeader.style"
              ></div>
            </ng-container>
            <ng-container *ngIf="tableHeader.dataType === dataType.Template">
              <div
                class="responsive-table-mobile-row-content responsive-table-mobile-row-content-template"
                [ngStyle]="tableHeader.style"
              >
                <ng-template
                  [ngTemplateOutlet]="tableHeader.template"
                  [ngTemplateOutletContext]="{ object: object }"
                ></ng-template>
              </div>
            </ng-container>

            <div class="responsive-table-mobile-row-checkbox" *ngIf="selectableObjects">
              <app-checkbox
                [isChecked]="objectIsSelected(object)"
                (checked)="onSelectUnselectSingle(object)"
                (click)="$event.stopPropagation()"
              ></app-checkbox>
            </div>
          </div>
        </div>
      </div>
    </swiper>
  </ng-container>

  <app-pagination
    *ngIf="!useInfinityScroll && totalPages > 1"
    [paginationId]="paginationId"
  ></app-pagination>
</div>
