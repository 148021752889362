import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';
import { Account } from 'src/app/modules/shared/models/account';
import { Employee } from 'src/app/modules/shared/models/employee';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ValidationUtilities } from 'src/app/modules/shared/utilities/validation.utilities';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { IncidentsManager } from 'src/app/modules/shared/managers/incidents.manager';
import { IncidentDetailsItemViewModel } from 'src/app/modules/incidents/viewModels/incidentDetailsItemViewModel';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { PrivacyStatuses } from 'src/app/modules/shared/enums/privacyStatuses';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { ZoneViewModel } from 'src/app/modules/shared/viewModels/zoneViewModel';
import { FilterToViewModelUtilityService } from 'src/app/modules/shared/services/utilities/filterToViewModelUtilityService';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { FixedFilters } from 'src/app/modules/shared/enums/fixedFilters.enum';
import { LocationService } from 'src/app/modules/shared/services/location.service';
import { LocationUtilities } from 'src/app/modules/shared/utilities/location.utilities';
import { IncidentLogsService } from 'src/app/modules/shared/services/incident-logs.service';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { EnumUtilities } from 'src/app/modules/shared/utilities/enum.utilities';
import * as moment from 'moment';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { Subject } from 'rxjs';

@Component({
  selector: 'incident-log-add-modal',
  templateUrl: './incident-log-add-modal.component.html',
  styleUrls: ['./incident-log-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class IncidentLogAddModal implements OnInit {
  @ViewChild('header') protected readonly wrapper: ElementRef<HTMLElement>;
  @Output() closed = new EventEmitter();

  // added for PIR linking & event association
  public itemCreated: Subject<{item: IncidentDetailsItemViewModel, objectType: ObjectTypes}> = new Subject();
  public isCalledFromPIR: boolean = false;
  public copiedEventFilter: FilterViewModel;
  protected copyEventFromPIR: boolean;

  public currentAccount: Account;
  public currentEmployee: Employee;
  public incidentLog: IncidentDetailsItemViewModel = new IncidentDetailsItemViewModel();
  public filterTypes = FilterTypes;
  public filters: FilterViewModel[] = [];
  public loading: boolean = false;
  public showPickers = false;
  public objectType = ObjectTypes.IncidentItem;
  public editableFieldTypes = EditableFieldTypes;
  public localisedOwners: string;
  public localisedDepartments: string;
  public privacyStatuses: { key: PrivacyStatuses; value: string }[] = EnumUtilities.items(PrivacyStatuses);
  public privacyStatus: { key: PrivacyStatuses; value: string };
  public showDetails = false;
  public setDefaultOwner: boolean = true;
  public setDefaultDepartment: boolean = true;
  public quickAddTitle: string = '';
  private allowedFilters: FilterViewModel[];
  public localisedZones: string;
  public localisedAreas: string;
  public selectedZone: FilterViewModel;
  public areaFilters: FilterViewModel[] = [];
  public applicableAreaFilters: FilterViewModel[] = [];
  public zonesViewModels: ZoneViewModel[] = [];
  public areasViewModels: AreaViewModel[] = [];
  public mapZones: ZoneViewModel[] = [];
  public mapAreas: AreaViewModel[] = [];
  public logForm: UntypedFormGroup;
  public initialSelectedZone: FilterViewModel;
  public markerType = MarkerType.LOG;
  public readonly T = T;
  public localisedLog = 'Log';
  public readonly objectTypes = ObjectTypes;
  private zones: ZoneViewModel[] = [];

  protected preselectedCategories: FilterViewModel[];

  constructor(
    private readonly authenticationService: AuthenticationService,
    public readonly bsModalRef: BsModalRef,
    protected readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly incidentLogsService: IncidentLogsService,
    private readonly timezoneService: TimeZoneService,
    private readonly incidentsManager: IncidentsManager,
    private readonly cachingService: CachingService,
    private readonly localisationService: LocalisationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly filterToViewModelUtilityService: FilterToViewModelUtilityService,
    private readonly locationService: LocationService,
    private fb: UntypedFormBuilder,
    private readonly translateService: TranslateService,
    private readonly alertService: AlertService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit() {
    this.loading = true;
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.currentAccount = this.authenticationService.getCurrentAccount();
    this.closed.emit();

    this.copyEventFromPIR = this.isCalledFromPIR;

    if(this.isCalledFromPIR && this.copiedEventFilter) {
      this.filters.push(this.copiedEventFilter);
      this.incidentLog.eventId = +this.copiedEventFilter.filterValue;
    }

    this.locationService.getZones().subscribe((res) => {
      this.zones = res;
      this.setLiveFilters();
      this.localiseItems();
      this.initAllowedFilters();
      this.setZonesAndAreas();

      this.loading = false;
      this.changeDetectorRef.detectChanges();
    });

    const tempDate = new Date().toJSON();
    this.incidentLog.startTime = JSON.parse(JSON.stringify(tempDate)) as string;
    this.incidentLog.endTime = JSON.parse(JSON.stringify(tempDate)) as string;
    this.incidentLog.accountId = this.currentEmployee.accountId;
    this.privacyStatus = this.privacyStatuses.find((x) => x.key === PrivacyStatuses.Open);
    this.areaFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Area);

    this.logForm = this.fb.group({
      title: [
        this.quickAddTitle.length ? this.quickAddTitle : '',
        { validators: [Validators.required, this.wtValidators.title(3, 250), this.wtValidators.restrictedChars([';', '<', '>', '!'])] },
      ],
    });



    if(this.preselectedCategories && this.preselectedCategories.length > 0) {
      this.filters = [...this.filters, ...this.preselectedCategories];
    }
  }

  setLiveFilters() {
    const preselectedPIREventFilter = this.isCalledFromPIR && this.copiedEventFilter;

    if (this.currentAccount.fixedFilters === FixedFilters.Event && !preselectedPIREventFilter) {
      this.incidentLog.eventId = +this.cachingService.selectedEventID;
      this.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Event, this.cachingService.selectedEventID, '', undefined));
    } else if (this.currentAccount.fixedFilters === FixedFilters.VenueZone && !preselectedPIREventFilter) {
      this.initialSelectedZone = FilterUtilities.GenerateFilter(
        FilterTypes.Zone,
        this.cachingService.selectedZoneID,
        '',
        undefined
      );
      this.filters.push(this.initialSelectedZone);
    }
  }

  get valid(): boolean {
    let isEndTimeValid = false;
    if (!this.incidentLog.startTime || !this.incidentLog.endTime) {
      isEndTimeValid = true;
    } else {
      isEndTimeValid =
        this.incidentLog.startTime === this.incidentLog.endTime || this.incidentLog.endTime > this.incidentLog.startTime;
    }
    return ValidationUtilities.validateAgainstMinLength(this.incidentLog.title, 3) && isEndTimeValid;
  }

  get localisedEndDateShort(): string {
    if (this.incidentLog && this.incidentLog.endTime) {
      return this.timezoneService.localiseDateISOString(this.incidentLog.endTime, false, false);
    }
    return '';
  }

  get severityColor(): string {
    return this.incidentsManager.getColorBySeverity(this.incidentLog.severity);
  }

  get localisedStartDateShort(): string {
    if (this.incidentLog && this.incidentLog.startTime) {
      return this.timezoneService.localiseDateISOString(this.incidentLog.startTime, false, false);
    }
    return '';
  }

  setInitialZonePin() {
    this.incidentLog = LocationUtilities.setLocationDetails(this.mapZones, this.incidentLog) as IncidentDetailsItemViewModel;

    this.changeDetectorRef.detectChanges();
  }

  onAddIncidentLog() {
    this.loading = true;

    if (this.logForm.valid) {
      this.incidentLog.title = this.logForm.controls.title.value.trim();
    }

    this.incidentLog.accountId = this.currentEmployee.accountId;
    this.incidentLog.incidentItemType = IncidentItemTypes.Log;
    this.incidentLog.incidentChannelId = this.incidentChannelId;
    this.incidentLog.filters = this.filters;
    this.incidentLog.startTime = moment().toISOString();

    if (!this.incidentLog.filters) {
      this.incidentLog.filters = [];
    }

    this.incidentLog.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Title, this.incidentLog.title));
    this.incidentLog.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Item_Type, IncidentItemTypes.Log));
    this.incidentLog.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Incident_Channel, this.incidentChannelId));
    this.incidentLog.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Event, this.incidentLog.eventId));

    this.incidentLog.filters.push(
      FilterUtilities.GenerateFilter(FilterTypes.Date, moment().toString(), '', FilterDateOptions.Start_Date)
    );
    if (!this.showPickers) {
      this.incidentLog.startTime = undefined;
      this.incidentLog.endTime = undefined;
    }

    // When creating log if start time is not set, we set it to current time
    if(!this.incidentLog.startTime){
      this.incidentLog.startTime = moment().toISOString();
    }

    if(this.isCalledFromPIR && !this.copyEventFromPIR && this.copiedEventFilter) {
      const filtersWithoutEventFilter = this.incidentLog.filters.filter(f => f.filterType !== this.filterTypes.Event);
      this.incidentLog.filters = filtersWithoutEventFilter;
      this.incidentLog.eventId = null;
    }

    this.incidentLogsService.add(this.incidentLog).subscribe((res) => {
      void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedLog }));
      this.itemCreated.next({item: res.returnModel as IncidentDetailsItemViewModel, objectType: this.objectType});
      this.closed.emit();
      this.loading = false;
      this.bsModalRef.hide();
    });
  }

  handleFilterChange(filters: FilterViewModel[]) {
    this.filters = filters.slice();
    this.selectedZone = filters.find((f) => f.filterType === FilterTypes.Zone);
    this.setZonesAndAreas();

    this.changeDetectorRef.detectChanges();
  }

  onStartDateChanged(dateAsObj: string) {
    this.incidentLog.startTime = dateAsObj;
  }

  onEndDateChanged(dateAsObj: string) {
    this.incidentLog.endTime = dateAsObj;
  }

  onShowPickersChanged(state: boolean) {
    if (!state) {
      this.incidentLog.endTime = undefined;
      this.incidentLog.startTime = undefined;
    }
    this.showPickers = state;
  }

  get incidentChannelId() {
    return this.incidentsManager.getIncidentChannelId();
  }

  get filteredStatuses(): { key: PrivacyStatuses; value: string }[] {
    return this.privacyStatuses
      .filter((s) => s.key !== PrivacyStatuses.Open)
      .map((s) => {
        return {
          key: s.key,
          value: this.localisationService.localiseFilterValueByFilterType(s.key, FilterTypes.Privacy_Status),
        };
      });
  }

  updateDescription(description: string) {
    this.incidentLog.description = description;
    const filter = this.incidentLog.filters.find((f) => f.filterType === FilterTypes.Description);

    if (filter) {
      filter.filterValue = description;
    }
  }

  onPrivacyStatusSelected(privacyStatus: { key: PrivacyStatuses; value: string }) {
    this.privacyStatus = privacyStatus;
    this.incidentLog.privacyStatus = privacyStatus.key;
  }

  toggleDetailsState() {
    this.showDetails = !this.showDetails;
  }

  setAccountForCurrentOwner() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        s => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.currentEmployee.id.toString()
      );
      if (empFilter !== null && this.filters.indexOf(empFilter) < 0) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }
  }

  setMyDepartment() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (f) =>
          f.filterType === FilterTypes.Department && f.filterValue.toString() === this.currentEmployee.departmentId.toString()
      );
      if (empFilter && this.filters.indexOf(empFilter) < 0) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }
  }

  initAllowedFilters() {
    // Load them on ngOnInit (in the beggining) so when the client click on "Assign Me" the assign of the filter will be instant;
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
    if (this.setDefaultDepartment) {
      this.setMyDepartment();
    }

    if (this.setDefaultOwner) {
      this.setAccountForCurrentOwner();
    }
  }

  closeModal() {
    this.closed.emit();
    this.bsModalRef.hide();
  }

  setZonesAndAreas() {
    this.mapZones = this.filterToViewModelUtilityService.getZoneViewModelsFromFilters(this.filters, this.zones);
    this.mapAreas = this.filterToViewModelUtilityService.getAreaViewModelsFromFilters(this.filters);

    if (
      !this.incidentLog.latitude &&
      !this.incidentLog.longitude &&
      this.filters.some((x) => x.filterType === FilterTypes.Zone) &&
      this.mapZones.length
    ) {
      this.setInitialZonePin();
    }

    this.setApplicableFilters();
    this.changeDetectorRef.markForCheck();
  }

  private setApplicableFilters() {
    if (this.mapZones && this.mapZones.length) {
      const areas: FilterViewModel[] = [];
      this.mapZones.forEach((zone) => {
        const zoneAreas = this.areaFilters.filter((a) => +a.relatedObjectId === zone.id);

        if (zoneAreas) {
          areas.push(...zoneAreas);
        }
      });
      this.applicableAreaFilters = areas.slice();

      this.filters.forEach((filter) => {
        if (filter.filterType === FilterTypes.Area) {
          if (!this.mapZones.some((zone) => zone.id === filter.relatedObjectId)) {
            this.filters = this.filters.filter((x) => x.filterValue != filter.filterValue);
          }
        }
      });
    } else {
      this.mapAreas = [];
      this.applicableAreaFilters = [];
      this.filters = this.filters.filter((x) => x.filterType !== FilterTypes.Area);
    }
  }

  onIncidentLocationUpdated(location: LocationViewModel) {
    this.incidentLog.locationDetails = location.locationDetails;
    this.incidentLog.latitude = location.latitude;
    this.incidentLog.longitude = location.longitude;
  }

  private localiseItems() {
    this.localisedLog = this.translateService.instant(T.defaultLocalizations.log.one);
    this.localisedZones = this.localisationService.localiseObjectType(ObjectTypes.Zone, true);
    this.localisedAreas = this.localisationService.localiseObjectType(ObjectTypes.Area, true);
    this.localisedOwners = this.localisationService.localiseFilterType(FilterTypes.Owner, true);
    this.localisedDepartments = this.localisationService.localiseFilterType(FilterTypes.Department, true);
  }
}
