import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Configuration } from "src/app/app.constants";
import { ObjectTypes } from "../enums/objectTypes";
import { ColumnFilterViewModel } from "../viewModels/columnFilterViewModel";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ColumnFilterService {
  private actionUrl: string = '';

  constructor(
    private http: HttpClient,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`ColumnFilter/`);
  }

  getColumnFilter(objectType: ObjectTypes): Observable<{ defaultFilters: ColumnFilterViewModel[], employeeFilters: ColumnFilterViewModel[] }> {
    return this.http.get<{ defaultFilters: ColumnFilterViewModel[], employeeFilters: ColumnFilterViewModel[] }>(`${this.actionUrl}${objectType}`);
  }

  saveColumnFilter(objectType: ObjectTypes, columnFilters: ColumnFilterViewModel[]): Observable<ColumnFilterViewModel[]> {
    return this.http.post<null>(`${this.actionUrl}${objectType}`, columnFilters);
  }
}
