<ng-container *forScreen="'desktop'">
  <app-document-manager-general-settings-desktop
    [employeeIsAdmin]="employeeIsAdmin"
    [filterType]="filterType"
    [handleUpdatesInternally]="handleUpdatesInternally"
    [isModal]="isModal"
    [object]="object"
    [objectFilters]="objectFilters"
    [objectId]="objectId"
    [objectType]="objectType"
    [isInAccordion]="isInAccordion"
    [isDetailsPage]="isDetailsPage"
    [showListBoardButtons]="showListBoardButtons"
    [showSharedResources]="showSharedResources"
    [showCategoryDropdowns]="showCategoryDropdowns"
    (documentChanged)="documentChanged.emit($event)"
    (objectClicked)="objectClicked.emit($event)"
    (objectsSelected)="objectsSelected.emit($event)"
    (documentsChanged)="documentsChanged.emit($event)"
    (uploadChanged)="uploadChanged.emit($event)"
    (uploadDeleted)="uploadDeleted.emit($event)"
    (uploadViewModelsChanged)="uploadViewModelsChanged.emit($event)"
  ></app-document-manager-general-settings-desktop>
</ng-container>
<ng-container *forScreen="'mobile'">
  <app-document-manager-general-settings-mobile
    [employeeIsAdmin]="employeeIsAdmin"
    [filterType]="filterType"
    [handleUpdatesInternally]="handleUpdatesInternally"
    [isModal]="isModal"
    [object]="object"
    [objectFilters]="objectFilters"
    [objectId]="objectId"
    [objectType]="objectType"
    [isDetailsPage]="isDetailsPage"
    [isInAccordion]="isInAccordion"
    [showSharedResources]="showSharedResources"
    [showCategoryDropdowns]="showCategoryDropdowns"
    (documentChanged)="documentChanged.emit($event)"
    (objectClicked)="objectClicked.emit($event)"
    (objectsSelected)="objectsSelected.emit($event)"
    (documentsChanged)="documentsChanged.emit($event)"
    (uploadChanged)="uploadChanged.emit($event)"
    (uploadDeleted)="uploadDeleted.emit($event)"
    (uploadViewModelsChanged)="uploadViewModelsChanged.emit($event)"
  ></app-document-manager-general-settings-mobile>
</ng-container>
