import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { ModifiableEntityViewModel } from 'src/app/modules/shared/viewModels/bases/modifiableEntityViewModel';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';

@Component({
  selector: 'app-document-manager-general-settings',
  templateUrl: './document-manager-general-settings.component.html',
  styleUrls: ['./document-manager-general-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      state('*', style({ 'overflow-y': 'hidden' })),
      state('void', style({ 'overflow-y': 'hidden' })),
      transition('* => void', [style({ height: '*' }), animate('250ms ease-out', style({ height: 0 }))]),
      transition('void => *', [style({ height: 0 }), animate('250ms ease-in', style({ height: '*' }))]),
    ]),
  ],
})
export class DocumentManagerGeneralSettingsComponent {
  @Input() handleUpdatesInternally: boolean = true;
  @Input() objectId: number;
  @Input() objectType: ObjectTypes;
  @Input() objectFilters: FilterViewModel[] = [];
  @Input() object: ModifiableEntityViewModel;
  @Input() filterType: FilterTypes;
  @Input() isModal: boolean = false;
  @Input() employeeIsAdmin: boolean;
  @Input() isDetailsPage: boolean = false;
  /**
   * Whether the component is inside an accordion component or not
   */
  @Input() isInAccordion: boolean = false;
  @Input() showSharedResources: boolean = false;
  /**
   * Whether to show the buttons for list and board view
   */
  @Input() showListBoardButtons: boolean = true;
  @Input() showCategoryDropdowns: boolean = true;

  @Output() uploadViewModelsChanged: EventEmitter<UploadViewModel[]> = new EventEmitter<UploadViewModel[]>();
  @Output() uploadChanged: EventEmitter<UploadViewModel> = new EventEmitter<UploadViewModel>();
  @Output() uploadDeleted: EventEmitter<UploadViewModel> = new EventEmitter<UploadViewModel>();
  @Output() documentsChanged = new EventEmitter<unknown>();
  @Output() objectClicked = new EventEmitter<object> ();
  @Output() objectsSelected = new EventEmitter<object[]>();
  @Output() documentChanged = new EventEmitter<unknown>();
}
