import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Configuration } from "src/app/app.constants";
import { SuggestedRiskAction, SuggestedRiskViewModel } from "../models/SuggestedRiskViewModel";

export type SuggestedRiskPayload = {
  venueTypeIds?: number[];
  venueTypes?: string[];
  eventTypeIds?: number[];
  eventTypes?: string[];
  riskImpactTypeIds?: number[];
  riskImpactTypes?: string[];
  keywords?: string[];
  searchTerm?: string,
  impactRange?: any;
  likelihoodRange?: any;
  limit?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SuggestedRisksService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`Library/GlobalRisk/`);
  }

  getList(filters: any): Observable<SuggestedRiskViewModel[]> {
    return this.http.post<SuggestedRiskViewModel[]>(`${this.actionUrl}list`, filters);
  }

  getRiskActionsList(filters: any): Observable<SuggestedRiskAction[]> {
    return this.http.post<SuggestedRiskAction[]>(`${this.actionUrl}controls/list`, filters);
  }

  getCategoryFilter(filters: any): Observable<any> {
    return this.http.post(`${this.actionUrl}categories`, filters);
  }
}
