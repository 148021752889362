export const T = {
  search: {
    placeholder_for_objectType: "search.placeholder_for_objectType",
    placeholder_without_objectType: "search.placeholder_without_objectType"
  },
  support: {
    support: "support.support",
    help_articles: "support.help_articles",
    email_our_team: "support.email_our_team",
    chat_to_our_team: "support.chat_to_our_team"
  },
  settings: {
    settings: "settings.settings",
    power_bi: {
      power_bi_feed: {
        one: "settings.power_bi.power_bi_feed.one",
        many: "settings.power_bi.power_bi_feed.many"
      },
      risks_and_actions: "settings.power_bi.risks_and_actions",
      incident_checklist_items_feed: "settings.power_bi.incident_checklist_items_feed",
      jobs_checklist_items_feed: "settings.power_bi.jobs_checklist_items_feed",
      indicator_update_feed: "settings.power_bi.indicator_update_feed",
      unknown: "settings.power_bi.unknown",
      never: "settings.power_bi.never",
      add: "settings.power_bi.add",
      edit: "settings.power_bi.edit",
      edit_profile: "settings.power_bi.edit_profile",
      deleted: "settings.power_bi.deleted",
      last_used: "settings.power_bi.last_used",
      object_type: {
        one: "settings.power_bi.object_type.one",
        many: "settings.power_bi.object_type.many"
      },
      this_item: {
        one: "settings.power_bi.this_item.one",
        many: "settings.power_bi.this_item.many"
      },
      credential: {
        one: "settings.power_bi.credential.one",
        many: "settings.power_bi.credential.many"
      }
    },
    my_preferences: {
      my_preferences_text: "settings.my_preferences.my_preferences_text",
      profile_and_notification_settings_text: "settings.my_preferences.profile_and_notification_settings_text",
      profile: {
        trasnlation_beta_helper_text: "settings.my_preferences.profile.trasnlation_beta_helper_text",
        profile_text: "settings.my_preferences.profile.profile_text",
        name: "settings.my_preferences.profile.name",
        role: "settings.my_preferences.profile.role",
        email: "settings.my_preferences.profile.email",
        profile_photo: "settings.my_preferences.profile.profile_photo",
        mobile_phone: "settings.my_preferences.profile.mobile_phone",
        language: "settings.my_preferences.profile.language",
        choose_language: "settings.my_preferences.profile.choose_language",
        invalid_phone_number: "settings.my_preferences.profile.invalid_phone_number"
      },
      password: {
        password_text: "settings.my_preferences.password.password_text",
        last_login: "settings.my_preferences.password.last_login",
        change_password: "settings.my_preferences.password.change_password"
      },
      notifications: {
        notifications_text: "settings.my_preferences.notifications.notifications_text",
        send_my_personal_summary_email: "settings.my_preferences.notifications.send_my_personal_summary_email",
        notify_me_about_planning_module_updates: "settings.my_preferences.notifications.notify_me_about_planning_module_updates",
        notify_me_via_sms: "settings.my_preferences.notifications.notify_me_via_sms",
        notify_me_public_incident_reports: "settings.my_preferences.notifications.notify_me_public_incident_reports",
        are_you_sure_automatic_rag_updates: "settings.my_preferences.notifications.are_you_sure_automatic_rag_updates",
        notification_setttings_updated: "settings.my_preferences.notifications.notification_setttings_updated",
        notify_me_about_control_module_updates: "settings.my_preferences.notifications.notify_me_about_control_module_updates",
        notify_me_about_readiness_module_updates: "settings.my_preferences.notifications.notify_me_about_readiness_module_updates",
        send_my_personal_readiness_summary_email: "settings.my_preferences.notifications.send_my_personal_readiness_summary_email",
        send_my_personal_sustainability_summary_email: "settings.my_preferences.notifications.send_my_personal_sustainability_summary_email"
      },
      permissions: {
        permissions_text: "settings.my_preferences.permissions.permissions_text",
        your_role_is: "settings.my_preferences.permissions.your_role_is",
        employee_roles: {
          objective_manager: "settings.my_preferences.permissions.employee_roles.objective_manager",
          risk_manager: "settings.my_preferences.permissions.employee_roles.risk_manager",
          employee_manager: "settings.my_preferences.permissions.employee_roles.employee_manager",
          activity_manager: "settings.my_preferences.permissions.employee_roles.activity_manager",
          sees_confidential_risks: "settings.my_preferences.permissions.employee_roles.sees_confidential_risks",
          gantt_editor: "settings.my_preferences.permissions.employee_roles.gantt_editor",
          finance_manager: "settings.my_preferences.permissions.employee_roles.finance_manager",
          can_see_confidential_incidents: "settings.my_preferences.permissions.employee_roles.can_see_confidential_incidents",
          incident_management_user: "settings.my_preferences.permissions.employee_roles.incident_management_user",
          main_operations_center_user: "settings.my_preferences.permissions.employee_roles.main_operations_center_user",
          venue_manager: "settings.my_preferences.permissions.employee_roles.venue_manager",
          write_admin: "settings.my_preferences.permissions.employee_roles.write_admin",
          readiness_user: "settings.my_preferences.permissions.employee_roles.readiness_user",
          incidents_user: "settings.my_preferences.permissions.employee_roles.incidents_user",
          runsheets_user: "settings.my_preferences.permissions.employee_roles.runsheets_user",
          peaps_controller: "settings.my_preferences.permissions.employee_roles.peaps_controller",
          planning_user: "settings.my_preferences.permissions.employee_roles.planning_user",
          general_user: "settings.my_preferences.permissions.employee_roles.general_user",
          account_admin: "settings.my_preferences.permissions.employee_roles.account_admin",
          user_admin: "settings.my_preferences.permissions.employee_roles.user_admin",
          admin: "settings.my_preferences.permissions.employee_roles.admin",
          project_admin: "settings.my_preferences.permissions.employee_roles.project_admin",
          peaps_user: "settings.my_preferences.permissions.employee_roles.peaps_user",
          control_admin: "settings.my_preferences.permissions.employee_roles.control_admin",
          readiness_admin: "settings.my_preferences.permissions.employee_roles.readiness_admin",
          sustainability_user: "settings.my_preferences.permissions.employee_roles.sustainability_user",
          item_admin: "settings.my_preferences.permissions.employee_roles.item_admin",
          risks_user: "settings.my_preferences.permissions.employee_roles.risks_user"
        },
        general_user_role_information: "settings.my_preferences.permissions.general_user_role_information",
        account_admin_role_information: "settings.my_preferences.permissions.account_admin_role_information",
        user_admin_role_information: "settings.my_preferences.permissions.user_admin_role_information",
        admin_role_information: "settings.my_preferences.permissions.admin_role_information",
        the_modules_you_can_access_are: "settings.my_preferences.permissions.the_modules_you_can_access_are",
        planning_module_roles_and_permissions: "settings.my_preferences.permissions.planning_module_roles_and_permissions",
        control_module_roles_and_permissions: "settings.my_preferences.permissions.control_module_roles_and_permissions",
        readiness_module_roles_and_permissions: "settings.my_preferences.permissions.readiness_module_roles_and_permissions",
        roles: "settings.my_preferences.permissions.roles",
        project_admin_tooltip: "settings.my_preferences.permissions.project_admin_tooltip",
        localisedProject_admin_tooltip: "settings.my_preferences.permissions.localisedProject_admin_tooltip",
        gantt_editor_tooltip: "settings.my_preferences.permissions.gantt_editor_tooltip",
        risk_manager_tooltip: "settings.my_preferences.permissions.risk_manager_tooltip",
        can_see_confidential_incidents_tooltip: "settings.my_preferences.permissions.can_see_confidential_incidents_tooltip",
        control_admin_tooltip: "settings.my_preferences.permissions.control_admin_tooltip",
        peaps_controller_tooltip: "settings.my_preferences.permissions.peaps_controller_tooltip",
        can_see_items: "settings.my_preferences.permissions.can_see_items",
        can_edit_items: "settings.my_preferences.permissions.can_edit_items",
        total_days: "settings.my_preferences.permissions.total_days",
        days_remaining: "settings.my_preferences.permissions.days_remaining",
        all_projects_and_all_tasks_in_all_departments: "settings.my_preferences.permissions.all_projects_and_all_tasks_in_all_departments",
        projects_and_tasks_in_your_departments: "settings.my_preferences.permissions.projects_and_tasks_in_your_departments",
        confidential_projects: "settings.my_preferences.permissions.confidential_projects",
        all_confidential_projects: "settings.my_preferences.permissions.all_confidential_projects",
        confidential_projects_listed_below: "settings.my_preferences.permissions.confidential_projects_listed_below",
        risks_assigned_to_all_departments: "settings.my_preferences.permissions.risks_assigned_to_all_departments",
        risks_assigned_to_your_deparmtnets_only: "settings.my_preferences.permissions.risks_assigned_to_your_deparmtnets_only",
        risks_assigned_to_you: "settings.my_preferences.permissions.risks_assigned_to_you",
        incidents_and_logs_assigned_to_all_departments: "settings.my_preferences.permissions.incidents_and_logs_assigned_to_all_departments",
        incidents_and_logs_assigned_to_your_departments: "settings.my_preferences.permissions.incidents_and_logs_assigned_to_your_departments",
        incidents_and_logs_assigned_you: "settings.my_preferences.permissions.incidents_and_logs_assigned_you",
        can_edit_confidential: "settings.my_preferences.permissions.can_edit_confidential",
        capabilities_evals_lessons_and_actions_to_all_departments: "settings.my_preferences.permissions.capabilities_evals_lessons_and_actions_to_all_departments",
        capabilities_evals_lessons_and_actions_to_your_departments: "settings.my_preferences.permissions.capabilities_evals_lessons_and_actions_to_your_departments",
        capabilities_evals_lessons_and_actions_to_you: "settings.my_preferences.permissions.capabilities_evals_lessons_and_actions_to_you"
      },
      calendar_subscriptions: {
        calendar_subscriptions_text: "settings.my_preferences.calendar_subscriptions.calendar_subscriptions_text",
        my_tasks_and_subTasks: "settings.my_preferences.calendar_subscriptions.my_tasks_and_subTasks",
        my_risks_issues_and_opportunities: "settings.my_preferences.calendar_subscriptions.my_risks_issues_and_opportunities",
        my_actions_risks_and_issues: "settings.my_preferences.calendar_subscriptions.my_actions_risks_and_issues",
        confirm_remove_calendar_subscription: "settings.my_preferences.calendar_subscriptions.confirm_remove_calendar_subscription",
        explanation_text: "settings.my_preferences.calendar_subscriptions.explanation_text",
        helper_info_text: "settings.my_preferences.calendar_subscriptions.helper_info_text",
        risks_and_issues: "settings.my_preferences.calendar_subscriptions.risks_and_issues"
      }
    },
    document_manager: "settings.document_manager",
    logout: "settings.logout",
    account_and_configuration: {
      account_and_configuration_text: "settings.account_and_configuration.account_and_configuration_text",
      account_and_configuration_description: "settings.account_and_configuration.account_and_configuration_description",
      account: "settings.account_and_configuration.account",
      account_logo_and_background: {
        account_logo_and_background_text: "settings.account_and_configuration.account_logo_and_background.account_logo_and_background_text",
        logo_and_background: "settings.account_and_configuration.account_logo_and_background.logo_and_background",
        helper_text: "settings.account_and_configuration.account_logo_and_background.helper_text",
        logo: "settings.account_and_configuration.account_logo_and_background.logo",
        background: "settings.account_and_configuration.account_logo_and_background.background",
        account_details_updated: "settings.account_and_configuration.account_logo_and_background.account_details_updated"
      },
      time_and_date: {
        time_and_date_text: "settings.account_and_configuration.time_and_date.time_and_date_text",
        time_and_date_settings: "settings.account_and_configuration.time_and_date.time_and_date_settings",
        site_timezone: "settings.account_and_configuration.time_and_date.site_timezone",
        time_zones: "settings.account_and_configuration.time_and_date.time_zones",
        preferred_date_format: "settings.account_and_configuration.time_and_date.preferred_date_format",
        date_formats: "settings.account_and_configuration.time_and_date.date_formats",
        time_and_date_updated_successfully: "settings.account_and_configuration.time_and_date.time_and_date_updated_successfully"
      },
      language_customisation: {
        language_customisation_text: "settings.account_and_configuration.language_customisation.language_customisation_text",
        configure_system_terminology: "settings.account_and_configuration.language_customisation.configure_system_terminology",
        term: "settings.account_and_configuration.language_customisation.term",
        display_as: "settings.account_and_configuration.language_customisation.display_as",
        account_language_settings_updated: "settings.account_and_configuration.language_customisation.account_language_settings_updated"
      },
      general_settings: {
        general_settings_text: "settings.account_and_configuration.general_settings.general_settings_text",
        helper_text: "settings.account_and_configuration.general_settings.helper_text",
        weather_widgets: "settings.account_and_configuration.general_settings.weather_widgets",
        select_to_hide_in_all_dashboards: "settings.account_and_configuration.general_settings.select_to_hide_in_all_dashboards",
        hide_weather_widgets: "settings.account_and_configuration.general_settings.hide_weather_widgets",
        disable_account_notifications: "settings.account_and_configuration.general_settings.disable_account_notifications",
        account_notifications_helper_text: "settings.account_and_configuration.general_settings.account_notifications_helper_text",
        disable_notifications_for_whole_account: "settings.account_and_configuration.general_settings.disable_notifications_for_whole_account",
        onboarding_pdf: "settings.account_and_configuration.general_settings.onboarding_pdf",
        upload_pdf_for_users_to_download: "settings.account_and_configuration.general_settings.upload_pdf_for_users_to_download",
        replace_existing_guidelines_pdf: "settings.account_and_configuration.general_settings.replace_existing_guidelines_pdf",
        upload_pdf_help_file: "settings.account_and_configuration.general_settings.upload_pdf_help_file",
        account_details_updated: "settings.account_and_configuration.general_settings.account_details_updated",
        hide_weather_widget_updated: "settings.account_and_configuration.general_settings.hide_weather_widget_updated",
        whole_account_notification_updated: "settings.account_and_configuration.general_settings.whole_account_notification_updated"
      },
      security: {
        security_text: "settings.account_and_configuration.security.security_text",
        multi_factor_authentication: "settings.account_and_configuration.security.multi_factor_authentication",
        to_force_all_users_use_mfa: "settings.account_and_configuration.security.to_force_all_users_use_mfa",
        enforce_mfa_for_all_users: "settings.account_and_configuration.security.enforce_mfa_for_all_users"
      },
      custom_branding_settings: {
        custom_branding_settings_text: "settings.account_and_configuration.custom_branding_settings.custom_branding_settings_text",
        alternative_brand_logo: "settings.account_and_configuration.custom_branding_settings.alternative_brand_logo",
        alternative_brand_logo_white: "settings.account_and_configuration.custom_branding_settings.alternative_brand_logo_white",
        helper_text: "settings.account_and_configuration.custom_branding_settings.helper_text",
        helper_text_logo: "settings.account_and_configuration.custom_branding_settings.helper_text_logo",
        application_name: "settings.account_and_configuration.custom_branding_settings.application_name",
        application_name_placeholder: "settings.account_and_configuration.custom_branding_settings.application_name_placeholder",
        customr_urls_for_privacy_policy_and_terms_of_use: "settings.account_and_configuration.custom_branding_settings.customr_urls_for_privacy_policy_and_terms_of_use",
        use_your_organisation_privacy_policy: "settings.account_and_configuration.custom_branding_settings.use_your_organisation_privacy_policy",
        replace_wetrack_policy_entering_links: "settings.account_and_configuration.custom_branding_settings.replace_wetrack_policy_entering_links",
        privacy_policy_url: "settings.account_and_configuration.custom_branding_settings.privacy_policy_url",
        use_your_organisation_terms_of_use: "settings.account_and_configuration.custom_branding_settings.use_your_organisation_terms_of_use",
        replace_wetrack_terms_of_use_entering_links: "settings.account_and_configuration.custom_branding_settings.replace_wetrack_terms_of_use_entering_links",
        terms_of_use_url: "settings.account_and_configuration.custom_branding_settings.terms_of_use_url"
      }
    },
    employees_departments_org: {
      description_with_sustainability: "settings.employees_departments_org.description_with_sustainability",
      description_without_sustainability: "settings.employees_departments_org.description_without_sustainability",
      user_groups_helper_text: "settings.employees_departments_org.user_groups_helper_text",
      confirm_deleting_employee: "settings.employees_departments_org.confirm_deleting_employee",
      localisedEmployees_added_to_group_groupName: "settings.employees_departments_org.localisedEmployees_added_to_group_groupName",
      localisedEmployees_removed_from_group_groupName: "settings.employees_departments_org.localisedEmployees_removed_from_group_groupName",
      manage_employee: {
        general_details: "settings.employees_departments_org.manage_employee.general_details",
        active_user: "settings.employees_departments_org.manage_employee.active_user",
        username_to_be_use_to_sign_in: "settings.employees_departments_org.manage_employee.username_to_be_use_to_sign_in",
        department_helper_text: "settings.employees_departments_org.manage_employee.department_helper_text",
        admin_roles: "settings.employees_departments_org.manage_employee.admin_roles",
        account_admin_roles_information: "settings.employees_departments_org.manage_employee.account_admin_roles_information",
        general_role: "settings.employees_departments_org.manage_employee.general_role",
        general_role_information: "settings.employees_departments_org.manage_employee.general_role_information",
        module_permissions: "settings.employees_departments_org.manage_employee.module_permissions",
        save_user: "settings.employees_departments_org.manage_employee.save_user",
        save_and_send_invite: "settings.employees_departments_org.manage_employee.save_and_send_invite",
        add_localisedGroups_to_employee_with_names: "settings.employees_departments_org.manage_employee.add_localisedGroups_to_employee_with_names",
        can_access_planning_module: "settings.employees_departments_org.manage_employee.can_access_planning_module",
        can_access_control_module: "settings.employees_departments_org.manage_employee.can_access_control_module",
        can_access_jobs: "settings.employees_departments_org.manage_employee.can_access_jobs",
        can_access_readiness_module: "settings.employees_departments_org.manage_employee.can_access_readiness_module",
        can_access_run_sheets_module: "settings.employees_departments_org.manage_employee.can_access_run_sheets_module",
        can_access_sustainability_module: "settings.employees_departments_org.manage_employee.can_access_sustainability_module",
        localisedEmployee_can_see: "settings.employees_departments_org.manage_employee.localisedEmployee_can_see",
        localisedEmployee_can_edit: "settings.employees_departments_org.manage_employee.localisedEmployee_can_edit",
        localisedEmployee_can_see_and_edit: "settings.employees_departments_org.manage_employee.localisedEmployee_can_see_and_edit",
        just_owned_items: "settings.employees_departments_org.manage_employee.just_owned_items",
        just_assigned_items: "settings.employees_departments_org.manage_employee.just_assigned_items",
        localisedPEAPs_controller: "settings.employees_departments_org.manage_employee.localisedPEAPs_controller",
        localisedPEAPs_controller_tooltip: "settings.employees_departments_org.manage_employee.localisedPEAPs_controller_tooltip",
        confidential_incidents: "settings.employees_departments_org.manage_employee.confidential_incidents",
        confidentialJobs_access_tooltip: "settings.employees_departments_org.manage_employee.confidentialJobs_access_tooltip",
        readiness_admin_tooltip: "settings.employees_departments_org.manage_employee.readiness_admin_tooltip",
        can_access_risk_module: "settings.employees_departments_org.manage_employee.can_access_risk_module"
      },
      add_localisedEmployees_to_group_groupName: "settings.employees_departments_org.add_localisedEmployees_to_group_groupName",
      confirm_delete_department: {
        one: "settings.employees_departments_org.confirm_delete_department.one",
        many: "settings.employees_departments_org.confirm_delete_department.many"
      }
    },
    planning: {
      settings_language_share: "settings.planning.settings_language_share",
      settings_language_unshare: "settings.planning.settings_language_unshare",
      settings_milestone_share: "settings.planning.settings_milestone_share",
      settings_milestone_unshare: "settings.planning.settings_milestone_unshare",
      settings_language_modal: {
        share_title: "settings.planning.settings_language_modal.share_title",
        share_body: "settings.planning.settings_language_modal.share_body",
        unshare_title: "settings.planning.settings_language_modal.unshare_title",
        unshare_body: "settings.planning.settings_language_modal.unshare_body"
      },
      settings_milestone_modal: {
        share_title: "settings.planning.settings_milestone_modal.share_title",
        share_body: "settings.planning.settings_milestone_modal.share_body",
        unshare_title: "settings.planning.settings_milestone_modal.unshare_title",
        unshare_body: "settings.planning.settings_milestone_modal.unshare_body"
      },
      settings_description: "settings.planning.settings_description",
      auto_rag_updates: {
        automatic_rag_update_rules: "settings.planning.auto_rag_updates.automatic_rag_update_rules",
        amber_helper_text: "settings.planning.auto_rag_updates.amber_helper_text",
        red_helper_text: "settings.planning.auto_rag_updates.red_helper_text",
        task_auto_update_updated: "settings.planning.auto_rag_updates.task_auto_update_updated",
        task_auto_update_added: "settings.planning.auto_rag_updates.task_auto_update_added",
        task_auto_update_removed: "settings.planning.auto_rag_updates.task_auto_update_removed",
        enforce_red_rule_text: "settings.planning.auto_rag_updates.enforce_red_rule_text",
        running_rag_rules: "settings.planning.auto_rag_updates.running_rag_rules",
        localisedTasks_rags_updated: "settings.planning.auto_rag_updates.localisedTasks_rags_updated",
        how_it_works: "settings.planning.auto_rag_updates.how_it_works",
        how_it_works_description: "settings.planning.auto_rag_updates.how_it_works_description",
        amber_rule: "settings.planning.auto_rag_updates.amber_rule",
        automatically_turn_localisedTasks_amber: "settings.planning.auto_rag_updates.automatically_turn_localisedTasks_amber",
        notify_owner: "settings.planning.auto_rag_updates.notify_owner",
        red_rule: "settings.planning.auto_rag_updates.red_rule",
        automatically_turn_localisedTasks_red: "settings.planning.auto_rag_updates.automatically_turn_localisedTasks_red",
        blue_localisedTasks_will_never_be_automatically_updated: "settings.planning.auto_rag_updates.blue_localisedTasks_will_never_be_automatically_updated",
        restict_rag_updates_for_localisedTasks: "settings.planning.auto_rag_updates.restict_rag_updates_for_localisedTasks",
        run_updates_now: "settings.planning.auto_rag_updates.run_updates_now",
        choose_when_to_turn_localisedTask: "settings.planning.auto_rag_updates.choose_when_to_turn_localisedTask",
        choose_number_of_days: "settings.planning.auto_rag_updates.choose_number_of_days",
        before_or_after: "settings.planning.auto_rag_updates.before_or_after"
      },
      risk_rag_matrix: {
        risk_rag_matrix_text: "settings.planning.risk_rag_matrix.risk_rag_matrix_text",
        rag_matrix_updated: "settings.planning.risk_rag_matrix.rag_matrix_updated",
        confirm_reset_rag_matrix: "settings.planning.risk_rag_matrix.confirm_reset_rag_matrix",
        rag_matrix_has_been_reset: "settings.planning.risk_rag_matrix.rag_matrix_has_been_reset",
        risk_rag_matrix: "settings.planning.risk_rag_matrix.risk_rag_matrix",
        create_customised_rag_matrix: "settings.planning.risk_rag_matrix.create_customised_rag_matrix",
        rag_matrix_sub_header: "settings.planning.risk_rag_matrix.rag_matrix_sub_header",
        edit_rag_labels: "settings.planning.risk_rag_matrix.edit_rag_labels",
        edit_matrix_labels_colors: "settings.planning.risk_rag_matrix.edit_matrix_labels_colors",
        customise_rag_matrix: "settings.planning.risk_rag_matrix.customise_rag_matrix"
      },
      other_risk_settings: {
        other_risk_settings_text: "settings.planning.other_risk_settings.other_risk_settings_text",
        create_your_first_impact_type: "settings.planning.other_risk_settings.create_your_first_impact_type",
        create_your_first_insurance_policy: "settings.planning.other_risk_settings.create_your_first_insurance_policy",
        policy_title: "settings.planning.other_risk_settings.policy_title",
        policy_document: "settings.planning.other_risk_settings.policy_document",
        uploading_file_will_replace_existing_policy: "settings.planning.other_risk_settings.uploading_file_will_replace_existing_policy",
        upload_file: "settings.planning.other_risk_settings.upload_file",
        add_link_to_policy: "settings.planning.other_risk_settings.add_link_to_policy",
        policy_link_address: "settings.planning.other_risk_settings.policy_link_address",
        create_your_first_localisedPeap: "settings.planning.other_risk_settings.create_your_first_localisedPeap"
      },
      impact_category_matrix: {
        impact_category_matrix_text: "settings.planning.impact_category_matrix.impact_category_matrix_text",
        impact_type_matrix_text: "settings.planning.impact_category_matrix.impact_type_matrix_text",
        risk_impact_types_sub_header: "settings.planning.impact_category_matrix.risk_impact_types_sub_header",
        add_edit_impact_description_helper_text: "settings.planning.impact_category_matrix.add_edit_impact_description_helper_text"
      },
      rag_descriptions: {
        rag_descriptions_text: "settings.planning.rag_descriptions.rag_descriptions_text",
        configure_rag_descriptions_text: "settings.planning.rag_descriptions.configure_rag_descriptions_text"
      }
    },
    risk_settings_description: "settings.risk_settings_description",
    setting_updated: "settings.setting_updated",
    notify_a_localisedEmployee_when_updates_happen: "settings.notify_a_localisedEmployee_when_updates_happen",
    notify_item_manager: "settings.notify_item_manager",
    localisedEmployee_to_notify: "settings.localisedEmployee_to_notify",
    control: {
      control_settings: "settings.control.control_settings",
      severity_scale_guide: {
        severity_scale_guide_text: "settings.control.severity_scale_guide.severity_scale_guide_text",
        header_helper_text: "settings.control.severity_scale_guide.header_helper_text"
      },
      integration: {
        title: "settings.control.integration.title",
        webhook: "settings.control.integration.webhook"
      },
      slack_integration: {
        slack_integration_text: "settings.control.slack_integration.slack_integration_text",
        token: "settings.control.slack_integration.token",
        channel: "settings.control.slack_integration.channel",
        bot_token: "settings.control.slack_integration.bot_token",
        header_helper_text: "settings.control.slack_integration.header_helper_text",
        enable_slack_integration: "settings.control.slack_integration.enable_slack_integration"
      },
      channel_manager: {
        channel_manager_text: "settings.control.channel_manager.channel_manager_text",
        enable_disable_channels: "settings.control.channel_manager.enable_disable_channels",
        enable_disable_channels_description: "settings.control.channel_manager.enable_disable_channels_description",
        incident_channel_description: "settings.control.channel_manager.incident_channel_description",
        number_active_channels: "settings.control.channel_manager.number_active_channels",
        channel_number: "settings.control.channel_manager.channel_number",
        please_select_checklist_to_add_item: "settings.control.channel_manager.please_select_checklist_to_add_item"
      },
      confidential_types: {
        confidential_types_text: "settings.control.confidential_types.confidential_types_text",
        header_helper_text: "settings.control.confidential_types.header_helper_text",
        confidential_incident_types: "settings.control.confidential_types.confidential_incident_types"
      },
      incident_checkists: {
        incident_checkist_texts: "settings.control.incident_checkists.incident_checkist_texts",
        helper_text: "settings.control.incident_checkists.helper_text",
        checklist_template_added: "settings.control.incident_checkists.checklist_template_added",
        confirm_delete_chekclist_template: "settings.control.incident_checkists.confirm_delete_chekclist_template",
        checklist_template_deleted: "settings.control.incident_checkists.checklist_template_deleted",
        checklist_template_update: "settings.control.incident_checkists.checklist_template_update",
        checklist_iten_template_added: "settings.control.incident_checkists.checklist_iten_template_added",
        checklist_item_template_updated: "settings.control.incident_checkists.checklist_item_template_updated",
        chekclist_item_template_deleted: "settings.control.incident_checkists.chekclist_item_template_deleted",
        chekclist_item_templates_view_order_updated: "settings.control.incident_checkists.chekclist_item_templates_view_order_updated",
        add_checklist_template: "settings.control.incident_checkists.add_checklist_template",
        edit_checklist_template: "settings.control.incident_checkists.edit_checklist_template",
        apply_checklist_to_new_incidents: "settings.control.incident_checkists.apply_checklist_to_new_incidents",
        apply_checklist_to_incidents_description: "settings.control.incident_checkists.apply_checklist_to_incidents_description",
        edit_checklist_item_template: "settings.control.incident_checkists.edit_checklist_item_template",
        add_checklist_item_template: "settings.control.incident_checkists.add_checklist_item_template",
        confirm_delete_cheklist_item_template: "settings.control.incident_checkists.confirm_delete_cheklist_item_template",
        confirm_delete_category: "settings.control.incident_checkists.confirm_delete_category",
        delete_checklist: "settings.control.incident_checkists.delete_checklist"
      },
      setting_description: "settings.control.setting_description",
      job_checklists: {
        helper_text: "settings.control.job_checklists.helper_text"
      }
    },
    peaps: {
      peaps_settings_description: "settings.peaps.peaps_settings_description",
      localisedPeaps_setup: {
        localisedPeaps_setup_text: "settings.peaps.localisedPeaps_setup.localisedPeaps_setup_text"
      },
      manage_checks: {
        manage_checks_text: "settings.peaps.manage_checks.manage_checks_text"
      },
      manage_checkpoints: {
        manage_checkpoints_text: "settings.peaps.manage_checkpoints.manage_checkpoints_text"
      },
      manage_peaps_groups: {
        manage_items_groups_text: "settings.peaps.manage_peaps_groups.manage_items_groups_text"
      },
      group_peaps_empty_state: "settings.peaps.group_peaps_empty_state",
      checkpoint_empty_state: "settings.peaps.checkpoint_empty_state",
      checkpoint_type_empty_state: "settings.peaps.checkpoint_type_empty_state",
      check_empty_state: "settings.peaps.check_empty_state",
      assign_to_localisedPEAPs: "settings.peaps.assign_to_localisedPEAPs",
      create_your_first_checkpoint_type: "settings.peaps.create_your_first_checkpoint_type",
      show_all_types: "settings.peaps.show_all_types",
      create_your_first_check: "settings.peaps.create_your_first_check",
      create_your_first_checkpoint: "settings.peaps.create_your_first_checkpoint",
      matching_checks: "settings.peaps.matching_checks",
      non_matching_checks: "settings.peaps.non_matching_checks",
      select_checks_to_be_carried_out: "settings.peaps.select_checks_to_be_carried_out",
      custom_check_added: "settings.peaps.custom_check_added",
      localisedPEAP_templates: "settings.peaps.localisedPEAP_templates",
      show_all_checkpoint_types: "settings.peaps.show_all_checkpoint_types",
      checkpoints_in_this_localisedPeaps: "settings.peaps.checkpoints_in_this_localisedPeaps",
      add_checkpoints_form_left_to_create_localisedPEAP: "settings.peaps.add_checkpoints_form_left_to_create_localisedPEAP",
      save_localisedPeap_template: "settings.peaps.save_localisedPeap_template",
      localisedPeap_title_added_to_group: "settings.peaps.localisedPeap_title_added_to_group",
      schedule_and_assign_localisedPeaps: "settings.peaps.schedule_and_assign_localisedPeaps",
      assign_peaps_description: "settings.peaps.assign_peaps_description",
      previously_sent_count: "settings.peaps.previously_sent_count",
      schedule_a_group_of_localisedPEAPs: "settings.peaps.schedule_a_group_of_localisedPEAPs",
      link_to_an_localisedEvent: "settings.peaps.link_to_an_localisedEvent",
      past_localisedPEAP_group: "settings.peaps.past_localisedPEAP_group",
      duplicate_peaps_from_previous_group: "settings.peaps.duplicate_peaps_from_previous_group",
      choose_localisedPeaps_and_assign_localisedUsers_manually: "settings.peaps.choose_localisedPeaps_and_assign_localisedUsers_manually",
      schedule_and_assign_a_group_of_localisedPEAPs: "settings.peaps.schedule_and_assign_a_group_of_localisedPEAPs",
      schedule_localisedPeaps: "settings.peaps.schedule_localisedPeaps",
      choose_if_you_want_to_link_this_localisedPeap_to_localisedEvent: "settings.peaps.choose_if_you_want_to_link_this_localisedPeap_to_localisedEvent",
      choose_when_localisedPeap_group_should_be_sent_to_users: "settings.peaps.choose_when_localisedPeap_group_should_be_sent_to_users",
      select_whether_to_duplicate_localisePeaps: "settings.peaps.select_whether_to_duplicate_localisePeaps",
      select_localisedPeaps_to_be_scheduled: "settings.peaps.select_localisedPeaps_to_be_scheduled",
      peaps_successfully_scheduled: "settings.peaps.peaps_successfully_scheduled",
      click_on_a_localisedPeap_in_the_list_below: "settings.peaps.click_on_a_localisedPeap_in_the_list_below",
      select_localisedPeap_to_assing_user: "settings.peaps.select_localisedPeap_to_assing_user",
      schedule_count_localisedPeaps: "settings.peaps.schedule_count_localisedPeaps"
    },
    run_sheets: {
      run_sheets_description: "settings.run_sheets.run_sheets_description",
      runsheets_settings: "settings.run_sheets.runsheets_settings",
      priorities: {
        configure_priorities: "settings.run_sheets.priorities.configure_priorities"
      },
      subscriber_alerts: {
        subscriber_alerts_text: "settings.run_sheets.subscriber_alerts.subscriber_alerts_text"
      },
      allow_dependencies_between_run_sheet_items: "settings.run_sheets.allow_dependencies_between_run_sheet_items",
      send_updated_run_sheet_to_all_subscribers: "settings.run_sheets.send_updated_run_sheet_to_all_subscribers"
    },
    readiness: {
      readiness_description: "settings.readiness.readiness_description",
      evaluation_feedback_data: {
        evaluation_feedback_data_text: "settings.readiness.evaluation_feedback_data.evaluation_feedback_data_text"
      },
      readiness_settings_text: "settings.readiness.readiness_settings_text",
      severities: {
        configure_severities: "settings.readiness.severities.configure_severities"
      },
      activity_types: {
        localisedActivityTypes_delete_error: "settings.readiness.activity_types.localisedActivityTypes_delete_error",
        choose_icon_for_activity_type: "settings.readiness.activity_types.choose_icon_for_activity_type",
        icon_is_already_assigned_to_activity_type: "settings.readiness.activity_types.icon_is_already_assigned_to_activity_type",
        activity_type_icon_is_required: "settings.readiness.activity_types.activity_type_icon_is_required"
      },
      checklists: {
        checklist_helper_text: "settings.readiness.checklists.checklist_helper_text"
      },
      evaluation_feedback: {
        evaluation_feedback_data: "settings.readiness.evaluation_feedback.evaluation_feedback_data",
        download_feedback_data: "settings.readiness.evaluation_feedback.download_feedback_data"
      }
    },
    sustainability: {
      climatiq_settings: "settings.sustainability.climatiq_settings",
      scope: "settings.sustainability.scope",
      region: "settings.sustainability.region",
      source: "settings.sustainability.source",
      source_link: "settings.sustainability.source_link",
      indicator_created_text: "settings.sustainability.indicator_created_text",
      climatiq_region: "settings.sustainability.climatiq_region",
      region_updated: "settings.sustainability.region_updated",
      climatiq_source: "settings.sustainability.climatiq_source",
      source_updated: "settings.sustainability.source_updated",
      update_values_past_t_days: "settings.sustainability.update_values_past_t_days",
      update_values_past_Y: "settings.sustainability.update_values_past_Y",
      updates_past_t_days: "settings.sustainability.updates_past_t_days",
      cumulative_totals: "settings.sustainability.cumulative_totals",
      updates_past_y_days: "settings.sustainability.updates_past_y_days",
      total_update_value: "settings.sustainability.total_update_value",
      unit_measurements: {
        unit_Type_label: "settings.sustainability.unit_measurements.unit_Type_label",
        unit_label: "settings.sustainability.unit_measurements.unit_label",
        unit_conversion_label: "settings.sustainability.unit_measurements.unit_conversion_label"
      },
      sustainablity_description: "settings.sustainability.sustainablity_description",
      sustainability_settings: "settings.sustainability.sustainability_settings",
      unit_types: "settings.sustainability.unit_types",
      unit_title: "settings.sustainability.unit_title",
      un_sdgs_description: "settings.sustainability.un_sdgs_description",
      checklists_description: "settings.sustainability.checklists_description",
      units_of_measurement_helper_text: "settings.sustainability.units_of_measurement_helper_text",
      avarage_trend_status: "settings.sustainability.avarage_trend_status",
      configure_trend_rag_names: "settings.sustainability.configure_trend_rag_names",
      trend_rag: {
        one: "settings.sustainability.trend_rag.one",
        many: "settings.sustainability.trend_rag.many"
      }
    },
    location_manager: {
      location_manager_text: "settings.location_manager.location_manager_text",
      location_manager_description: "settings.location_manager.location_manager_description",
      location_settings: "settings.location_manager.location_settings",
      click_to_open_location_manager: "settings.location_manager.click_to_open_location_manager",
      manager_modal: {
        zone_add_confirm_text: "settings.location_manager.manager_modal.zone_add_confirm_text",
        area_add_confirm_text: "settings.location_manager.manager_modal.area_add_confirm_text",
        add_zone_note: "settings.location_manager.manager_modal.add_zone_note",
        add_area_note: "settings.location_manager.manager_modal.add_area_note",
        add_zone_note_below: "settings.location_manager.manager_modal.add_zone_note_below",
        add_area_note_below: "settings.location_manager.manager_modal.add_area_note_below",
        add_zone_level_note: "settings.location_manager.manager_modal.add_zone_level_note",
        add_area_level_note: "settings.location_manager.manager_modal.add_area_level_note",
        add_area_location_restriction_note: "settings.location_manager.manager_modal.add_area_location_restriction_note"
      }
    },
    imports_and_exports: {
      imports_and_exports_text: "settings.imports_and_exports.imports_and_exports_text",
      import_export_description: "settings.imports_and_exports.import_export_description",
      import_new_items: "settings.imports_and_exports.import_new_items",
      export_and_update: "settings.imports_and_exports.export_and_update",
      apply_filter_to_just_export_items: "settings.imports_and_exports.apply_filter_to_just_export_items",
      export_items: "settings.imports_and_exports.export_items",
      download_item_import_template_xlsx: "settings.imports_and_exports.download_item_import_template_xlsx",
      export_items_to_xlsx: "settings.imports_and_exports.export_items_to_xlsx",
      choose_what_you_want_to_import: "settings.imports_and_exports.choose_what_you_want_to_import",
      choose_what_you_want_to_export_and_update: "settings.imports_and_exports.choose_what_you_want_to_export_and_update",
      downloading_template: "settings.imports_and_exports.downloading_template",
      validating_your_file: "settings.imports_and_exports.validating_your_file",
      you_have_to_select_excel_file: "settings.imports_and_exports.you_have_to_select_excel_file",
      you_can_upload_1_file_at_a_time: "settings.imports_and_exports.you_can_upload_1_file_at_a_time",
      problem_with_your_file: "settings.imports_and_exports.problem_with_your_file",
      uploading_your_file: "settings.imports_and_exports.uploading_your_file",
      what_you_want_to_import: "settings.imports_and_exports.what_you_want_to_import",
      choose_import_type: "settings.imports_and_exports.choose_import_type",
      import_types: "settings.imports_and_exports.import_types",
      do_you_have_XLSX_import_template: "settings.imports_and_exports.do_you_have_XLSX_import_template",
      export_the_items_you_want_to_update: "settings.imports_and_exports.export_the_items_you_want_to_update",
      match_these_filters: "settings.imports_and_exports.match_these_filters",
      import_an_updated_item_import_template: "settings.imports_and_exports.import_an_updated_item_import_template",
      import_template: "settings.imports_and_exports.import_template",
      import_tooltip_description: "settings.imports_and_exports.import_tooltip_description",
      import_validation_status: "settings.imports_and_exports.import_validation_status",
      there_are_errors_preventing_importing: "settings.imports_and_exports.there_are_errors_preventing_importing",
      errors_on_your_items_import_template: "settings.imports_and_exports.errors_on_your_items_import_template",
      correct_errors_import_template: "settings.imports_and_exports.correct_errors_import_template",
      rows_on_which_error_occurs: "settings.imports_and_exports.rows_on_which_error_occurs",
      corrected: "settings.imports_and_exports.corrected",
      your_import_template_is_missing_the_below_columns_localisedTasks: "settings.imports_and_exports.your_import_template_is_missing_the_below_columns_localisedTasks",
      your_file_is_ready_to_import: "settings.imports_and_exports.your_file_is_ready_to_import",
      count_item_will_be_added: "settings.imports_and_exports.count_item_will_be_added",
      count_item_will_be_updated: "settings.imports_and_exports.count_item_will_be_updated",
      count_items_will_be_added: "settings.imports_and_exports.count_items_will_be_added",
      count_items_will_be_updated: "settings.imports_and_exports.count_items_will_be_updated",
      warnings_on_your_items_import_template: "settings.imports_and_exports.warnings_on_your_items_import_template",
      rows_on_which_warning_occurs: "settings.imports_and_exports.rows_on_which_warning_occurs",
      import_complete: "settings.imports_and_exports.import_complete",
      success_import_sent_for_processing: "settings.imports_and_exports.success_import_sent_for_processing",
      your_import_is_now_being_processed: "settings.imports_and_exports.your_import_is_now_being_processed",
      import_another_file: "settings.imports_and_exports.import_another_file",
      guide_to_using_the_item_import_template: "settings.imports_and_exports.guide_to_using_the_item_import_template",
      template_column: "settings.imports_and_exports.template_column",
      how_to_use: "settings.imports_and_exports.how_to_use",
      import_guides: {
        id_description: "settings.imports_and_exports.import_guides.id_description",
        jobs_id_description: "settings.imports_and_exports.import_guides.jobs_id_description",
        run_sheet_title_desc: "settings.imports_and_exports.import_guides.run_sheet_title_desc",
        runsheet_owner_desc: "settings.imports_and_exports.import_guides.runsheet_owner_desc",
        runsheet_department_desc: "settings.imports_and_exports.import_guides.runsheet_department_desc",
        runsheet_zone_desc: "settings.imports_and_exports.import_guides.runsheet_zone_desc",
        runsheet_area_desc: "settings.imports_and_exports.import_guides.runsheet_area_desc",
        runsheet_event_desc: "settings.imports_and_exports.import_guides.runsheet_event_desc",
        run_sheet_notes: "settings.imports_and_exports.import_guides.run_sheet_notes",
        run_sheet_item_id_desc: "settings.imports_and_exports.import_guides.run_sheet_item_id_desc",
        title_of_item_up_to_count_characters: "settings.imports_and_exports.import_guides.title_of_item_up_to_count_characters",
        unique_title_of_item_up_to_count_characters: "settings.imports_and_exports.import_guides.unique_title_of_item_up_to_count_characters",
        runsheet_start_time_desc: "settings.imports_and_exports.import_guides.runsheet_start_time_desc",
        runsheet_before_after_desc: "settings.imports_and_exports.import_guides.runsheet_before_after_desc",
        runsheet_duration_desc: "settings.imports_and_exports.import_guides.runsheet_duration_desc",
        runsheet_depends_on_desc: "settings.imports_and_exports.import_guides.runsheet_depends_on_desc",
        dependency_time_differential: "settings.imports_and_exports.import_guides.dependency_time_differential",
        dependency_time_differential_desc: "settings.imports_and_exports.import_guides.dependency_time_differential_desc",
        before_or_after: "settings.imports_and_exports.import_guides.before_or_after",
        runsheet_before_or_after_desc: "settings.imports_and_exports.import_guides.runsheet_before_or_after_desc",
        runsheet_priority_desc: "settings.imports_and_exports.import_guides.runsheet_priority_desc",
        runsheet_requires_completion_desc: "settings.imports_and_exports.import_guides.runsheet_requires_completion_desc",
        item_description_desc: "settings.imports_and_exports.import_guides.item_description_desc",
        readiness_types_desc: "settings.imports_and_exports.import_guides.readiness_types_desc",
        capability_department_desc: "settings.imports_and_exports.import_guides.capability_department_desc",
        capability_owner_desc: "settings.imports_and_exports.import_guides.capability_owner_desc",
        capability_priority_desc: "settings.imports_and_exports.import_guides.capability_priority_desc",
        capabulity_expected_severity: "settings.imports_and_exports.import_guides.capabulity_expected_severity",
        capability_expected_result_desc: "settings.imports_and_exports.import_guides.capability_expected_result_desc",
        item_subscribers_desc: "settings.imports_and_exports.import_guides.item_subscribers_desc",
        user_groups_desc: "settings.imports_and_exports.import_guides.user_groups_desc",
        event_desc: "settings.imports_and_exports.import_guides.event_desc",
        item_tags_desc: "settings.imports_and_exports.import_guides.item_tags_desc",
        activity_date_desc: "settings.imports_and_exports.import_guides.activity_date_desc",
        activity_event_desc: "settings.imports_and_exports.import_guides.activity_event_desc",
        activity_debrief_desc: "settings.imports_and_exports.import_guides.activity_debrief_desc",
        evaluation_title_desc: "settings.imports_and_exports.import_guides.evaluation_title_desc",
        evaluation_ref_desc: "settings.imports_and_exports.import_guides.evaluation_ref_desc",
        evaluation_activity_desc: "settings.imports_and_exports.import_guides.evaluation_activity_desc",
        evaluation_time_desc: "settings.imports_and_exports.import_guides.evaluation_time_desc",
        evaluation_status_description: "settings.imports_and_exports.import_guides.evaluation_status_description",
        evaluation_owner_desc: "settings.imports_and_exports.import_guides.evaluation_owner_desc",
        evaluation_zone_desc: "settings.imports_and_exports.import_guides.evaluation_zone_desc",
        evaluation_area_desc: "settings.imports_and_exports.import_guides.evaluation_area_desc",
        evaluation_grab_desc: "settings.imports_and_exports.import_guides.evaluation_grab_desc",
        response_severity: "settings.imports_and_exports.import_guides.response_severity",
        response_severity_desc: "settings.imports_and_exports.import_guides.response_severity_desc",
        item_number: "settings.imports_and_exports.import_guides.item_number",
        task_item_number_desc: "settings.imports_and_exports.import_guides.task_item_number_desc",
        task_project_desc: "settings.imports_and_exports.import_guides.task_project_desc",
        task_task_group_desc: "settings.imports_and_exports.import_guides.task_task_group_desc",
        task_parent_desc: "settings.imports_and_exports.import_guides.task_parent_desc",
        task_rag_desc: "settings.imports_and_exports.import_guides.task_rag_desc",
        task_start_date_desc: "settings.imports_and_exports.import_guides.task_start_date_desc",
        item_due_date_desc: "settings.imports_and_exports.import_guides.item_due_date_desc",
        item_department_desc: "settings.imports_and_exports.import_guides.item_department_desc",
        task_owners_desc: "settings.imports_and_exports.import_guides.task_owners_desc",
        task_milestone_type_desc: "settings.imports_and_exports.import_guides.task_milestone_type_desc",
        task_zones_desc: "settings.imports_and_exports.import_guides.task_zones_desc",
        item_area_desc: "settings.imports_and_exports.import_guides.item_area_desc",
        baseline_start_date: "settings.imports_and_exports.import_guides.baseline_start_date",
        task_baseline_start_date_desc: "settings.imports_and_exports.import_guides.task_baseline_start_date_desc",
        baseline_due_date: "settings.imports_and_exports.import_guides.baseline_due_date",
        task_baseline_due_date_desc: "settings.imports_and_exports.import_guides.task_baseline_due_date_desc",
        percentage_complete: "settings.imports_and_exports.import_guides.percentage_complete",
        percentage_complete_with_input: "settings.imports_and_exports.import_guides.percentage_complete_with_input",
        task_percentage_complete_desc: "settings.imports_and_exports.import_guides.task_percentage_complete_desc",
        risk_item_number_desc: "settings.imports_and_exports.import_guides.risk_item_number_desc",
        parent_risk: "settings.imports_and_exports.import_guides.parent_risk",
        parent_risk_desc: "settings.imports_and_exports.import_guides.parent_risk_desc",
        risk_owner: "settings.imports_and_exports.import_guides.risk_owner",
        risk_owner_desc: "settings.imports_and_exports.import_guides.risk_owner_desc",
        risk_action_owners: "settings.imports_and_exports.import_guides.risk_action_owners",
        risk_action_owners_desc: "settings.imports_and_exports.import_guides.risk_action_owners_desc",
        risk_status_desc: "settings.imports_and_exports.import_guides.risk_status_desc",
        risk_type_desc: "settings.imports_and_exports.import_guides.risk_type_desc",
        risk_action_status_desc: "settings.imports_and_exports.import_guides.risk_action_status_desc",
        risk_impact_desc: "settings.imports_and_exports.import_guides.risk_impact_desc",
        risk_likelihood_desc: "settings.imports_and_exports.import_guides.risk_likelihood_desc",
        risk_projects_desc: "settings.imports_and_exports.import_guides.risk_projects_desc",
        risk_start_date_desc: "settings.imports_and_exports.import_guides.risk_start_date_desc",
        risk_impact_type_desc: "settings.imports_and_exports.import_guides.risk_impact_type_desc",
        risk_strategy_desc: "settings.imports_and_exports.import_guides.risk_strategy_desc",
        risk_insurance_policy_desc: "settings.imports_and_exports.import_guides.risk_insurance_policy_desc",
        item_events_desc: "settings.imports_and_exports.import_guides.item_events_desc",
        item_event_types_desc: "settings.imports_and_exports.import_guides.item_event_types_desc",
        item_zone_or_area_desc: "settings.imports_and_exports.import_guides.item_zone_or_area_desc",
        risk_identified_by_desc: "settings.imports_and_exports.import_guides.risk_identified_by_desc",
        risk_additional_info_desc: "settings.imports_and_exports.import_guides.risk_additional_info_desc",
        risk_baseline_impact_desc: "settings.imports_and_exports.import_guides.risk_baseline_impact_desc",
        risk_baseline_likelihood_desc: "settings.imports_and_exports.import_guides.risk_baseline_likelihood_desc",
        project_owners_desc: "settings.imports_and_exports.import_guides.project_owners_desc",
        project_lead_department_desc: "settings.imports_and_exports.import_guides.project_lead_department_desc",
        project_privacy_status_desc: "settings.imports_and_exports.import_guides.project_privacy_status_desc",
        project_expected_due_date_desc: "settings.imports_and_exports.import_guides.project_expected_due_date_desc",
        project_status_desc: "settings.imports_and_exports.import_guides.project_status_desc",
        item_ref_code_desc: "settings.imports_and_exports.import_guides.item_ref_code_desc",
        department_department_category_desc: "settings.imports_and_exports.import_guides.department_department_category_desc",
        task_group_title_desc: "settings.imports_and_exports.import_guides.task_group_title_desc",
        task_group_project_title_desc: "settings.imports_and_exports.import_guides.task_group_project_title_desc",
        item_title_desc_40_chars: "settings.imports_and_exports.import_guides.item_title_desc_40_chars",
        area_zone_desc: "settings.imports_and_exports.import_guides.area_zone_desc",
        indicator_expected_direction_desc: "settings.imports_and_exports.import_guides.indicator_expected_direction_desc",
        indicator_template_expected_direction_desc: "settings.imports_and_exports.import_guides.indicator_template_expected_direction_desc",
        indicator_update_type_desc: "settings.imports_and_exports.import_guides.indicator_update_type_desc",
        indicator_unit_type_desc: "settings.imports_and_exports.import_guides.indicator_unit_type_desc",
        indicator_default_unit_desc: "settings.imports_and_exports.import_guides.indicator_default_unit_desc",
        indicator_conversion_unit_desc: "settings.imports_and_exports.import_guides.indicator_conversion_unit_desc",
        indicator_template_unit_type_desc: "settings.imports_and_exports.import_guides.indicator_template_unit_type_desc",
        indicator_template_default_unit_desc: "settings.imports_and_exports.import_guides.indicator_template_default_unit_desc",
        indicator_template_conversion_unit_desc: "settings.imports_and_exports.import_guides.indicator_template_conversion_unit_desc",
        indicator_template_update_type_desc: "settings.imports_and_exports.import_guides.indicator_template_update_type_desc",
        indicator_unit_desc: "settings.imports_and_exports.import_guides.indicator_unit_desc",
        indicator_template_unit_desc: "settings.imports_and_exports.import_guides.indicator_template_unit_desc",
        update_frequency_count: "settings.imports_and_exports.import_guides.update_frequency_count",
        confirm_delete_indicator_template: "settings.imports_and_exports.import_guides.confirm_delete_indicator_template",
        confirm_delete_organisation: "settings.imports_and_exports.import_guides.confirm_delete_organisation",
        view_indicator_template: "settings.imports_and_exports.import_guides.view_indicator_template",
        increasing: "settings.imports_and_exports.import_guides.increasing",
        decreasing: "settings.imports_and_exports.import_guides.decreasing",
        indicator_update_frequency_count_desc: "settings.imports_and_exports.import_guides.indicator_update_frequency_count_desc",
        indicator_template_update_frequency_count_desc: "settings.imports_and_exports.import_guides.indicator_template_update_frequency_count_desc",
        update_frequency_interval: "settings.imports_and_exports.import_guides.update_frequency_interval",
        indicator_update_frequency_interval_desc: "settings.imports_and_exports.import_guides.indicator_update_frequency_interval_desc",
        indicator_template_update_frequency_interval_desc: "settings.imports_and_exports.import_guides.indicator_template_update_frequency_interval_desc",
        indicator_template_update_count_desc: "settings.imports_and_exports.import_guides.indicator_template_update_count_desc",
        tracking_start_date: "settings.imports_and_exports.import_guides.tracking_start_date",
        tracking_start_date_desc: "settings.imports_and_exports.import_guides.tracking_start_date_desc",
        tracking_end_date: "settings.imports_and_exports.import_guides.tracking_end_date",
        tracking_end_date_desc: "settings.imports_and_exports.import_guides.tracking_end_date_desc",
        baseline_value_desc: "settings.imports_and_exports.import_guides.baseline_value_desc",
        indicator_baseline_value_desc: "settings.imports_and_exports.import_guides.indicator_baseline_value_desc",
        indicator_category_desc: "settings.imports_and_exports.import_guides.indicator_category_desc",
        indicator_template_category_desc: "settings.imports_and_exports.import_guides.indicator_template_category_desc",
        indicator_priority_desc: "settings.imports_and_exports.import_guides.indicator_priority_desc",
        indicator_template_priority_desc: "settings.imports_and_exports.import_guides.indicator_template_priority_desc",
        indicator_owner_desc: "settings.imports_and_exports.import_guides.indicator_owner_desc",
        indicator_template_owner_desc: "settings.imports_and_exports.import_guides.indicator_template_owner_desc",
        indicator_department_desc: "settings.imports_and_exports.import_guides.indicator_department_desc",
        indicator_template_department_desc: "settings.imports_and_exports.import_guides.indicator_template_department_desc",
        indicator_un_sdgs_desc: "settings.imports_and_exports.import_guides.indicator_un_sdgs_desc",
        indicator_template_un_sdgs_desc: "settings.imports_and_exports.import_guides.indicator_template_un_sdgs_desc",
        indicator_target_indicator_id_desc: "settings.imports_and_exports.import_guides.indicator_target_indicator_id_desc",
        target_value_desc: "settings.imports_and_exports.import_guides.target_value_desc",
        target_due_date: "settings.imports_and_exports.import_guides.target_due_date",
        target_due_date_desc: "settings.imports_and_exports.import_guides.target_due_date_desc",
        indicator_target_type_desc: "settings.imports_and_exports.import_guides.indicator_target_type_desc",
        indicator_target_level_desc: "settings.imports_and_exports.import_guides.indicator_target_level_desc",
        indicator_update_id_desc: "settings.imports_and_exports.import_guides.indicator_update_id_desc",
        indicator_update_indicator_id_desc: "settings.imports_and_exports.import_guides.indicator_update_indicator_id_desc",
        indicator_update_indicator_title_desc: "settings.imports_and_exports.import_guides.indicator_update_indicator_title_desc",
        indicator_update_date_desc: "settings.imports_and_exports.import_guides.indicator_update_date_desc",
        indicator_value_desc: "settings.imports_and_exports.import_guides.indicator_value_desc",
        indicator_update_comment_desc: "settings.imports_and_exports.import_guides.indicator_update_comment_desc",
        indicator_update_conversion_unit_desc: "settings.imports_and_exports.import_guides.indicator_update_conversion_unit_desc",
        update_duration_count: "settings.imports_and_exports.import_guides.update_duration_count",
        indicator_template_update_duration_count_desc: "settings.imports_and_exports.import_guides.indicator_template_update_duration_count_desc",
        update_duration_interval: "settings.imports_and_exports.import_guides.update_duration_interval",
        indicator_template_update_duration_interval_desc: "settings.imports_and_exports.import_guides.indicator_template_update_duration_interval_desc",
        organisation_website_desc: "settings.imports_and_exports.import_guides.organisation_website_desc",
        job_type_desc: "settings.imports_and_exports.import_guides.job_type_desc",
        job_status_desc: "settings.imports_and_exports.import_guides.job_status_desc",
        job_priority_desc: "settings.imports_and_exports.import_guides.job_priority_desc",
        job_category_desc: "settings.imports_and_exports.import_guides.job_category_desc",
        job_department_desc: "settings.imports_and_exports.import_guides.job_department_desc",
        job_owner_desc: "settings.imports_and_exports.import_guides.job_owner_desc",
        job_start_date_desc: "settings.imports_and_exports.import_guides.job_start_date_desc",
        job_due_date_desc: "settings.imports_and_exports.import_guides.job_due_date_desc",
        job_privacy_status_desc: "settings.imports_and_exports.import_guides.job_privacy_status_desc",
        job_zone_desc: "settings.imports_and_exports.import_guides.job_zone_desc",
        job_area_desc: "settings.imports_and_exports.import_guides.job_area_desc",
        job_headline_status_desc: "settings.imports_and_exports.import_guides.job_headline_status_desc",
        employee_email_desc: "settings.imports_and_exports.import_guides.employee_email_desc",
        employee_first_name_desc: "settings.imports_and_exports.import_guides.employee_first_name_desc",
        employee_last_name_desc: "settings.imports_and_exports.import_guides.employee_last_name_desc",
        employee_active_desc: "settings.imports_and_exports.import_guides.employee_active_desc",
        employee_department_desc: "settings.imports_and_exports.import_guides.employee_department_desc",
        employee_zone_desc: "settings.imports_and_exports.import_guides.employee_zone_desc",
        employee_user_groups_desc: "settings.imports_and_exports.import_guides.employee_user_groups_desc",
        employee_account_admin_desc: "settings.imports_and_exports.import_guides.employee_account_admin_desc",
        employee_user_admin_desc: "settings.imports_and_exports.import_guides.employee_user_admin_desc",
        employee_admin_desc: "settings.imports_and_exports.import_guides.employee_admin_desc",
        employee_general_desc: "settings.imports_and_exports.import_guides.employee_general_desc",
        employee_can_access_planning: "settings.imports_and_exports.import_guides.employee_can_access_planning",
        project_admin_desc: "settings.imports_and_exports.import_guides.project_admin_desc",
        employee_gantt_editor: "settings.imports_and_exports.import_guides.employee_gantt_editor",
        employee_risk_manager: "settings.imports_and_exports.import_guides.employee_risk_manager",
        employee_can_see_projects_desc: "settings.imports_and_exports.import_guides.employee_can_see_projects_desc",
        employee_can_see_departments_desc: "settings.imports_and_exports.import_guides.employee_can_see_departments_desc",
        employee_can_edit_projects_desc: "settings.imports_and_exports.import_guides.employee_can_edit_projects_desc",
        employee_can_edit_departments_desc: "settings.imports_and_exports.import_guides.employee_can_edit_departments_desc",
        employee_can_see_risks_desc: "settings.imports_and_exports.import_guides.employee_can_see_risks_desc",
        employee_can_edit_risks_desc: "settings.imports_and_exports.import_guides.employee_can_edit_risks_desc",
        employee_can_see_edit_confidential_projects_desc: "settings.imports_and_exports.import_guides.employee_can_see_edit_confidential_projects_desc",
        employee_can_access_control_desc: "settings.imports_and_exports.import_guides.employee_can_access_control_desc",
        employee_can_access_jobs_desc: "settings.imports_and_exports.import_guides.employee_can_access_jobs_desc",
        employee_control_admin_desc: "settings.imports_and_exports.import_guides.employee_control_admin_desc",
        can_see_control_module: "settings.imports_and_exports.import_guides.can_see_control_module",
        can_see_control_desc: "settings.imports_and_exports.import_guides.can_see_control_desc",
        can_edit_control_module: "settings.imports_and_exports.import_guides.can_edit_control_module",
        can_edit_control_desc: "settings.imports_and_exports.import_guides.can_edit_control_desc",
        can_see_edit_conf_incidents: "settings.imports_and_exports.import_guides.can_see_edit_conf_incidents",
        can_see_edit_conf_incidents_desc: "settings.imports_and_exports.import_guides.can_see_edit_conf_incidents_desc",
        can_see_jobs: "settings.imports_and_exports.import_guides.can_see_jobs",
        can_see_jobs_desc: "settings.imports_and_exports.import_guides.can_see_jobs_desc",
        can_edit_jobs: "settings.imports_and_exports.import_guides.can_edit_jobs",
        can_edit_jobs_desc: "settings.imports_and_exports.import_guides.can_edit_jobs_desc",
        can_see_edit_conf_jobs: "settings.imports_and_exports.import_guides.can_see_edit_conf_jobs",
        can_see_edit_conf_jobs_desc: "settings.imports_and_exports.import_guides.can_see_edit_conf_jobs_desc",
        can_access_peaps_desc: "settings.imports_and_exports.import_guides.can_access_peaps_desc",
        peaps_controller_desc: "settings.imports_and_exports.import_guides.peaps_controller_desc",
        can_access_runsheets_desc: "settings.imports_and_exports.import_guides.can_access_runsheets_desc",
        can_access_readiness_desc: "settings.imports_and_exports.import_guides.can_access_readiness_desc",
        readiness_admin_desc: "settings.imports_and_exports.import_guides.readiness_admin_desc",
        can_see_readiness: "settings.imports_and_exports.import_guides.can_see_readiness",
        can_see_readiness_desc: "settings.imports_and_exports.import_guides.can_see_readiness_desc",
        can_edit_readiness: "settings.imports_and_exports.import_guides.can_edit_readiness",
        can_edit_readiness_desc: "settings.imports_and_exports.import_guides.can_edit_readiness_desc",
        event_start_desc: "settings.imports_and_exports.import_guides.event_start_desc",
        event_end_desc: "settings.imports_and_exports.import_guides.event_end_desc",
        event_type_desc: "settings.imports_and_exports.import_guides.event_type_desc"
      }
    },
    back_to_settings: "settings.back_to_settings",
    events_manager: {
      events_manager_description: "settings.events_manager.events_manager_description",
      set_live_tooltip_msg: "settings.events_manager.set_live_tooltip_msg",
      show_dashboard_widget_settings: "settings.events_manager.show_dashboard_widget_settings"
    },
    tag_manager: {
      tag_manager_text: "settings.tag_manager.tag_manager_text",
      tag_manager_description: "settings.tag_manager.tag_manager_description",
      tag_manager_helper_text: "settings.tag_manager.tag_manager_helper_text",
      item_tags: "settings.tag_manager.item_tags",
      confirm_delete_tag_group: "settings.tag_manager.confirm_delete_tag_group",
      link_tag_group_to_type: "settings.tag_manager.link_tag_group_to_type",
      link_tags_to_which_tag_group: "settings.tag_manager.link_tags_to_which_tag_group"
    },
    document_manager_settings: {
      document_manager_description: "settings.document_manager_settings.document_manager_description",
      all_attachments: "settings.document_manager_settings.all_attachments",
      shared_resources: "settings.document_manager_settings.shared_resources"
    },
    assign_to_localisedItems_dotted: "settings.assign_to_localisedItems_dotted",
    type_item: "settings.type_item",
    custom_scheduling: "settings.custom_scheduling",
    regular_scheduling: "settings.regular_scheduling",
    event_scheduling: "settings.event_scheduling",
    job_category_scheduling_settings: "settings.job_category_scheduling_settings",
    scheduled_for_all_events: "settings.scheduled_for_all_events",
    schedule_job_how_often_label: "settings.schedule_job_how_often_label",
    select_event_type: "settings.select_event_type",
    schedule_before_or_after_event: "settings.schedule_before_or_after_event",
    days_before_event_date: "settings.days_before_event_date",
    days_after_event_date: "settings.days_after_event_date",
    jobs_will_be_created_for_all_events_label: "settings.jobs_will_be_created_for_all_events_label",
    number_of_days_before_or_after_label: "settings.number_of_days_before_or_after_label",
    choose_a_date_to_schedule_the_job: "settings.choose_a_date_to_schedule_the_job",
    start_date_of_scheduling: "settings.start_date_of_scheduling",
    end_date_of_scheduling: "settings.end_date_of_scheduling",
    starting_from: "settings.starting_from",
    schedule_until: "settings.schedule_until",
    custom: "settings.custom",
    view_checklist: "settings.view_checklist",
    discard: "settings.discard",
    schedule_job_category_modal_header: "settings.schedule_job_category_modal_header",
    schedule_job_category_modal_sub_header: "settings.schedule_job_category_modal_sub_header",
    schedule_job_category_modal_info: "settings.schedule_job_category_modal_info",
    scheduled_for_event: "settings.scheduled_for_event",
    scheduled_hourly: "settings.scheduled_hourly",
    scheduled_daily: "settings.scheduled_daily",
    scheduled_monthly: "settings.scheduled_monthly",
    scheduled_weekly: "settings.scheduled_weekly",
    public_incident_reports_settings: {
      dedicated_number: "settings.public_incident_reports_settings.dedicated_number",
      no_number_assigned: "settings.public_incident_reports_settings.no_number_assigned",
      incoming_reports_texts: "settings.public_incident_reports_settings.incoming_reports_texts",
      incoming_reports_texts_info: "settings.public_incident_reports_settings.incoming_reports_texts_info",
      response_shortcut: "settings.public_incident_reports_settings.response_shortcut",
      confirm_shortcut_deletion: "settings.public_incident_reports_settings.confirm_shortcut_deletion",
      confirm_shortcut_deletion_header: "settings.public_incident_reports_settings.confirm_shortcut_deletion_header",
      add_response_shortcut: "settings.public_incident_reports_settings.add_response_shortcut",
      prepopulated_message_updated: "settings.public_incident_reports_settings.prepopulated_message_updated",
      prepopulated_message_deleted: "settings.public_incident_reports_settings.prepopulated_message_deleted",
      keywords_specification: "settings.public_incident_reports_settings.keywords_specification",
      suggested_keywords: "settings.public_incident_reports_settings.suggested_keywords",
      suggested_keywords_info_text: "settings.public_incident_reports_settings.suggested_keywords_info_text",
      existing_keywords: "settings.public_incident_reports_settings.existing_keywords",
      confirm_keyword_deletion: "settings.public_incident_reports_settings.confirm_keyword_deletion",
      confirm_keyword_deletion_header: "settings.public_incident_reports_settings.confirm_keyword_deletion_header",
      keyword_deleted: "settings.public_incident_reports_settings.keyword_deleted",
      add_keyword_specification: "settings.public_incident_reports_settings.add_keyword_specification",
      edit_keyword_specification: "settings.public_incident_reports_settings.edit_keyword_specification",
      add_keywords_or_phrases: "settings.public_incident_reports_settings.add_keywords_or_phrases",
      edit_keywords_or_phrases: "settings.public_incident_reports_settings.edit_keywords_or_phrases"
    },
    risk_profiling_settings: {
      risk_profiling_text: "settings.risk_profiling_settings.risk_profiling_text",
      risk_profiling_questionnaire: "settings.risk_profiling_settings.risk_profiling_questionnaire",
      answer_score_definitions: "settings.risk_profiling_settings.answer_score_definitions",
      acceptability_matrix: "settings.risk_profiling_settings.acceptability_matrix",
      questionnaire_header_text: "settings.risk_profiling_settings.questionnaire_header_text",
      profiling_question: "settings.risk_profiling_settings.profiling_question",
      create_your_first_item: "settings.risk_profiling_settings.create_your_first_item",
      flag_as_high_risk: "settings.risk_profiling_settings.flag_as_high_risk",
      answer_score_definitions_sub_header: "settings.risk_profiling_settings.answer_score_definitions_sub_header",
      acceptability_matrix_sub_header: "settings.risk_profiling_settings.acceptability_matrix_sub_header",
      risk_definitions: "settings.risk_profiling_settings.risk_definitions",
      risk_score_range: "settings.risk_profiling_settings.risk_score_range",
      recommended_actions: "settings.risk_profiling_settings.recommended_actions",
      acceptability_matrix_reset_success: "settings.risk_profiling_settings.acceptability_matrix_reset_success",
      score_range_tooltip_text: "settings.risk_profiling_settings.score_range_tooltip_text",
      filter_by_event_type: "settings.risk_profiling_settings.filter_by_event_type",
      "settings.imports_and_exports.import_guides.unique_title_of_item_up_to_count_characters": "settings.risk_profiling_settings.settings.imports_and_exports.import_guides.unique_title_of_item_up_to_count_characters",
      link_to_master_risk: "settings.risk_profiling_settings.link_to_master_risk",
      remove_master_risk: "settings.risk_profiling_settings.remove_master_risk"
    }
  },
  hub: {
    common: {
      days_before_due: "hub.common.days_before_due",
      baseline_start: "hub.common.baseline_start",
      baseline_end: "hub.common.baseline_end",
      download_pdf_single: "hub.common.download_pdf_single",
      download_multiple_pdf: "hub.common.download_multiple_pdf",
      download_Current_vuew_pdf: "hub.common.download_Current_vuew_pdf",
      clear_filters: "hub.common.clear_filters",
      hub_users: "hub.common.hub_users",
      this_account: "hub.common.this_account",
      all_accounts: "hub.common.all_accounts",
      account_manager: "hub.common.account_manager",
      hub_management: "hub.common.hub_management",
      work_table: "hub.common.work_table",
      work: "hub.common.work",
      gantt_chart: "hub.common.gantt_chart",
      hub_user_update_success_msg: "hub.common.hub_user_update_success_msg",
      hub_user_delete_success_msg: "hub.common.hub_user_delete_success_msg",
      no_description: "hub.common.no_description",
      go_to_hub: "hub.common.go_to_hub",
      active_users: "hub.common.active_users",
      my_data: "hub.common.my_data",
      all_data: "hub.common.all_data"
    },
    home: {
      overview: {
        overview_title: "hub.home.overview.overview_title",
        welcome_overview_description: "hub.home.overview.welcome_overview_description",
        overview_description: "hub.home.overview.overview_description",
        overview_secondary_description: "hub.home.overview.overview_secondary_description"
      },
      account_management: {
        account_management_title: "hub.home.account_management.account_management_title",
        account_management_list: "hub.home.account_management.account_management_list",
        account_management_account_Settings: "hub.home.account_management.account_management_account_Settings",
        account_management_user_and_groups: "hub.home.account_management.account_management_user_and_groups",
        account_management_departments: "hub.home.account_management.account_management_departments",
        account_management_planning_settings: "hub.home.account_management.account_management_planning_settings",
        account_management_control_settings: "hub.home.account_management.account_management_control_settings",
        account_management_sus_settings: "hub.home.account_management.account_management_sus_settings"
      },
      hub_tabs: {
        hub_tabs_active: "hub.home.hub_tabs.hub_tabs_active",
        hub_tabs_inactive: "hub.home.hub_tabs.hub_tabs_inactive",
        hub_tabs_no_data: "hub.home.hub_tabs.hub_tabs_no_data"
      },
      planning_settings: {
        planning_milestone: "hub.home.planning_settings.planning_milestone",
        planning_autoRagUpdates: "hub.home.planning_settings.planning_autoRagUpdates",
        planning_ragDescription: "hub.home.planning_settings.planning_ragDescription",
        planning_milestone_description: "hub.home.planning_settings.planning_milestone_description",
        planning_autoRagUpdates_description: "hub.home.planning_settings.planning_autoRagUpdates_description",
        planning_ragDescription_description: "hub.home.planning_settings.planning_ragDescription_description"
      },
      account_settings_list: {
        accountLogo_background_settings: "hub.home.account_settings_list.accountLogo_background_settings",
        system_terminology: "hub.home.account_settings_list.system_terminology",
        time_date_settings: "hub.home.account_settings_list.time_date_settings",
        language_customizations: "hub.home.account_settings_list.language_customizations",
        general_settings: "hub.home.account_settings_list.general_settings",
        security_settings: "hub.home.account_settings_list.security_settings",
        custom_branding_settings: "hub.home.account_settings_list.custom_branding_settings",
        branding_settings: "hub.home.account_settings_list.branding_settings",
        accountLogo_background_settings_description: "hub.home.account_settings_list.accountLogo_background_settings_description",
        general_settings_description: "hub.home.account_settings_list.general_settings_description",
        time_date_settings_description: "hub.home.account_settings_list.time_date_settings_description",
        language_customizations_description: "hub.home.account_settings_list.language_customizations_description",
        system_terminology_description: "hub.home.account_settings_list.system_terminology_description",
        custom_branding_settings_description: "hub.home.account_settings_list.custom_branding_settings_description",
        security_settings_description: "hub.home.account_settings_list.security_settings_description",
        branding_settings_description: "hub.home.account_settings_list.branding_settings_description",
        configure_for_all_accounts: "hub.home.account_settings_list.configure_for_all_accounts",
        general_settings_success_modal: "hub.home.account_settings_list.general_settings_success_modal",
        security_settings_success_modal: "hub.home.account_settings_list.security_settings_success_modal",
        branding_settings_success_modal: "hub.home.account_settings_list.branding_settings_success_modal",
        incident_category_success_modal: "hub.home.account_settings_list.incident_category_success_modal",
        checklist_success_modal: "hub.home.account_settings_list.checklist_success_modal"
      }
    },
    gantt: {
      save_gant_view: "hub.gantt.save_gant_view",
      show_all_gantt_tree_levels: "hub.gantt.show_all_gantt_tree_levels",
      projects_task_groups_and_tasks: "hub.gantt.projects_task_groups_and_tasks",
      task_groups_and_tasks: "hub.gantt.task_groups_and_tasks",
      tasks_only: "hub.gantt.tasks_only"
    },
    myObject: "hub.myObject",
    allObject: "hub.allObject",
    project_details_modal_title: "hub.project_details_modal_title",
    work_table: {
      choose_which_columns_you_want_on_the_table: "hub.work_table.choose_which_columns_you_want_on_the_table"
    },
    simple_modals_accordeon: "hub.simple_modals_accordeon",
    hub_parent_Account_title: "hub.hub_parent_Account_title",
    hub_filters: {
      hub_filter_owner: "hub.hub_filters.hub_filter_owner",
      hub_filter_departments: "hub.hub_filters.hub_filter_departments",
      hub_change_view: "hub.hub_filters.hub_change_view"
    },
    users_groups: {
      delete_user_confirm_info: "hub.users_groups.delete_user_confirm_info",
      email_change_info: "hub.users_groups.email_change_info",
      user_edit_general_info: "hub.users_groups.user_edit_general_info",
      user_edit_modal_title: "hub.users_groups.user_edit_modal_title",
      user_add_general_info: "hub.users_groups.user_add_general_info",
      user_add_modal_title: "hub.users_groups.user_add_modal_title"
    }
  },
  common: {
    each_day: "common.each_day",
    go_to_details: "common.go_to_details",
    view_full_details: "common.view_full_details",
    help: "common.help",
    full_screen: "common.full_screen",
    add_items: "common.add_items",
    edit_items: "common.edit_items",
    refresh_data: "common.refresh_data",
    error_imageLoad: "common.error_imageLoad",
    error_imageWrongExt: "common.error_imageWrongExt",
    dashboard: "common.dashboard",
    calendar: "common.calendar",
    chat: "common.chat",
    shortcuts: "common.shortcuts",
    history: "common.history",
    list: "common.list",
    list_type: "common.list_type",
    summary_type: "common.summary_type",
    incoming_reports: "common.incoming_reports",
    copy_event_from_incoming_report: "common.copy_event_from_incoming_report",
    public_incident_reports: "common.public_incident_reports",
    message: "common.message",
    send_message: "common.send_message",
    type_reponse: "common.type_reponse",
    status_cannot_be_changed: "common.status_cannot_be_changed",
    issues: "common.issues",
    deselect_all: "common.deselect_all",
    map_view: "common.map_view",
    live_tracker: "common.live_tracker",
    gantt: "common.gantt",
    report: {
      one: "common.report.one",
      many: "common.report.many"
    },
    planning: "common.planning",
    risk: "common.risk",
    control: "common.control",
    readiness: "common.readiness",
    sustainability: "common.sustainability",
    account_hub: "common.account_hub",
    general: "common.general",
    run_sheets: "common.run_sheets",
    reporting: "common.reporting",
    here: "common.here",
    reports: {
      employeeReport: {
        task_update_last_T_days: "common.reports.employeeReport.task_update_last_T_days",
        risk_update_last_Y_days: "common.reports.employeeReport.risk_update_last_Y_days",
        inc_Assing_T_Days: "common.reports.employeeReport.inc_Assing_T_Days",
        inc_Assing_Last_Y: "common.reports.employeeReport.inc_Assing_Last_Y",
        indicator_Update_T_days: "common.reports.employeeReport.indicator_Update_T_days",
        indicator_Update_Last_Y: "common.reports.employeeReport.indicator_Update_Last_Y",
        inc_Update_T_days: "common.reports.employeeReport.inc_Update_T_days",
        inc_Update_Last_Y: "common.reports.employeeReport.inc_Update_Last_Y",
        jobs_assing_T_days: "common.reports.employeeReport.jobs_assing_T_days",
        jobs_assing_Last_Y: "common.reports.employeeReport.jobs_assing_Last_Y",
        jobs_update_T_days: "common.reports.employeeReport.jobs_update_T_days",
        jobs_update_Last_Y: "common.reports.employeeReport.jobs_update_Last_Y",
        jobs_due_next_30_days: "common.reports.employeeReport.jobs_due_next_30_days",
        jobs_due_last_30_days: "common.reports.employeeReport.jobs_due_last_30_days",
        jobs_due_last_year: "common.reports.employeeReport.jobs_due_last_year",
        sessions_past_thirty_days: "common.reports.employeeReport.sessions_past_thirty_days",
        sessions_past_year: "common.reports.employeeReport.sessions_past_year"
      }
    },
    achievement: {
      one: "common.achievement.one",
      many: "common.achievement.many"
    },
    switch_account: "common.switch_account",
    no_account_module_access: "common.no_account_module_access",
    no_employee_module_access: "common.no_employee_module_access",
    sort_edit: {
      sort_edit_list: "common.sort_edit.sort_edit_list",
      sort_edit_cards_for_item: "common.sort_edit.sort_edit_cards_for_item",
      edit_cards_for_item: "common.sort_edit.edit_cards_for_item",
      a_z_alphabetically: "common.sort_edit.a_z_alphabetically",
      z_a_alphabetically: "common.sort_edit.z_a_alphabetically",
      sending_last_to_first: "common.sort_edit.sending_last_to_first",
      sending_first_to_last: "common.sort_edit.sending_first_to_last",
      sort_edit_modal_info_for_item: "common.sort_edit.sort_edit_modal_info_for_item",
      edit_modal_info_for_item: "common.sort_edit.edit_modal_info_for_item",
      items_count_high_to_low: "common.sort_edit.items_count_high_to_low",
      item_due_date_earliest_first: "common.sort_edit.item_due_date_earliest_first",
      hide_attributes_label: "common.sort_edit.hide_attributes_label",
      reset_to_default: "common.sort_edit.reset_to_default",
      sort_edit: "common.sort_edit.sort_edit",
      rag_with_colors: "common.sort_edit.rag_with_colors",
      selected_first: "common.sort_edit.selected_first",
      created_new_to_old: "common.sort_edit.created_new_to_old",
      created_old_to_new: "common.sort_edit.created_old_to_new",
      updated_new_to_old: "common.sort_edit.updated_new_to_old",
      updated_old_to_new: "common.sort_edit.updated_old_to_new",
      title_a_to_z: "common.sort_edit.title_a_to_z",
      title_z_to_a: "common.sort_edit.title_z_to_a",
      severity_1_to_5: "common.sort_edit.severity_1_to_5",
      severity_5_to_1: "common.sort_edit.severity_5_to_1",
      created_date_ascending: "common.sort_edit.created_date_ascending",
      created_date_descending: "common.sort_edit.created_date_descending",
      updated_date_ascending: "common.sort_edit.updated_date_ascending",
      updated_date_descending: "common.sort_edit.updated_date_descending",
      title_ascending: "common.sort_edit.title_ascending",
      title_descending: "common.sort_edit.title_descending",
      severity_ascending: "common.sort_edit.severity_ascending",
      severity_descending: "common.sort_edit.severity_descending",
      task_breakdown_ascending: "common.sort_edit.task_breakdown_ascending",
      task_breakdown_descending: "common.sort_edit.task_breakdown_descending",
      owner_ascending: "common.sort_edit.owner_ascending",
      owner_descending: "common.sort_edit.owner_descending",
      functional_area_ascending: "common.sort_edit.functional_area_ascending",
      functional_area_descending: "common.sort_edit.functional_area_descending",
      rag_status_sorting_colors_grey_to_blue: "common.sort_edit.rag_status_sorting_colors_grey_to_blue",
      rag_status_sorting_colors_blue_to_grey: "common.sort_edit.rag_status_sorting_colors_blue_to_grey",
      risk_breakdown_ascending: "common.sort_edit.risk_breakdown_ascending",
      risk_breakdown_descending: "common.sort_edit.risk_breakdown_descending",
      risk_rag_low_to_significant: "common.sort_edit.risk_rag_low_to_significant",
      risk_rag_significant_to_low: "common.sort_edit.risk_rag_significant_to_low",
      risk_impact_minor_to_extreme: "common.sort_edit.risk_impact_minor_to_extreme",
      risk_impact_extreme_to_minor: "common.sort_edit.risk_impact_extreme_to_minor",
      risk_probability_unlikely_to_almost_certain: "common.sort_edit.risk_probability_unlikely_to_almost_certain",
      risk_probability_almost_certain_to_unlikely: "common.sort_edit.risk_probability_almost_certain_to_unlikely",
      issue_breakdown_ascending: "common.sort_edit.issue_breakdown_ascending",
      issue_breakdown_descending: "common.sort_edit.issue_breakdown_descending",
      opportunity_breakdown_descending: "common.sort_edit.opportunity_breakdown_descending",
      localisedEvent_ascending: "common.sort_edit.localisedEvent_ascending",
      localisedEvent_descending: "common.sort_edit.localisedEvent_descending",
      department_ascending: "common.sort_edit.department_ascending",
      department_descending: "common.sort_edit.department_descending",
      due_date_ascending: "common.sort_edit.due_date_ascending",
      due_date_descending: "common.sort_edit.due_date_descending",
      start_date_ascending: "common.sort_edit.start_date_ascending",
      start_date_descending: "common.sort_edit.start_date_descending",
      ref_code_ascending: "common.sort_edit.ref_code_ascending",
      ref_code_descending: "common.sort_edit.ref_code_descending",
      project_ascending: "common.sort_edit.project_ascending",
      project_descending: "common.sort_edit.project_descending",
      assignee_a_to_z: "common.sort_edit.assignee_a_to_z",
      most_failed_checks_first: "common.sort_edit.most_failed_checks_first",
      failed_checks_first: "common.sort_edit.failed_checks_first",
      trend_status_r_a_g_grey: "common.sort_edit.trend_status_r_a_g_grey",
      trend_status_g_a_r_grey: "common.sort_edit.trend_status_g_a_r_grey",
      due_date_most_recent_first: "common.sort_edit.due_date_most_recent_first",
      due_date_oldest_first: "common.sort_edit.due_date_oldest_first",
      due_date_earliest_to_latest: "common.sort_edit.due_date_earliest_to_latest",
      due_date_latest_to_earliest: "common.sort_edit.due_date_latest_to_earliest",
      updated_when_most_recent_first: "common.sort_edit.updated_when_most_recent_first",
      updated_when_oldest_first: "common.sort_edit.updated_when_oldest_first"
    },
    add_dependency: "common.add_dependency",
    task_complete: "common.task_complete",
    task_count: "common.task_count",
    task_due_date: "common.task_due_date",
    attribute: {
      one: "common.attribute.one",
      many: "common.attribute.many"
    },
    display_on_card: "common.display_on_card",
    sort: "common.sort",
    cancel: "common.cancel",
    apply: "common.apply",
    apply_changes: "common.apply_changes",
    apply_to_all: "common.apply_to_all",
    save: "common.save",
    search: "common.search",
    update: "common.update",
    clear: "common.clear",
    preview: "common.preview",
    close: "common.close",
    reopen: "common.reopen",
    move: "common.move",
    unsure_checks: {
      one: "common.unsure_checks.one",
      many: "common.unsure_checks.many"
    },
    failed_check: {
      one: "common.failed_check.one",
      many: "common.failed_check.many"
    },
    no_item_to_display: "common.no_item_to_display",
    to_do_count: "common.to_do_count",
    open_in_new_tab: "common.open_in_new_tab",
    open_in_modal: "common.open_in_modal",
    add_comment: "common.add_comment",
    pin_to_mytrack: "common.pin_to_mytrack",
    unpin_from_mytrack: "common.unpin_from_mytrack",
    share_item: "common.share_item",
    subscribe_to_updates: "common.subscribe_to_updates",
    unsubscribe: "common.unsubscribe",
    archive: "common.archive",
    unarchive: "common.unarchive",
    delete: "common.delete",
    edit: "common.edit",
    view_edit: "common.view_edit",
    colour: {
      one: "common.colour.one",
      many: "common.colour.many"
    },
    confirm_delete_item: "common.confirm_delete_item",
    confirm_delete_items: "common.confirm_delete_items",
    confirm_delete_element: "common.confirm_delete_element",
    confirm_delete_item_with_count_of_secondary_items: "common.confirm_delete_item_with_count_of_secondary_items",
    item_deleted: "common.item_deleted",
    items_deleted: "common.items_deleted",
    confirm: "common.confirm",
    enter_following_confirm_code: "common.enter_following_confirm_code",
    headline_status_message_info: "common.headline_status_message_info",
    headline_status_message_last_updated: "common.headline_status_message_last_updated",
    time_ago: {
      just_now: "common.time_ago.just_now",
      hour_ago: {
        one: "common.time_ago.hour_ago.one",
        many: "common.time_ago.hour_ago.many"
      },
      minute_ago: {
        one: "common.time_ago.minute_ago.one",
        many: "common.time_ago.minute_ago.many"
      },
      seconds_ago: {
        one: "common.time_ago.seconds_ago.one",
        many: "common.time_ago.seconds_ago.many"
      },
      days_ago: {
        one: "common.time_ago.days_ago.one",
        many: "common.time_ago.days_ago.many"
      },
      month_ago: {
        one: "common.time_ago.month_ago.one",
        many: "common.time_ago.month_ago.many"
      },
      more_than_7_days_ago: "common.time_ago.more_than_7_days_ago",
      more_than_1_year_ago: "common.time_ago.more_than_1_year_ago"
    },
    title_saved_successfully: "common.title_saved_successfully",
    prompt_confirm_archive_item: "common.prompt_confirm_archive_item",
    prompt_confirm_archive_items: "common.prompt_confirm_archive_items",
    prompt_confirm_unarchive_item: "common.prompt_confirm_unarchive_item",
    prompt_confirm_share_item: "common.prompt_confirm_share_item",
    archived_item_successfully: "common.archived_item_successfully",
    shared_item_successfully: "common.shared_item_successfully",
    unarchived_item_successfully: "common.unarchived_item_successfully",
    confirm_delete: "common.confirm_delete",
    lozenges_saved_successfully: "common.lozenges_saved_successfully",
    changes_were_saved_successfully: "common.changes_were_saved_successfully",
    successfully_subscribed_for_item: "common.successfully_subscribed_for_item",
    successfully_unsubscribed_for_item: "common.successfully_unsubscribed_for_item",
    unscheduled: "common.unscheduled",
    scheduled: "common.scheduled",
    none: "common.none",
    undefined: "common.undefined",
    view_all_items_with_count: "common.view_all_items_with_count",
    view_details: "common.view_details",
    no_item: "common.no_item",
    search_item: "common.search_item",
    count_items: {
      one: "common.count_items.one",
      many: "common.count_items.many"
    },
    count_matches: {
      one: "common.count_matches.one",
      many: "common.count_matches.many"
    },
    count_selected: {
      one: "common.count_selected.one",
      many: "common.count_selected.many"
    },
    count_total: "common.count_total",
    clear_all: "common.clear_all",
    select_all: "common.select_all",
    filters_exclude: {
      default_label: "common.filters_exclude.default_label",
      default_message: "common.filters_exclude.default_message"
    },
    primary_only: "common.primary_only",
    check_to_show_primary_only: "common.check_to_show_primary_only",
    title_goes_here: "common.title_goes_here",
    add_item: "common.add_item",
    quick_add_item: "common.quick_add_item",
    event_details_no_profile_text: "common.event_details_no_profile_text",
    event_details_no_assessment_text: "common.event_details_no_assessment_text",
    event_details_no_job_text: "common.event_details_no_job_text",
    event_details_no_incident_text: "common.event_details_no_incident_text",
    remove_dependency: "common.remove_dependency",
    edit_dates: "common.edit_dates",
    add_dates: "common.add_dates",
    count_days: {
      one: "common.count_days.one",
      many: "common.count_days.many"
    },
    item_rag_updated: "common.item_rag_updated",
    item_is_archived: "common.item_is_archived",
    completion: "common.completion",
    rag_description: {
      not_started: "common.rag_description.not_started",
      escalated_late: "common.rag_description.escalated_late",
      slipped: "common.rag_description.slipped",
      issues_due: "common.rag_description.issues_due",
      slipping: "common.rag_description.slipping",
      on_track: "common.rag_description.on_track",
      complete: "common.rag_description.complete"
    },
    back_to_list: "common.back_to_list",
    loading: "common.loading",
    details: "common.details",
    linked_item: {
      one: "common.linked_item.one",
      many: "common.linked_item.many"
    },
    attachment: {
      one: "common.attachment.one",
      many: "common.attachment.many"
    },
    location: {
      one: "common.location.one",
      many: "common.location.many",
      none: "common.location.none"
    },
    edit_item: "common.edit_item",
    item_status: {
      one: "common.item_status.one",
      many: "common.item_status.many"
    },
    item_privacy_status: {
      one: "common.item_privacy_status.one",
      many: "common.item_privacy_status.many"
    },
    rag_status: {
      one: "common.rag_status.one",
      many: "common.rag_status.many"
    },
    item_updated: "common.item_updated",
    items_updated: "common.items_updated",
    item_added: "common.item_added",
    items_added: "common.items_added",
    item_was_added_successfully: "common.item_was_added_successfully",
    expected_due_date: "common.expected_due_date",
    is_the_same: "common.is_the_same",
    final_item_s_due_date: "common.final_item_s_due_date",
    let_people_know_what_is_happening: "common.let_people_know_what_is_happening",
    click_here_to_update_headline_status: "common.click_here_to_update_headline_status",
    project_date_slider_baselineInfo: "common.project_date_slider_baselineInfo",
    click_to_add_description: "common.click_to_add_description",
    hide_full_description: "common.hide_full_description",
    show_full_description: "common.show_full_description",
    add_comment_or_update: "common.add_comment_or_update",
    no_items_selected: "common.no_items_selected",
    primary: "common.primary",
    non_primary: "common.non_primary",
    primary_filter_details_lozenge_tooltip_message: "common.primary_filter_details_lozenge_tooltip_message",
    no_grouping: "common.no_grouping",
    milestone: {
      one: "common.milestone.one",
      many: "common.milestone.many"
    },
    expand: "common.expand",
    collapse: "common.collapse",
    expand_all: "common.expand_all",
    collapse_all: "common.collapse_all",
    group_by: "common.group_by",
    rag_statuses: {
      grey: "common.rag_statuses.grey",
      red: "common.rag_statuses.red",
      amber: "common.rag_statuses.amber",
      green: "common.rag_statuses.green",
      blue: "common.rag_statuses.blue"
    },
    item_is_loading: "common.item_is_loading",
    show_completion_percentage: "common.show_completion_percentage",
    show_more: "common.show_more",
    show_less: "common.show_less",
    add_filter: "common.add_filter",
    my_filters: "common.my_filters",
    count_filters: {
      one: "common.count_filters.one",
      many: "common.count_filters.many"
    },
    item_title: "common.item_title",
    items_title: {},
    item_details: "common.item_details",
    include_comment: "common.include_comment",
    you_must_include_comment_to_continue: "common.you_must_include_comment_to_continue",
    add_comment_to_explain_rag_update: "common.add_comment_to_explain_rag_update",
    rag_update_item_to_blue_start_date: "common.rag_update_item_to_blue_start_date",
    rag_update_item_to_blue_due_date: "common.rag_update_item_to_blue_due_date",
    task_date_slider_baselineInfo: "common.task_date_slider_baselineInfo",
    rag_status_will_not_be_changed_automatically: "common.rag_status_will_not_be_changed_automatically",
    open_details_page: "common.open_details_page",
    no_date: "common.no_date",
    baseline_start: "common.baseline_start",
    baseline_due: "common.baseline_due",
    baseline_info: "common.baseline_info",
    show_baseline_dates: "common.show_baseline_dates",
    confirm_remove_link_for_item: "common.confirm_remove_link_for_item",
    item_removed: "common.item_removed",
    remove: "common.remove",
    category: {
      one: "common.category.one",
      many: "common.category.many"
    },
    all_items: "common.all_items",
    all_channels: "common.all_channels",
    open_items: "common.open_items",
    close_items: "common.close_items",
    upload_document: "common.upload_document",
    action: {
      one: "common.action.one",
      many: "common.action.many"
    },
    delete_item: "common.delete_item",
    delete_items: "common.delete_items",
    take_a_photo: "common.take_a_photo",
    choose_a_file: "common.choose_a_file",
    add_link: "common.add_link",
    link_to_incident: "common.link_to_incident",
    link_to_log: "common.link_to_log",
    link_to_job: "common.link_to_job",
    create: "common.create",
    create_incident: "common.create_incident",
    create_log: "common.create_log",
    create_job: "common.create_job",
    take_photo_or_upload_file: "common.take_photo_or_upload_file",
    download: "common.download",
    document_category_updated_successfully: "common.document_category_updated_successfully",
    document_category_update_failed: "common.document_category_update_failed",
    item_removed_successfully: "common.item_removed_successfully",
    type: {
      one: "common.type.one",
      many: "common.type.many"
    },
    document: {
      one: "common.document.one",
      many: "common.document.many"
    },
    file_to_upload_is_too_large_try_smaller_file: "common.file_to_upload_is_too_large_try_smaller_file",
    file_to_upload_not_supported_try_different_file: "common.file_to_upload_not_supported_try_different_file",
    link: {
      one: "common.link.one",
      many: "common.link.many",
      verb: "common.link.verb"
    },
    no_location_for_this_item: "common.no_location_for_this_item",
    assign_subscriber_s: "common.assign_subscriber_s",
    assign_group_s: "common.assign_group_s",
    no_subscribers: "common.no_subscribers",
    no_subscriber_groups: "common.no_subscriber_groups",
    employee_added_to_subscribers: "common.employee_added_to_subscribers",
    group_added_to_subscriber_groups: "common.group_added_to_subscriber_groups",
    employee_removed_from_subscribers: "common.employee_removed_from_subscribers",
    group_removed_from_subscriber_groups: "common.group_removed_from_subscriber_groups",
    all_history: "common.all_history",
    comments_only: "common.comments_only",
    comment: {
      one: "common.comment.one",
      many: "common.comment.many"
    },
    item: {
      one: "common.item.one",
      many: "common.item.many"
    },
    parent_item: "common.parent_item",
    learn_more: "common.learn_more",
    dependency: {
      one: "common.dependency.one",
      many: "common.dependency.many"
    },
    completion_tooltip_for_item: "common.completion_tooltip_for_item",
    back: "common.back",
    importance: "common.importance",
    created: "common.created",
    you_dont_have_permission_to_edit: "common.you_dont_have_permission_to_edit",
    assign_item: "common.assign_item",
    assign_items: "common.assign_items",
    are_sure_you_want_to_leave_page: "common.are_sure_you_want_to_leave_page",
    stay: "common.stay",
    leave: "common.leave",
    upstream: "common.upstream",
    downstream: "common.downstream",
    are_you_sure_you_want_to_remove_dependency: "common.are_you_sure_you_want_to_remove_dependency",
    count_item_selected: {
      one: "common.count_item_selected.one",
      many: "common.count_item_selected.many"
    },
    selected_items: "common.selected_items",
    only_show_dependencies_after_item: "common.only_show_dependencies_after_item",
    only_show_dependencies_before_item: "common.only_show_dependencies_before_item",
    select_items_to_link: "common.select_items_to_link",
    select_item_type_to_link: "common.select_item_type_to_link",
    item_type_to_link: "common.item_type_to_link",
    add_count_item: "common.add_count_item",
    select_filter: "common.select_filter",
    show_all_filters: "common.show_all_filters",
    privacy: {
      one: "common.privacy.one",
      many: "common.privacy.many"
    },
    the_date_by_which_your_item_should_be_completed: "common.the_date_by_which_your_item_should_be_completed",
    what_s_this: "common.what_s_this",
    assign_me: "common.assign_me",
    assign_me_as_an_owner: "common.assign_me_as_an_owner",
    group: {
      none: "common.group.none",
      one: "common.group.one",
      many: "common.group.many"
    },
    assign_lead_localisedDepartment: "common.assign_lead_localisedDepartment",
    assign_my_localisedDepartment: "common.assign_my_localisedDepartment",
    status: {
      one: "common.status.one",
      many: "common.status.many"
    },
    keyboard_shortcuts: "common.keyboard_shortcuts",
    save_and_close: "common.save_and_close",
    save_and_view_details: "common.save_and_view_details",
    save_and_add_another: "common.save_and_add_another",
    save_amp_close: "common.save_amp_close",
    save_amp_view_details: "common.save_amp_view_details",
    save_amp_add_another: "common.save_amp_add_another",
    save_amp_add_item: "common.save_amp_add_item",
    add: "common.add",
    add_new_item: "common.add_new_item",
    assign_owner: {
      one: "common.assign_owner.one",
      many: "common.assign_owner.many"
    },
    start_date: {
      one: "common.start_date.one",
      many: "common.start_date.many"
    },
    end_date: {
      one: "common.end_date.one",
      many: "common.end_date.many"
    },
    add_description: "common.add_description",
    add_questions: "common.add_questions",
    something_happened: "common.something_happened",
    risk_add_modal_title_tooltip: "common.risk_add_modal_title_tooltip",
    risk_add_modal_impact_type_tooltip: "common.risk_add_modal_impact_type_tooltip",
    risk_add_modal_strategy_tooltip: "common.risk_add_modal_strategy_tooltip",
    likelihood: {
      one: "common.likelihood.one",
      many: "common.likelihood.many"
    },
    impact: {
      one: "common.impact.one",
      many: "common.impact.many"
    },
    impact_type: {
      one: "common.impact_type.one",
      many: "common.impact_type.many"
    },
    strategy: {
      one: "common.strategy.one",
      many: "common.strategy.many"
    },
    no_insurance_yet: "common.no_insurance_yet",
    all: "common.all",
    completed_jobs: "common.completed_jobs",
    no_elements_to_display: "common.no_elements_to_display",
    rag_summary: "common.rag_summary",
    items_list: "common.items_list",
    open: {
      one: "common.open.one",
      many: "common.open.many"
    },
    draft: {
      one: "common.draft.one",
      many: "common.draft.many"
    },
    closed: {
      one: "common.closed.one",
      many: "common.closed.many"
    },
    archived: {
      one: "common.archived.one",
      many: "common.archived.many"
    },
    favourites: "common.favourites",
    suggested: "common.suggested",
    my_filters_favourite_helper: "common.my_filters_favourite_helper",
    enter_title_for_your_filters: "common.enter_title_for_your_filters",
    count_favourites: "common.count_favourites",
    count_suggested: "common.count_suggested",
    select_favorite_to_update: "common.select_favorite_to_update",
    clear_selected: "common.clear_selected",
    planning_history: "common.planning_history",
    history_feed_default_description: "common.history_feed_default_description",
    baseline_rag_status: "common.baseline_rag_status",
    baseline_likelihood: "common.baseline_likelihood",
    baseline_impact: "common.baseline_impact",
    click_to_add_additional_info: "common.click_to_add_additional_info",
    edit_location: "common.edit_location",
    insurance_policy: {
      one: "common.insurance_policy.one",
      many: "common.insurance_policy.many"
    },
    change_risk_to_opportunity: "common.change_risk_to_opportunity",
    change_opportunity_to_risk: "common.change_opportunity_to_risk",
    other_items: "common.other_items",
    all_types: "common.all_types",
    planning_dashboard: "common.planning_dashboard",
    countdown: "common.countdown",
    countdown_complete: "common.countdown_complete",
    no_countdown_set: "common.no_countdown_set",
    admin_users_can_set_countdown_in_dashboard: "common.admin_users_can_set_countdown_in_dashboard",
    getting_ready: "common.getting_ready",
    momentum: "common.momentum",
    item_rag_breakdown: "common.item_rag_breakdown",
    no_item_rags_to_display_for_this_filter_type: "common.no_item_rags_to_display_for_this_filter_type",
    milestone_snapshot: "common.milestone_snapshot",
    item_milestone_snapshot: "common.item_milestone_snapshot",
    items_snapshot: "common.items_snapshot",
    no_items_are_due_last_week_this_week_and_next_week: "common.no_items_are_due_last_week_this_week_and_next_week",
    no_milestone_milestones_are_due_last_week_and_next_week: "common.no_milestone_milestones_are_due_last_week_and_next_week",
    localisedTasks_and_milestone_snapshot: "common.localisedTasks_and_milestone_snapshot",
    localised_overdue_milestones: "common.localised_overdue_milestones",
    data_view: "common.data_view",
    reload: "common.reload",
    no_items_created_or_closed_in_last_3_weeks: "common.no_items_created_or_closed_in_last_3_weeks",
    by_item: "common.by_item",
    completed_on_time: {
      one: "common.completed_on_time.one",
      many: "common.completed_on_time.many"
    },
    completed_on_time_last_30_days: "common.completed_on_time_last_30_days",
    no_data_available: "common.no_data_available",
    finding_your_location_this_may_take_while: "common.finding_your_location_this_may_take_while",
    options: "common.options",
    reset_layout: "common.reset_layout",
    general_info: "common.general_info",
    add_location: "common.add_location",
    confirm_move_task_groupd_into_project: "common.confirm_move_task_groupd_into_project",
    show_all_items: "common.show_all_items",
    show_all_channels: "common.show_all_channels",
    which_items_are_affected: "common.which_items_are_affected",
    which_items_are_involved: "common.which_items_are_involved",
    which_individuals_are_involved_and_should_be_notified: "common.which_individuals_are_involved_and_should_be_notified",
    which_groups_of_localisedEmployees_should_be_notified: "common.which_groups_of_localisedEmployees_should_be_notified",
    make_confidential: "common.make_confidential",
    create_as_draft: "common.create_as_draft",
    severity: {
      one: "common.severity.one",
      many: "common.severity.many"
    },
    uploads: "common.uploads",
    assign: "common.assign",
    add_another: "common.add_another",
    skip: "common.skip",
    continue: "common.continue",
    add_owner: {
      one: "common.add_owner.one",
      many: "common.add_owner.many"
    },
    add_employee_group: {
      one: "common.add_employee_group.one",
      many: "common.add_employee_group.many"
    },
    browse: "common.browse",
    or_add_a_link: "common.or_add_a_link",
    drag_or_paste_files_here_to_upload: "common.drag_or_paste_files_here_to_upload",
    the_file_is_too_large_try_smaller: "common.the_file_is_too_large_try_smaller",
    the_file_is_not_supported_try_different_type: "common.the_file_is_not_supported_try_different_type",
    create_item: "common.create_item",
    add_details: "common.add_details",
    save_and_go_to_item_details: "common.save_and_go_to_item_details",
    item_title_and_summary: "common.item_title_and_summary",
    item_location: "common.item_location",
    want_learn_more_visit_help_centre: "common.want_learn_more_visit_help_centre",
    what_needs_to_be_done: "common.what_needs_to_be_done",
    showing_count_of_total_items: "common.showing_count_of_total_items",
    sort_list: "common.sort_list",
    opened_today: "common.opened_today",
    open_for: "common.open_for",
    item_status_updated: "common.item_status_updated",
    headline_status_updated: "common.headline_status_updated",
    update_headline_status: "common.update_headline_status",
    your_change_may_affect_rag_rating: "common.your_change_may_affect_rag_rating",
    please_add_comment_or_update_bellow: "common.please_add_comment_or_update_bellow",
    add_item_update: "common.add_item_update",
    item_shared_successfully: "common.item_shared_successfully",
    share_this_localisedItem: "common.share_this_localisedItem",
    add_user_below_to_share_details_of_this_item: "common.add_user_below_to_share_details_of_this_item",
    share: "common.share",
    pull_to_refresh: "common.pull_to_refresh",
    the_item_was_updated_successfully: "common.the_item_was_updated_successfully",
    are_you_sure_you_want_to_leave_changes_may_not_be_saved: "common.are_you_sure_you_want_to_leave_changes_may_not_be_saved",
    are_you_sure_you_want_to_leave_text_changes_may_not_be_saved: "common.are_you_sure_you_want_to_leave_text_changes_may_not_be_saved",
    show_all_item_types: "common.show_all_item_types",
    due_including_a_date: "common.due_including_a_date",
    due_days: "common.due_days",
    due_in_days: "common.due_in_days",
    overdue: {
      one: "common.overdue.one",
      many: "common.overdue.many"
    },
    ongoing: "common.ongoing",
    starting_today: "common.starting_today",
    ending_today: "common.ending_today",
    due_today: "common.due_today",
    due_yesterday: "common.due_yesterday",
    upcoming: {
      one: "common.upcoming.one",
      many: "common.upcoming.many"
    },
    status_summary: "common.status_summary",
    type_and_press_to_add_item: "common.type_and_press_to_add_item",
    select_category_to_quick_add_item: "common.select_category_to_quick_add_item",
    select_status: "common.select_status",
    select_priority: "common.select_priority",
    no_item_category: "common.no_item_category",
    checks_amp_history: "common.checks_amp_history",
    leave_as_new: "common.leave_as_new",
    change_to_inProgress: "common.change_to_inProgress",
    incomplete: {
      one: "common.incomplete.one",
      many: "common.incomplete.many"
    },
    complete: {
      one: "common.complete.one",
      many: "common.complete.many"
    },
    new_item: "common.new_item",
    add_new_job: "common.add_new_job",
    view_all_jobs: "common.view_all_jobs",
    no_jobs_added: "common.no_jobs_added",
    no_risk_added: "common.no_risk_added",
    no_risk_actions_added: "common.no_risk_actions_added",
    add_a_risk_to_start_your_assessment: "common.add_a_risk_to_start_your_assessment",
    star_items_that_are_important: "common.star_items_that_are_important",
    star_this_item: "common.star_this_item",
    subscribed: "common.subscribed",
    count_slash_total_completed: "common.count_slash_total_completed",
    required: {
      one: "common.required.one",
      many: "common.required.many"
    },
    active: {
      one: "common.active.one",
      many: "common.active.many"
    },
    inactive: {
      one: "common.inactive.one",
      many: "common.inactive.many"
    },
    past: {
      one: "common.past.one",
      many: "common.past.many"
    },
    no_items_match_these_filters: "common.no_items_match_these_filters",
    n_a: "common.n_a",
    view: "common.view",
    sent: "common.sent",
    assignee: {
      one: "common.assignee.one",
      many: "common.assignee.many"
    },
    start_item: "common.start_item",
    complete_item: "common.complete_item",
    finish_item: "common.finish_item",
    start_time_set: "common.start_time_set",
    never: "common.never",
    immediately: "common.immediately",
    can_see: "common.can_see",
    can_edit: "common.can_edit",
    success: "common.success",
    copy: "common.copy",
    url_copied: "common.url_copied",
    click_to_update: "common.click_to_update",
    and: "common.and",
    use_item: "common.use_item",
    use_none: "common.use_none",
    downloading_file: "common.downloading_file",
    uploading_file: "common.uploading_file",
    you_have_to_select_pdf_file: "common.you_have_to_select_pdf_file",
    you_can_only_upload_1_file_at_time: "common.you_can_only_upload_1_file_at_time",
    there_was_an_error_uploading_file: "common.there_was_an_error_uploading_file",
    file_uploaded_successfully: "common.file_uploaded_successfully",
    send_invites: "common.send_invites",
    manage_items: "common.manage_items",
    permanent_delete: "common.permanent_delete",
    invitations_sent_to_localisedEmployees: "common.invitations_sent_to_localisedEmployees",
    successfully_deleted_the_group_groupName: "common.successfully_deleted_the_group_groupName",
    successfully_added_the_group_groupName: "common.successfully_added_the_group_groupName",
    successfully_updated_the_group_groupName: "common.successfully_updated_the_group_groupName",
    there_was_a_problem: "common.there_was_a_problem",
    show_all: "common.show_all",
    hubRole: "common.hubRole",
    username: "common.username",
    first_name: "common.first_name",
    surname: "common.surname",
    last_name: "common.last_name",
    you_should_select_items_first: "common.you_should_select_items_first",
    planning_module: "common.planning_module",
    risk_module: "common.risk_module",
    control_module: "common.control_module",
    readiness_module: "common.readiness_module",
    run_sheets_module: "common.run_sheets_module",
    sustainability_module: "common.sustainability_module",
    manage_groups: "common.manage_groups",
    select_item: "common.select_item",
    select_items: "common.select_items",
    only_their_localisedDepartment: "common.only_their_localisedDepartment",
    confidential_items: "common.confidential_items",
    can_acess_items: "common.can_acess_items",
    everything: "common.everything",
    color: "common.color",
    item_could_not_be_deleted_went_wrong: "common.item_could_not_be_deleted_went_wrong",
    items_could_not_be_deleted_went_wrong: "common.items_could_not_be_deleted_went_wrong",
    item_ref: "common.item_ref",
    item_could_not_be_added_went_wrong: "common.item_could_not_be_added_went_wrong",
    item_could_not_be_updated_went_wrong: "common.item_could_not_be_updated_went_wrong",
    items_could_not_be_updated_went_wrong: "common.items_could_not_be_updated_went_wrong",
    item_could_not_be_archived_went_wrong: "common.item_could_not_be_archived_went_wrong",
    items_could_not_be_archived_went_wrong: "common.items_could_not_be_archived_went_wrong",
    item_settings_updated: "common.item_settings_updated",
    PercentageUpToDate: "common.PercentageUpToDate",
    UpdatesPastYear: "common.UpdatesPastYear",
    TotalIndicators: "common.TotalIndicators",
    UpdatesDueNext30Days: "common.UpdatesDueNext30Days",
    UpdatesPast30Days: "common.UpdatesPast30Days",
    organisationtags: "common.organisationtags",
    website: "common.website",
    item_manager: "common.item_manager",
    sort_items_by: "common.sort_items_by",
    before: "common.before",
    after: "common.after",
    label_updated: "common.label_updated",
    configure_milestone_names: "common.configure_milestone_names",
    label: "common.label",
    item_filter: "common.item_filter",
    item_filters: "common.item_filters",
    no_elements_found: "common.no_elements_found",
    last_updated: "common.last_updated",
    unsupported_file_type: "common.unsupported_file_type",
    enable: "common.enable",
    disable: "common.disable",
    starred: "common.starred",
    reorder: "common.reorder",
    select_type: "common.select_type",
    completed: {
      one: "common.completed.one",
      many: "common.completed.many"
    },
    get_started: "common.get_started",
    setup: "common.setup",
    step_count: "common.step_count",
    sort_items: "common.sort_items",
    add_selected: "common.add_selected",
    add_all: "common.add_all",
    remove_selected: "common.remove_selected",
    remove_all: "common.remove_all",
    item_group: "common.item_group",
    localisedItem_removed: "common.localisedItem_removed",
    item_assigned: "common.item_assigned",
    send_date: "common.send_date",
    sending_date: "common.sending_date",
    sent_date: "common.sent_date",
    external_localisedEmployee: "common.external_localisedEmployee",
    localisedEmployee_s_profile: "common.localisedEmployee_s_profile",
    assign_localisedEmployee_for: "common.assign_localisedEmployee_for",
    unassigned: "common.unassigned",
    assign_localisedEmployee: "common.assign_localisedEmployee",
    icon: {
      one: "common.icon.one",
      many: "common.icon.many"
    },
    choose_an_icon: "common.choose_an_icon",
    update_item: "common.update_item",
    update_items: "common.update_items",
    unit: {
      one: "common.unit.one",
      many: "common.unit.many"
    },
    abbreviation: "common.abbreviation",
    no_item_selected: "common.no_item_selected",
    map: "common.map",
    localisedAreas_in_the_localisedZone: "common.localisedAreas_in_the_localisedZone",
    level: {
      one: "common.level.one",
      many: "common.level.many"
    },
    note: "common.note",
    item_title_is_required: "common.item_title_is_required",
    cancel_upload: "common.cancel_upload",
    cancel_selection: "common.cancel_selection",
    select: "common.select",
    are_you_sure_archive_confirm_text: "common.are_you_sure_archive_confirm_text",
    go_back: "common.go_back",
    try_again: "common.try_again",
    error_details: "common.error_details",
    column: "common.column",
    do_you_want_to_proceed: "common.do_you_want_to_proceed",
    go_to_mytrack: "common.go_to_mytrack",
    id: "common.id",
    id_in_header: "common.id_in_header",
    notes: "common.notes",
    item_id: "common.item_id",
    day_before_after: "common.day_before_after",
    duration: "common.duration",
    depends_on: "common.depends_on",
    requires_completion: "common.requires_completion",
    debrief: "common.debrief",
    time: "common.time",
    parent: "common.parent",
    action_status: "common.action_status",
    lead_localisedDepartment: "common.lead_localisedDepartment",
    value: "common.value",
    can_see_edit_confidential_items: "common.can_see_edit_confidential_items",
    item_categories: "common.item_categories",
    live: "common.live",
    choose_item: "common.choose_item",
    set_live: "common.set_live",
    yes: "common.yes",
    no: "common.no",
    duty_manager: "common.duty_manager",
    contact: "common.contact",
    key_updates: "common.key_updates",
    item_title_exact: "common.item_title_exact",
    submitted: {
      one: "common.submitted.one",
      many: "common.submitted.many"
    },
    failed: {
      one: "common.failed.one",
      many: "common.failed.many"
    },
    not_updated: {
      one: "common.not_updated.one",
      many: "common.not_updated.many"
    },
    this_field_is_required: "common.this_field_is_required",
    adding_item: "common.adding_item",
    no_items_added_yet: "common.no_items_added_yet",
    you_can_filter_add_items_from_the_left_pane: "common.you_can_filter_add_items_from_the_left_pane",
    count_to_be_added: "common.count_to_be_added",
    count_existing: "common.count_existing",
    remove_time: "common.remove_time",
    set_time: "common.set_time",
    status_guide: "common.status_guide",
    count_of_total_complete: "common.count_of_total_complete",
    no_elements: "common.no_elements",
    save_item: "common.save_item",
    add_another_item: "common.add_another_item",
    ok: "common.ok",
    press_ctrl_enter_or_click_to_save: "common.press_ctrl_enter_or_click_to_save",
    press_ctrl_enter_to_save: "common.press_ctrl_enter_to_save",
    click_away_to_save: "common.click_away_to_save",
    partial: "common.partial",
    passed: "common.passed",
    no_result: "common.no_result",
    show_items: "common.show_items",
    are_you_sure: "common.are_you_sure",
    item_date_updated: "common.item_date_updated",
    view_all: "common.view_all",
    view_item_details: "common.view_item_details",
    show_items_in_calendar: "common.show_items_in_calendar",
    copy_of_item: "common.copy_of_item",
    duplicate_of_item: "common.duplicate_of_item",
    item_duplicated: "common.item_duplicated",
    click_to_add_notes: "common.click_to_add_notes",
    add_notes: "common.add_notes",
    title_was_updated: "common.title_was_updated",
    duplicate_item: "common.duplicate_item",
    export_to_pdf: "common.export_to_pdf",
    export_to_excel: "common.export_to_excel",
    table_view: "common.table_view",
    gantt_view: "common.gantt_view",
    applied_filters: "common.applied_filters",
    view_history: "common.view_history",
    add_update: "common.add_update",
    please_select_starting_time: "common.please_select_starting_time",
    showing_all_items: "common.showing_all_items",
    range_semicolon_for_time: "common.range_semicolon_for_time",
    remove_range: "common.remove_range",
    select_zone_modal: {
      please_select_zone_info_1: "common.select_zone_modal.please_select_zone_info_1",
      please_select_zone_info_2: "common.select_zone_modal.please_select_zone_info_2",
      please_select_zone_info_3: "common.select_zone_modal.please_select_zone_info_3"
    },
    clear_value: "common.clear_value",
    unachieved_only: "common.unachieved_only",
    achieved: "common.achieved",
    loading_items: "common.loading_items",
    no_items_found: "common.no_items_found",
    getting_started: "common.getting_started",
    visit_our_help_center_to_learn_more: "common.visit_our_help_center_to_learn_more",
    add_new: "common.add_new",
    view_items: "common.view_items",
    drag_and_drop_logo_here: "common.drag_and_drop_logo_here",
    square_logos_recommended: "common.square_logos_recommended",
    or_upload_from_files: "common.or_upload_from_files",
    drop_the_file_here: "common.drop_the_file_here",
    ajdust_the_cropping_tool: "common.ajdust_the_cropping_tool",
    next: "common.next",
    update_settings: "common.update_settings",
    owner_s: "common.owner_s",
    view_more: "common.view_more",
    view_updates: "common.view_updates",
    hide_details: "common.hide_details",
    all_achievements: "common.all_achievements",
    dashboard_changes_saved_successfully: "common.dashboard_changes_saved_successfully",
    something_happened_try_again: "common.something_happened_try_again",
    empty: "common.empty",
    your_changes_may_be_lost_confirm_go_back: "common.your_changes_may_be_lost_confirm_go_back",
    item_module: "common.item_module",
    you_changes_may_be_lost_confirm_quit: "common.you_changes_may_be_lost_confirm_quit",
    my_items: "common.my_items",
    day: {
      one: "common.day.one",
      many: "common.day.many"
    },
    week: {
      one: "common.week.one",
      many: "common.week.many"
    },
    month: {
      one: "common.month.one",
      many: "common.month.many"
    },
    year: {
      one: "common.year.one",
      many: "common.year.many"
    },
    clear_restriction: "common.clear_restriction",
    connected_links_count: "common.connected_links_count",
    restriction_applied: "common.restriction_applied",
    grid: "common.grid",
    gantt_chart: "common.gantt_chart",
    add_item_to_this_secondaryItem: "common.add_item_to_this_secondaryItem",
    will_depend_on: "common.will_depend_on",
    dependency_created_successfully: "common.dependency_created_successfully",
    create_dependency: "common.create_dependency",
    delete_dependency: "common.delete_dependency",
    gantt_delete_dependency_msg: "common.gantt_delete_dependency_msg",
    save_changes: "common.save_changes",
    gantt_save_changes_msg: "common.gantt_save_changes_msg",
    item_dates_updated_successfully: "common.item_dates_updated_successfully",
    gantt_is_loading: "common.gantt_is_loading",
    lengthy_operation_in_progress: "common.lengthy_operation_in_progress",
    there_are_no_results_for_this_filter_combination: "common.there_are_no_results_for_this_filter_combination",
    view_summary: "common.view_summary",
    default_view: "common.default_view",
    timeline_of_items: "common.timeline_of_items",
    items_live_in_next_30_days: "common.items_live_in_next_30_days",
    save_as_dots: "common.save_as_dots",
    choose_localisedTask_dependency_tree: "common.choose_localisedTask_dependency_tree",
    gantt_options: "common.gantt_options",
    grid_options: "common.grid_options",
    view_options: "common.view_options",
    gantt_views: "common.gantt_views",
    name_is_required: "common.name_is_required",
    timeline_zoom: "common.timeline_zoom",
    set_table_columns: "common.set_table_columns",
    customise_table: "common.customise_table",
    show_hide_columns: "common.show_hide_columns",
    show_hide_columns_and_rows: "common.show_hide_columns_and_rows",
    choose_which_columns_and_rows_you_want: "common.choose_which_columns_and_rows_you_want",
    columns: "common.columns",
    rows: "common.rows",
    uncheck_note: "common.uncheck_note",
    timeline_restrictions: "common.timeline_restrictions",
    note_localisedTask_editing_and_dep_creation: "common.note_localisedTask_editing_and_dep_creation",
    starting: "common.starting",
    ending: "common.ending",
    preserve_original_localisedTask_dates_in_the_grid: "common.preserve_original_localisedTask_dates_in_the_grid",
    group_gantt_by: "common.group_gantt_by",
    general_options: "common.general_options",
    show_localisedTasks_baselines: "common.show_localisedTasks_baselines",
    show_weekends: "common.show_weekends",
    week_starts_on_monday: "common.week_starts_on_monday",
    show_today_marker: "common.show_today_marker",
    show_critical_path: "common.show_critical_path",
    show_milestone_animations: "common.show_milestone_animations",
    show_titles_on_timeline: "common.show_titles_on_timeline",
    show_avatars_on_timeline: "common.show_avatars_on_timeline",
    sorting_options: "common.sorting_options",
    gantt_sorting_options_note: "common.gantt_sorting_options_note",
    gantt_sorting_options_note_2: "common.gantt_sorting_options_note_2",
    export_options: "common.export_options",
    export_current_gantt_view: "common.export_current_gantt_view",
    main: "common.main",
    items_tag_groups: "common.items_tag_groups",
    youre_about_to_go_heave_on_detail_day: "common.youre_about_to_go_heave_on_detail_day",
    auto: "common.auto",
    quarter: {
      one: "common.quarter.one",
      many: "common.quarter.many"
    },
    select_live_localisedEvent: "common.select_live_localisedEvent",
    localisedDepartment_localisedTask_rag_breakdown: "common.localisedDepartment_localisedTask_rag_breakdown",
    indicator_trend_rag_breakdown: "common.indicator_trend_rag_breakdown",
    milestones_due_this_month: "common.milestones_due_this_month",
    nothing_to_display: "common.nothing_to_display",
    localisedDepCategory_localisedTask_rag_breakdown: "common.localisedDepCategory_localisedTask_rag_breakdown",
    weather_forecast: "common.weather_forecast",
    manage_widgets_and_layout: "common.manage_widgets_and_layout",
    customize_your_dashboard_by_dragging: "common.customize_your_dashboard_by_dragging",
    dashboard_widgets: "common.dashboard_widgets",
    my_track: "common.my_track",
    title_cant_contain_special_chars: "common.title_cant_contain_special_chars",
    view_all_risks_and_issues: "common.view_all_risks_and_issues",
    see_all: "common.see_all",
    hide: "common.hide",
    show: "common.show",
    relevance: "common.relevance",
    suggested_risks: "common.suggested_risks",
    suggested_risks_text: "common.suggested_risks_text",
    select_relevant_risks: "common.select_relevant_risks",
    update_risk_details: "common.update_risk_details",
    update_risk_details_text: "common.update_risk_details_text",
    count_of_actions: {
      one: "common.count_of_actions.one",
      many: "common.count_of_actions.many",
      none: "common.count_of_actions.none"
    },
    selected_of: "common.selected_of",
    select_actions_to_include: "common.select_actions_to_include",
    save_settings_and_add_risk: "common.save_settings_and_add_risk",
    use_risk: "common.use_risk",
    remove_risk: "common.remove_risk",
    create_number_of_risks: "common.create_number_of_risks",
    number_risks_selected: "common.number_risks_selected",
    count_of_total_actions_completed: "common.count_of_total_actions_completed",
    count_of_total_completed: "common.count_of_total_completed",
    risk_rating: "common.risk_rating",
    baseline_rating: "common.baseline_rating",
    hide_baseline_rating: "common.hide_baseline_rating",
    show_baseline_rating: "common.show_baseline_rating",
    files: "common.files",
    all_statuses: "common.all_statuses",
    board: "common.board",
    invalid_code: "common.invalid_code",
    confirm_code_is_not_correct: "common.confirm_code_is_not_correct",
    confirmation_code: "common.confirmation_code",
    risk_is_changed_to_opportunity: "common.risk_is_changed_to_opportunity",
    opportunity_is_changed_to_risk: "common.opportunity_is_changed_to_risk",
    remove_link: "common.remove_link",
    view_todays_items: "common.view_todays_items",
    count_of_total_check_completed: "common.count_of_total_check_completed",
    count_incomplete: "common.count_incomplete",
    count_failed_slash_poor: "common.count_failed_slash_poor",
    count_unsure_slash_fair: "common.count_unsure_slash_fair",
    actions_and_controls: "common.actions_and_controls",
    controls_and_actions: "common.controls_and_actions",
    controls_and_jobs: "common.controls_and_jobs",
    titleOnly_modals_share: "common.titleOnly_modals_share",
    show_completed: "common.show_completed",
    hide_completed: "common.hide_completed",
    sub_tasks: "common.sub_tasks",
    action_step_type: "common.action_step_type",
    comments_and_rag_updates: "common.comments_and_rag_updates",
    comments_and_grab_updates: "common.comments_and_grab_updates",
    incomplete_evaluation_actions: "common.incomplete_evaluation_actions",
    capability_expected_results: "common.capability_expected_results",
    most_recent: "common.most_recent",
    all_actions: "common.all_actions",
    my_actions: "common.my_actions",
    department_category_summary: "common.department_category_summary",
    task_group_summary: "common.task_group_summary",
    incident_category_summary: "common.incident_category_summary",
    zone_summary: "common.zone_summary",
    impact_type_summary: "common.impact_type_summary",
    changed_from: "common.changed_from",
    changed_to: "common.changed_to",
    longitude: "common.longitude",
    latitude: "common.latitude",
    completed_by: "common.completed_by",
    parent_rio: "common.parent_rio",
    parent_item_type: "common.parent_item_type",
    parent_impact: "common.parent_impact",
    parent_likelihood: "common.parent_likelihood",
    parent_rag: "common.parent_rag",
    parent_impact_type: "common.parent_impact_type",
    parent_financial_impact: "common.parent_financial_impact",
    full_name: "common.full_name",
    actual_due_date: "common.actual_due_date",
    action_steps: "common.action_steps",
    incomplete_actions: "common.incomplete_actions",
    parent_id: "common.parent_id",
    parent_title: "common.parent_title",
    percentage_completion: "common.percentage_completion",
    baseline_start_differential: "common.baseline_start_differential",
    baseline_due_differential: "common.baseline_due_differential",
    evaluation_count: "common.evaluation_count",
    breakdown: "common.breakdown",
    date_range: "common.date_range",
    venue: "common.venue",
    task_system: "common.task_system",
    venue_category: "common.venue_category",
    venue_type: "common.venue_type",
    venue_type_localised: "common.venue_type_localised",
    venue_details_localised: "common.venue_details_localised",
    venue_details_info_text_localised: "common.venue_details_info_text_localised",
    save_location: "common.save_location",
    clear_location: "common.clear_location",
    venue_tag: "common.venue_tag",
    capacity: "common.capacity",
    health_and_safety: "common.health_and_safety",
    client_group: "common.client_group",
    venue_department: "common.venue_department",
    "task_has attachments": "common.task_has attachments",
    "task_has dependencies": "common.task_has dependencies",
    hide_confidential: "common.hide_confidential",
    incident_has_attachments: "common.incident_has_attachments",
    show_archived_incidents: "common.show_archived_incidents",
    view_incidents: "common.view_incidents",
    api_access: "common.api_access",
    date_range_type: "common.date_range_type",
    idscsv: "common.idscsv",
    current_employee_id: "common.current_employee_id",
    global_object_id: "common.global_object_id",
    global_object_type: "common.global_object_type",
    output_source_column: "common.output_source_column",
    output_source_asc: "common.output_source_asc",
    url: "common.url",
    report_history_types: "common.report_history_types",
    show_archived: "common.show_archived",
    has_attachments: "common.has_attachments",
    chart_object_type: "common.chart_object_type",
    chart_object: "common.chart_object",
    time_scale: "common.time_scale",
    log_types: "common.log_types",
    filter_dates_by: "common.filter_dates_by",
    date_ranges: "common.date_ranges",
    tag_category: "common.tag_category",
    has_incomplete_actions: "common.has_incomplete_actions",
    show_only_incomplete_actions: "common.show_only_incomplete_actions",
    not_mapped_to_an_activity: "common.not_mapped_to_an_activity",
    action_item: "common.action_item",
    expected_severity: "common.expected_severity",
    module: "common.module",
    import_upload_object: "common.import_upload_object",
    parent_task: "common.parent_task",
    task_breakdown: "common.task_breakdown",
    project_privacy_status: "common.project_privacy_status",
    checkpoint: "common.checkpoint",
    parent_only: "common.parent_only",
    show_empty: "common.show_empty",
    location_longitude: "common.location_longitude",
    location_details: "common.location_details",
    runsheet_item_dependencies_count: "common.runsheet_item_dependencies_count",
    runsheet_item_dependencies: "common.runsheet_item_dependencies",
    indicator: "common.indicator",
    indicator_target_badge_type: "common.indicator_target_badge_type",
    indicator_template_target: "common.indicator_template_target",
    job_priority: "common.job_priority",
    hub_object_types: "common.hub_object_types",
    active_inactive_state: "common.active_inactive_state",
    due_date: "common.due_date",
    sort_by: "common.sort_by",
    department_summary: "common.department_summary",
    comment_added_success: "common.comment_added_success",
    risk_profile_added_success: "common.risk_profile_added_success",
    add_risk_profile: "common.add_risk_profile",
    create_risk_profile: "common.create_risk_profile",
    venue_summary_page: "common.venue_summary_page",
    total_updates: "common.total_updates",
    channel_updated: "common.channel_updated",
    powerbi_integrations: "common.powerbi_integrations",
    show_credentials: "common.show_credentials",
    reload_error_message: "common.reload_error_message",
    minimum_length_is_count_symbols: "common.minimum_length_is_count_symbols",
    item_minimum_length_is_count_symbols: "common.item_minimum_length_is_count_symbols",
    maximum_length_is_count_symbols: "common.maximum_length_is_count_symbols",
    item_maximum_length_is_count_symbols: "common.item_maximum_length_is_count_symbols",
    should_now_start_end_empty_space: "common.should_now_start_end_empty_space",
    cant_contain_symbols: "common.cant_contain_symbols",
    value_must_be_numeric: "common.value_must_be_numeric",
    cant_contain_empty_space: "common.cant_contain_empty_space",
    invalid_url: "common.invalid_url",
    item_is_required: "common.item_is_required",
    please_enter_valid_email: "common.please_enter_valid_email",
    checks_marked_fail: "common.checks_marked_fail",
    no_failed_checks: "common.no_failed_checks",
    no_active_peaps: "common.no_active_peaps",
    no_unsure_checks: "common.no_unsure_checks",
    checks_marked_unsure: "common.checks_marked_unsure",
    no_active_peaps_not_started: "common.no_active_peaps_not_started",
    latest_update: "common.latest_update",
    crop_image: "common.crop_image",
    last: "common.last",
    no_category: "common.no_category",
    media: "common.media",
    all_categories: "common.all_categories",
    default_dashboard_layout: "common.default_dashboard_layout",
    item_leaderboard: "common.item_leaderboard",
    item_overview: "common.item_overview",
    filename: "common.filename",
    date_uploaded: "common.date_uploaded",
    uploaded_by: "common.uploaded_by",
    saved_view_title: "common.saved_view_title",
    public: "common.public",
    configure_priorities: "common.configure_priorities",
    updated: "common.updated",
    parent_task_update: "common.parent_task_update",
    sub_task_update: "common.sub_task_update",
    show_sub_tasks: "common.show_sub_tasks",
    hide_sub_tasks: "common.hide_sub_tasks",
    sub_task_details: "common.sub_task_details",
    automatic_update: "common.automatic_update",
    added: "common.added",
    removed: "common.removed",
    scheduling: "common.scheduling",
    assign_to: "common.assign_to",
    create_new_checklist: "common.create_new_checklist",
    assign_new_checklist: "common.assign_new_checklist",
    assign_checklist: "common.assign_checklist",
    new_checklist: "common.new_checklist",
    new_check: "common.new_check",
    click_here_to_update_description: "common.click_here_to_update_description",
    add_edit_description: "common.add_edit_description",
    update_time_modal_header: "common.update_time_modal_header",
    update_duration_modal_header: "common.update_duration_modal_header",
    view_all_projects_and_tasks: "common.view_all_projects_and_tasks",
    impact_type_text: {
      one: "common.impact_type_text.one",
      many: "common.impact_type_text.many"
    },
    hours: "common.hours",
    minutes: "common.minutes",
    count_results: {
      one: "common.count_results.one",
      many: "common.count_results.many"
    },
    from: "common.from",
    to: "common.to",
    saved_filters: "common.saved_filters",
    save_filter: "common.save_filter",
    save_filters: "common.save_filters",
    saved_filters_updated: "common.saved_filters_updated",
    no_saved_filters: "common.no_saved_filters",
    show_risk_actions: "common.show_risk_actions",
    risk_action: "common.risk_action",
    risk_action_status: "common.risk_action_status",
    risk_action_type: "common.risk_action_type",
    search_filters: "common.search_filters",
    no_matching_filters: "common.no_matching_filters",
    empty_filters_message: "common.empty_filters_message",
    you_can_add_risks_from_details_page: "common.you_can_add_risks_from_details_page",
    go_to_risk_assessment: "common.go_to_risk_assessment",
    assessment_overview: "common.assessment_overview",
    view_assessment_overview: "common.view_assessment_overview",
    confirm_remove_risk_association: "common.confirm_remove_risk_association",
    duplicate_assessment: "common.duplicate_assessment",
    create_another_risk_assessment: "common.create_another_risk_assessment",
    what_is_the_focus_of_risk_assessment: "common.what_is_the_focus_of_risk_assessment",
    risk_assessment_for_item: "common.risk_assessment_for_item",
    context_of_the_risk_assessment: "common.context_of_the_risk_assessment",
    risk_assessment_will_be_shared_on_this_date: "common.risk_assessment_will_be_shared_on_this_date",
    risk_assessment_must_be_approved_by_date: "common.risk_assessment_must_be_approved_by_date",
    assignments_and_confidentiality: "common.assignments_and_confidentiality",
    responsible_for_managing_risk_assessment: "common.responsible_for_managing_risk_assessment",
    responsible_for_approving_the_risk_assessment: "common.responsible_for_approving_the_risk_assessment",
    add_risks_to_risk_assessment: "common.add_risks_to_risk_assessment",
    add_questions_to_risk_profile: "common.add_questions_to_risk_profile",
    add_questions_to_profiler: "common.add_questions_to_profiler",
    count_of_questions: "common.count_of_questions",
    view_profile: "common.view_profile",
    risk_profiling_questions: "common.risk_profiling_questions",
    risk_register: "common.risk_register",
    count_risks_selected: "common.count_risks_selected",
    count_questions_selected: "common.count_questions_selected",
    save_risk_assessment: "common.save_risk_assessment",
    add_risk_assessment: "common.add_risk_assessment",
    create_risk_assessment: "common.create_risk_assessment",
    confidentiality: "common.confidentiality",
    approve_risk_assessment: "common.approve_risk_assessment",
    add_risks: "common.add_risks",
    total_risks: "common.total_risks",
    risk_rating_summary: "common.risk_rating_summary",
    total_items: "common.total_items",
    item_user_group: "common.item_user_group",
    risk_history: "common.risk_history",
    master_risk: "common.master_risk",
    mark_as_closed: "common.mark_as_closed",
    this_is_copy_of_master_risk: "common.this_is_copy_of_master_risk",
    risk_assestment_is_successfully_copied: "common.risk_assestment_is_successfully_copied",
    completed_all_time: "common.completed_all_time",
    completed_this_year: "common.completed_this_year",
    number_of_associated_risk_assessments: "common.number_of_associated_risk_assessments",
    create_your_first_risk_strategy: "common.create_your_first_risk_strategy",
    confirm_delete_item_with_count_of_affected_items: "common.confirm_delete_item_with_count_of_affected_items",
    configure_widget: "common.configure_widget",
    highest_severity: "common.highest_severity",
    recently_added: "common.recently_added",
    recently_updated: "common.recently_updated",
    new: "common.new",
    duplicate: "common.duplicate",
    bulk_archive: "common.bulk_archive",
    associated_risk_action: "common.associated_risk_action",
    associated_job_to_risk_actions: "common.associated_job_to_risk_actions",
    associated_job_title_to_risk_actions: "common.associated_job_title_to_risk_actions",
    risk_assessment_confirmation_message: "common.risk_assessment_confirmation_message",
    confirm_remove_risk_action_association: "common.confirm_remove_risk_action_association",
    score: "common.score",
    reset: "common.reset",
    question: {
      one: "common.question.one",
      many: "common.question.many"
    },
    select_answer: "common.select_answer",
    flagged_as_high_risk: "common.flagged_as_high_risk",
    risk_profile_score: "common.risk_profile_score",
    risk_profile_calculation: "common.risk_profile_calculation",
    risk_profile_calculation_info_text: "common.risk_profile_calculation_info_text",
    risk_profile_how_is: "common.risk_profile_how_is",
    no_assessment_required: "common.no_assessment_required",
    received_responses: "common.received_responses",
    risk_response_summary: "common.risk_response_summary",
    added_comment: "common.added_comment",
    questionnaire: "common.questionnaire",
    answered: "common.answered",
    unanswered: "common.unanswered",
    total_items_due: "common.total_items_due",
    incident_summary: "common.incident_summary",
    linked_to: "common.linked_to",
    error_messages: {
      name_already_in_use: "common.error_messages.name_already_in_use"
    },
    marked_as_applicable: "common.marked_as_applicable",
    marked_as_important: "common.marked_as_important",
    applicable: "common.applicable",
    important: "common.important",
    mark_as_not_applicable: "common.mark_as_not_applicable",
    mark_as_applicable: "common.mark_as_applicable",
    mark_as_not_important: "common.mark_as_not_important",
    mark_as_important: "common.mark_as_important",
    marked_not_applicable: "common.marked_not_applicable",
    manage: "common.manage"
  },
  calendar: {
    previous_date: "calendar.previous_date",
    next_date: "calendar.next_date",
    months: {
      january: "calendar.months.january",
      february: "calendar.months.february",
      march: "calendar.months.march",
      april: "calendar.months.april",
      may: "calendar.months.may",
      june: "calendar.months.june",
      july: "calendar.months.july",
      august: "calendar.months.august",
      september: "calendar.months.september",
      october: "calendar.months.october",
      november: "calendar.months.november",
      december: "calendar.months.december"
    },
    days_of_week: {
      mon: "calendar.days_of_week.mon",
      tue: "calendar.days_of_week.tue",
      wed: "calendar.days_of_week.wed",
      thu: "calendar.days_of_week.thu",
      fri: "calendar.days_of_week.fri",
      sat: "calendar.days_of_week.sat",
      sun: "calendar.days_of_week.sun"
    },
    days_of_week_full: {
      monday: "calendar.days_of_week_full.monday",
      tuesday: "calendar.days_of_week_full.tuesday",
      wednesday: "calendar.days_of_week_full.wednesday",
      thursday: "calendar.days_of_week_full.thursday",
      friday: "calendar.days_of_week_full.friday",
      saturday: "calendar.days_of_week_full.saturday",
      sunday: "calendar.days_of_week_full.sunday"
    },
    today: "calendar.today",
    yesterday: "calendar.yesterday",
    month_view: "calendar.month_view",
    list_view: "calendar.list_view",
    day_view: "calendar.day_view",
    week_view: "calendar.week_view",
    week: {
      one: "calendar.week.one",
      many: "calendar.week.many"
    },
    day: {
      one: "calendar.day.one",
      many: "calendar.day.many"
    },
    hour: {
      one: "calendar.hour.one",
      many: "calendar.hour.many"
    },
    minute: {
      one: "calendar.minute.one",
      many: "calendar.minute.many"
    },
    second: {
      one: "calendar.second.one",
      many: "calendar.second.many"
    },
    due_last_week: "calendar.due_last_week",
    due_this_week: "calendar.due_this_week",
    due_next_week: "calendar.due_next_week",
    count_weeks_ago: {
      one: "calendar.count_weeks_ago.one",
      many: "calendar.count_weeks_ago.many"
    },
    last_week: "calendar.last_week",
    this_week: "calendar.this_week",
    now: "calendar.now",
    expected_date: "calendar.expected_date",
    start: "calendar.start",
    end: "calendar.end",
    set_start_and_end_time: "calendar.set_start_and_end_time",
    endLabel_time_should_be_after_startLabel_time: "calendar.endLabel_time_should_be_after_startLabel_time",
    set_start_and_end_time_exact: "calendar.set_start_and_end_time_exact",
    later: "calendar.later",
    started: "calendar.started",
    daily: "calendar.daily",
    weekly: "calendar.weekly",
    monthly: "calendar.monthly",
    yearly: "calendar.yearly",
    every_time: "calendar.every_time",
    start_or_due_date: "calendar.start_or_due_date",
    up_to_today: "calendar.up_to_today",
    last_3_months: "calendar.last_3_months",
    last_6_months: "calendar.last_6_months",
    last_30_days: "calendar.last_30_days",
    last_7_days: "calendar.last_7_days",
    next_7_days: "calendar.next_7_days",
    next_30_days: "calendar.next_30_days",
    next_3_months: "calendar.next_3_months",
    fixed_date_range: "calendar.fixed_date_range",
    tomorrow: "calendar.tomorrow",
    next_2_weeks: "calendar.next_2_weeks",
    onward: {
      one: "calendar.onward.one",
      many: "calendar.onward.many"
    },
    next_6_months: "calendar.next_6_months",
    next_year: "calendar.next_year",
    fixed_time_range: "calendar.fixed_time_range",
    previous_24_hours: "calendar.previous_24_hours",
    created_date: "calendar.created_date",
    updated_date: "calendar.updated_date",
    closed_date: "calendar.closed_date",
    not_updated_date: "calendar.not_updated_date",
    last_updated_date: "calendar.last_updated_date",
    in_count_days: {
      one: "calendar.in_count_days.one",
      many: "calendar.in_count_days.many"
    },
    past_count_days: "calendar.past_count_days",
    older: "calendar.older",
    starts: "calendar.starts",
    ends: "calendar.ends",
    day_before: "calendar.day_before",
    on_the_day: "calendar.on_the_day",
    day_after: "calendar.day_after",
    starts_at: "calendar.starts_at",
    ends_at: "calendar.ends_at",
    start_end_date: "calendar.start_end_date",
    click_next_when_youre_ready: "calendar.click_next_when_youre_ready",
    perc_up_to_date: "calendar.perc_up_to_date",
    up_to_date: "calendar.up_to_date",
    right_now: "calendar.right_now",
    hourly: "calendar.hourly",
    custom_dates: "calendar.custom_dates",
    one_day_overdue: "calendar.one_day_overdue",
    count_days_overdue: "calendar.count_days_overdue",
    count_days_later: "calendar.count_days_later",
    count_days_earlier: "calendar.count_days_earlier",
    today_date: "calendar.today_date",
    due_tomorrow: "calendar.due_tomorrow",
    due_in_count_days: "calendar.due_in_count_days",
    count_hours_and_count_minutes: "calendar.count_hours_and_count_minutes",
    count_years_and_count_days: "calendar.count_years_and_count_days",
    less_than_a_minute: "calendar.less_than_a_minute",
    count_days_and_hours_and_count_minutes: "calendar.count_days_and_hours_and_count_minutes"
  },
  defaultLocalizations: {
    accessibility: {
      one: "defaultLocalizations.accessibility.one"
    },
    accommodation: {
      one: "defaultLocalizations.accommodation.one"
    },
    "agriculture,_forestry_&_fishing": {
      one: "defaultLocalizations.agriculture,_forestry_&_fishing.one"
    },
    "anti-corruption": {
      one: "defaultLocalizations.anti-corruption.one"
    },
    "biodiversity_&_green_spaces": {
      one: "defaultLocalizations.biodiversity_&_green_spaces.one"
    },
    board_approach: {
      one: "defaultLocalizations.board_approach.one"
    },
    "buildings_&_infrastructure": {
      one: "defaultLocalizations.buildings_&_infrastructure.one"
    },
    carbon: {
      one: "defaultLocalizations.carbon.one"
    },
    charitable_donations: {
      one: "defaultLocalizations.charitable_donations.one"
    },
    client_relations: {
      one: "defaultLocalizations.client_relations.one"
    },
    community_engagement: {
      one: "defaultLocalizations.community_engagement.one"
    },
    "construction_&_design": {
      one: "defaultLocalizations.construction_&_design.one"
    },
    "consumer_goods_&_services": {
      one: "defaultLocalizations.consumer_goods_&_services.one"
    },
    "diversity_&_inclusion": {
      one: "defaultLocalizations.diversity_&_inclusion.one"
    },
    economy: {
      one: "defaultLocalizations.economy.one"
    },
    energy: {
      one: "defaultLocalizations.energy.one"
    },
    environmental_protection: {
      one: "defaultLocalizations.environmental_protection.one"
    },
    equipment: {
      one: "defaultLocalizations.equipment.one"
    },
    "food_&_beverage": {
      one: "defaultLocalizations.food_&_beverage.one"
    },
    "health_&_social_care": {
      one: "defaultLocalizations.health_&_social_care.one"
    },
    human_rights: {
      one: "defaultLocalizations.human_rights.one"
    },
    "information_&_communication": {
      one: "defaultLocalizations.information_&_communication.one"
    },
    "insurance_&_financial_services": {
      one: "defaultLocalizations.insurance_&_financial_services.one"
    },
    labor_standards: {
      one: "defaultLocalizations.labor_standards.one"
    },
    land_use: {
      one: "defaultLocalizations.land_use.one"
    },
    "learning_&_development": {
      one: "defaultLocalizations.learning_&_development.one"
    },
    legacy: {
      one: "defaultLocalizations.legacy.one"
    },
    management_approach: {
      one: "defaultLocalizations.management_approach.one"
    },
    "materials_&_manufacturing": {
      one: "defaultLocalizations.materials_&_manufacturing.one"
    },
    organizational_activities: {
      one: "defaultLocalizations.organizational_activities.one"
    },
    procurement: {
      one: "defaultLocalizations.procurement.one"
    },
    public_relations: {
      one: "defaultLocalizations.public_relations.one"
    },
    "refrigerants_&_fugitive_gases": {
      one: "defaultLocalizations.refrigerants_&_fugitive_gases.one"
    },
    resource_consumption: {
      one: "defaultLocalizations.resource_consumption.one"
    },
    social_improvement: {
      one: "defaultLocalizations.social_improvement.one"
    },
    "staff_&_culture": {
      one: "defaultLocalizations.staff_&_culture.one"
    },
    supply_chain: {
      one: "defaultLocalizations.supply_chain.one"
    },
    sustainability_education: {
      one: "defaultLocalizations.sustainability_education.one"
    },
    talent_acquisition: {
      one: "defaultLocalizations.talent_acquisition.one"
    },
    "transport_&_travel": {
      one: "defaultLocalizations.transport_&_travel.one"
    },
    waste: {
      one: "defaultLocalizations.waste.one"
    },
    water: {
      one: "defaultLocalizations.water.one"
    },
    session_last_tirthDays: {
      many: "defaultLocalizations.session_last_tirthDays.many"
    },
    session_last_year: {
      one: "defaultLocalizations.session_last_year.one"
    },
    employee: {
      one: "defaultLocalizations.employee.one",
      many: "defaultLocalizations.employee.many"
    },
    account: {
      one: "defaultLocalizations.account.one",
      many: "defaultLocalizations.account.many"
    },
    Id: {
      one: "defaultLocalizations.Id.one",
      many: "defaultLocalizations.Id.many"
    },
    department: {
      one: "defaultLocalizations.department.one",
      many: "defaultLocalizations.department.many",
      none: "defaultLocalizations.department.none"
    },
    department_category: {
      one: "defaultLocalizations.department_category.one",
      many: "defaultLocalizations.department_category.many"
    },
    organisation: {
      one: "defaultLocalizations.organisation.one",
      many: "defaultLocalizations.organisation.many"
    },
    project: {
      one: "defaultLocalizations.project.one",
      many: "defaultLocalizations.project.many"
    },
    task: {
      one: "defaultLocalizations.task.one",
      many: "defaultLocalizations.task.many"
    },
    sub_task: {
      one: "defaultLocalizations.sub_task.one",
      many: "defaultLocalizations.sub_task.many"
    },
    incident_item: {
      one: "defaultLocalizations.incident_item.one",
      many: "defaultLocalizations.incident_item.many"
    },
    incident_item_with_count: {
      one: "defaultLocalizations.incident_item_with_count.one",
      many: "defaultLocalizations.incident_item_with_count.many"
    },
    risk_item_with_count: {
      one: "defaultLocalizations.risk_item_with_count.one",
      many: "defaultLocalizations.risk_item_with_count.many"
    },
    runsheet: {
      one: "defaultLocalizations.runsheet.one",
      many: "defaultLocalizations.runsheet.many"
    },
    runsheet_item: {
      one: "defaultLocalizations.runsheet_item.one",
      many: "defaultLocalizations.runsheet_item.many"
    },
    capability: {
      one: "defaultLocalizations.capability.one",
      many: "defaultLocalizations.capability.many"
    },
    activity: {
      one: "defaultLocalizations.activity.one",
      many: "defaultLocalizations.activity.many"
    },
    evaluation_action: {
      one: "defaultLocalizations.evaluation_action.one",
      many: "defaultLocalizations.evaluation_action.many"
    },
    evaluation_lesson: {
      one: "defaultLocalizations.evaluation_lesson.one",
      many: "defaultLocalizations.evaluation_lesson.many"
    },
    evaluation: {
      one: "defaultLocalizations.evaluation.one",
      many: "defaultLocalizations.evaluation.many"
    },
    tag: {
      one: "defaultLocalizations.tag.one",
      many: "defaultLocalizations.tag.many"
    },
    zone: {
      one: "defaultLocalizations.zone.one",
      many: "defaultLocalizations.zone.many"
    },
    area: {
      one: "defaultLocalizations.area.one",
      many: "defaultLocalizations.area.many"
    },
    incidents_response_plans: "defaultLocalizations.incidents_response_plans",
    response_plans: "defaultLocalizations.response_plans",
    incident_category: {
      one: "defaultLocalizations.incident_category.one",
      many: "defaultLocalizations.incident_category.many",
      none: "defaultLocalizations.incident_category.none",
      top: "defaultLocalizations.incident_category.top"
    },
    checklist_item: {
      none: "defaultLocalizations.checklist_item.none",
      one: "defaultLocalizations.checklist_item.one",
      many: "defaultLocalizations.checklist_item.many",
      additional: "defaultLocalizations.checklist_item.additional"
    },
    event: {
      one: "defaultLocalizations.event.one",
      many: "defaultLocalizations.event.many"
    },
    event_type: {
      one: "defaultLocalizations.event_type.one",
      many: "defaultLocalizations.event_type.many",
      none: "defaultLocalizations.event_type.none"
    },
    incident_channel: {
      one: "defaultLocalizations.incident_channel.one",
      many: "defaultLocalizations.incident_channel.many"
    },
    report_history: {
      one: "defaultLocalizations.report_history.one",
      many: "defaultLocalizations.report_history.many"
    },
    tag_group: {
      one: "defaultLocalizations.tag_group.one",
      many: "defaultLocalizations.tag_group.many"
    },
    task_group: {
      one: "defaultLocalizations.task_group.one",
      many: "defaultLocalizations.task_group.many"
    },
    risk: {
      one: "defaultLocalizations.risk.one",
      many: "defaultLocalizations.risk.many"
    },
    risk_action_item: {
      one: "defaultLocalizations.risk_action_item.one",
      many: "defaultLocalizations.risk_action_item.many"
    },
    risk_assessment: {
      one: "defaultLocalizations.risk_assessment.one",
      many: "defaultLocalizations.risk_assessment.many"
    },
    risk_profile: {
      one: "defaultLocalizations.risk_profile.one",
      many: "defaultLocalizations.risk_profile.many"
    },
    associated_risk: {
      one: "defaultLocalizations.associated_risk.one",
      many: "defaultLocalizations.associated_risk.many"
    },
    associated_incident: {
      one: "defaultLocalizations.associated_incident.one",
      many: "defaultLocalizations.associated_incident.many"
    },
    associated_job: {
      one: "defaultLocalizations.associated_job.one",
      many: "defaultLocalizations.associated_job.many"
    },
    risk_impact_type: {
      one: "defaultLocalizations.risk_impact_type.one",
      many: "defaultLocalizations.risk_impact_type.many",
      none: "defaultLocalizations.risk_impact_type.none"
    },
    risk_insurance_policy: {
      one: "defaultLocalizations.risk_insurance_policy.one",
      many: "defaultLocalizations.risk_insurance_policy.many"
    },
    filter: {
      one: "defaultLocalizations.filter.one",
      many: "defaultLocalizations.filter.many"
    },
    favorite_filter: {
      one: "defaultLocalizations.favorite_filter.one",
      many: "defaultLocalizations.favorite_filter.many"
    },
    peap_checkpoint_type: {
      one: "defaultLocalizations.peap_checkpoint_type.one",
      many: "defaultLocalizations.peap_checkpoint_type.many"
    },
    peap: {
      one: "defaultLocalizations.peap.one",
      many: "defaultLocalizations.peap.many"
    },
    peap_check: {
      one: "defaultLocalizations.peap_check.one",
      many: "defaultLocalizations.peap_check.many"
    },
    peap_checkpoint: {
      one: "defaultLocalizations.peap_checkpoint.one",
      many: "defaultLocalizations.peap_checkpoint.many"
    },
    allowed_filter: {
      one: "defaultLocalizations.allowed_filter.one",
      many: "defaultLocalizations.allowed_filter.many"
    },
    indicator: {
      one: "defaultLocalizations.indicator.one",
      many: "defaultLocalizations.indicator.many"
    },
    indicator_template_category: {
      one: "defaultLocalizations.indicator_template_category.one",
      many: "defaultLocalizations.indicator_template_category.many"
    },
    indicator_category: {
      one: "defaultLocalizations.indicator_category.one",
      many: "defaultLocalizations.indicator_category.many"
    },
    indicator_target: {
      one: "defaultLocalizations.indicator_target.one",
      many: "defaultLocalizations.indicator_target.many"
    },
    indicator_target_type: {
      one: "defaultLocalizations.indicator_target_type.one",
      many: "defaultLocalizations.indicator_target_type.many"
    },
    number_indicators_selected: "defaultLocalizations.number_indicators_selected",
    organisations: {
      one: "defaultLocalizations.organisations.one",
      many: "defaultLocalizations.organisations.many"
    },
    indicator_update: {
      one: "defaultLocalizations.indicator_update.one",
      many: "defaultLocalizations.indicator_update.many"
    },
    owner: {
      none: "defaultLocalizations.owner.none",
      one: "defaultLocalizations.owner.one",
      many: "defaultLocalizations.owner.many"
    },
    rag: {
      one: "defaultLocalizations.rag.one",
      many: "defaultLocalizations.rag.many"
    },
    due_date: {
      one: "defaultLocalizations.due_date.one",
      many: "defaultLocalizations.due_date.many"
    },
    milestone_type: {
      one: "defaultLocalizations.milestone_type.one",
      many: "defaultLocalizations.milestone_type.many"
    },
    priority: {
      one: "defaultLocalizations.priority.one",
      many: "defaultLocalizations.priority.many"
    },
    keyword: {
      one: "defaultLocalizations.keyword.one",
      many: "defaultLocalizations.keyword.many"
    },
    item_type: {
      one: "defaultLocalizations.item_type.one",
      many: "defaultLocalizations.item_type.many"
    },
    archived: {
      one: "defaultLocalizations.archived.one",
      many: "defaultLocalizations.archived.many"
    },
    risk_likelyhood: {
      one: "defaultLocalizations.risk_likelyhood.one",
      many: "defaultLocalizations.risk_likelyhood.many"
    },
    risk_impact: {
      one: "defaultLocalizations.risk_impact.one",
      many: "defaultLocalizations.risk_impact.many"
    },
    risk_privacy_status: {
      one: "defaultLocalizations.risk_privacy_status.one",
      many: "defaultLocalizations.risk_privacy_status.many"
    },
    risk_strategy: {
      one: "defaultLocalizations.risk_strategy.one",
      many: "defaultLocalizations.risk_strategy.many",
      none: "defaultLocalizations.risk_strategy.none"
    },
    risk_status: {
      one: "defaultLocalizations.risk_status.one",
      many: "defaultLocalizations.risk_status.many"
    },
    risk_type: {
      one: "defaultLocalizations.risk_type.one",
      many: "defaultLocalizations.risk_type.many"
    },
    incident_severity: {
      one: "defaultLocalizations.incident_severity.one",
      many: "defaultLocalizations.incident_severity.many"
    },
    incident_status: {
      one: "defaultLocalizations.incident_status.one",
      many: "defaultLocalizations.incident_status.many"
    },
    privacy_status: {
      one: "defaultLocalizations.privacy_status.one",
      many: "defaultLocalizations.privacy_status.many"
    },
    incident_resolution_time: {
      one: "defaultLocalizations.incident_resolution_time.one",
      many: "defaultLocalizations.incident_resolution_time.many"
    },
    headline_status: {
      none: "defaultLocalizations.headline_status.none",
      one: "defaultLocalizations.headline_status.one",
      many: "defaultLocalizations.headline_status.many"
    },
    incident_type: {
      one: "defaultLocalizations.incident_type.one",
      many: "defaultLocalizations.incident_type.many"
    },
    live_zone: {
      one: "defaultLocalizations.live_zone.one",
      many: "defaultLocalizations.live_zone.many"
    },
    created_by: {
      one: "defaultLocalizations.created_by.one",
      many: "defaultLocalizations.created_by.many"
    },
    identified_by: {
      one: "defaultLocalizations.identified_by.one",
      many: "defaultLocalizations.identified_by.many"
    },
    incident: {
      one: "defaultLocalizations.incident.one",
      many: "defaultLocalizations.incident.many"
    },
    financial_impact: {
      one: "defaultLocalizations.financial_impact.one",
      many: "defaultLocalizations.financial_impact.many"
    },
    updated_by: {
      one: "defaultLocalizations.updated_by.one",
      many: "defaultLocalizations.updated_by.many"
    },
    title: {
      one: "defaultLocalizations.title.one",
      many: "defaultLocalizations.title.many"
    },
    rating: {
      one: "defaultLocalizations.rating.one",
      many: "defaultLocalizations.rating.many"
    },
    description: {
      none: "defaultLocalizations.description.none",
      one: "defaultLocalizations.description.one",
      many: "defaultLocalizations.description.many"
    },
    risk_description: {
      one: "defaultLocalizations.risk_description.one",
      many: "defaultLocalizations.risk_description.many"
    },
    document_category: {
      one: "defaultLocalizations.document_category.one",
      many: "defaultLocalizations.document_category.many"
    },
    what_changed: {
      one: "defaultLocalizations.what_changed.one",
      many: "defaultLocalizations.what_changed.many"
    },
    ref_code: {
      one: "defaultLocalizations.ref_code.one",
      many: "defaultLocalizations.ref_code.many"
    },
    reported_by: {
      one: "defaultLocalizations.reported_by.one",
      many: "defaultLocalizations.reported_by.many"
    },
    date: {
      one: "defaultLocalizations.date.one",
      many: "defaultLocalizations.date.many"
    },
    subscriber: {
      one: "defaultLocalizations.subscriber.one",
      many: "defaultLocalizations.subscriber.many"
    },
    additional_info: {
      one: "defaultLocalizations.additional_info.one",
      many: "defaultLocalizations.additional_info.many"
    },
    unit_of_measurement: {
      one: "defaultLocalizations.unit_of_measurement.one",
      many: "defaultLocalizations.unit_of_measurement.many"
    },
    dependent_task: {
      one: "defaultLocalizations.dependent_task.one",
      many: "defaultLocalizations.dependent_task.many"
    },
    action_owner: {
      one: "defaultLocalizations.action_owner.one",
      many: "defaultLocalizations.action_owner.many"
    },
    activity_type: {
      one: "defaultLocalizations.activity_type.one",
      many: "defaultLocalizations.activity_type.many"
    },
    capability_type: {
      one: "defaultLocalizations.capability_type.one",
      many: "defaultLocalizations.capability_type.many"
    },
    grab: {
      one: "defaultLocalizations.grab.one",
      many: "defaultLocalizations.grab.many"
    },
    capability_owner: {
      one: "defaultLocalizations.capability_owner.one",
      many: "defaultLocalizations.capability_owner.many"
    },
    lesson_item: {
      one: "defaultLocalizations.lesson_item.one",
      many: "defaultLocalizations.lesson_item.many"
    },
    action_item: {
      one: "defaultLocalizations.action_item.one",
      many: "defaultLocalizations.action_item.many"
    },
    readiness_severity: {
      one: "defaultLocalizations.readiness_severity.one",
      many: "defaultLocalizations.readiness_severity.many"
    },
    readiness_priority: {
      one: "defaultLocalizations.readiness_priority.one",
      many: "defaultLocalizations.readiness_priority.many"
    },
    expected_severity: {
      one: "defaultLocalizations.expected_severity.one",
      many: "defaultLocalizations.expected_severity.many"
    },
    start_time: {
      one: "defaultLocalizations.start_time.one",
      many: "defaultLocalizations.start_time.many"
    },
    due_time: {
      one: "defaultLocalizations.due_time.one",
      many: "defaultLocalizations.due_time.many"
    },
    updated_time: {
      one: "defaultLocalizations.updated_time.one",
      many: "defaultLocalizations.updated_time.many"
    },
    end_time: {
      one: "defaultLocalizations.end_time.one",
      many: "defaultLocalizations.end_time.many"
    },
    live_event: {
      one: "defaultLocalizations.live_event.one",
      many: "defaultLocalizations.live_event.many"
    },
    task_breakdown: {
      one: "defaultLocalizations.task_breakdown.one",
      many: "defaultLocalizations.task_breakdown.many"
    },
    project_status: {
      one: "defaultLocalizations.project_status.one",
      many: "defaultLocalizations.project_status.many"
    },
    risk_breakdown: {
      one: "defaultLocalizations.risk_breakdown.one",
      many: "defaultLocalizations.risk_breakdown.many"
    },
    issue_breakdown: {
      one: "defaultLocalizations.issue_breakdown.one",
      many: "defaultLocalizations.issue_breakdown.many"
    },
    opportunity_breakdown: {
      one: "defaultLocalizations.opportunity_breakdown.one",
      many: "defaultLocalizations.opportunity_breakdown.many"
    },
    risk_action_breakdown: {
      one: "defaultLocalizations.risk_action_breakdown.one",
      many: "defaultLocalizations.risk_action_breakdown.many"
    },
    risk_action_status: {
      one: "defaultLocalizations.risk_action_status.one",
      many: "defaultLocalizations.risk_action_status.many"
    },
    risk_action_type: {
      one: "defaultLocalizations.risk_action_type.one",
      many: "defaultLocalizations.risk_action_type.many"
    },
    checkpoint_type: {
      one: "defaultLocalizations.checkpoint_type.one",
      many: "defaultLocalizations.checkpoint_type.many"
    },
    checkpoint: {
      one: "defaultLocalizations.checkpoint.one",
      many: "defaultLocalizations.checkpoint.many"
    },
    peap_group: {
      one: "defaultLocalizations.peap_group.one",
      many: "defaultLocalizations.peap_group.many"
    },
    resolution: {
      one: "defaultLocalizations.resolution.one",
      many: "defaultLocalizations.resolution.many"
    },
    peap_group_status: {
      one: "defaultLocalizations.peap_group_status.one",
      many: "defaultLocalizations.peap_group_status.many"
    },
    peap_check_status: {
      one: "defaultLocalizations.peap_check_status.one",
      many: "defaultLocalizations.peap_check_status.many"
    },
    external_user: {
      one: "defaultLocalizations.external_user.one",
      many: "defaultLocalizations.external_user.many"
    },
    risk_rag: {
      one: "defaultLocalizations.risk_rag.one",
      many: "defaultLocalizations.risk_rag.many"
    },
    insurance_coverage: {
      one: "defaultLocalizations.insurance_coverage.one",
      many: "defaultLocalizations.insurance_coverage.many"
    },
    grab_breakdown: {
      one: "defaultLocalizations.grab_breakdown.one",
      many: "defaultLocalizations.grab_breakdown.many"
    },
    evaluation_action_status: {
      one: "defaultLocalizations.evaluation_action_status.one",
      many: "defaultLocalizations.evaluation_action_status.many"
    },
    evaluation_breakdown: {
      one: "defaultLocalizations.evaluation_breakdown.one",
      many: "defaultLocalizations.evaluation_breakdown.many"
    },
    readiness_status: {
      one: "defaultLocalizations.readiness_status.one",
      many: "defaultLocalizations.readiness_status.many"
    },
    activity_title: {
      one: "defaultLocalizations.activity_title.one",
      many: "defaultLocalizations.activity_title.many"
    },
    lesson_type: {
      one: "defaultLocalizations.lesson_type.one",
      many: "defaultLocalizations.lesson_type.many"
    },
    expected_result: {
      one: "defaultLocalizations.expected_result.one",
      many: "defaultLocalizations.expected_result.many"
    },
    action_type: {
      one: "defaultLocalizations.action_type.one",
      many: "defaultLocalizations.action_type.many"
    },
    created: {
      one: "defaultLocalizations.created.one",
      many: "defaultLocalizations.created.many"
    },
    created_time: {
      one: "defaultLocalizations.created_time.one",
      many: "defaultLocalizations.created_time.many"
    },
    updated: {
      one: "defaultLocalizations.updated.one",
      many: "defaultLocalizations.updated.many"
    },
    indicator_rag: {
      one: "defaultLocalizations.indicator_rag.one",
      many: "defaultLocalizations.indicator_rag.many"
    },
    indicator_update_type: {
      one: "defaultLocalizations.indicator_update_type.one",
      many: "defaultLocalizations.indicator_update_type.many"
    },
    indicator_tracking_date_type: {
      one: "defaultLocalizations.indicator_tracking_date_type.one",
      many: "defaultLocalizations.indicator_tracking_date_type.many"
    },
    indicator_priority: {
      one: "defaultLocalizations.indicator_priority.one",
      many: "defaultLocalizations.indicator_priority.many"
    },
    update_type: {
      one: "defaultLocalizations.update_type.one",
      many: "defaultLocalizations.update_type.many"
    },
    un_sdg: {
      one: "defaultLocalizations.un_sdg.one",
      many: "defaultLocalizations.un_sdg.many"
    },
    runsheet_item_priority: {
      one: "defaultLocalizations.runsheet_item_priority.one",
      many: "defaultLocalizations.runsheet_item_priority.many"
    },
    runsheet_incomplete_items: {
      one: "defaultLocalizations.runsheet_incomplete_items.one",
      many: "defaultLocalizations.runsheet_incomplete_items.many"
    },
    dependent_runsheet_item: {
      one: "defaultLocalizations.dependent_runsheet_item.one",
      many: "defaultLocalizations.dependent_runsheet_item.many"
    },
    runsheet_item_dependencies_count: {
      one: "defaultLocalizations.runsheet_item_dependencies_count.one",
      many: "defaultLocalizations.runsheet_item_dependencies_count.many"
    },
    runsheet_item_dependencies: {
      one: "defaultLocalizations.runsheet_item_dependencies.one",
      many: "defaultLocalizations.runsheet_item_dependencies.many"
    },
    user_group: {
      one: "defaultLocalizations.user_group.one",
      many: "defaultLocalizations.user_group.many"
    },
    subscriber_group: {
      one: "defaultLocalizations.subscriber_group.one",
      many: "defaultLocalizations.subscriber_group.many"
    },
    log: {
      one: "defaultLocalizations.log.one",
      many: "defaultLocalizations.log.many"
    },
    job: {
      one: "defaultLocalizations.job.one",
      many: "defaultLocalizations.job.many"
    },
    job_category: {
      one: "defaultLocalizations.job_category.one",
      many: "defaultLocalizations.job_category.many"
    },
    job_status: {
      one: "defaultLocalizations.job_status.one",
      many: "defaultLocalizations.job_status.many"
    },
    job_type: {
      one: "defaultLocalizations.job_type.one",
      many: "defaultLocalizations.job_type.many"
    },
    issue: {
      one: "defaultLocalizations.issue.one",
      many: "defaultLocalizations.issue.many"
    },
    opportunity: {
      one: "defaultLocalizations.opportunity.one",
      many: "defaultLocalizations.opportunity.many"
    },
    user: {
      one: "defaultLocalizations.user.one",
      many: "defaultLocalizations.user.many"
    },
    incident_severity_Breakdown: {
      one: "defaultLocalizations.incident_severity_Breakdown.one",
      many: "defaultLocalizations.incident_severity_Breakdown.many"
    },
    comments_and_history: {
      one: "defaultLocalizations.comments_and_history.one",
      many: "defaultLocalizations.comments_and_history.many"
    },
    checklist_title: {
      one: "defaultLocalizations.checklist_title.one",
      many: "defaultLocalizations.checklist_title.many"
    },
    check_title: {
      one: "defaultLocalizations.check_title.one",
      many: "defaultLocalizations.check_title.many"
    },
    incomplete_action: {
      one: "defaultLocalizations.incomplete_action.one",
      many: "defaultLocalizations.incomplete_action.many"
    },
    task_priority: {
      one: "defaultLocalizations.task_priority.one",
      many: "defaultLocalizations.task_priority.many"
    },
    closed: {
      one: "defaultLocalizations.closed.one",
      many: "defaultLocalizations.closed.many"
    },
    item_owner: "defaultLocalizations.item_owner",
    item_project: "defaultLocalizations.item_project",
    item_department: "defaultLocalizations.item_department",
    item_department_category: "defaultLocalizations.item_department_category",
    item_zone: "defaultLocalizations.item_zone",
    item_area: "defaultLocalizations.item_area",
    item_rag: "defaultLocalizations.item_rag",
    item_due_date: "defaultLocalizations.item_due_date",
    item_organisation: "defaultLocalizations.item_organisation",
    item_milestone_type: "defaultLocalizations.item_milestone_type",
    item_priority: "defaultLocalizations.item_priority",
    common_item_type: "defaultLocalizations.common_item_type",
    item_status: "defaultLocalizations.item_status",
    item_archived: "defaultLocalizations.item_archived",
    item_start_time: "defaultLocalizations.item_start_time",
    item_end_time: "defaultLocalizations.item_end_time",
    item_event: "defaultLocalizations.item_event",
    item_event_type: "defaultLocalizations.item_event_type",
    item_has_attachments: "defaultLocalizations.item_has_attachments",
    item_start_date: "defaultLocalizations.item_start_date",
    item_created: "defaultLocalizations.item_created",
    item_updated: "defaultLocalizations.item_updated",
    item_closed: "defaultLocalizations.item_closed",
    item_end_date: "defaultLocalizations.item_end_date",
    approver: {
      one: "defaultLocalizations.approver.one",
      many: "defaultLocalizations.approver.many"
    },
    approver_with_name: "defaultLocalizations.approver_with_name",
    question: {
      one: "defaultLocalizations.question.one",
      many: "defaultLocalizations.question.many"
    },
    answer: {
      one: "defaultLocalizations.answer.one",
      many: "defaultLocalizations.answer.many"
    },
    link_risk_to_category_empty_state: "defaultLocalizations.link_risk_to_category_empty_state",
    link_risk_to_incident_category: "defaultLocalizations.link_risk_to_incident_category",
    event_list: "defaultLocalizations.event_list",
    event_calendar: "defaultLocalizations.event_calendar",
    manage_event_type: {
      one: "defaultLocalizations.manage_event_type.one",
      many: "defaultLocalizations.manage_event_type.many"
    }
  },
  defaultFilterValues: {
    privacy_statuses: {
      open: "defaultFilterValues.privacy_statuses.open",
      confidential: "defaultFilterValues.privacy_statuses.confidential",
      medical: "defaultFilterValues.privacy_statuses.medical",
      police: "defaultFilterValues.privacy_statuses.police"
    },
    readiness_severity: {
      severity_1: "defaultFilterValues.readiness_severity.severity_1",
      severity_2: "defaultFilterValues.readiness_severity.severity_2",
      severity_3: "defaultFilterValues.readiness_severity.severity_3",
      severity_4: "defaultFilterValues.readiness_severity.severity_4",
      severity_5: "defaultFilterValues.readiness_severity.severity_5"
    },
    runsheet_item_priority: {
      level_1: "defaultFilterValues.runsheet_item_priority.level_1",
      level_2: "defaultFilterValues.runsheet_item_priority.level_2",
      level_3: "defaultFilterValues.runsheet_item_priority.level_3"
    },
    indicator_priority: {
      one_star: "defaultFilterValues.indicator_priority.one_star",
      two_stars: "defaultFilterValues.indicator_priority.two_stars",
      three_stars: "defaultFilterValues.indicator_priority.three_stars"
    },
    milestone_type: {
      no_milestone: "defaultFilterValues.milestone_type.no_milestone",
      bronze: "defaultFilterValues.milestone_type.bronze",
      silver: "defaultFilterValues.milestone_type.silver",
      gold: "defaultFilterValues.milestone_type.gold"
    },
    rag_status: {
      not_started: "defaultFilterValues.rag_status.not_started",
      slipped: "defaultFilterValues.rag_status.slipped",
      slipping: "defaultFilterValues.rag_status.slipping",
      on_track: "defaultFilterValues.rag_status.on_track",
      complete: "defaultFilterValues.rag_status.complete"
    },
    risk_action_status: {
      in_progress: "defaultFilterValues.risk_action_status.in_progress",
      slipped: "defaultFilterValues.risk_action_status.slipped",
      complete: "defaultFilterValues.risk_action_status.complete"
    },
    risk_action_type: {
      mitigate: "defaultFilterValues.risk_action_type.mitigate",
      contingency: "defaultFilterValues.risk_action_type.contingency",
      insure: "defaultFilterValues.risk_action_type.insure",
      avoid: "defaultFilterValues.risk_action_type.avoid",
      exploit: "defaultFilterValues.risk_action_type.exploit",
      accept: "defaultFilterValues.risk_action_type.accept",
      control: "defaultFilterValues.risk_action_type.control"
    },
    task_priority: {
      one_star: "defaultFilterValues.task_priority.one_star",
      two_stars: "defaultFilterValues.task_priority.two_stars",
      three_stars: "defaultFilterValues.task_priority.three_stars"
    }
  },
  lozenges_label: {
    date_related: {
      action_due: "lozenges_label.date_related.action_due",
      activity: "lozenges_label.date_related.activity",
      closed: "lozenges_label.date_related.closed",
      created: "lozenges_label.date_related.created",
      date: "lozenges_label.date_related.date",
      due: "lozenges_label.date_related.due",
      start: "lozenges_label.date_related.start",
      updated: "lozenges_label.date_related.updated",
      expected_due: "lozenges_label.date_related.expected_due",
      end: "lozenges_label.date_related.end"
    },
    risk_impact_type: "lozenges_label.risk_impact_type",
    ref_code: "lozenges_label.ref_code",
    risk_action_status: "lozenges_label.risk_action_status",
    risk_action_type: "lozenges_label.risk_action_type",
    runsheet_dependencies: "lozenges_label.runsheet_dependencies"
  },
  weather: {
    widgetMaintenanceMessage: "weather.widgetMaintenanceMessage",
    weather: "weather.weather",
    weather_at: "weather.weather_at",
    wind: "weather.wind",
    rain: "weather.rain",
    forecast: "weather.forecast",
    snow: "weather.snow",
    celsius: "weather.celsius",
    farenheit: "weather.farenheit"
  },
  dashboard: {
    sync_changes_dashboard_widget: "dashboard.sync_changes_dashboard_widget",
    dashboard_options: "dashboard.dashboard_options",
    dashboard_widgets: "dashboard.dashboard_widgets",
    choose_widget_to_display: "dashboard.choose_widget_to_display",
    no_available_widgets: "dashboard.no_available_widgets",
    weather_widget: "dashboard.weather_widget",
    chose_temperature_unit: "dashboard.chose_temperature_unit",
    set_weather_locations: "dashboard.set_weather_locations",
    risk_and_issue_snapshot: "dashboard.risk_and_issue_snapshot",
    leaderboard_last_30_days: "dashboard.leaderboard_last_30_days",
    leaderboard: "dashboard.leaderboard",
    countdown_timer: "dashboard.countdown_timer",
    countdown_title: "dashboard.countdown_title",
    countdown_time: "dashboard.countdown_time",
    control_overview: "dashboard.control_overview",
    daily_schedule: "dashboard.daily_schedule",
    failed_checks: "dashboard.failed_checks",
    incidents_summary: "dashboard.incidents_summary",
    jobs_by_status: "dashboard.jobs_by_status",
    overdue_jobs: "dashboard.overdue_jobs",
    no_zone: "dashboard.no_zone",
    jobs_summary: "dashboard.jobs_summary",
    jobs_due: "dashboard.jobs_due",
    unsure_checks: "dashboard.unsure_checks",
    countdown_date: "dashboard.countdown_date",
    countdown_timer_updated: "dashboard.countdown_timer_updated",
    info_widget_options: "dashboard.info_widget_options",
    dashboard_info_widget_updated: "dashboard.dashboard_info_widget_updated",
    temperature_setting_updated: "dashboard.temperature_setting_updated",
    weather_locations_updated: "dashboard.weather_locations_updated",
    widgets_available_to_be_dragged: "dashboard.widgets_available_to_be_dragged",
    dashboard_layout: "dashboard.dashboard_layout",
    top_component_goes_here: "dashboard.top_component_goes_here",
    drag_widget_here: "dashboard.drag_widget_here",
    two_column: "dashboard.two_column",
    three_column: "dashboard.three_column",
    issues_snapshot: "dashboard.issues_snapshot",
    company_bulletin: "dashboard.company_bulletin",
    sustainability_summary: "dashboard.sustainability_summary",
    control_summary_bar: "dashboard.control_summary_bar",
    manage_saved_views: "dashboard.manage_saved_views",
    customize_layout: "dashboard.customize_layout",
    enter_title_for_your_saved_view: "dashboard.enter_title_for_your_saved_view",
    enter_layout_title_sustainability: "dashboard.enter_layout_title_sustainability",
    planning: "dashboard.planning",
    or_select_existing_saved_view: "dashboard.or_select_existing_saved_view",
    save_as_public_view: "dashboard.save_as_public_view",
    click_here_to_customize_dashboard: "dashboard.click_here_to_customize_dashboard",
    save_dashboard_view: "dashboard.save_dashboard_view",
    update_dashboard_view: "dashboard.update_dashboard_view",
    unsaved_view: "dashboard.unsaved_view",
    incident_module: {
      activity_feed: "dashboard.incident_module.activity_feed",
      top_incidents: "dashboard.incident_module.top_incidents",
      weather_forecast: "dashboard.incident_module.weather_forecast",
      incident_severity_breakdown: "dashboard.incident_module.incident_severity_breakdown",
      incident_map_widget: "dashboard.incident_module.incident_map_widget",
      completed_today: "dashboard.incident_module.completed_today",
      created_today: "dashboard.incident_module.created_today",
      jobs_due_today: "dashboard.incident_module.jobs_due_today",
      failed_checks: "dashboard.incident_module.failed_checks",
      in_progress: "dashboard.incident_module.in_progress",
      incidents_created_today: "dashboard.incident_module.incidents_created_today",
      incidents_closed_today: "dashboard.incident_module.incidents_closed_today",
      jobs_created_today: "dashboard.incident_module.jobs_created_today",
      jobs_completed_today: "dashboard.incident_module.jobs_completed_today",
      jobs_closed_today: "dashboard.incident_module.jobs_closed_today",
      jobs_failed_checks: "dashboard.incident_module.jobs_failed_checks",
      open_incidents: "dashboard.incident_module.open_incidents",
      top_zone: "dashboard.incident_module.top_zone",
      top_category: "dashboard.incident_module.top_category"
    },
    sustainability_module: {
      add_indicator: "dashboard.sustainability_module.add_indicator",
      suggested_indicators: "dashboard.sustainability_module.suggested_indicators",
      select_carbon_emissions: "dashboard.sustainability_module.select_carbon_emissions",
      suggested_emissions_factor: "dashboard.sustainability_module.suggested_emissions_factor",
      update_indicator_details: "dashboard.sustainability_module.update_indicator_details",
      based_on_the_inforamation: "dashboard.sustainability_module.based_on_the_inforamation",
      update_tracking_details_text: "dashboard.sustainability_module.update_tracking_details_text",
      update_tracking_details_confirmation: "dashboard.sustainability_module.update_tracking_details_confirmation",
      update_owners: "dashboard.sustainability_module.update_owners",
      update_owners_confirmation_text: "dashboard.sustainability_module.update_owners_confirmation_text",
      update_departments: "dashboard.sustainability_module.update_departments",
      update_departments_confirmation_text: "dashboard.sustainability_module.update_departments_confirmation_text",
      available_emissions_factor: "dashboard.sustainability_module.available_emissions_factor",
      indicator_template_totals: "dashboard.sustainability_module.indicator_template_totals",
      unsdg_progress: "dashboard.sustainability_module.unsdg_progress",
      organisation_updates_leaderboard: "dashboard.sustainability_module.organisation_updates_leaderboard",
      updates_over_time: "dashboard.sustainability_module.updates_over_time",
      updates_summary: "dashboard.sustainability_module.updates_summary",
      user_leaderboard: "dashboard.sustainability_module.user_leaderboard",
      current_progres_tracker: "dashboard.sustainability_module.current_progres_tracker",
      overall_progress_towards_targets: "dashboard.sustainability_module.overall_progress_towards_targets",
      categories_progress: "dashboard.sustainability_module.categories_progress",
      upcoming_targets: "dashboard.sustainability_module.upcoming_targets",
      tasks_leaderboard: "dashboard.sustainability_module.tasks_leaderboard",
      event_leaderboard: "dashboard.sustainability_module.event_leaderboard",
      department_leaderboard: "dashboard.sustainability_module.department_leaderboard",
      organisation_leaderboard: "dashboard.sustainability_module.organisation_leaderboard",
      suggested_indicators_explanation: "dashboard.sustainability_module.suggested_indicators_explanation",
      select_relevant_indicators: "dashboard.sustainability_module.select_relevant_indicators"
    },
    planning_module: {
      momentum: "dashboard.planning_module.momentum",
      tasks_rag_breakdown: "dashboard.planning_module.tasks_rag_breakdown",
      tasks_milestone_snapshot: "dashboard.planning_module.tasks_milestone_snapshot",
      milestones_due_this_month: "dashboard.planning_module.milestones_due_this_month",
      overdue_milestones: "dashboard.planning_module.overdue_milestones",
      risk_heatmap: "dashboard.planning_module.risk_heatmap",
      projects_overview: "dashboard.planning_module.projects_overview",
      countdown: "dashboard.planning_module.countdown",
      department_rag_breakdown: "dashboard.planning_module.department_rag_breakdown",
      issues_snapshot: "dashboard.planning_module.issues_snapshot",
      leaderboard: "dashboard.planning_module.leaderboard",
      company_bulletin: "dashboard.planning_module.company_bulletin",
      weather_forecast: "dashboard.planning_module.weather_forecast",
      risk_heatmap_impact_type: "dashboard.planning_module.risk_heatmap_impact_type",
      general_info: "dashboard.planning_module.general_info",
      issue_snapshot: "dashboard.planning_module.issue_snapshot",
      open_risks_and_issues: "dashboard.planning_module.open_risks_and_issues",
      task_rags_in: "dashboard.planning_module.task_rags_in",
      task_rags_in_all_projects: "dashboard.planning_module.task_rags_in_all_projects"
    }
  },
  control: {
    add_incident: {
      one: "control.add_incident.one",
      many: "control.add_incident.many"
    },
    add_job: {
      one: "control.add_job.one",
      many: "control.add_job.many"
    },
    add_log: {
      one: "control.add_log.one",
      many: "control.add_log.many"
    },
    add_quick_log: {
      one: "control.add_quick_log.one",
      many: "control.add_quick_log.many"
    },
    add_quick_incident: {
      one: "control.add_quick_incident.one",
      many: "control.add_quick_incident.many"
    },
    control_dashboard: "control.control_dashboard",
    select_severity: "control.select_severity",
    hide_scale_guide: "control.hide_scale_guide",
    show_scale_guide: "control.show_scale_guide",
    severities_descriptions: {
      severity_1: "control.severities_descriptions.severity_1",
      severity_2: "control.severities_descriptions.severity_2",
      severity_3: "control.severities_descriptions.severity_3",
      severity_4: "control.severities_descriptions.severity_4",
      severity_5: "control.severities_descriptions.severity_5"
    },
    checklist_template_message: "control.checklist_template_message",
    checklist_template_with_localisedDepartment_message: "control.checklist_template_with_localisedDepartment_message",
    view_incident_details: "control.view_incident_details",
    adding_incident: "control.adding_incident",
    add_checklist_to_this_incident: "control.add_checklist_to_this_incident",
    add_incident_category: {
      one: "control.add_incident_category.one",
      many: "control.add_incident_category.many"
    },
    search_item_checklists: "control.search_item_checklists",
    localisedJob_is_something_that_needs_doing_or_checking: "control.localisedJob_is_something_that_needs_doing_or_checking",
    describe_your_localisedJob_and_main_details: "control.describe_your_localisedJob_and_main_details",
    localisedJobs_are_used_for_different_purposes: "control.localisedJobs_are_used_for_different_purposes",
    categories_are_used_to_provide_context_to_localisedJobs: "control.categories_are_used_to_provide_context_to_localisedJobs",
    assign_and_schedule_your_localisedJob: "control.assign_and_schedule_your_localisedJob",
    assign_localisedJob_to_owners_and_departments: "control.assign_localisedJob_to_owners_and_departments",
    schedule_the_localisedJob: "control.schedule_the_localisedJob",
    who_is_responsible_for_this_localisedJob: "control.who_is_responsible_for_this_localisedJob",
    where_is_this_localisedJob_happening: "control.where_is_this_localisedJob_happening",
    almost_done_do_you_want_to_add_checklists: "control.almost_done_do_you_want_to_add_checklists",
    add_checklist_from_the_list_of_templates_on_the_left: "control.add_checklist_from_the_list_of_templates_on_the_left",
    type_and_press_to_add_log: "control.type_and_press_to_add_log",
    type_and_press_to_add_incident: "control.type_and_press_to_add_incident",
    select_category_to_quick_add_log: "control.select_category_to_quick_add_log",
    select_category_to_quick_add_incident: "control.select_category_to_quick_add_incident",
    category_log_on_date: "control.category_log_on_date",
    category_incident_on_date: "control.category_incident_on_date",
    category_localisedJob_on_date: "control.category_localisedJob_on_date",
    all_starred_checklists_must_be_completed_before_closing: "control.all_starred_checklists_must_be_completed_before_closing",
    incident_zone_label: "control.incident_zone_label",
    confirm_conver_log_to_incident: "control.confirm_conver_log_to_incident",
    log_was_changed_to_incident: "control.log_was_changed_to_incident",
    escalate_log_to_incident: "control.escalate_log_to_incident",
    it_looks_like_you_have_started_this_localisedJob: "control.it_looks_like_you_have_started_this_localisedJob",
    starting_this_localisedJob: "control.starting_this_localisedJob",
    all_checklists_items_have_been_completed: "control.all_checklists_items_have_been_completed",
    no_checklists_found: "control.no_checklists_found",
    no_checklists_yet: "control.no_checklists_yet",
    add_from_template: "control.add_from_template",
    add_items_from_a_template: "control.add_items_from_a_template",
    added_from_checklist_template: "control.added_from_checklist_template",
    response_type: "control.response_type",
    mark_as_complete: "control.mark_as_complete",
    text_response: "control.text_response",
    fail_ensure_pass: "control.fail_ensure_pass",
    poor_fair_good: "control.poor_fair_good",
    this_item_must_be_completed_before_incident_be_closed: "control.this_item_must_be_completed_before_incident_be_closed",
    you_are_subscribed_to_updates_for_this_incident: "control.you_are_subscribed_to_updates_for_this_incident",
    view_check: "control.view_check",
    view_checks: "control.view_checks",
    control_history: "control.control_history",
    show_as_localisedPeaps: "control.show_as_localisedPeaps",
    show_as_checks: "control.show_as_checks",
    check: {
      one: "control.check.one",
      many: "control.check.many"
    },
    call_sign: "control.call_sign",
    peap_group: "control.peap_group",
    fail: "control.fail",
    unsure: "control.unsure",
    pass: "control.pass",
    failed: "control.failed",
    event_date: "control.event_date",
    event_name: "control.event_name",
    additional_checks: "control.additional_checks",
    confirm_check_status_change_to_pass: "control.confirm_check_status_change_to_pass",
    event_slash_date: "control.event_slash_date",
    resolution_types: {
      awaiting_resolution: "control.resolution_types.awaiting_resolution",
      in_progress: "control.resolution_types.in_progress",
      unresolved: "control.resolution_types.unresolved",
      resolved: "control.resolution_types.resolved"
    },
    incidents_and_log_items: "control.incidents_and_log_items",
    localisedJob_type: {
      one: "control.localisedJob_type.one",
      many: "control.localisedJob_type.many"
    },
    localisedJob_category: {
      one: "control.localisedJob_category.one",
      many: "control.localisedJob_category.many"
    },
    localisedJob_checklist: {
      one: "control.localisedJob_checklist.one",
      many: "control.localisedJob_checklist.many"
    },
    this_item_must_be_completed_before_item_can_be_closed: "control.this_item_must_be_completed_before_item_can_be_closed",
    click_to_toggle_traffic_layer: "control.click_to_toggle_traffic_layer",
    show_traffic: "control.show_traffic",
    count_incidents_at_this_location: "control.count_incidents_at_this_location",
    the_below_localisedEvents: "control.the_below_localisedEvents",
    this_will_give_you_focused_view: "control.this_will_give_you_focused_view",
    note_if_you_not_working_at_live_localisedEvent: "control.note_if_you_not_working_at_live_localisedEvent",
    no_live_localisedEvent: "control.no_live_localisedEvent",
    live_localisedEvent: "control.live_localisedEvent",
    create_job: "control.create_job",
    numeric_response: "control.numeric_response",
    check_response: "control.check_response",
    copy_event_from_incident: "control.copy_event_from_incident",
    copy_location_from_incident: "control.copy_location_from_incident",
    subtitle: {
      show_id_updated_actual: "control.subtitle.show_id_updated_actual",
      show_id_started_actual: "control.subtitle.show_id_started_actual",
      show_id_updated_relative: "control.subtitle.show_id_updated_relative",
      show_id_started_relative: "control.subtitle.show_id_started_relative",
      show_headline_status: "control.subtitle.show_headline_status",
      show_headline_status_timeline: "control.subtitle.show_headline_status_timeline",
      show_id_job_type: "control.subtitle.show_id_job_type",
      show_id_created: "control.subtitle.show_id_created",
      show_id_due: "control.subtitle.show_id_due"
    },
    assign_categories_to_ensure: "control.assign_categories_to_ensure",
    reopen: "control.reopen",
    reopen_comments: "control.reopen_comments",
    reopen_explanation: "control.reopen_explanation",
    submit_close: "control.submit_close",
    closing_comments: "control.closing_comments",
    closing_explanation: "control.closing_explanation",
    submit: "control.submit",
    open_comments: "control.open_comments",
    draft_to_open_explanation: "control.draft_to_open_explanation",
    draft_to_closed_explanation: "control.draft_to_closed_explanation",
    public_incidents_summary_new_reports: "control.public_incidents_summary_new_reports",
    public_incidents_summary_linked_reports: "control.public_incidents_summary_linked_reports",
    public_incidents_summary_reports_on_hold: "control.public_incidents_summary_reports_on_hold",
    public_incidents_summary_closed_reports: "control.public_incidents_summary_closed_reports",
    public_incident_reports_reporter_number: "control.public_incident_reports_reporter_number",
    public_incident_reports_close_report: "control.public_incident_reports_close_report",
    all_incoming_reports: "control.all_incoming_reports",
    all_linked_reports: "control.all_linked_reports",
    all_not_closed_reports: "control.all_not_closed_reports",
    all_closed_reports_today: "control.all_closed_reports_today",
    selected_public_incident_reports_out_of: "control.selected_public_incident_reports_out_of",
    subject: "control.subject",
    more: "control.more",
    search_by_report_id_phone_number_or_key_word: "control.search_by_report_id_phone_number_or_key_word"
  },
  sustainability: {
    unsdg_helper_text: "sustainability.unsdg_helper_text",
    add_indicator_title_helper_text: "sustainability.add_indicator_title_helper_text",
    add_indicator_unit_type_helper_text: "sustainability.add_indicator_unit_type_helper_text",
    add_indicator_unit_helper_text: "sustainability.add_indicator_unit_helper_text",
    add_indicator_convert_value_to_helper_text: "sustainability.add_indicator_convert_value_to_helper_text",
    update_type_helper_text: "sustainability.update_type_helper_text",
    target_type: {
      one: "sustainability.target_type.one",
      many: "sustainability.target_type.many"
    },
    expected_direction: {
      one: "sustainability.expected_direction.one",
      many: "sustainability.expected_direction.many"
    },
    unit_type: {
      one: "sustainability.unit_type.one",
      many: "sustainability.unit_type.many"
    },
    default_unit: {
      one: "sustainability.default_unit.one",
      many: "sustainability.default_unit.many"
    },
    conversion_unit: {
      one: "sustainability.conversion_unit.one",
      many: "sustainability.conversion_unit.many"
    },
    unit: {
      one: "sustainability.unit.one",
      many: "sustainability.unit.many"
    },
    update_type: {
      one: "sustainability.update_type.one",
      many: "sustainability.update_type.many"
    },
    update_frequency: {
      one: "sustainability.update_frequency.one",
      many: "sustainability.update_frequency.many"
    },
    baseline_value: "sustainability.baseline_value",
    target_value: "sustainability.target_value",
    start_value: "sustainability.start_value",
    update_date: "sustainability.update_date",
    start_from_previous_target_value: "sustainability.start_from_previous_target_value",
    start_from_baseline: "sustainability.start_from_baseline",
    start_from_custom_value: "sustainability.start_from_custom_value",
    update_due: "sustainability.update_due",
    indicator_count_high_low: "sustainability.indicator_count_high_low",
    indicator_count_low_high: "sustainability.indicator_count_low_high",
    add_a_target: "sustainability.add_a_target",
    baseline_date: "sustainability.baseline_date",
    last_date: "sustainability.last_date",
    expected: "sustainability.expected",
    take_first_step_to_start_tracking: "sustainability.take_first_step_to_start_tracking",
    add_first_update: "sustainability.add_first_update",
    add_baseline_value: "sustainability.add_baseline_value",
    indicator_baseline_updated: "sustainability.indicator_baseline_updated",
    your_indicator_does_not_have_baseline_value: "sustainability.your_indicator_does_not_have_baseline_value",
    baseline_value_is_value_for_this_indicator: "sustainability.baseline_value_is_value_for_this_indicator",
    update_indicator_baseline_modal_intro: "sustainability.update_indicator_baseline_modal_intro",
    update_indicator_baseline_modal_warning: "sustainability.update_indicator_baseline_modal_warning",
    baseline: "sustainability.baseline",
    updating_baseline: "sustainability.updating_baseline",
    update_indicator_baseline: "sustainability.update_indicator_baseline",
    confirm_target_affect_trend_another_target_title: "sustainability.confirm_target_affect_trend_another_target_title",
    target: "sustainability.target",
    targets: "sustainability.targets",
    indicator_title: "sustainability.indicator_title",
    this_will_recalculate_the_trend_for_all_updates: "sustainability.this_will_recalculate_the_trend_for_all_updates",
    badge: "sustainability.badge",
    existing_targets_for_this_indicator: "sustainability.existing_targets_for_this_indicator",
    choose_value_for_this_target: "sustainability.choose_value_for_this_target",
    set_target_date: "sustainability.set_target_date",
    target_start_date: "sustainability.target_start_date",
    target_due_date: "sustainability.target_due_date",
    no_badge: "sustainability.no_badge",
    gold: "sustainability.gold",
    silver: "sustainability.silver",
    bronze: "sustainability.bronze",
    starting_on: "sustainability.starting_on",
    changing_this_indicator_tracking_period: "sustainability.changing_this_indicator_tracking_period",
    these_updated_will_happen: "sustainability.these_updated_will_happen",
    updates_will_be_created_for_this_indicator: "sustainability.updates_will_be_created_for_this_indicator",
    the_final_update_will_be_due_on: "sustainability.the_final_update_will_be_due_on",
    change_tracking_period: "sustainability.change_tracking_period",
    extend_tracking_period: "sustainability.extend_tracking_period",
    extend_tracking_duration: "sustainability.extend_tracking_duration",
    progress: "sustainability.progress",
    finance: "sustainability.finance",
    coming_soon_way_to_manage_costs: "sustainability.coming_soon_way_to_manage_costs",
    due_in_1_year: "sustainability.due_in_1_year",
    due_in_count_years: "sustainability.due_in_count_years",
    due_count_years_ago: "sustainability.due_count_years_ago",
    due_1_year_ago: "sustainability.due_1_year_ago",
    due_years_and_days_ago: "sustainability.due_years_and_days_ago",
    due_count_days_ago: "sustainability.due_count_days_ago",
    due_1_day_ago: "sustainability.due_1_day_ago",
    due_in_1_day: "sustainability.due_in_1_day",
    due_in_count_days: "sustainability.due_in_count_days",
    due_count_hours_ago: "sustainability.due_count_hours_ago",
    due_1_hour_ago: "sustainability.due_1_hour_ago",
    due_1_minute_ago: "sustainability.due_1_minute_ago",
    due_count_minutes_ago: "sustainability.due_count_minutes_ago",
    next_date: "sustainability.next_date",
    no_updates_remaining: "sustainability.no_updates_remaining",
    no_update: "sustainability.no_update",
    or_set_goal_to_track_against: "sustainability.or_set_goal_to_track_against",
    chart: "sustainability.chart",
    both: "sustainability.both",
    count_of_total_updates_completed: "sustainability.count_of_total_updates_completed",
    last_update_original: "sustainability.last_update_original",
    last_update_converted: "sustainability.last_update_converted",
    expected_value: "sustainability.expected_value",
    progress_due_date_header_tooltip: "sustainability.progress_due_date_header_tooltip",
    value_original: "sustainability.value_original",
    exp_value_abbreviation: "sustainability.exp_value_abbreviation",
    progress_exp_val_header_tooltip: "sustainability.progress_exp_val_header_tooltip",
    act_val_abbreviation: "sustainability.act_val_abbreviation",
    progress_act_val_header_tooltip: "sustainability.progress_act_val_header_tooltip",
    trend: "sustainability.trend",
    progress_trend_header_tooltip: "sustainability.progress_trend_header_tooltip",
    total_change_percent: "sustainability.total_change_percent",
    progress_total_change_header_tooltip: "sustainability.progress_total_change_header_tooltip",
    improv_percent: "sustainability.improv_percent",
    progress_improv_percent_header_tooltip: "sustainability.progress_improv_percent_header_tooltip",
    progress_target_header_tooltip: "sustainability.progress_target_header_tooltip",
    progress_attachments_header_tooltip: "sustainability.progress_attachments_header_tooltip",
    progress_comments_header_tooltip: "sustainability.progress_comments_header_tooltip",
    progress_confirm_update_for_1_day: "sustainability.progress_confirm_update_for_1_day",
    progress_confirm_update_for_count_days: "sustainability.progress_confirm_update_for_count_days",
    loading_chart_data: "sustainability.loading_chart_data",
    loading_data: "sustainability.loading_data",
    add_baseline: "sustainability.add_baseline",
    provide_update_for: "sustainability.provide_update_for",
    this_update_is_not_due_yet: "sustainability.this_update_is_not_due_yet",
    last_update: "sustainability.last_update",
    next_update: "sustainability.next_update",
    higher_than_previous_update: "sustainability.higher_than_previous_update",
    lower_than_previous_update: "sustainability.lower_than_previous_update",
    confirm_clear_update: "sustainability.confirm_clear_update",
    confirm_clear_baseline: "sustainability.confirm_clear_baseline",
    value_cleared: "sustainability.value_cleared",
    target_date: "sustainability.target_date",
    indicator_tracking_details: "sustainability.indicator_tracking_details",
    manage_accounts_tracking: "sustainability.manage_accounts_tracking",
    manage_indicators_tracking: "sustainability.manage_indicators_tracking",
    save_accout_indicators: "sustainability.save_accout_indicators",
    indicators_tracked_by: "sustainability.indicators_tracked_by",
    manage_sustainability_tracking: "sustainability.manage_sustainability_tracking",
    tracking_period_table_header: "sustainability.tracking_period_table_header",
    update_accounts: "sustainability.update_accounts",
    account_updated: "sustainability.account_updated",
    go_to_assignments: "sustainability.go_to_assignments",
    go_to_tracking_details: "sustainability.go_to_tracking_details",
    go_to_tracking_update_types: "sustainability.go_to_tracking_update_types",
    enter_tracking_details: "sustainability.enter_tracking_details",
    create_indicators_and_indicator_templates: "sustainability.create_indicators_and_indicator_templates",
    all_accounts: "sustainability.all_accounts",
    indicator_templates_selected: "sustainability.indicator_templates_selected",
    accounts_selected: "sustainability.accounts_selected",
    accounts_tracked_by: "sustainability.accounts_tracked_by",
    manage_accounts_owner_and_dates: "sustainability.manage_accounts_owner_and_dates",
    manage_indicators_owner_and_dates: "sustainability.manage_indicators_owner_and_dates",
    manage_the_owners_of_indicators: "sustainability.manage_the_owners_of_indicators",
    manage_the_owners_of_accounts: "sustainability.manage_the_owners_of_accounts",
    accounts_being_added: "sustainability.accounts_being_added",
    indicators_being_added: "sustainability.indicators_being_added",
    create_new_indicator: "sustainability.create_new_indicator",
    create_indicator_template: "sustainability.create_indicator_template",
    indicator_template_added: "sustainability.indicator_template_added",
    indicator_added: "sustainability.indicator_added",
    indicaor_is_something_to_be_tested: "sustainability.indicaor_is_something_to_be_tested",
    describe_your_indicator_and_add_details: "sustainability.describe_your_indicator_and_add_details",
    indicator_add_update_types_details: "sustainability.indicator_add_update_types_details",
    indicators_must_be_assigned_to_a_category: "sustainability.indicators_must_be_assigned_to_a_category",
    indicator_select_update_type: "sustainability.indicator_select_update_type",
    link_your_indicators_to_any_17_un_goals: "sustainability.link_your_indicators_to_any_17_un_goals",
    link_unit_to_your_indicator: "sustainability.link_unit_to_your_indicator",
    choose_how_indicator_to_be_tracked: "sustainability.choose_how_indicator_to_be_tracked",
    assign_indicator_to_individuals: "sustainability.assign_indicator_to_individuals",
    owners_are_those_responsible_for_completing: "sustainability.owners_are_those_responsible_for_completing",
    localisedDepartments_can_be_assigned_to_indicators: "sustainability.localisedDepartments_can_be_assigned_to_indicators",
    assign_indicators_to_localisedOrganisations: "sustainability.assign_indicators_to_localisedOrganisations",
    choose_whether_you_want_to_add_indicator: "sustainability.choose_whether_you_want_to_add_indicator",
    single_indicator: "sustainability.single_indicator",
    indicator_that_only_to_be_tracked_once: "sustainability.indicator_that_only_to_be_tracked_once",
    indicator_template: "sustainability.indicator_template",
    template_which_can_be_used_to_track: "sustainability.template_which_can_be_used_to_track",
    if_you_have_template_set_up_part_1: "sustainability.if_you_have_template_set_up_part_1",
    if_you_have_template_set_up_part_2: "sustainability.if_you_have_template_set_up_part_2",
    start_by_describing_indicator: "sustainability.start_by_describing_indicator",
    un_sustainability_goals: "sustainability.un_sustainability_goals",
    who_is_responsible_for_these_indicators: "sustainability.who_is_responsible_for_these_indicators",
    how_do_you_want_indicators_to_be_updated: "sustainability.how_do_you_want_indicators_to_be_updated",
    how_often_indicators_to_be_updated: "sustainability.how_often_indicators_to_be_updated",
    updates_for_indicator_should_be_provided: "sustainability.updates_for_indicator_should_be_provided",
    frequency: "sustainability.frequency",
    start_tracking: "sustainability.start_tracking",
    end_tracking: "sustainability.end_tracking",
    the_tracking_period_should_last_for: "sustainability.the_tracking_period_should_last_for",
    update_count: "sustainability.update_count",
    add_target_for_this_indicator: "sustainability.add_target_for_this_indicator",
    go_to_indicator_details: "sustainability.go_to_indicator_details",
    create_another_indicator: "sustainability.create_another_indicator",
    add_another_indicator: "sustainability.add_another_indicator",
    add_target_structure_for_indicator_template: "sustainability.add_target_structure_for_indicator_template",
    go_to_indicator_template_details: "sustainability.go_to_indicator_template_details",
    add_another_indicator_template: "sustainability.add_another_indicator_template",
    expected_tracking_updates: "sustainability.expected_tracking_updates",
    count_updates_will_be_created_for_indicator: "sustainability.count_updates_will_be_created_for_indicator",
    these_updates_will_happen: "sustainability.these_updates_will_happen",
    frequency_starting_on_date: "sustainability.frequency_starting_on_date",
    final_update_will_be_due_on: "sustainability.final_update_will_be_due_on",
    tracking_period_will_last_for: "sustainability.tracking_period_will_last_for",
    now_that_have_added_your_indicators: "sustainability.now_that_have_added_your_indicators",
    indicator_template_add_target: "sustainability.indicator_template_add_target",
    target_due_on_update_number: "sustainability.target_due_on_update_number",
    overview: "sustainability.overview",
    requirements: "sustainability.requirements",
    view_indicator_templates: "sustainability.view_indicator_templates",
    indicator_templates_amp_groups: "sustainability.indicator_templates_amp_groups",
    select_indicators_to_include: "sustainability.select_indicators_to_include",
    tracking_period: "sustainability.tracking_period",
    indicator: {
      one: "sustainability.indicator.one",
      many: "sustainability.indicator.many"
    },
    add_some_indicator_templates: "sustainability.add_some_indicator_templates",
    each_localisedOrganisation_will_have: "sustainability.each_localisedOrganisation_will_have",
    select_indicators_you_want_to_track: "sustainability.select_indicators_you_want_to_track",
    from_list_of_indicator_templates_on_right: "sustainability.from_list_of_indicator_templates_on_right",
    some_of_indicators_are_already_tracked: "sustainability.some_of_indicators_are_already_tracked",
    orgTitle_is_already_tracking: "sustainability.orgTitle_is_already_tracking",
    indicator_group: {
      one: "sustainability.indicator_group.one",
      many: "sustainability.indicator_group.many"
    },
    update_settings_for_each_localisedOrganisation: "sustainability.update_settings_for_each_localisedOrganisation",
    each_of_your_count_localisedOrganisations: "sustainability.each_of_your_count_localisedOrganisations",
    set_owners_for_indicators_for_localisedOrganisation: "sustainability.set_owners_for_indicators_for_localisedOrganisation",
    choose_when_tracking_should_start: "sustainability.choose_when_tracking_should_start",
    note_any_of_the_changes: "sustainability.note_any_of_the_changes",
    initiate_launch_sequence: "sustainability.initiate_launch_sequence",
    once_you_are_happy: "sustainability.once_you_are_happy",
    we_will_send_you_email: "sustainability.we_will_send_you_email",
    count_expected_indicator_updates: "sustainability.count_expected_indicator_updates",
    indicator_s: "sustainability.indicator_s",
    count_indicators_will_be_created: "sustainability.count_indicators_will_be_created",
    overall_status: "sustainability.overall_status",
    total_indicators: "sustainability.total_indicators",
    top_performers: "sustainability.top_performers",
    top_localisedOrganisation: "sustainability.top_localisedOrganisation",
    organisations_on_track_on_latest_update: "sustainability.organisations_on_track_on_latest_update",
    top_indicator: "sustainability.top_indicator",
    indicators_on_track_on_latest_update: "sustainability.indicators_on_track_on_latest_update",
    indicators_on_track: "sustainability.indicators_on_track",
    targets_achieved: "sustainability.targets_achieved",
    indicators_achieving_their_targets: "sustainability.indicators_achieving_their_targets",
    targets_achieved_across_all_indicators: "sustainability.targets_achieved_across_all_indicators",
    top: "sustainability.top",
    performance_summary: "sustainability.performance_summary",
    confirm_update_all_indicators_in_group: "sustainability.confirm_update_all_indicators_in_group",
    update_other_targets: "sustainability.update_other_targets",
    updating_this_template: "sustainability.updating_this_template",
    indicator_group_summary: "sustainability.indicator_group_summary",
    view_all_indicator_groups: "sustainability.view_all_indicator_groups",
    template_overview: "sustainability.template_overview",
    template_id: "sustainability.template_id",
    tracking_details: "sustainability.tracking_details",
    exp_direction: "sustainability.exp_direction",
    no_of_updates: "sustainability.no_of_updates",
    add_new_target: "sustainability.add_new_target",
    you_cant_add_targets_once_indicators: "sustainability.you_cant_add_targets_once_indicators",
    summary: "sustainability.summary",
    updates: "sustainability.updates",
    indicator_summary: "sustainability.indicator_summary",
    total: "sustainability.total",
    average: "sustainability.average",
    latest_indicator_updates: "sustainability.latest_indicator_updates",
    latest_updates_by_localisedOrganisations: "sustainability.latest_updates_by_localisedOrganisations",
    target_structure: "sustainability.target_structure",
    updates_summary: "sustainability.updates_summary",
    updates_due: "sustainability.updates_due",
    organisation_performance_tracker: "sustainability.organisation_performance_tracker",
    localisedOrganisations_tracking: "sustainability.localisedOrganisations_tracking",
    indicators_being_tracked_by: "sustainability.indicators_being_tracked_by",
    search_units: "sustainability.search_units",
    search_for_an_organisation: "sustainability.search_for_an_organisation",
    search_for_an_indicator: "sustainability.search_for_an_indicator",
    updates_due_me: "sustainability.updates_due_me",
    updates_due_all: "sustainability.updates_due_all",
    recent_updates: "sustainability.recent_updates",
    no_updates_yet: "sustainability.no_updates_yet",
    localisedOrganisation_notes: "sustainability.localisedOrganisation_notes",
    localisedOrganisation_rags: "sustainability.localisedOrganisation_rags",
    resources: "sustainability.resources",
    people: "sustainability.people",
    indicators_you_are_assigned: "sustainability.indicators_you_are_assigned",
    indicators_for_organisation_which: "sustainability.indicators_for_organisation_which",
    indicators_for_indicator_group: "sustainability.indicators_for_indicator_group",
    indicators_updates_due_next_30_days: "sustainability.indicators_updates_due_next_30_days",
    recent_updates_for_organisation_indicators: "sustainability.recent_updates_for_organisation_indicators",
    review_and_add_other_notes_for_organisation: "sustainability.review_and_add_other_notes_for_organisation",
    other_localisedOrganisation_updates: "sustainability.other_localisedOrganisation_updates",
    missed_targets: "sustainability.missed_targets",
    upcoming_targets: "sustainability.upcoming_targets",
    grouped_by_indicator_category: "sustainability.grouped_by_indicator_category",
    create_image_to_share_achievement: "sustainability.create_image_to_share_achievement",
    edit_labels: "sustainability.edit_labels",
    customise_labels_for_badge: "sustainability.customise_labels_for_badge",
    image_label_indicator_title: "sustainability.image_label_indicator_title",
    download_badge_only: "sustainability.download_badge_only",
    download_full_image: "sustainability.download_full_image",
    badge_label_target_name: "sustainability.badge_label_target_name",
    sustainability_dashboard: "sustainability.sustainability_dashboard",
    cannot_be_tracked: "sustainability.cannot_be_tracked",
    emissions_factors: "sustainability.emissions_factors",
    total_emissions: "sustainability.total_emissions",
    total_indicators_tracking_emissions: "sustainability.total_indicators_tracking_emissions",
    total_emissions_tooltip: "sustainability.total_emissions_tooltip",
    total_indicators_tracking_emissions_tooltip: "sustainability.total_indicators_tracking_emissions_tooltip",
    emissions_category_tooltip: "sustainability.emissions_category_tooltip",
    emissions_scope_tooltip: "sustainability.emissions_scope_tooltip",
    emissions_over_time_tooltip: "sustainability.emissions_over_time_tooltip",
    emissions_by_indicators_tooltip: "sustainability.emissions_by_indicators_tooltip",
    track_c02_button: "sustainability.track_c02_button",
    add_emissions_factors: "sustainability.add_emissions_factors",
    getting_emissions_factors: "sustainability.getting_emissions_factors",
    choose_factors: "sustainability.choose_factors",
    enter_emissions_keyword: "sustainability.enter_emissions_keyword",
    no_options_found: "sustainability.no_options_found",
    set_emissions_factors: "sustainability.set_emissions_factors",
    set_emissions_factors_sub_title: "sustainability.set_emissions_factors_sub_title",
    track_carbon_emissions: "sustainability.track_carbon_emissions",
    dont_track_emissions: "sustainability.dont_track_emissions",
    based_on_your_account: "sustainability.based_on_your_account",
    emissions_overview: "sustainability.emissions_overview",
    emissions_over_time: "sustainability.emissions_over_time",
    emissions_calculations: "sustainability.emissions_calculations",
    emissions_by_scope: "sustainability.emissions_by_scope",
    emissions_by_indicator: "sustainability.emissions_by_indicator",
    emissions_by_category: "sustainability.emissions_by_category",
    all_time: "sustainability.all_time",
    past_year: "sustainability.past_year",
    this_year: "sustainability.this_year",
    this_quarter: "sustainability.this_quarter",
    updates_completed: "sustainability.updates_completed",
    total_targets: "sustainability.total_targets",
    current_progress_tracker: "sustainability.current_progress_tracker",
    indicators_achieved_their_target: "sustainability.indicators_achieved_their_target",
    overall_progress_towards_targets: "sustainability.overall_progress_towards_targets",
    item_progress: "sustainability.item_progress",
    recent_achievements: "sustainability.recent_achievements",
    average_percentage_improvement: "sustainability.average_percentage_improvement",
    localisedDepartment_leaderboard: "sustainability.localisedDepartment_leaderboard",
    localisedEvent_leaderboard: "sustainability.localisedEvent_leaderboard",
    indicator_localisedDepartment: "sustainability.indicator_localisedDepartment",
    percent_on_track_indicators: "sustainability.percent_on_track_indicators",
    extend_indicator_template_tracking_period_sub_title: "sustainability.extend_indicator_template_tracking_period_sub_title",
    expected_extra_updates_summary_title: "sustainability.expected_extra_updates_summary_title",
    expected_extra_updates_summary: "sustainability.expected_extra_updates_summary",
    localisedOrganisation_leaderboard: "sustainability.localisedOrganisation_leaderboard",
    indicator_template_inactive: "sustainability.indicator_template_inactive",
    stop_tracking_this_indicator_group: "sustainability.stop_tracking_this_indicator_group",
    confirm_stop_tracking_indicator_template: "sustainability.confirm_stop_tracking_indicator_template",
    indicator_inactive: "sustainability.indicator_inactive",
    stop_tracking_indicator: "sustainability.stop_tracking_indicator",
    confirm_stop_tracking_indicator: "sustainability.confirm_stop_tracking_indicator",
    stop_tracking_item: "sustainability.stop_tracking_item",
    organisation_inactive: "sustainability.organisation_inactive",
    confirm_stop_tracking_organisation: "sustainability.confirm_stop_tracking_organisation",
    assign_unsdgs: "sustainability.assign_unsdgs",
    view_indicator_details: "sustainability.view_indicator_details",
    complete_checklists_below: "sustainability.complete_checklists_below",
    update_indicator: "sustainability.update_indicator",
    indicator_updated: "sustainability.indicator_updated",
    target_title: "sustainability.target_title",
    expected_achievement_date: "sustainability.expected_achievement_date",
    unsdg: "sustainability.unsdg",
    last_update_value: "sustainability.last_update_value",
    last_update_preConversion_value: "sustainability.last_update_preConversion_value",
    preConversion_value: "sustainability.preConversion_value",
    next_update_value: "sustainability.next_update_value",
    next_target: "sustainability.next_target",
    next_trget_date: "sustainability.next_trget_date",
    targets_reached: "sustainability.targets_reached",
    tracking_frequency: "sustainability.tracking_frequency",
    actual_value: "sustainability.actual_value",
    overdue_update: {
      one: "sustainability.overdue_update.one",
      many: "sustainability.overdue_update.many"
    },
    most_overdue_updates: "sustainability.most_overdue_updates",
    rag: "sustainability.rag",
    percent_dial: "sustainability.percent_dial",
    least_overdue_updates: "sustainability.least_overdue_updates",
    converted_unit: "sustainability.converted_unit",
    pillar: "sustainability.pillar",
    reporting_directive: "sustainability.reporting_directive",
    ghg_reporting_category: "sustainability.ghg_reporting_category",
    ghg_category: "sustainability.ghg_category",
    unsdgs_descriptions: {
      goal_1: "sustainability.unsdgs_descriptions.goal_1",
      goal_2: "sustainability.unsdgs_descriptions.goal_2",
      goal_3: "sustainability.unsdgs_descriptions.goal_3",
      goal_4: "sustainability.unsdgs_descriptions.goal_4",
      goal_5: "sustainability.unsdgs_descriptions.goal_5",
      goal_6: "sustainability.unsdgs_descriptions.goal_6",
      goal_7: "sustainability.unsdgs_descriptions.goal_7",
      goal_8: "sustainability.unsdgs_descriptions.goal_8",
      goal_9: "sustainability.unsdgs_descriptions.goal_9",
      goal_10: "sustainability.unsdgs_descriptions.goal_10",
      goal_11: "sustainability.unsdgs_descriptions.goal_11",
      goal_12: "sustainability.unsdgs_descriptions.goal_12",
      goal_13: "sustainability.unsdgs_descriptions.goal_13",
      goal_14: "sustainability.unsdgs_descriptions.goal_14",
      goal_15: "sustainability.unsdgs_descriptions.goal_15",
      goal_16: "sustainability.unsdgs_descriptions.goal_16",
      goal_17: "sustainability.unsdgs_descriptions.goal_17"
    },
    total_emissions_accounts: "sustainability.total_emissions_accounts",
    emissions_by_indicator_group: "sustainability.emissions_by_indicator_group",
    emissions_by_indicator_group_tooltip: "sustainability.emissions_by_indicator_group_tooltip",
    define_the_method_of_tracking: "sustainability.define_the_method_of_tracking",
    choose_how_you_want_to_track_this_indicator: "sustainability.choose_how_you_want_to_track_this_indicator"
  },
  runsheet: {
    run_sheet_date: "runsheet.run_sheet_date",
    run_sheets_are_auto_archived_10_days_after: "runsheet.run_sheets_are_auto_archived_10_days_after",
    table_view: {
      tooltip_msgs: {
        start_time: "runsheet.table_view.tooltip_msgs.start_time",
        end_time: "runsheet.table_view.tooltip_msgs.end_time",
        dependent_items: "runsheet.table_view.tooltip_msgs.dependent_items",
        title: "runsheet.table_view.tooltip_msgs.title",
        priority: "runsheet.table_view.tooltip_msgs.priority",
        departments: "runsheet.table_view.tooltip_msgs.departments",
        owners: "runsheet.table_view.tooltip_msgs.owners",
        zones: "runsheet.table_view.tooltip_msgs.zones",
        areas: "runsheet.table_view.tooltip_msgs.areas",
        status: "runsheet.table_view.tooltip_msgs.status"
      },
      view_edit_this_item: "runsheet.table_view.view_edit_this_item",
      create_new_item_above_15m: "runsheet.table_view.create_new_item_above_15m",
      create_new_item_below_15m: "runsheet.table_view.create_new_item_below_15m"
    },
    add_dependent_item: "runsheet.add_dependent_item",
    duplicate_this_item: "runsheet.duplicate_this_item",
    delete_this_item: "runsheet.delete_this_item",
    dependent_item: "runsheet.dependent_item",
    confirm_duplicate_item: "runsheet.confirm_duplicate_item",
    confirm_delete_runsheet_item_with_1_dependency: "runsheet.confirm_delete_runsheet_item_with_1_dependency",
    confirm_delete_runsheet_item_with_many_dependencies: "runsheet.confirm_delete_runsheet_item_with_many_dependencies",
    this_item_is_set_to_start_time_after: "runsheet.this_item_is_set_to_start_time_after",
    this_item_is_set_to_start_time_before: "runsheet.this_item_is_set_to_start_time_before",
    add_item_modal: "runsheet.add_item_modal",
    add_item_inline: "runsheet.add_item_inline",
    enable_disable_completion_status: "runsheet.enable_disable_completion_status",
    enable_completion_status: "runsheet.enable_completion_status",
    disable_completion_status: "runsheet.disable_completion_status",
    item_below_is_set_set_to_start_time_after: "runsheet.item_below_is_set_set_to_start_time_after",
    item_above_is_set_to_start_time_before: "runsheet.item_above_is_set_to_start_time_before",
    cant_set_start_1_day_before_runsheet: "runsheet.cant_set_start_1_day_before_runsheet",
    cant_set_start_1_day_after_runsheet: "runsheet.cant_set_start_1_day_after_runsheet",
    should_set_at_least_1_min_difference: "runsheet.should_set_at_least_1_min_difference",
    end_time_cant_be_before_start_time: "runsheet.end_time_cant_be_before_start_time",
    item_add_modal_info_msg: "runsheet.item_add_modal_info_msg",
    time_is_fixed: "runsheet.time_is_fixed",
    time_is_dependent: "runsheet.time_is_dependent",
    item_start_time: "runsheet.item_start_time",
    item_starts_at: "runsheet.item_starts_at",
    item_will_start: "runsheet.item_will_start",
    show_whole_run_sheet: "runsheet.show_whole_run_sheet",
    runsheet_items_have_no_locations_at_time: "runsheet.runsheet_items_have_no_locations_at_time",
    none_of_the_runsheet_items_have_location: "runsheet.none_of_the_runsheet_items_have_location",
    show_run_sheets: "runsheet.show_run_sheets",
    showing_items_for_runsheet: "runsheet.showing_items_for_runsheet",
    show_runsheet_items_in_calendar: "runsheet.show_runsheet_items_in_calendar",
    item_end_time: "runsheet.item_end_time"
  },
  my_track: {
    show_just_my_items: "my_track.show_just_my_items",
    my_items: "my_track.my_items",
    show_my_localisedDepartment_s_items: "my_track.show_my_localisedDepartment_s_items",
    my_track: "my_track.my_track",
    my_track_just_my_localisedDepartment_s_items: "my_track.my_track_just_my_localisedDepartment_s_items",
    my_track_just_my_items: "my_track.my_track_just_my_items",
    count_percentage_complete: "my_track.count_percentage_complete",
    overdue_items: "my_track.overdue_items",
    updates_due_next_30_days: "my_track.updates_due_next_30_days",
    due_next_30_days: "my_track.due_next_30_days",
    open_risks_and_issues: "my_track.open_risks_and_issues",
    overdue_just_my_items: "my_track.overdue_just_my_items",
    overdue_my_localisedDepartment: "my_track.overdue_my_localisedDepartment",
    no_overdue_localisedItems_here: "my_track.no_overdue_localisedItems_here",
    no_overdue_items_here: "my_track.no_overdue_items_here",
    no_overdue_actions_here: "my_track.no_overdue_actions_here",
    due_next_30_days_just_my_items: "my_track.due_next_30_days_just_my_items",
    due_next_30_days_my_localisedDepartment: "my_track.due_next_30_days_my_localisedDepartment",
    no_upcoming_localisedItems_here: "my_track.no_upcoming_localisedItems_here",
    no_upcoming_items_here: "my_track.no_upcoming_items_here",
    no_upcoming_actions_here: "my_track.no_upcoming_actions_here",
    my_pinned_items: "my_track.my_pinned_items",
    no_pinned_localisedItems_here: "my_track.no_pinned_localisedItems_here",
    no_pinned_items_here: "my_track.no_pinned_items_here",
    my_personal_checklist: "my_track.my_personal_checklist",
    add_a_to_do: "my_track.add_a_to_do",
    my_localisedItems: "my_track.my_localisedItems",
    my_unsubmitted_localisedItems: "my_track.my_unsubmitted_localisedItems",
    my_upcoming_localisedItems: "my_track.my_upcoming_localisedItems",
    my_open_localisedItems: "my_track.my_open_localisedItems"
  },
  reports: {
    search_reports: "reports.search_reports",
    favourites: "reports.favourites",
    suggested: "reports.suggested",
    reports_shared_with_me: "reports.reports_shared_with_me",
    reports_shared_by_me: "reports.reports_shared_by_me",
    all_shared_reports: "reports.all_shared_reports",
    reports_shared_in_the_past: "reports.reports_shared_in_the_past",
    report_title: "reports.report_title",
    sections: "reports.sections",
    button_actions: "reports.button_actions",
    unsubscribed_from_report_successfully: "reports.unsubscribed_from_report_successfully",
    sending_to: "reports.sending_to",
    send_frequency: "reports.send_frequency",
    sent_by: "reports.sent_by",
    daily_at_time: "reports.daily_at_time",
    weekly_on_dayOfWeek_at_time: "reports.weekly_on_dayOfWeek_at_time",
    monthly_on_dayOfMonth_at_time: "reports.monthly_on_dayOfMonth_at_time",
    sendingFrequency_expired: "reports.sendingFrequency_expired",
    report_will_be_deleted_and_stop_sharing: "reports.report_will_be_deleted_and_stop_sharing",
    delete_report_question: "reports.delete_report_question",
    report_added: "reports.report_added",
    build_new_report: "reports.build_new_report",
    no_reports_found: "reports.no_reports_found",
    to_get_started: "reports.to_get_started",
    you_dont_have_favourite_reports_yet: "reports.you_dont_have_favourite_reports_yet",
    and_save_it_as_suggested: "reports.and_save_it_as_suggested",
    you_dont_have_suggested_reports_yet: "reports.you_dont_have_suggested_reports_yet",
    shared_with_me: "reports.shared_with_me",
    reports_that_were_shared_with_you_by_other_users: "reports.reports_that_were_shared_with_you_by_other_users",
    you_dont_have_any_reports_shared_with_you_yet: "reports.you_dont_have_any_reports_shared_with_you_yet",
    shared_by_me: "reports.shared_by_me",
    reports_that_you_shared_with_other_users: "reports.reports_that_you_shared_with_other_users",
    you_dont_have_any_shared_reports_yet: "reports.you_dont_have_any_shared_reports_yet",
    all_shared: "reports.all_shared",
    all_shared_reports_for_all_users: "reports.all_shared_reports_for_all_users",
    there_is_no_shared_reports_yet: "reports.there_is_no_shared_reports_yet",
    past_shared: "reports.past_shared",
    reports_that_were_shared_but_which_have_expired: "reports.reports_that_were_shared_but_which_have_expired",
    there_is_no_expired_reports_yet: "reports.there_is_no_expired_reports_yet",
    view_report: "reports.view_report",
    edit_report: "reports.edit_report",
    share_immediately: "reports.share_immediately",
    scheduled_sharing_settings: "reports.scheduled_sharing_settings",
    export_to_pdf: "reports.export_to_pdf",
    delete_report: "reports.delete_report",
    add_to_favourites: "reports.add_to_favourites",
    already_in_favourites: "reports.already_in_favourites",
    new_report_section: "reports.new_report_section",
    report_builder: "reports.report_builder",
    back_to_reports_home: "reports.back_to_reports_home",
    report_sections: "reports.report_sections",
    add_section: "reports.add_section",
    edit_previous_section: "reports.edit_previous_section",
    section_count_of_total: "reports.section_count_of_total",
    edit_next_section: "reports.edit_next_section",
    edit_report_type: "reports.edit_report_type",
    what_do_you_want_to_report_on: "reports.what_do_you_want_to_report_on",
    choose_which_module_you_want_to_run_report_for: "reports.choose_which_module_you_want_to_run_report_for",
    choose_module: "reports.choose_module",
    choose_report_type: "reports.choose_report_type",
    report_type: "reports.report_type",
    item_report_templates: "reports.item_report_templates",
    build: "reports.build",
    section_title: "reports.section_title",
    edit_and_sort_columns: "reports.edit_and_sort_columns",
    show_more_info_in_the_extra_row: "reports.show_more_info_in_the_extra_row",
    delete_section: "reports.delete_section",
    reportType_report: "reports.reportType_report",
    export_xlsx_just_this_section: "reports.export_xlsx_just_this_section",
    export_pdf_just_this_section: "reports.export_pdf_just_this_section",
    export_pdf_whole_report: "reports.export_pdf_whole_report",
    share_immediately_once: "reports.share_immediately_once",
    save_as_new: "reports.save_as_new",
    expired: "reports.expired",
    shared: "reports.shared",
    export: "reports.export",
    reshare: "reports.reshare",
    view_previous_section: "reports.view_previous_section",
    edit_section: "reports.edit_section",
    view_next_section: "reports.view_next_section",
    sort_and_edit_columns: "reports.sort_and_edit_columns",
    report_preview: "reports.report_preview",
    save_changes_before_closing: "reports.save_changes_before_closing",
    order: "reports.order",
    reorder_report_sections: "reports.reorder_report_sections",
    report_columns: "reports.report_columns",
    display: "reports.display",
    first_sort: "reports.first_sort",
    confirm_leave_without_exporting: "reports.confirm_leave_without_exporting",
    pdf_export_report: "reports.pdf_export_report",
    give_your_exported_report_title: "reports.give_your_exported_report_title",
    hourly_at_chosen_time: "reports.hourly_at_chosen_time",
    daily_at_chosen_time: "reports.daily_at_chosen_time",
    weekly_at_chosen_time: "reports.weekly_at_chosen_time",
    monthly_at_chosen_time: "reports.monthly_at_chosen_time",
    attachmentType_report_will_be_sent_howOften: {
      one: "reports.attachmentType_report_will_be_sent_howOften.one",
      many: "reports.attachmentType_report_will_be_sent_howOften.many"
    },
    attachmentType_report_will_be_sent_to: {
      one: "reports.attachmentType_report_will_be_sent_to.one",
      many: "reports.attachmentType_report_will_be_sent_to.many"
    },
    scheduled_report_sharing_settings: "reports.scheduled_report_sharing_settings",
    share_report_immediately: "reports.share_report_immediately",
    scheduling_html_info: "reports.scheduling_html_info",
    regular_scheduling: "reports.regular_scheduling",
    custom_scheduling: "reports.custom_scheduling",
    send_reports_how_often: "reports.send_reports_how_often",
    start_can_not_be_after_end_date: "reports.start_can_not_be_after_end_date",
    send_from: "reports.send_from",
    date_is_required: "reports.date_is_required",
    send_until: "reports.send_until",
    starting_at: "reports.starting_at",
    choose_date: "reports.choose_date",
    send_at: "reports.send_at",
    clear_selection: "reports.clear_selection",
    report_sharing_settings: "reports.report_sharing_settings",
    first_report: "reports.first_report",
    time_on_date: "reports.time_on_date",
    last_report: "reports.last_report",
    report_dates: "reports.report_dates",
    select_the_dates_and_time: "reports.select_the_dates_and_time",
    on_date: "reports.on_date",
    share_now: "reports.share_now",
    share_with: "reports.share_with",
    include_me: "reports.include_me",
    share_with_external_users: "reports.share_with_external_users",
    add_new_external_user: "reports.add_new_external_user",
    share_with_groups: "reports.share_with_groups",
    attach_report_to_email: "reports.attach_report_to_email",
    count_rows: "reports.count_rows",
    my_localisedTasks_due_next_30_days: "reports.my_localisedTasks_due_next_30_days",
    my_red_tasks: "reports.my_red_tasks",
    milestones_due_next_3_months: "reports.milestones_due_next_3_months",
    only_milestones: "reports.only_milestones",
    item_milestones: "reports.item_milestones",
    my_localisedDepartments_tasks: "reports.my_localisedDepartments_tasks",
    my_department_tasks_due_next_30_days: "reports.my_department_tasks_due_next_30_days",
    my_department_red_tasks: "reports.my_department_red_tasks",
    projects_with_overall_rag_of_red_or_amber: "reports.projects_with_overall_rag_of_red_or_amber",
    projects_containing_tasks_due_next_30_days: "reports.projects_containing_tasks_due_next_30_days",
    projects_containing_red_tasks: "reports.projects_containing_red_tasks",
    projects_with_milestones_due_next_30_days: "reports.projects_with_milestones_due_next_30_days",
    all_risks_etc: "reports.all_risks_etc",
    my_risks_etc: "reports.my_risks_etc",
    red_risks_and_issues: "reports.red_risks_and_issues",
    risks_etc_created_last_7_days: "reports.risks_etc_created_last_7_days",
    my_localisedDepartment_risks_etc: "reports.my_localisedDepartment_risks_etc",
    my_overdue_items: "reports.my_overdue_items",
    open_actions_on_red_risks_and_issues: "reports.open_actions_on_red_risks_and_issues",
    open_actions_on_my_department_risks: "reports.open_actions_on_my_department_risks",
    all_risks_and_impact_types: "reports.all_risks_and_impact_types",
    impact_types_summary_for_my_risks: "reports.impact_types_summary_for_my_risks",
    impact_types_summary_for_my_department_risks: "reports.impact_types_summary_for_my_department_risks",
    all_history_for_last_7_days: "reports.all_history_for_last_7_days",
    history_for_my_risks: "reports.history_for_my_risks",
    due_date_changes_in_last_7_days: "reports.due_date_changes_in_last_7_days",
    all_checklists_from_jobs_today: "reports.all_checklists_from_jobs_today",
    all_checklists_from_my_jobs_today: "reports.all_checklists_from_my_jobs_today",
    all_checklists_from_my_department_jobs_today: "reports.all_checklists_from_my_department_jobs_today",
    all_checklists_from_jobs_yesterday: "reports.all_checklists_from_jobs_yesterday",
    all_checklists_from_my_jobs_yesterday: "reports.all_checklists_from_my_jobs_yesterday",
    all_checklists_from_my_department_jobs_yesterday: "reports.all_checklists_from_my_department_jobs_yesterday",
    all_checklists_from_jobs_in_the_past_7_days: "reports.all_checklists_from_jobs_in_the_past_7_days",
    my_new_jobs: "reports.my_new_jobs",
    priority_3_jobs: "reports.priority_3_jobs",
    jobs_created_today: "reports.jobs_created_today",
    jobs_updated_today: "reports.jobs_updated_today",
    jobs_closed_today: "reports.jobs_closed_today",
    severity_5_incidents: "reports.severity_5_incidents",
    incidents_created_today: "reports.incidents_created_today",
    incidents_updated_today: "reports.incidents_updated_today",
    incidents_closed_today: "reports.incidents_closed_today",
    close_incident: "reports.close_incident",
    my_department_incidents: "reports.my_department_incidents",
    incident_logs_created_today: "reports.incident_logs_created_today",
    open_incidents_for_all_departments: "reports.open_incidents_for_all_departments",
    incident_department_summary_for_last_30_days: "reports.incident_department_summary_for_last_30_days",
    incident_departmentCategory_summary_for_last_30_days: "reports.incident_departmentCategory_summary_for_last_30_days",
    open_incidents_for_all_incident_categories: "reports.open_incidents_for_all_incident_categories",
    incident_category_summary_for_last_30_days: "reports.incident_category_summary_for_last_30_days",
    open_incidents_for_all_zones: "reports.open_incidents_for_all_zones",
    incident_zone_summary_for_last_30_days: "reports.incident_zone_summary_for_last_30_days",
    all_history_for_today: "reports.all_history_for_today",
    my_failed_capabilities: "reports.my_failed_capabilities",
    high_priority_capabilities: "reports.high_priority_capabilities",
    my_department_capabilities: "reports.my_department_capabilities",
    my_department_failed_capabilities: "reports.my_department_failed_capabilities",
    my_localisedActions_due_next_30_days: "reports.my_localisedActions_due_next_30_days",
    high_priority_items: "reports.high_priority_items",
    lessons_created_today: "reports.lessons_created_today",
    lessons_created_last_7_days: "reports.lessons_created_last_7_days",
    lessons_for_my_evaluations: "reports.lessons_for_my_evaluations",
    lessons_for_my_capabilities: "reports.lessons_for_my_capabilities",
    my_unsubmitted_items: "reports.my_unsubmitted_items",
    my_evaluations_in_next_7_days: "reports.my_evaluations_in_next_7_days",
    my_failed_evaluations: "reports.my_failed_evaluations",
    items_today: "reports.items_today",
    items_updated_last_7_days: "reports.items_updated_last_7_days",
    items_upcoming_in_next_30_days: "reports.items_upcoming_in_next_30_days",
    all_feedback: "reports.all_feedback",
    items_from_run_sheets_happening_today: "reports.items_from_run_sheets_happening_today",
    starred_items_from_run_sheets_happenin_today: "reports.starred_items_from_run_sheets_happenin_today",
    items_from_run_sheets_happening_tomorrow: "reports.items_from_run_sheets_happening_tomorrow",
    starred_items_from_run_sheets_happening_tomorrow: "reports.starred_items_from_run_sheets_happening_tomorrow",
    incomplete_items_from_yesterdays_run_sheets: "reports.incomplete_items_from_yesterdays_run_sheets",
    my_items_from_run_sheets_for_the_next_week: "reports.my_items_from_run_sheets_for_the_next_week",
    history_for_my_tasks: "reports.history_for_my_tasks",
    my_items_added_last_30_days: "reports.my_items_added_last_30_days",
    missed_items: "reports.missed_items",
    all_organisations: "reports.all_organisations",
    all_indicator_templates: "reports.all_indicator_templates"
  },
  gantt: {
    save_gant_view: "gantt.save_gant_view",
    enter_layout_title_gantt: "gantt.enter_layout_title_gantt",
    gantt_options: "gantt.gantt_options"
  }
}