<app-modal [fullScreen]="true">
  <app-modal-header style="z-index: 50" [title]="T.common.create_item | translate: { item: localisedJob }"
    [showDelete]="false" (closed)="closeModal()"></app-modal-header>

  <div class="top-navigation-container">
    <div class="top-back-icon" (click)="decreaseStep()">
      <span class="material-symbols-outlined chevron-icon">chevron_left</span>
    </div>
    <div class="top-step-text">{{ currentStepText[currentStep] }}</div>
  </div>

  <app-threaded-beads [beads]="beads" [showBubbles]="false"></app-threaded-beads>

  <app-modal-body class="custom-scrollbar" style="flex: 1">
    <div #wrapper class="wrapper">
    <div class="modal-body-container">
      <div class="left-side" [class.fullscreen-map]="currentStep === 2">
        <ng-container>
          <ng-container *ngIf="currentStep === 0">
            <div class="left-side-wrapper">
              <ng-container *ngTemplateOutlet="JobTitleAndSummaryLeftSideTemplate"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="currentStep === 1">
            <div class="left-side-wrapper">
              <ng-container *ngTemplateOutlet="JobDetailsLeftSideTemplate"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="currentStep === 2">
            <ng-container *ngTemplateOutlet="JobLocationMapLeftSideTemplate"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="right-side custom-scrollbar">
        <ng-container *ngIf="!loading">
          <ng-container *ngIf="currentStep === 0">
            <ng-container *ngTemplateOutlet="JobTitleAndSummaryRightSideTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="currentStep === 1">
            <ng-container *ngTemplateOutlet="JobDetailsRightSideTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="currentStep === 2">
            <ng-container *ngTemplateOutlet="JobLocationRightSideTemplate"></ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="loading">
          <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
        </ng-container>
      </div>
    </div>
    </div>
  </app-modal-body>

  <app-modal-footer>
    <ng-container *ngIf="currentStep !== 3">
      <app-button-rectangle [buttonTheme]="'white'" [class]="'button'" [fitToContainer]="true" [size]="'large'"
        [text]="T.common.cancel | translate" (clicked)="closeModal()"></app-button-rectangle>
      <app-button-rectangle [disabled]="validateSteps" [class]="'button'" [fitToContainer]="true" [size]="'large'"
        [text]="footerButtonText[currentStep]" (clicked)="increaseStep()"></app-button-rectangle>
    </ng-container>
  </app-modal-footer>
</app-modal>

<ng-template #JobTitleAndSummaryLeftSideTemplate>
  <div class="left-side-information">
    <div class="left-side-top">
      <div class="left-side-title">
        {{ localisedJobs }}
      </div>
      <div class="left-side-text border-bottom">
        {{
        T.control.localisedJob_is_something_that_needs_doing_or_checking | translate: { localisedJob: localisedJob }
        }}
      </div>
    </div>
    <div class="left-side-middle border-bottom">
      <div class="left-side-text">
        {{ T.control.describe_your_localisedJob_and_main_details | translate: { localisedJob: localisedJob } }}:
      </div>
      <div class="attributes-wrapper">
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-symbols-outlined">assignment_turned_in</i>
            </div>
            <div class="attribute-title">{{ T.common.type.one | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.control.localisedJobs_are_used_for_different_purposes | translate: { localisedJobs: localisedJobs } }}
          </div>
        </div>
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-symbols-outlined">category</i>
            </div>
            <div class="attribute-title">{{ T.common.category.one | translate }}</div>
          </div>
          <div class="attribute-right">
            {{
            T.control.categories_are_used_to_provide_context_to_localisedJobs
            | translate: { localisedJobs: localisedJobs }
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="left-side-bottom">
      <div class="left-side-bottom-text">
        <a href="https://supportcenter.ungerboeck.com/#wetrack" target="_blank">{{
          T.common.want_learn_more_visit_help_centre | translate
          }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #JobDetailsLeftSideTemplate>
  <div class="left-side-information">
    <div class="left-side-top">
      <div class="left-side-title">
        {{ localisedJobs }}
      </div>
      <div class="left-side-text border-bottom">
        {{
        T.control.localisedJob_is_something_that_needs_doing_or_checking | translate: { localisedJob: localisedJob }
        }}
      </div>
    </div>
    <div class="left-side-middle border-bottom">
      <div class="left-side-text">
        {{ T.control.assign_and_schedule_your_localisedJob | translate: { localisedJob: localisedJob } }}
      </div>
      <div class="attributes-wrapper">
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-symbols-outlined">group</i>
            </div>
            <div class="attribute-title">{{ T.common.assign | translate }}</div>
          </div>
          <div class="attribute-right">
            {{ T.control.assign_localisedJob_to_owners_and_departments | translate: { localisedJob: localisedJob } }}
          </div>
        </div>
        <div class="attribute">
          <div class="attribute-left">
            <div class="attribute-icon">
              <i class="material-symbols-outlined">event</i>
            </div>
            <div class="attribute-title">
              {{ T.calendar.start | translate }}/{{ T.lozenges_label.date_related.due | translate }}
            </div>
          </div>
          <div class="attribute-right">
            {{ T.control.schedule_the_localisedJob | translate: { localisedJob: localisedJob } }}
          </div>
        </div>
      </div>
    </div>
    <div class="left-side-bottom">
      <div class="left-side-bottom-text">
        <a href="https://supportcenter.ungerboeck.com/#wetrack" target="_blank">{{
          T.common.want_learn_more_visit_help_centre | translate
          }}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #JobTitleAndSummaryRightSideTemplate>
  <div class="add-details-container" [formGroup]="jobForm">
    <div class="mb-32 add-details-text-info">{{ T.common.what_needs_to_be_done | translate }}</div>

    <div class="mb-32">
      <app-text-field [placeholder]="(T.common.item_title | translate: { item: localisedJob }) + '*'"
        [displayError]="triggerIndicatorErrors" formControlName="title" [formGroup]="jobForm" [maxLength]="250"
        [required]="true" [useReactiveMessages]="true" [autofocus]="true" [required]="true" [disableMargin]="true"
        name="title">
      </app-text-field>
    </div>

    <div class="mb-32">
      <div class="container-with-bottom-element col-12 order-md-1 mb-32">
        <div class="job-type-container-wrapper">
          <div class="job-type-container">
            <app-filter-dropdown-new class="modal-project" [displayError]="triggerIndicatorErrors"
              [filterByModuleObjectType]="false" [filterType]="filterTypes.Job_Type" [disableMargin]="true"
              [required]="true" [disabled]="false" [singleSelect]="true" [appliedFilters]="filters" [typeahead]="true"
              [currentlySelectedSettingValue]="selectedJobType?.filterValue"
              (onFilterUpdate)="handleJobTypeChange($event)" [label]="(T.common.type.one | translate) + '*'">
            </app-filter-dropdown-new>
          </div>
          <div class="status-container">
            <app-simple-dropdown #searchDropdown [objects]="jobStatuses" [bindProperty]="'value'"
              [label]="T.common.status.one | translate" [currentlySelectedObject]="jobStatus"
              (objectSelected)="onJobStatusSelected($event)">
            </app-simple-dropdown>
          </div>
        </div>
      </div>
      <div class="d-flex container-with-bottom-element col-12 order-md-3">
        <div class="drop-arrow">
          <div class="vertical-line"></div>
          <div class="horizontal-line"></div>
        </div>
        <app-filter-dropdown-new [filterByModuleObjectType]="false" [disabled]="!selectedJobType" class="flex-fill modal-taskgroup"
          [filterType]="filterTypes.Job_Category" [disableMargin]="true" [singleSelect]="true" [required]="true"
          [appliedFilters]="filters" [typeahead]="true" [singleSelect]="true" [label]="(T.common.category.one | translate) + '*'"
          [currentlySelectedSettingValue]="selectedJobCategory?.filterValue"
          (onFilterUpdate)="handleFilterChange($event)" [relatedObjectId]="selectedJobType?.filterValue">
        </app-filter-dropdown-new>
      </div>
    </div>

    <div class="mb-32">
      <app-text-field name="headlineStatus" [formGroup]="jobForm"
      [placeholder]="T.defaultLocalizations.headline_status.one | translate" [maxLength]="255" [minLength]="3"
      [displayError]="triggerIndicatorErrors" formControlName="headlineStatus"
      [required]="false" [useReactiveMessages]="true"
      >
    </app-text-field>
    </div>

    <div class="mb-32">
      <app-details-editable-field [input]="job.description ? job.description : ''"
        [header]="T.defaultLocalizations.description.one | translate" [type]="editableFieldTypes.RichText"
        [canEdit]="true" (onUpdate)="onDescriptionChange($event)" [placeholder]="T.common.add_description | translate"
        name="description">
        [maxLength]="2000" (onInputChange)="onDescriptionChange($event)"
      </app-details-editable-field>
    </div>

    <div *ngIf="isCalledFromPIR && copiedEventFilter" class="checkbox-wrapper mb-32">
      <app-checkbox
        [isChecked]="copyEventFromPIR"
        (checked)="copyEventFromPIR = $event"
      >
      </app-checkbox>
      <span>{{ T.common.copy_event_from_incoming_report | translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #JobDetailsRightSideTemplate>
  <div class="job-details-container" [formGroup]="jobForm">
    <div class="mb-32 add-job-details-info">
      {{ T.control.who_is_responsible_for_this_localisedJob | translate: { localisedJob: localisedJob } }}
    </div>

    <div class="mb-32">
      <app-filter-dropdown-new [filterType]="filterTypes.Owner" [appliedFilters]="filters" [disableMargin]="true"
        [typeahead]="true" [label]="T.common.assign_owner.many | translate"
        (onFilterUpdate)="handleFilterChange($event)">
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex">
        <app-button-transparent size="small" icon="add" text="Assign Me" (clicked)="addCurrentUserAsOwner()">
        </app-button-transparent>
      </div>
    </div>

    <div class="mb-32">
      <app-filter-dropdown-new [filterType]="filterTypes.Department" [appliedFilters]="filters" [disableMargin]="true"
        [typeahead]="true" [label]="T.common.assign_item | translate: { item: localisedDepartments }"
        (onFilterUpdate)="handleFilterChange($event)">
      </app-filter-dropdown-new>
      <div class="bottom-element d-flex">
        <app-button-transparent size="small" icon="add"
          [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
          (clicked)="addCurrentUserDepartment()"></app-button-transparent>
      </div>
    </div>

    <div class="mb-32 privacy-and-priority-container">
      <div class="job-privacy-status">
        <app-simple-dropdown [objects]="localisedPrivacyStatuses" [currentlySelectedObject]="privacyStatus"
          [bindProperty]="'value'" [label]="T.defaultLocalizations.privacy_status.one | translate"
          (objectSelected)="onPrivacyStatusSelected($event)">
        </app-simple-dropdown>
    </div>
    <div class="job-priority">
      <app-priority
        [selectedPriority]="currentSelectedPriorityFilter"
        (priorityChanged)="onApply($event)"
        [showSelectPriorityLabel]="true"
        [filterType]="filterTypes.Job_Priority">
      </app-priority>
    </div>
    </div>

    <div class="mb-32 job-date-container" ngDropdown [templateRef]="datepickerTemplate" [adaptToElementWidth]="true">
      <div class="start-date">
        <app-text-field [placeholder]="T.calendar.start | translate" [formGroup]="jobForm" [required]="true"
          [disableMargin]="true" [formGroup]="jobForm" formControlName="startTime" name="startTime">
        </app-text-field>
      </div>

      <div class="end-date">
        <app-text-field [placeholder]="T.lozenges_label.date_related.due | translate" [formGroup]="jobForm"
          [required]="true" [disableMargin]="true" [formGroup]="jobForm" formControlName="endTime" name="endTime">
        </app-text-field>
      </div>
    </div>

    <div class="mb-32">
      <app-add-modal-tag-groups [showTitle]="false" [filters]="filters" [layoutClasses]="'col-12'"
        [tagGroupObjectType]="objectTypes.Job" (onFiltersUpdated)="handleFilterChange($event)">
      </app-add-modal-tag-groups>
    </div>
  </div>
</ng-template>

<ng-template #JobLocationRightSideTemplate>
  <div class="job-location-container" [formGroup]="jobForm">
    <div class="mb-32 add-job-location-info">
      {{ T.control.where_is_this_localisedJob_happening | translate: { localisedJob: localisedJob } }}
    </div>

    <div class="mb-32">
      <app-filter-dropdown-new [label]="localisedZone" [filterType]="filterTypes.Zone"
        [filterByModuleObjectType]="false" [appliedFilters]="filters" [typeahead]="true"
        (onFilterUpdate)="handleFilterChange($event)">
      </app-filter-dropdown-new>
    </div>

    <div class="mb-32">
      <app-filter-dropdown-new [label]="localisedArea" [filterType]="filterTypes.Area"
        [filterByModuleObjectType]="false" [appliedFilters]="filters" [typeahead]="true" [usePredefinedFilters]="true"
        [predefinedFilters]="applicableAreaFilters" [relatedObjectId]="selectedZone?.filterValue"
        [disabled]="applicableAreaFilters.length === 0" (onFilterUpdate)="handleFilterChange($event)">
      </app-filter-dropdown-new>
    </div>
  </div>
</ng-template>

<ng-template #JobLocationMapLeftSideTemplate>
  <div class="location-map">
    <app-incident-map [defaultZoom]="20" [lat]="job.latitude" [lng]="job.longitude" [selectedZones]="mapZones"
      [selectedAreas]="mapAreas" [showIncidentZonesUponLoad]="true" [editMode]="true"
      (onLocationUpdated)="onJobLocationUpdated($event)">
    </app-incident-map>
  </div>
</ng-template>

<ng-template #datepickerTemplate>
  <app-ng-range-datepicker [component]="true" [timePicker]="true" [showHeader]="false" [showFooter]="true"
    [startDate]="jobStartTime" [endDate]="jobEndTime" [minDate]="jobStartTime" [maxDate]="jobEndTime"
    (datesChanged)="onDatesChange($event)" (innerClosed)="onClosedDatepicker($event)">
  </app-ng-range-datepicker>
</ng-template>

<ng-template #jobPriorityTemplate let-i="index" let-v="value">
  <span class="material-symbols-outlined"
    [ngStyle]="{ color: i < v ? 'var(--wt-black)' : 'var(--wt-grey-light)' }">star</span>
</ng-template>

<ng-template #loadingTemplate><app-loading [displayLogo]="true"></app-loading></ng-template>
