import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SwiperComponent } from 'ngx-swiper-wrapper-v-13';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgDropdownDirective } from 'src/app/modules/shared/directives/ngDropdown.directive';
import { WindowEventsEmitter } from 'src/app/modules/shared/events/window.events';
import { PaginationManager, PaginationModel } from 'src/app/modules/shared/managers/paginationManager';
import { Constants } from 'src/app/modules/shared/models/constants';
import { T } from 'src/assets/i18n/translation-keys';

export enum DataType {
  String,
  Number,
  Date,
  Boolean,
  Template,
}

export class TableHeader {
  title: string;
  dataType: DataType;
  property?: string;
  headerTooltipMessage?: string;
  template?: TemplateRef<ElementRef<HTMLElement>>;
  headerTemplate?: TemplateRef<ElementRef<HTMLElement>>;
  isFilterApplied?: boolean | undefined;
  propertyFunction?: (object: object, context?: unknown) => string;
  style?: object;
  titleIcon?: string;
  emitClick?: boolean;
  sortDataType?: DataType;
  sortProperty?: string | ((object: object, context?: unknown) => string);
}
@Component({
  selector: 'app-responsive-table',
  templateUrl: './responsive-table.component.html',
  styleUrls: ['./responsive-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponsiveTableComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {
  private readonly _mobileWidth: number = Constants.xs;
  private readonly _debounceTime: number = 100;
  private readonly _resizeDebounceTime: number = 100;
  private readonly _defaultObjectsPerPage: number = 10;
  private readonly _sortDataTypes: DataType[] = [DataType.String, DataType.Number, DataType.Date, DataType.Boolean];

  private _originalObjects: object[];
  private _tableWidth: number | undefined;
  private _tableHeadersWithTemplate: TableHeader[] = [];



  // the objects you want to display in the table
  @Input() set objects(objects: object[]) {
    if (objects) {
      this._originalObjects = objects.slice();
      this.executeSearch(this.searchQuery);
      this.updatePagination();
    } else {
      this.filteredObjects = [];
      this._originalObjects = [];
      this.updatePagination();
    }
  }

  // the objects that are currently selected
  @Input() selectedObjects: object[] = [];
  // the table headers you want to display
  @Input() tableHeaders: TableHeader[];
  // whether or not to show checkboxes
  @Input() selectableObjects: boolean = true;
  // the count of object per page
  @Input() objectsPerPage: number = this._defaultObjectsPerPage;
  // whether or not to show the search
  @Input() useSearch: boolean = true;
  // by default you can select an object by clicking anywhere in the row
  // if disabled you will be able to select only by clicking the checkbox
  @Input() wholeRowSelection: boolean = true;
  // if enabled the responsive table container will have 100% height
  // making it occupy the parent space and have its own scrollable container
  @Input() adoptParentHeight: boolean = true;
  // you can pass a custom search subject in case you want to have
  // a separate search outside of the component
  @Input() searchSubject: Subject<string> = new Subject();
  // you can specify which properties should be searched
  @Input() searchProperties: string[];
  // If true it will use CdkScroll instead of pagination
  @Input() useInfinityScroll: boolean = false;
  // Display the checkboxes on the left side
  @Input() checkBoxesOnTheLeft:boolean = false;
  // Add specific styling only for the PIR seen/not-seen functionality
  @Input() isAPublicIncidentReport: boolean = false;

  @Input() showTableRowsCount: boolean = false;

  // emits an array of the selected objects
  @Output() objectsSelected= new EventEmitter<object[]>();
  // emits an object when a row is clicked
  @Output() objectClicked = new EventEmitter<object>();
  // emits the id of the table header template which have been clicked
  @Output() tableHeaderClicked = new EventEmitter<string>();

  @ViewChild('responsiveTable') responsiveTable: ElementRef<HTMLElement>;
  @ViewChild('fixedTableHeaders') fixedTableHeaders: ElementRef<HTMLElement>;
  @ViewChild('trTableHeaders') trTableHeaders: ElementRef<HTMLElement>;
  @ViewChild(SwiperComponent) swiper: SwiperComponent;
  @ViewChild(CdkVirtualScrollViewport, {static: false}) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  @ViewChildren('mobileRow') mobileRows: QueryList<ElementRef<HTMLElement>>;
  @ViewChildren('mobileRowsContainer') mobileRowsContainers: QueryList<ElementRef<HTMLElement>>;
  @ViewChildren('mobileHeader') mobileHeaders: QueryList<ElementRef<HTMLElement>>;
  @ViewChildren(NgDropdownDirective) ngDropDownDirectives: QueryList<NgDropdownDirective> | undefined;

  filteredObjects: object[];
  ascendingByTableHeaderIndex: { [key: number]: boolean } = {};
  private page: number = 1;
  dataType = DataType;
  private resizeSubject: Subject<void> = new Subject();
  private currentlySortedHeader: TableHeader;
  private subscriptions = new Subscription();
  paginationId: string;
  private paginationModel: PaginationModel;
  private searchQuery: string;
  allSelected: boolean = false;
  totalPages: number = 0;
  private cdkViewportCheckedCount = 0;
  public readonly T = T;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly paginationManager: PaginationManager,
    private readonly windowEventEmitters: WindowEventsEmitter
  ) {}

  ngOnInit() {
    this.totalPages = Math.ceil(this.filteredObjects.length / this.objectsPerPage);
    this.paginationId = this.paginationManager.generateID();
    const objectsCount = this.filteredObjects ? this.filteredObjects.length : 0;
    this.paginationManager.addModel(this.paginationId, this.objectsPerPage, objectsCount, this.page);
    this.paginationModel = this.paginationManager.getPaginationModel(this.paginationId);

    this._tableWidth = this.responsiveTable?.nativeElement?.clientWidth;
    this._tableHeadersWithTemplate = this.tableHeaders.filter((h) => h.headerTemplate);

    this.paginationManager.paginationModels$.subscribe((res) => {
      const newPaginationModel = res.find((m) => m.id === this.paginationId);

      if (newPaginationModel) {
        if (newPaginationModel.itemsPerPage !== this.objectsPerPage) {
          this.changeObjectsPerPage(newPaginationModel.itemsPerPage);
        } else if (newPaginationModel.currentPage !== this.page) {
          this.onPageChanged(newPaginationModel.currentPage);
        }
      }

      this.paginationModel = newPaginationModel;
    });

    this.subscriptions.add(
      this.windowEventEmitters.windowResizeEventTriggered$.subscribe(() => {
        this.onResized();
      })
    );

    this.initSearchSubject();
    this.initResizeSubject();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['objects']) {
      this.resort();
    }

    // This is a hack in order to fix the issue with the virtual scroll not rendering the correct number of items
    if(this.cdkVirtualScrollViewport && (this.cdkVirtualScrollViewport.getViewportSize() === 0 || this.cdkViewportCheckedCount < 3)) {
      this.cdkVirtualScrollViewport.checkViewportSize();
      this.cdkViewportCheckedCount+=1;
    }

    if (changes['selectedObjects']) {
      this.handleSelectedObjects();
    }

    if(changes['objectsPerPage']) {
      this.totalPages = Math.ceil(this.filteredObjects.length / this.objectsPerPage);
      this.paginationManager.updateModel({ ...this.paginationModel, itemsPerPage: this.objectsPerPage });
    }
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();

    this.resizeHandler();
  }

  ngAfterViewChecked() {
    this.equalizeDesktopHeadersWidths();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  updatePagination() {
    if (this.filteredObjects) {
      const objectsCount = this.filteredObjects.length;
      this.totalPages = Math.ceil(objectsCount / this.objectsPerPage);
      this.paginationManager.updateModel({ ...this.paginationModel, itemsCount: objectsCount, currentPage: 1 });
    }
  }

  get mobile(): boolean {
    if (!this.responsiveTable) {
      return;
    }

    return window.innerWidth <= this._mobileWidth;
  }

  get paddingRight(): number {
    if (!this.mobileRows.first) {
      return 0;
    }

    if (!this.mobileRows.first.nativeElement.getBoundingClientRect().width) {
      return 0;
    }

    return (
      this.responsiveTable.nativeElement.getBoundingClientRect().width -
      this.mobileRows.first.nativeElement.getBoundingClientRect().width
    );
  }

  get swiperIndex(): number {
    if (!this.swiper) {
      return;
    }

    return this.swiper.directiveRef.getIndex();
  }

  get tableRowCount(): number {
    return this.filteredObjects?.length;
  }

  resizeHandler() {
    this.changeDetectorRef.detectChanges();

    if (this.swiper) {
      this.swiper.directiveRef.update();
    }

    this.handleMobileHeadersHeights();
    this.handleMobileRowsHeights();
  }

  onResized(): void {
    this.resizeSubject.next();

    // Reposition header templates if there are any
    if (this.ngDropDownDirectives) {
      if (this._tableWidth !== this.responsiveTable?.nativeElement.clientWidth) {
        this._tableWidth = this.responsiveTable?.nativeElement.clientWidth;
        this.ngDropDownDirectives
          .filter(
            (dir) =>
              dir.ngDropdownComponentInstance.visible &&
              this._tableHeadersWithTemplate.some((th) => th.headerTemplate === dir.templateRef)
          )
          .forEach((dir) => dir.updatePosition());
      }
    }
  }

  getTableValue(tableHeader: TableHeader, object: object): any {
    //For table value tableHeader.propertyFunction takes precedende over tableHeader.property if both are set
    return tableHeader.propertyFunction ? tableHeader.propertyFunction(object, tableHeader) : object[tableHeader.property];
  }

  getSortValue(tableHeader: TableHeader, object: object): any {
    //If sortProperty is set use that otherwise use property in which case tableHeader.property takes precedende over tableHeader.propertyFunction if both are set
    if (tableHeader.sortProperty) {
      return typeof tableHeader.sortProperty === 'function' ? (tableHeader.sortProperty as (object: object, context?: unknown) => string)(object) : object[tableHeader.sortProperty as string];
    } else {
      return tableHeader.property ? object[tableHeader.property] : tableHeader.propertyFunction(object);
    }
  }

  sort(tableHeader: TableHeader, tableHeaderIndex: number): void {
    // Ignore sorting for tabs that have dropdown
    if (!tableHeader.sortDataType && !tableHeader.sortProperty && this.ngDropDownDirectives.get(tableHeaderIndex).templateRef) {
      return;
    }

    this.currentlySortedHeader = tableHeader;

    let equalityPredicate: (a: object, b: object) => number;
    const getValue: (object: object) => any = (object) => {
      let result: any = this.getSortValue(tableHeader, object);
      return result;
    };
    //If sortDataType is set use that otherwise use dataType if it is one of the sortable data types
    const dataType: DataType = this._sortDataTypes.includes(tableHeader.sortDataType) ? tableHeader.sortDataType : (this._sortDataTypes.includes(tableHeader.dataType) ? tableHeader.dataType : null);
    switch (dataType) {
      case DataType.String:
        if (!this.ascendingByTableHeaderIndex[tableHeaderIndex]) {
          equalityPredicate = (a: object, b: object) => getValue(b).toString().localeCompare(getValue(a).toString());
        } else {
          equalityPredicate = (a: object, b: object) => getValue(a).toString().localeCompare(getValue(b).toString());
        }

        break;
      case DataType.Number:
        if (!this.ascendingByTableHeaderIndex[tableHeaderIndex]) {
          equalityPredicate = (a: object, b: object) => +getValue(b) - +getValue(a);
        } else {
          equalityPredicate = (a: object, b: object) => +getValue(a) - +getValue(b);
        }

        break;
      case DataType.Date:
        if (!this.ascendingByTableHeaderIndex[tableHeaderIndex]) {
          equalityPredicate = (a: object, b: object) => new Date(getValue(b)).getTime() - new Date(getValue(a)).getTime();
        } else {
          equalityPredicate = (a: object, b: object) => new Date(getValue(a)).getTime() - new Date(getValue(b)).getTime();
        }

        break;
      case DataType.Boolean:
        if (!this.ascendingByTableHeaderIndex[tableHeaderIndex]) {
          equalityPredicate = (a: object, b: object) => +getValue(b) - +getValue(a);
        } else {
          equalityPredicate = (a: object, b: object) => +getValue(a) - +getValue(b);
        }

        break;
    }

    const truthyObjects: object[] = [];
    const falsyObjects: object[] = [];

    for (let index = 0; index < this.filteredObjects.length; index++) {
      const object = this.filteredObjects[index];

      if (getValue(object)) {
        truthyObjects.push(object);
      } else {
        falsyObjects.push(object);
      }
    }

    this.filteredObjects = [...truthyObjects.sort(equalityPredicate).concat(falsyObjects)];

    this.ascendingByTableHeaderIndex[tableHeaderIndex] = !this.ascendingByTableHeaderIndex[tableHeaderIndex];

    Object.keys(this.ascendingByTableHeaderIndex)
      .filter((i) => +i !== tableHeaderIndex)
      .forEach((i) => {
        this.ascendingByTableHeaderIndex[i] = false;
      });

    this.totalPages = Math.ceil(this.filteredObjects.length / this.objectsPerPage);

    this.changeDetectorRef.detectChanges();

		this.cdkVirtualScrollViewport.checkViewportSize();
    this.handleMobileHeadersHeights();
    this.handleMobileRowsHeights();
  }

  onSearch(value: string): void {
    this.searchSubject.next(value);
  }

  onClear(input: HTMLInputElement): void {
    this.onSearch((input.value = null));
  }

  private onPageChanged(page: number) {
    this.page = page;

    this.changeDetectorRef.detectChanges();

    this.handleMobileHeadersHeights();
    this.handleMobileRowsHeights();
  }

  getPaginatedObjects(): object[] {
    const objects: object[] = [];

    const start: number = this.page === 1 ? 0 : (this.page - 1) * this.objectsPerPage;
    const end: number = this.page * this.objectsPerPage;

    for (let index = start; index < end && index < this.filteredObjects.length; index++) {
      objects.push(this.filteredObjects[index]);
    }

    return objects;
  }

  onObjectClicked(object: object) {
    this.objectClicked.emit(object);
  }

  objectIsSelected(object): boolean {
    if (!this.selectedObjects) {
      return;
    }

    return !!this.selectedObjects.find((o) => o === object);
  }

  onSelectUnselectAll(selected: boolean): void {
    if (selected) {
      this.selectedObjects = this.filteredObjects.slice();
    } else {
      this.selectedObjects = [];
    }

    this.allSelected = this.selectedObjects.length === this.filteredObjects.length;

    this.changeDetectorRef.detectChanges();

    this.objectsSelected.emit(this.selectedObjects);
  }

  onSelectUnselectSingle(object: object): void {
    // O(n)
    const index = this.selectedObjects.findIndex((o) => o === object);

    if (index !== -1) {
      // O(1)
      this.selectedObjects[index] = this.selectedObjects[this.selectedObjects.length - 1];
      this.selectedObjects.pop();
    } else {
      this.selectedObjects.push(object);
    }

    this.allSelected = this.selectedObjects.length === this.filteredObjects.length;

    this.changeDetectorRef.detectChanges();

    this.objectsSelected.emit(this.selectedObjects);
  }

  onMobileObjectRowClick(e: Event, object: object): void {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.onSelectUnselectSingle(object);
  }

  getObjectIndex(object: object): number {
    return this._originalObjects.findIndex((o) => o === object);
  }

  onTableScroll(e: Event): void {
    const element: HTMLElement = e.target as HTMLElement;

    this.fixedTableHeaders.nativeElement.scrollLeft = element.scrollLeft;

    if (this.fixedTableHeaders.nativeElement.scrollLeft < element.scrollLeft) {
      const delta: number = element.scrollLeft - this.fixedTableHeaders.nativeElement.scrollLeft;

      this.fixedTableHeaders.nativeElement.style.marginLeft = `-${delta}px`;
    } else {
      this.fixedTableHeaders.nativeElement.style.marginLeft = null;
    }

    const headersHeight: number = this.trTableHeaders.nativeElement.getBoundingClientRect().height;

    if (element.scrollTop > headersHeight) {
      this.trTableHeaders.nativeElement.classList.add('responsive-table-headers-invisible');
      this.fixedTableHeaders.nativeElement.classList.add('responsive-table-fixed-headers-visible');
    } else {
      this.trTableHeaders.nativeElement.classList.remove('responsive-table-headers-invisible');
      this.fixedTableHeaders.nativeElement.classList.remove('responsive-table-fixed-headers-visible');
    }
  }

  /**
   * A table header is sortable if the tableHeader object contains a valid sortDataType property or its dataType property is not template
   * @param tableHeader
   * @returns boolean
   */
  tableHeaderIsSortable(tableHeader: TableHeader): boolean {
    return [DataType.String, DataType.Number, DataType.Date, DataType.Boolean].includes(tableHeader.sortDataType) || tableHeader.dataType !== DataType.Template;
  }

  tableHeaderEqualsCurrentlySortedHeader(tableHeader: TableHeader): boolean {
    if (!this.currentlySortedHeader) {
      return;
    }

    return tableHeader.title === this.currentlySortedHeader.title;
  }

  headersTrackByFn(index: number): number {
    return index;
  }

  rowsTrackBy(index: number, object: object): number {
    return this.getObjectIndex(object);
  }

  rowsTrackByFn = this.rowsTrackBy.bind(this) as number;

  onSliderMove() {
    this.equalizeMobileRowsAdjacentContainersScrolls();
  }

  private resort(): void {
    const descendingIndex: number = Object.keys(this.ascendingByTableHeaderIndex)
      .map((k) => this.ascendingByTableHeaderIndex[+k])
      .findIndex((ascending) => !ascending);

    if (descendingIndex === -1) {
      return;
    }

    this.sort(this.tableHeaders[descendingIndex], descendingIndex);
  }

  private initSearchSubject(): void {
    this.subscriptions.add(
      this.searchSubject.pipe(debounceTime(this._debounceTime)).subscribe((value) => {
        this.executeSearch(value);
      })
    );
  }

  private initResizeSubject(): void {
    this.subscriptions.add(
      this.resizeSubject.pipe(debounceTime(this._resizeDebounceTime)).subscribe(() => {
        this.resizeHandler();
      })
    );
  }

  private executeSearch(value: string) {
    this.searchQuery = value;

    if (!value) {
      this.filteredObjects = this._originalObjects.slice();
    } else {
      this.filteredObjects = this._originalObjects.filter((o) => {
        const values = this.searchProperties ? this.searchProperties.map((p) => o[p]) : Object.keys(o).map((k) => o[k]);

        return values.find((v) => `${v as string}`.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      });
    }

    this.paginationManager.updateModel({ ...this.paginationModel, currentPage: 1, itemsCount: this.filteredObjects.length });

    this.changeDetectorRef.detectChanges();

    this.handleMobileHeadersHeights();
    this.handleMobileRowsHeights();
  }

  private equalizeMobileRowsAdjacentContainersScrolls() {
    const mobileRowsContainersArray = this.mobileRowsContainers.toArray();
    const mobileRowsContainers = mobileRowsContainersArray
      .filter((_, i) => i === this.swiperIndex || i === this.swiperIndex - 1 || i === this.swiperIndex + 1)
      .map((mobileRowsContainer) => mobileRowsContainer.nativeElement);

    let different: boolean = false;

    for (let index = 1; index < mobileRowsContainers.length; index++) {
      const a: HTMLElement = mobileRowsContainers[index - 1];
      const b: HTMLElement = mobileRowsContainers[index];

      if (a.scrollTop !== b.scrollTop) {
        different = true;
      }
    }

    if (!different) {
      return;
    }

    const scrollTop: number = mobileRowsContainersArray[this.swiperIndex].nativeElement.scrollTop;

    mobileRowsContainers.forEach((mobileRowsContainer) => {
      mobileRowsContainer.scrollTop = scrollTop;
    });
  }

  private handleMobileHeadersHeights(): void {
    if (!this.mobile) {
      return;
    }

    const mobileHeaders: HTMLElement[] = this.mobileHeaders.toArray().map((mobileHeader) => mobileHeader.nativeElement);

    mobileHeaders.forEach((header) => (header.style.height = null));

    let highest: number = 0;

    mobileHeaders.forEach((header) => {
      const height: number = header.getBoundingClientRect().height;

      if (height > highest) {
        highest = height;
      }
    });

    mobileHeaders.forEach((header) => {
      header.style.height = `${highest}px`;
    });
  }

  private handleMobileRowsHeights(): void {
    if (!this.mobile) {
      return;
    }

    const mobileRows: HTMLElement[] = this.mobileRows.toArray().map((mobileRow) => mobileRow.nativeElement);

    mobileRows.forEach((row) => (row.style.height = null));

    let objectsPerPage =
      this.page < this.totalPages ? this.objectsPerPage : this._originalObjects.length - (this.totalPages * this.objectsPerPage);

    objectsPerPage = this.filteredObjects.length < objectsPerPage ? this.filteredObjects.length : objectsPerPage;

    for (let i = 0; i < objectsPerPage; i++) {
      let highest = 0;

      for (let page = 0; page < this.tableHeaders.length; page++) {
        const mobileRow = mobileRows[(objectsPerPage * page) + i];
        const height = mobileRow.getBoundingClientRect().height;

        if (height > highest) {
          highest = height;
        }
      }

      for (let page = 0; page < this.tableHeaders.length; page++) {
        const mobileRow = mobileRows[(objectsPerPage * page) + i];

        mobileRow.style.height = `${highest}px`;
      }
    }
  }

  private equalizeDesktopHeadersWidths(): void {
    if (this.mobile) {
      return;
    }

    if (!this.trTableHeaders || !this.fixedTableHeaders) {
      return;
    }

    const trTableHeaders: HTMLElement[] = Array.from(this.trTableHeaders.nativeElement.children) as HTMLElement[];
    const fixedTableHeaders: HTMLElement[] = Array.from(this.fixedTableHeaders.nativeElement.children) as HTMLElement[];

    for (let index = 0; index < trTableHeaders.length; index++) {
      if(fixedTableHeaders[index]) {
        fixedTableHeaders[index].style.minWidth = `${trTableHeaders[index].getBoundingClientRect().width}px`;
      }
    }
  }

  private handleSelectedObjects() {
    if (!this.selectedObjects) {
      this.selectedObjects = [];
    }
    this.allSelected = this.selectedObjects.length === this.filteredObjects.length;
  }

  private changeObjectsPerPage(itemsPerPage: number) {
    this.objectsPerPage = itemsPerPage;
    this.changeDetectorRef.detectChanges();

    this.handleMobileHeadersHeights();
    this.handleMobileRowsHeights();
  }

  // The table header emits this event with the tableHeader.property value if emitClick is set as true,
  // otherwise it emits null
  protected onTableHeaderClick(e: MouseEvent): void {
    const elementId = (e.target as Element).id;

    this.tableHeaderClicked.emit(elementId);
  }
}
