import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import {
  DataType,
  TableHeader,
} from 'src/app/modules/shared/components/common/responsive-table/responsive-table/responsive-table.component';
import { DocumentDetailsModalComponent } from 'src/app/modules/shared/components/modals/document-details/document-details-modal.component';
import { DocumentTypes } from 'src/app/modules/shared/enums/documentTypes';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { UploadTypes } from 'src/app/modules/shared/enums/uploadTypes';
import { CommentEventsEmitter } from 'src/app/modules/shared/events/comments.events';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { HasWritePermissionPipe } from 'src/app/modules/shared/pipes/has-write-permission.pipe';
import { DocumentService } from 'src/app/modules/shared/services/document.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { CapacitorUtilities } from 'src/app/modules/shared/utilities/capacitor.utilities';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { DocumentViewModel } from 'src/app/modules/shared/viewModels/documents/documentViewModel';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { PrivacyStatuses } from '../../../enums/privacyStatuses';
import { PopupEventsEmitter } from '../../../events/popup.events';
import { AlertService } from '../../../services/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfirmationService } from '../../../services/confirmation.service';
import { DocumentCategoryService } from '../../../services/documentCategory.service';
import { DocumentCategoryViewModel } from '../../../viewModels/documents/documentCategoryViewModel';
import { IncidentEditableItemTypes } from '../../../enums/incidents/incidentEditableItemTypes';
import { v4 as uuidv4 } from 'uuid';
import { Capacitor } from '@capacitor/core';
import { ModuleService } from 'src/app/modules/shared/services/module.service';
import { ModifiableEntityViewModel } from 'src/app/modules/shared/viewModels/bases/modifiableEntityViewModel';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { AttachmentsListViewTypes } from 'src/app/modules/shared/components/documents/enums/attachmentsListViewTypes';
import { AttachmentsListFilterTypes } from 'src/app/modules/shared/components/documents/enums/attachmentsListFilterTypes';
import { EnumUtilities } from 'src/app/modules/shared/utilities/enum.utilities';
import { AttachmentsEventsEmitter } from 'src/app/modules/shared/events/attachments.events';
import { ActionSheetController } from '@ionic/angular';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { DocumentCategoryAddModalComponent } from '../modals/document-category-add/document-category-add-modal.component';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DocumentManagerCoreComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  private bsModalRef: BsModalRef;
  private modalConfig = { backdrop: true, ignoreBackdropClick: true };
  private readonly smallDivWith: number = 671;
  private readonly largeDivWith: number = 1220;
  private currentEmployee: Employee;
  private imageExtensions = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
  private videoExtensions = ['mp4', 'mkv', 'avi', 'wmv', 'flv', 'mpeg', 'mov'];
  private documentExtensions = ['doc', 'docx', 'pdf', 'csv', 'txt', 'xls', 'xlsx', 'xlsm'];
  @ViewChild('descriptionIcon') descriptionIcon: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('itemIcon') itemIcon: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('file') fileElement: ElementRef<HTMLElement>;
  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;
  @ViewChild('titleTemplate') titleTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('objectTypeTemplate') objectTypeTemplate: TemplateRef<ElementRef<HTMLElement>>;

  @Input() handleUpdatesInternally: boolean = true;
  @Input() objectId: number;
  @Input() objectType: ObjectTypes;
  @Input() objectFilters: FilterViewModel[] = [];
  @Input() object: ModifiableEntityViewModel;
  @Input() filterType: FilterTypes;
  @Input() isModal: boolean = false;
  @Input() employeeIsAdmin: boolean;
  @Input() isDetailsPage: boolean = false;
  /**
   * Whether the component is inside an accordion component or not
   */
  @Input() isInAccordion: boolean = false;
  @Input() showSharedResources: boolean = false;
  /**
   * Whether to show the buttons for list and board view
   */
  @Input() showListBoardButtons: boolean = true;
  @Input() showCategoryDropdowns: boolean = true;

  @Output() uploadViewModelsChanged: EventEmitter<UploadViewModel[]> = new EventEmitter<UploadViewModel[]>();
  @Output() uploadChanged: EventEmitter<UploadViewModel> = new EventEmitter<UploadViewModel>();
  @Output() uploadDeleted: EventEmitter<UploadViewModel> = new EventEmitter<UploadViewModel>();
  @Output() documentsChanged = new EventEmitter<unknown>();
  @Output() objectClicked = new EventEmitter<object> ();
  @Output() objectsSelected = new EventEmitter<object[]>();
  @Output() documentChanged = new EventEmitter<unknown>();

  public selectedListView: AttachmentsListViewTypes = AttachmentsListViewTypes.Board;
  public selectedAttachmentsType = { key: 1, value: AttachmentsListFilterTypes.Show_All, translatedValue: this.translateService.instant(`common.${(AttachmentsListFilterTypes.Show_All).split(' ').join('_').toLowerCase()}`) };
  accountId: number;
  subscriptions = new Subscription();
  loading: boolean = true;
  allCategoryItemsSelected: boolean = false;
  documentCategories: DocumentCategoryViewModel[] = [];
  originalDocumentCategories: DocumentCategoryViewModel[] = [];
  privacyStatuses: { key: PrivacyStatuses; value: string }[] = EnumUtilities.items(PrivacyStatuses);
  privacyStatus: { key: PrivacyStatuses; value: string };
  wrapperIsSmall: boolean = false;
  wrapperIsLarge: boolean = false;

  documents: DocumentViewModel[];
  uploadViewModel: UploadViewModel;
  objects: DocumentViewModel[];
  documentObjects: DocumentCategoryViewModel[];
  originalObjects: DocumentViewModel[];
  selectedDocumentsArray: DocumentViewModel[] = [];
  selectedCategoriesArray: DocumentCategoryViewModel[];
  selectedCategory: DocumentCategoryViewModel;
  searchSubject: Subject<string> = new Subject();
  uploadViewModels: UploadViewModel[] = [];
  canEdit: boolean = false;

  public documentCategoriesRoute: boolean = false;
  public allAttachmentsRoute: boolean = false;
  public sharedResourcesRoute: boolean = false;
  public isSettingsRoute: boolean = false;
  public showOnlySharedResources: boolean = false;

  public attachmentsListViewTypes = AttachmentsListViewTypes;
  public isNativePlatform = Capacitor.getPlatform() !== 'web';
  public readonly T = T;


  constructor(
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly popupEventsEmitter: PopupEventsEmitter,
    private readonly authenticationService: AuthenticationService,
    private readonly alertService: AlertService,
    private readonly documentCategoryService: DocumentCategoryService,
    private readonly confirmationService: ConfirmationService,
    private readonly modalService: BsModalService,
    protected readonly timeZoneService: TimeZoneService,
    protected readonly allowedFiltersService: AllowedFiltersService,
    private readonly configuration: Configuration,
    private readonly documentService: DocumentService,
    private readonly commentsEventEmitter: CommentEventsEmitter,
    private readonly localisationService: LocalisationService,
    private readonly hasWritePermissionPipe: HasWritePermissionPipe,
    private readonly router: Router,
    private readonly moduleService: ModuleService,
    protected readonly translateService: TranslateService,
    protected readonly attachmentsEventsEmitter: AttachmentsEventsEmitter,
    protected readonly actionSheetCtrl: ActionSheetController,
  ) {}

  get isChildRoute(): boolean {
    return !!this.route.firstChild;
  }

  onScroll() {
    this.popupEventsEmitter.broadcastPopupPositionChanged();
  }

  canClickEditOrDelete(itemType: IncidentEditableItemTypes) {
    if (itemType === IncidentEditableItemTypes.DocumentCategory)
      return this.documentCategories.filter((t) => t.isSelected).length > 0;
  }

  ngOnInit(): void {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();

    if (this.employeeIsAdmin === undefined) {
      this.employeeIsAdmin = EmployeeUtil.IsAdmin(this.authenticationService.getCurrentEmployee());

      if (this.objectFilters.length > 0 && !this.employeeIsAdmin) {
        this.employeeIsAdmin = this.objectFilters.some(
          (r) => r.filterType === FilterTypes.Owner && +r.filterValue === this.currentEmployee.id
        );
      }
    }
    const module = this.moduleService.currentModule;
    this.documentCategoriesRoute = this.getRoute().endsWith('document-categories');
    this.allAttachmentsRoute = this.getRoute().endsWith('all-attachments');
    this.sharedResourcesRoute = this.getRoute().endsWith('shared-resources');
    this.showOnlySharedResources = this.showSharedResources || this.sharedResourcesRoute;
    this.isSettingsRoute = this.allAttachmentsRoute || this.documentCategoriesRoute || this.sharedResourcesRoute;
    this.canEdit = (this.employeeIsAdmin || this.showOnlySharedResources || this.object === undefined) ? true : (this.isDetailsPage && this.hasWritePermissionPipe.transform(this.object, module, this.filterType));

    if (!this.isDetailsPage)
      this.selectedListView = AttachmentsListViewTypes.List;

    this.subscriptions.add(
      this.attachmentsEventsEmitter.attachmentDeleted$.subscribe((document) => {
        this.deleteDocument(document);
      })
    );

    this.subscriptions.add(
      this.attachmentsEventsEmitter.attachmentDownloaded$.subscribe((documentId) => {
        this.downloadDocument(documentId);
      })
    );

    this.getTitle();
    this.initSubscriptions();
    this.getWrapperWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.objectId && !changes.objectId.firstChange) {
      this.initSubscriptions();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewChecked(): void {
    this.getWrapperWidth();
  }

  getWrapperWidth() {
    if (this.wrapper) {
      this.wrapperIsSmall = this.wrapper.nativeElement.offsetWidth <= this.smallDivWith;
      this.wrapperIsLarge = this.wrapper.nativeElement.offsetWidth >= this.largeDivWith;
      this.changeDetectorRef.markForCheck();
    }
  }

  initSubscriptions() {
    this.accountId = this.authenticationService.getCurrentAccount().id;
    this.getDocumentCategories();
  }

  private getDocumentCategories() {
    this.loading = true;

    if (this.objectId || this.objectType) {
      this.subscriptions.add(
        forkJoin([
          this.documentCategoryService.getDocumentCategoriesWithDocuments(),
          this.documentService.getByObjectId(this.objectId, this.objectType),
        ]).subscribe(([categories, documents]) => {
          this.documents = documents;
          const resultDocCat: DocumentCategoryViewModel[] = [];
          const documentIds = documents.map((d) => d.id);
          categories.forEach((x) => {
            if (x.documentViewModels.some((r) => documentIds.some((e) => e === r.id))) {
              let docs = x.documentViewModels.filter((r) => documentIds.some((e) => e === r.id));

              docs.forEach((d) => {
                d.documentCategoryId = x.id;
              });
              docs = docs.slice();

              x.documentViewModels = docs.slice();
              resultDocCat.push(x);
            } else if (x.title.toLocaleLowerCase() === 'no category') {
              x.title = this.translateService.instant(T.common.no_category);
              x.documentViewModels = documents.filter((r) => r.documentCategoryId === 0);
              resultDocCat.push(x);
            }
          });

          this.documentCategories = resultDocCat;
          const selectedCategory = new DocumentCategoryViewModel();
          selectedCategory.id = -1;
          selectedCategory.title = 'All Categories';
          this.documentCategories.unshift(selectedCategory);
          this.originalDocumentCategories = JSON.parse(JSON.stringify(categories)) as DocumentCategoryViewModel[];
          this.objects = documents;
          this.originalObjects = this.documentCategories.find((e) => e.id === 0)?.documentViewModels;
          this.selectedCategory =
            this.documentCategories && this.documentCategories.length
              ? this.documentCategories.find((x) => x.title.toLocaleLowerCase() === 'all categories')
              : undefined;

          if(!!this.selectedCategory){
            this.selectedCategory.title = this.translateService.instant(T.common.all_categories);
          }

          this.onCategoryOptionChange(this.selectedCategory);

          this.documentsChanged.next(documents);
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        })
      );
    } else {
      this.subscriptions.add(
        this.getDocumentCategoryObservable().subscribe((res) => {
          this.documentCategories = res.sort((a, b) => {
            if (a.id === 0) {
              return -1;
            } else {
              return a.title.localeCompare(b.title);
            }
          });
          const selectedCategory = new DocumentCategoryViewModel();
          selectedCategory.id = -1;
          selectedCategory.title = 'All Categories';
          this.documentCategories.unshift(selectedCategory);

          this.originalDocumentCategories = JSON.parse(JSON.stringify(res)) as DocumentCategoryViewModel[];

          this.objects = this.documentCategories.map((x) => x.documentViewModels).reduce((x, y) => x.concat(y), []);
          this.selectedCategory = this.documentCategories.find((x) => x.title.toLocaleLowerCase() === 'all categories');
          this.selectedCategory.title = this.translateService.instant(T.common.all_categories);
          this.documentObjects = this.documentCategories.filter((c) => c.id >= 0);
          this.onCategoryOptionChange(this.selectedCategory);
          this.loading = false;
          this.documentsChanged.next(this.objects);
          this.changeDetectorRef.markForCheck();
        })
      );
    }
  }

  /**
   * Return observable method of getting documents/categories based on whether this is only for shared resources
   */
  private getDocumentCategoryObservable():Observable<DocumentCategoryViewModel[]> {
    return this.showOnlySharedResources ? this.documentCategoryService.getSharedResources()
      : this.documentCategoryService.getDocumentCategoriesWithDocuments();
  }

  get documentCategoryDeleteButtonEnabled(): boolean {
    return this.canEdit && this.selectedCategoriesArray?.length > 0
  }

  deleteCategory(items: DocumentCategoryViewModel[]) {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.common.confirm_delete_item, { item: this.translateService.instant(T.common.category.one) }),
      () => {
        items.forEach((x) => {
          this.documentCategoryService.deleteDocumentCategory(x).subscribe(() => {
            void this.alertService.success(
              this.translateService.instant(T.common.item_removed_successfully, {
                item: this.translateService.instant(T.defaultLocalizations.document_category.one),
              })
            );
            this.originalDocumentCategories.splice(this.originalDocumentCategories.indexOf(x), 1);
            this.documentCategories = Object.assign([], this.originalDocumentCategories);
            this.getDocumentCategories();
            this.changeDetectorRef.markForCheck();
          });
        });
      },
      () => {
        //handle cancel if needed
      }
    );
  }

  addDocumentCategory() {
    const initialState = {
      parentCategories: this.documentCategories.filter(
        (c) => c.parentDocumentCategoryId === undefined || c.parentDocumentCategoryId === 0
      ),
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(DocumentCategoryAddModalComponent, modalParams);
    this.subscriptions.add(
      this.bsModalRef.content.documentCategoryResult.subscribe((res: DocumentCategoryViewModel) => {
        this.loading = true;
        res.accountId = this.accountId;
        this.documentCategoryService.addDocumentCategory(res).subscribe(() => {
          this.getDocumentCategories();
          this.changeDetectorRef.markForCheck();
        });
      })
    );
  }

  editDocumentCategory(object: DocumentCategoryViewModel) {
    const uploadCategoryViewModel: DocumentCategoryViewModel = new DocumentCategoryViewModel();
    uploadCategoryViewModel.id = object.id;
    uploadCategoryViewModel.accountId = object.accountId;
    uploadCategoryViewModel.clientId = object.clientId;
    uploadCategoryViewModel.title = object.title;
    uploadCategoryViewModel.description = object.description;
    uploadCategoryViewModel.parentDocumentCategoryId = object.parentDocumentCategoryId;
    uploadCategoryViewModel.parentDocumentCategoryTitle = object.parentDocumentCategoryTitle;
    uploadCategoryViewModel.viewOrder = object.viewOrder;
    uploadCategoryViewModel.privacyStatus = object.privacyStatus;
    uploadCategoryViewModel.created = object.created;
    uploadCategoryViewModel.isSelected = object.isSelected;
    uploadCategoryViewModel.documentViewModels = object.documentViewModels;

    const initialState = {
      title: this.translateService.instant(T.common.edit_item, {
        item: this.translateService.instant(T.defaultLocalizations.document_category.one),
      }),
      documentCategory: uploadCategoryViewModel,
      parentCategories: this.documentCategories.filter(
        (c) => c.parentDocumentCategoryId === undefined || c.parentDocumentCategoryId === 0
      ),
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(DocumentCategoryAddModalComponent, modalParams);
    this.subscriptions.add(
      this.bsModalRef.content.documentCategoryResult.subscribe((res: DocumentCategoryViewModel) => {
        this.loading = true;
        res.accountId = this.accountId;

        this.documentCategoryService.updateDocumentCategory(res).subscribe(() => {
          void this.alertService.success(
            this.translateService.instant(T.common.item_updated, {
              item: this.translateService.instant(T.defaultLocalizations.document_category.one),
            })
          );
          this.getDocumentCategories();
          this.changeDetectorRef.markForCheck();
        });
      })
    );
  }

  categoriesTableHeaders(): TableHeader[] {
    return [
      {
        dataType: DataType.String,
        title: this.translateService.instant(T.defaultLocalizations.title.one),
        property: 'title',
      },
      {
        dataType: DataType.Number,
        title: this.translateService.instant(T.common.achievement.many),
        property: 'documentViewModels',
        propertyFunction: (object: any) => {
          return object.documentViewModels?.length;
        },
      },
    ];
  }

  selectedCategories(objects: DocumentCategoryViewModel[]): void {
    this.objectsSelected.emit(objects);
    this.selectedCategoriesArray = objects;
  }

  attachmentDownload(object: DocumentViewModel): void {
    this.downloadDocument(object);
    this.objectClicked.emit(object);
  }

  attachmentLinkClicked(object: DocumentViewModel) {
    window.open(object.filename, '_blank');
  }

  onCategoryClicked(object: DocumentCategoryViewModel): void {
    if(this.canEdit) {
      this.editDocumentCategory(object);
      this.objectClicked.emit(object);
    }
  }

  onCategoryOptionChange(option: DocumentCategoryViewModel) {
    this.selectedCategory = this.documentCategories.find((e) => e.id === option.id);

    if (this.selectedCategory && this.selectedCategory.id !== -1) {
      this.objects = this.documentCategories.find((e) => e.id === this.selectedCategory.id)?.documentViewModels;
      this.originalObjects = this.documentCategories.find((e) => e.id === this.selectedCategory.id)?.documentViewModels;
    } else {
      this.objects = [];
      this.originalObjects = [];

      this.documentCategories.forEach((c) => {
        if (c.documentViewModels) {
          this.objects.push(...c.documentViewModels);
          this.originalObjects.push(...c.documentViewModels);
        }
      });
    }

    if (this.isDetailsPage) {
      this.attachmentsTypeChanged(this.selectedAttachmentsType);
    }

    this.changeDetectorRef.detectChanges();
  }

  // onListSearch(input: string) {
  //   if (!input) {
  //     this.objects = this.originalObjects.slice();
  //     this.changeDetectorRef.markForCheck();

  //     return;
  //   }
  //   this.objects = this.originalObjects.filter((c) => c.title.toLowerCase().indexOf(input.toLowerCase()) !== -1);

  //   this.changeDetectorRef.markForCheck();
  // }

  onCategorySearch(input: string) {
    if (!input) {
      this.documentObjects = this.documentCategories.filter((c) => c.id >= 0);
      this.changeDetectorRef.markForCheck();

      return;
    }

    this.documentObjects = this.documentCategories.filter((c) => c.title.toLowerCase().indexOf(input.toLowerCase()) !== -1);
    this.changeDetectorRef.markForCheck();
  }

  deleteDocument(document: DocumentViewModel) {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.common.confirm_delete_item, {
        item: this.translateService.instant(T.common.attachment.one),
      }),

      () => {
        this.documentService.deleteDocument(document.id, document.globalObjectId, document.globalObjectType).subscribe(() => {
          this.documentChanged.next(true);
          this.commentsEventEmitter.broadcastCommentAdded(document.globalObjectId);
          this.getDocumentCategories();
          this.changeDetectorRef.markForCheck();
        });
        void this.alertService.success(
          this.translateService.instant(T.common.item_deleted, { item: this.translateService.instant(T.common.document.one) })
        );
      },
      () => {
        //handle cancel if needed
      }
    );
  }

  downloadDocument(document: DocumentViewModel) {
    this.documentService.downloadDocument(document.id);
  }

  getTitle() {
    if (this.documentCategoriesRoute)
      return this.translateService.instant(T.defaultLocalizations.document_category.many);
    if (this.allAttachmentsRoute)
      return this.translateService.instant(T.settings.document_manager_settings.all_attachments);
    if(this.sharedResourcesRoute)
      return this.translateService.instant(T.settings.document_manager_settings.shared_resources);

    return '';
  }

  getRoute() {
    return this.router.url;
  }

  addFiles(files: File[]) {
    for (let i = 0; i < files.length; i++) {
      this.addUploadViewModel(files[i], '', UploadTypes.File);
    }
  }

  addLink() {
    this.addUploadViewModel(null, '', UploadTypes.Hyperlink);
  }

  private addUploadViewModel(file: File, link: string, uploadType: UploadTypes) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (file && uploadType === UploadTypes.File) {
      let useCompression = false;

      if (acceptedImageTypes.indexOf(file.type) !== -1) {
        useCompression = true;
      }
      this.documentService.splitUploadFile(file, useCompression, true, false).subscribe((res) => {
        this.addViewModel(file.name, link, uploadType, res);
        this.changeDetectorRef.detectChanges();
      });
    } else {
      this.addViewModel('', link, uploadType, '');
      this.changeDetectorRef.detectChanges();
    }
  }

  private addViewModel(originalFileName: string, link: string, uploadType: UploadTypes, fileName: string) {
    const uploadViewModel: UploadViewModel = new UploadViewModel();
    uploadViewModel.accountId = this.currentEmployee.accountId;
    uploadViewModel.employeeId = this.currentEmployee.id;
    uploadViewModel.description = '';
    uploadViewModel.fileName = fileName;
    uploadViewModel.link = link;
    uploadViewModel.documentCategoryIDs = [];
    uploadViewModel.globalObjectId = this.objectId;
    uploadViewModel.globalObjectType = this.objectType;
    uploadViewModel.uploadType = uploadType;
    uploadViewModel.title = uploadType === UploadTypes.File ? this.getDefaultFileTitle(originalFileName) : link;
    uploadViewModel.originalTitle = uploadType === UploadTypes.File ? this.getDefaultFileTitle(originalFileName) : link;
    uploadViewModel.shared = this.showOnlySharedResources;
    this.uploadViewModels.push(uploadViewModel);
    this.uploadViewModel = uploadViewModel;
    if (uploadViewModel.uploadType === UploadTypes.Hyperlink) {
      this.editUpload();
    } else {
      this.updateUploadViewModel(this.uploadViewModel);
      this.changeDetectorRef.markForCheck();
    }
  }

  private getDefaultFileTitle(fileName: string) {
    let title: string = '';
    const splitted = fileName.split('.');
    title = splitted[0].trim();
    title = title.replace(/-/g, ' ').replace(/_/g, ' ');
    return title;
  }

  openFileUpload() {
    if (this.fileElement) {
      const newEvent = new MouseEvent('click');
      this.fileElement.nativeElement.dispatchEvent(newEvent);
    }
  }

  async takePhoto() {
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
    });

    const blobData = CapacitorUtilities.b64toBlob(image.base64String, `image/${image.format}`);
    const imageName = `${uuidv4()}.png`;
    const imageFile = new File([blobData], imageName, { type: 'image/png' });
    this.addFiles([imageFile]);
  }

  onSelectFile(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        if (files[i].size > this.configuration.MaxFileSize) {
          void this.alertService.error(this.translateService.instant(T.common.file_to_upload_is_too_large_try_smaller_file));
        } else {
          const splitted = files[i].name.split('.');
          const extention = splitted[splitted.length - 1].toLowerCase();
          if (!this.configuration.AllowedUploadTypes.includes(extention)) {
            void this.alertService.error(this.translateService.instant(T.common.file_to_upload_not_supported_try_different_file));
          } else {
            this.addUploadViewModel(files[i], '', UploadTypes.File);
          }
        }
      }
    }
  }

  editUpload() {
    const initialState = {
      uploadDocumentViewModel: this.uploadViewModel,
      documentCategories: this.documentCategories.filter(
        (c) => c.parentDocumentCategoryId === undefined || c.parentDocumentCategoryId === 0
      ),
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(DocumentDetailsModalComponent, modalParams);
    this.subscriptions.add(
      this.bsModalRef.content.onSubmit.subscribe((res: UploadViewModel) => {
        this.updateUploadViewModel(res);
        this.changeDetectorRef.markForCheck();
        this.bsModalRef.hide();
      })
    );

    this.subscriptions.add(
      this.bsModalRef.content.cancelled.subscribe((res: UploadViewModel) => {
        if (res.fileName.length) {
          this.documentService.deleteUnassignedFile(res.fileName).subscribe(() => {
            this.uploadViewModel = undefined;
            this.uploadViewModels = this.uploadViewModels.filter((u) => u.fileName != res.fileName);
          });
        } else {
          this.uploadViewModel = undefined;
          this.uploadViewModels = this.uploadViewModels.filter((u) => u.link != res.link);
        }
      })
    );
  }

  editDocument(document: DocumentViewModel) {
    const uploadViewModel: UploadViewModel = new UploadViewModel();
    uploadViewModel.accountId = document.accountId;
    uploadViewModel.documentID = document.id;
    uploadViewModel.description = document.description ? document.description : '';
    uploadViewModel.employeeId = document.createdById;
    uploadViewModel.privacyStatus = document.privacyStatus;
    uploadViewModel.title = document.title ? document.title : '';
    uploadViewModel.uploadType = document.uploadType;
    uploadViewModel.link = document.uploadType === UploadTypes.Hyperlink ? document.filename : '';
    uploadViewModel.documentCategoryIDs = [];
    uploadViewModel.fileName = document.filename;
    const documentCategories = this.documentCategories.filter(
      (c) => c.documentViewModels?.filter((d) => d.id == document.id).length > 0
    );
    documentCategories.forEach((d) => uploadViewModel.documentCategoryIDs.push(d.id));
    const initialState = {
      title: this.translateService.instant(T.common.edit_item, { item: this.translateService.instant(T.common.attachment.one) }),
      uploadDocumentViewModel: uploadViewModel,
      documentCategories: this.documentCategories.filter(
        (c) => c.parentDocumentCategoryId === undefined || c.parentDocumentCategoryId === 0
      ),
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(DocumentDetailsModalComponent, modalParams);
    this.subscriptions.add(
      this.bsModalRef.content.onSubmit.subscribe((res: UploadViewModel) => {
        this.documentService.updateDocument(res).subscribe(() => {
          void this.alertService.success(
            this.translateService.instant(T.common.item_updated, { item: this.translateService.instant(T.common.document.one) })
          );
          this.getDocumentCategories();
          this.changeDetectorRef.markForCheck();
          this.bsModalRef.hide();
        });
      })
    );
  }

  updateUploadViewModel(uploadViewModel: UploadViewModel) {
    if (this.handleUpdatesInternally) {
      this.subscriptions.add(
        this.documentService.addDocument(uploadViewModel).subscribe((res) => {
          // if (!this.documents) {
          //   this.documents = [];
          // }

          // this.documents.push(res);

          // if(!this.objects) {
          //   this.objects = [];
          // }

          // this.objects.push(res);

          // this.uploadViewModels = [];
          this.getDocumentCategories();
          this.changeDetectorRef.markForCheck();
          this.documentsChanged.next(this.documents);
        })
      );
    } else {
      const uploads = this.uploadViewModels.filter((u) => u.fileName == uploadViewModel.fileName);
      if (uploads && uploads.length > 0) {
        uploads[0] = uploadViewModel;
        this.uploadViewModelsChanged.next(this.uploadViewModels);
        if (this.objectId && this.objectId !== 0) {
          this.uploadViewModels = [];
          this.changeDetectorRef.markForCheck();
        }
      }

      this.getDocumentCategories();
    }

    this.changeDetectorRef.markForCheck();
  }

  documentType(document: DocumentViewModel) {
    let documentType: DocumentTypes;
    let ext: string;

    if (document) {
      if (document.uploadType === UploadTypes.Hyperlink) {
        documentType = DocumentTypes.Link;
      } else if (document.filename) {
        const splitted = document.filename.split('.');
        ext = splitted[splitted.length - 1].toLowerCase();
      }
    } else {
      const fileName = this.uploadViewModel.fileName ? this.uploadViewModel.fileName : '';
      if (document.uploadType === UploadTypes.Hyperlink) {
        documentType = DocumentTypes.Link;
      } else if (fileName) {
        const splitted = fileName.split('.');
        ext = splitted[splitted.length - 1].toLowerCase();
      }
    }

    if (this.imageExtensions.indexOf(ext) !== -1) {
      documentType = DocumentTypes.Image;
    } else if (this.videoExtensions.indexOf(ext) !== -1) {
      documentType = DocumentTypes.Video;
    } else if (this.documentExtensions.indexOf(ext) !== -1) {
      documentType = DocumentTypes.Document;
    }

    return documentType;
  }

  public selectAttachmentsView(attachmentsListViewType: AttachmentsListViewTypes): void {
    this.selectedListView = attachmentsListViewType;
  }

  public attachmentsTypeChanged(option: {key: number, value: AttachmentsListFilterTypes, translatedValue: string}): void {
    let documents: DocumentViewModel[] = [];
    this.selectedAttachmentsType = option;
    this.originalObjects.forEach((object) => {
      if (option.value === AttachmentsListFilterTypes.Media) {
        if (this.documentType(object) === DocumentTypes.Image || this.documentType(object) === DocumentTypes.Video) {
          documents.push(object);
        }
      } else if (option.value === AttachmentsListFilterTypes.Files) {
        if (this.documentType(object) === DocumentTypes.Document) {
          documents.push(object);
        }
      } else if (option.value === AttachmentsListFilterTypes.Show_All) {
        documents = this.originalObjects;
      }
    });

    this.objects = documents;
  }
}
