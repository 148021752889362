import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { SuggestedRisksService } from '../../../services/suggested-risks.service';
import { Constants } from 'src/app/modules/shared/models/constants';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SuggestedRiskViewModel } from '../../../models/SuggestedRiskViewModel';
import { of, Subscription, switchMap } from 'rxjs';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { EmployeeSettingViewModel } from 'src/app/modules/shared/viewModels/employeeSettingViewModel';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { Account } from 'src/app/modules/shared/models/account';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { SuggestedRisksModalComponent } from '../suggested-risks-modal/suggested-risks-modal.component';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';

@Component({
  selector: 'app-suggested-risks-list',
  templateUrl: './suggested-risks-list.component.html',
  styleUrl: './suggested-risks-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestedRisksListComponent implements OnInit, OnDestroy {
  public readonly T = T;
  protected objectType = ObjectTypes.Risk;
  protected hideSuggestedRisksCarousel: boolean;
  protected hideCarouselSetting: EmployeeSettingViewModel;
  protected screenWidth: number = window.innerWidth;
  protected numberOfCardsToDisplay: number;
  public loading = true;
  protected account: Account;
  protected accountVenue: string;

  protected suggestedRisks: SuggestedRiskViewModel[] = [];
  protected subscriptions = new Subscription();

  @Input() isWithinAddRiskModal: boolean;
  @Output() prefillFormEvent = new EventEmitter();

  @HostListener('window:resize', ['$event'])
	onResize() {
		this.screenWidth = window.innerWidth;
    this.getTheNumberOfCardsToDisplay();
	}

  constructor(
    protected suggestedRisksService: SuggestedRisksService,
    private readonly bsModalService: BsModalService,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getTheNumberOfCardsToDisplay();

    this.account = this.authenticationService.getCurrentAccount();

    this.accountVenue = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Venue_Type).find(filter => filter.filterValue === this.account.venueType)?.filterText;

    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.HideSuggestedRisks).pipe(
        switchMap((res) => {
          this.hideCarouselSetting = res;
          this.hideSuggestedRisksCarousel = res.value === "true";
          this.changeDetectorRef.markForCheck();

          const getListParams =  this.isWithinAddRiskModal ?
            { venueTypes: this.accountVenue ? [this.accountVenue] : [], limit: 8 } :
            { venueTypes: this.accountVenue ? [this.accountVenue] : []}

          return !this.hideSuggestedRisksCarousel ? this.suggestedRisksService.getList(getListParams) : of(null);
          }
        )
      ).subscribe(res => this.setSuggestedRisks(res))
    );

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected getTheNumberOfCardsToDisplay(): void {
    if (this.isWithinAddRiskModal) {
      this.numberOfCardsToDisplay = 2;
      return;
    }
    if(this.screenWidth > Constants.xl) {
      this.numberOfCardsToDisplay = 4;
      return;
    } else if(this.screenWidth > Constants.customCarouselResolutionMedium && this.screenWidth < Constants.xl) {
      this.numberOfCardsToDisplay = 3;
      return;
    } else if(this.screenWidth > Constants.customCarouselResolutionSmall && this.screenWidth < Constants.customCarouselResolutionMedium) {
      this.numberOfCardsToDisplay = 2;
      return;
    } else {
      this.numberOfCardsToDisplay = 1;
    }
  }

  public toggleHideSuggestedRisksCarousel(visible: boolean): void {
    const getListParams =  this.isWithinAddRiskModal ?
      { venueTypes:  this.accountVenue ? [this.accountVenue] : [], limit: 8 } :
      { venueTypes:  this.accountVenue ? [this.accountVenue] : []}

    if(this.hideSuggestedRisksCarousel && !visible) {
      this.loading = true;
      this.subscriptions.add(
        this.suggestedRisksService.getList(getListParams).subscribe(res => {
          this.hideSuggestedRisksCarousel = visible;
          this.setSuggestedRisks(res);
          this.updateHideSuggestedRiskEmployeeSetting();
          this.changeDetectorRef.detectChanges();
        })
      )
    } else {
      this.hideSuggestedRisksCarousel = visible;
      this.updateHideSuggestedRiskEmployeeSetting();
      this.changeDetectorRef.detectChanges();
    }
  }

  public openSuggestedRisksModal(selectedItem?: SuggestedRiskViewModel): void {
    let copiedSelectedItem: SuggestedRiskViewModel;

    if(selectedItem) {
      copiedSelectedItem = Object.assign({}, selectedItem);
    }

    const modalConfig: ModalOptions = { class: 'modal-full-screen', ignoreBackdropClick: true };
    const initialState = {
      suggestedRisks: JSON.parse(JSON.stringify(this.suggestedRisks)) as SuggestedRiskViewModel[],
      preselectedRisk: copiedSelectedItem ? copiedSelectedItem : null,
      accountVenue: this.accountVenue
    };

    const modalParams = Object.assign({}, modalConfig, { initialState });
    this.bsModalService.show(SuggestedRisksModalComponent, modalParams);
  }

  protected updateHideSuggestedRiskEmployeeSetting(): void {
    this.hideCarouselSetting.value = this.hideSuggestedRisksCarousel.toString();
    const setting: EmployeeSettingViewModel = this.hideCarouselSetting;

    this.subscriptions.add(
      this.employeeSettingsService
      .updateEmployeeSetting(EmployeeSettingTypes.HideSuggestedRisks, setting)
      .subscribe()
    )
  }

  private setSuggestedRisks(suggestedRisksArr: SuggestedRiskViewModel[]): void {
    if(!suggestedRisksArr) return;

    suggestedRisksArr.forEach(risk => {
      const filters: FilterViewModel[] = [];

      const likelihoodFilter = FilterUtilities.GenerateFilter(FilterTypes.Risk_Likelihood, risk.likelihood);
      filters.push(likelihoodFilter);

      const impactFilter = FilterUtilities.GenerateFilter(FilterTypes.Risk_Impact, risk.impact);
      filters.push(impactFilter);

      risk.filters = filters;
    })
    this.suggestedRisks = suggestedRisksArr;
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  handlePrefillForm(item: any) {
    this.prefillFormEvent.emit(item);
  }
}

