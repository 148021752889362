<app-modal>
  <app-modal-header
    [title]="T.common.set_table_columns | translate"
    [fontSize]="18"
    (closed)="closeModal()">
  </app-modal-header>

  <app-modal-body>
    <div class="body-header-text">{{ T.hub.work_table.choose_which_columns_you_want_on_the_table | translate }}</div>
    <div class="column-filter-checkbox">
      <app-checkbox
        class="me-1"
        [disabled]="columns.length === defaultColumns.length"
        [isChecked]="columns.length === defaultColumns.length"
        (checked)="onCheckAll()">
      </app-checkbox>
      <span>{{ T.common.select_all | translate }}</span>
    </div>

    <ul class="column-filter-options">
      @for(column of defaultColumns; track column) {
        <li class="column-filter-checkbox">
          <app-checkbox
            class="me-1"
            [disabled]="column.id === defaultColumnFilterType"
            [isChecked]="isChecked(column)"
            (checked)="onChecked($event, column)">
          </app-checkbox>
          <span>{{ column?.title }}</span>
        </li>
      }
    </ul>
  </app-modal-body>

  <app-modal-footer>
    <app-button-rectangle
      class="me-3"
      [text]="T.common.cancel | translate"
      size="large"
      [buttonTheme]="'white'"
      [disabled]="false"
      (clicked)="closeModal()">
    </app-button-rectangle>
    <app-button-rectangle
      [text]="T.common.apply | translate"
      size="large"
      [disabled]="false"
      (clicked)="onApply()">
    </app-button-rectangle>
  </app-modal-footer>
</app-modal>
