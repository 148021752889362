<div #wrapper class="incident-quick-add-wrapper" resized (resizable)="onResized()">
  <ng-container *ngIf="!mobile">
    <div
      *ngIf="showQuickLogAdd"
      class="incident-quick-add-desktop-input-wrapper"
      [style.width]="!userHasWriteAccess ? '100%' : ''"
    >
      <div
        class="flex-container"
        [class.input-disabled]="logInputDisabled"
        [class.input-focused]="logDropdownButtonClicked"
        [closeUponSelection]="false"
        ngDropdown
        [templateRef]="logDropdown"
        [adaptToElementWidth]="true"
        (click)="logDropdownToggled()"
      >
        <div class="flex-item-left log-dropdown-button">
          <div class="log-icon">
            <i class="material-symbols-outlined">receipt</i>
          </div>
          <div class="dropdown-button-label">{{ T.control.add_log.one | translate }}</div>
          <div class="arrow-down-icon" [style.transform]="logDropdownButtonClicked ? 'rotate(180deg)' : ''">
            <i class="material-symbols-outlined">keyboard_arrow_down</i>
          </div>
        </div>
        <div class="flex-item-right">
          <input
            #logInput
            type="text"
            class="text-truncate"
            [formControl]="logInputFormControl"
            [class.incident-quick-add-invalid-input]="
              isFocused(logInput) && incidentInputFormControl.dirty && !logInputFormControl.valid
            "
            [style.padding]="logInputFormControl.value ? '0 22px' : '0 0 0 22px'"
            [style.width]="'100%'"
            [placeholder]="T.control.type_and_press_to_add_log | translate"
            [class.incident-quick-add-input-focused]="isFocused(logInput)"
            maxlength="250"
            minlength="3"
            (focus)="onFocus()"
            (blur)="onBlur()"
          />
          <ng-container *ngIf="logDropdownButtonClicked">
            <ng-container *ngIf="logInputFormControl.valid && logInputFormControl.dirty">
              <div class="add-button" (click)="addButtonPressed($event)">
                <i class="material-symbols-outlined material-close-icon">done</i>
              </div>
            </ng-container>
            <ng-container *ngIf="!logInputFormControl.valid && logInputFormControl.dirty">
              <div class="add-button border-color-red text-red">
                <i class="material-symbols-outlined material-close-icon text-red">close</i>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="logInputDisabled">
            <app-button-loader class="input-loader"></app-button-loader>
          </ng-container>
          <ng-template #logDropdown>
            <div *ngIf="logDropdownButtonClicked" class="dropdown" (click)="$event.stopImmediatePropagation()">
              <div class="dropdown-header">
                <div class="dropdown-header-icon">
                  <i class="material-symbols-outlined">category</i>
                </div>
                <div class="dropdown-header-text">{{ T.control.select_category_to_quick_add_log | translate }}</div>
                <div class="selected-count">
                  {{ T.common.count_selected.many | translate: { count: categoryFilters.length } }}
                </div>
              </div>
              <div class="dropdown-category-container custom-scrollbar">
                <ng-container *ngIf="incidentCategories.length">
                  <ng-container *ngFor="let object of incidentCategories">
                    <app-dropdown-option
                    [value]="object"
                    [focused]="isFocused(object)"
                    [selected]="objectIsSelected(object)"
                    (selectionChange)="onObjectSelected(object, quickAddItemTypes.Log)"
                    >
                      {{ object.filterText}}
                    </app-dropdown-option>
                  </ng-container>
                </ng-container>
              </div>
              <div class="dropdown-footer">
                <div class="assign-me-checkbox footer-column">
                  <app-checkbox-with-label
                    (clicked)="toggleLogsOwnerCheckbox()"
                    [intialState]="isOwnerSelectedForAddingLog ? 'checked' : 'unchecked'"
                    [label]="T.common.assign_me_as_an_owner | translate"
                  >
                  </app-checkbox-with-label>
                </div>
                <div class="assign-department-checkbox footer-column">
                  <app-checkbox-with-label
                    (clicked)="toggleLogsDepartmentCheckbox()"
                    [intialState]="isDepartmentSelectedForAddingLog ? 'checked' : 'unchecked'"
                    [label]="
                      T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }
                    "
                  >
                  </app-checkbox-with-label>
                </div>
                <div class="add-more-details footer-column">
                  <div class="incident-quick-add-desktop-action" (click)="onAddLogDetails()">
                    {{ T.common.add_details | translate }}
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="incident-quick-add-desktop-input-wrapper" [ngStyle]="{'margin-left.px': showQuickLogAdd ? 15 : 0}" *ngIf="userHasWriteAccess">
      <div
        [ngClass]="isWithinEventDetails ? 'container-is-within-event-details' : ''"
        class="flex-container"
        [class.input-disabled]="incidentInputDisabled"
        [class.input-focused]="incidentDropdownButtonClicked"
        [closeUponSelection]="false"
        ngDropdown
        [templateRef]="incidentDropdown"
        [adaptToElementWidth]="true"
        (click)="incidentDropdownToggled()"
      >
        <div [ngClass]="isWithinEventDetails ? 'flex-item-left-is-within-event-details' : ''" class="flex-item-left incident-dropdown-button">
          <div class="incident-icon">
            <i [ngClass]="isWithinEventDetails ? 'dropdown-is-within-event-details' : ''" class="material-symbols-outlined">
              {{ isWithinEventDetails ? 'assignment_turned_in' : 'error' }}
            </i>
          </div>
          <div
            class="dropdown-button-label"
            [ngClass]="isWithinEventDetails ? 'me-1' : ''">
            {{ isWithinEventDetails ? (T.common.quick_add_item | translate: { item: localisedIncident }) : (T.control.add_incident.one | translate) }}
          </div>
          <div *ngIf="!isWithinEventDetails" class="arrow-down-icon" [style.transform]="incidentDropdownButtonClicked ? 'rotate(180deg)' : ''">
            <i class="material-symbols-outlined">keyboard_arrow_down</i>
          </div>
        </div>
        <div class="flex-item-right">
          <input
            #incidentInput
            type="text"
            class="text-truncate"
            [style.padding]="incidentInputFormControl.value ? '0 22px' : '0 0 0 22px'"
            [style.width]="'100%'"
            [formControl]="incidentInputFormControl"
            [class.incident-quick-add-invalid-input]="isFocused(incidentInput) && !incidentInputFormControl.valid"
            [placeholder]="T.control.type_and_press_to_add_incident | translate"
            [class.incident-quick-add-input-focused]="isFocused(incidentInput)"
            maxlength="120"
            minlength="3"
            (focus)="onFocus()"
            (blur)="onBlur()"
          />
          <ng-container *ngIf="incidentDropdownButtonClicked">
            <ng-container *ngIf="incidentInputFormControl.valid && incidentInputFormControl.dirty">
              <div class="add-button" (click)="addButtonPressed($event)">
                <i class="material-symbols-outlined material-close-icon">done</i>
              </div>
            </ng-container>
            <ng-container *ngIf="!incidentInputFormControl.valid && incidentInputFormControl.dirty">
              <div class="add-button border-color-red text-red">
                <i class="material-symbols-outlined material-close-icon text-red">close</i>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="incidentInputDisabled">
            <app-button-loader class="input-loader"></app-button-loader>
          </ng-container>
          <ng-template #incidentDropdown>
            <div *ngIf="incidentDropdownButtonClicked" class="dropdown" (click)="$event.stopImmediatePropagation()">
              <div class="dropdown-header">
                <div class="dropdown-header-icon">
                  <i class="material-symbols-outlined">category</i>
                </div>
                <div class="dropdown-header-text">
                  {{ T.control.select_category_to_quick_add_incident | translate }}
                </div>
              </div>
              <div class="dropdown-category-container custom-scrollbar">
                <ng-container *ngIf="incidentCategories.length">
                  <ng-container *ngFor="let object of incidentCategories">
                    <app-dropdown-option
                    [value]="object"
                    [focused]="isFocused(object)"
                    [selected]="objectIsSelected(object)"
                    (selectionChange)="onObjectSelected(object, quickAddItemTypes.Incident)"
                    >
                      {{ object.filterText}}
                    </app-dropdown-option>
                  </ng-container>
                </ng-container>
              </div>
              <div class="dropdown-footer">
                <div class="assign-me-checkbox footer-column">
                  <app-checkbox-with-label
                    (clicked)="toggleIncidentsOwnerCheckbox()"
                    [intialState]="isOwnerSelectedForAddingIncident ? 'checked' : 'unchecked'"
                    [label]="T.common.assign_me_as_an_owner | translate"
                  >
                  </app-checkbox-with-label>
                </div>
                <div class="assign-department-checkbox footer-column">
                  <app-checkbox-with-label
                    (clicked)="toggleIncidentsDepartmentCheckbox()"
                    [intialState]="isDepartmentSelectedForAddingIncident ? 'checked' : 'unchecked'"
                    [label]="
                      T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }
                    "
                  >
                  </app-checkbox-with-label>
                </div>
                <div class="add-more-details footer-column">
                  <div class="incident-quick-add-desktop-action" (click)="onAddIncidentDetails()">
                    {{ T.common.add_details | translate }}
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="mobile && !mobileAddLogModal && !mobileAddIncidentModal">
    <div class="mobile-wrapper">
      <div *ngIf="showQuickLogAdd" class="mobile-flex-item mobile-log-dropdown-button" (click)="openMobileAddModal(quickAddItemTypes.Log)">
        <div class="log-icon">
          <i class="material-symbols-outlined">receipt</i>
        </div>
        <div class="mobile-button-label">{{ T.control.add_log.one | translate }}</div>
        <div class="arrow-down-icon" [style.transform]="logDropdownButtonClicked ? 'rotate(180deg)' : ''">
          <i class="material-symbols-outlined">keyboard_arrow_down</i>
        </div>
      </div>
      <div class="mobile-flex-item mobile-incident-dropdown-button" (click)="openMobileAddModal(quickAddItemTypes.Incident)">
        <div class="incident-icon">
          <i class="material-symbols-outlined">error</i>
        </div>
        <div class="mobile-button-label">{{ T.control.add_incident.one | translate }}</div>
        <div class="arrow-down-icon" [style.transform]="incidentDropdownButtonClicked ? 'rotate(180deg)' : ''">
          <i class="material-symbols-outlined">keyboard_arrow_down</i>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="mobile && mobileAddLogModal">
  <app-modal [fullScreen]="true" style="max-height: unset; height: 100%">
    <app-modal-header
      style="z-index: 50"
      [title]="T.control.add_quick_log.one | translate"
      [icon]="'receipt'"
      [showDelete]="false"
      (closed)="openMobileAddModal(null)"
    >
    </app-modal-header>
    <app-modal-body>
      <div class="mobile-input">
        <input
          #logInput
          type="text"
          [formControl]="logInputFormControl"
          [class.incident-quick-add-invalid-input]="
            isFocused(logInput) && logInputFormControl.dirty && !logInputFormControl.value
          "
          [style.padding]="logInputFormControl.value ? '0 22px' : '0 0 0 22px'"
          [style.width]="'100%'"
          [placeholder]="T.control.type_and_press_to_add_log | translate"
          [class.incident-quick-add-input-focused]="isFocused(logInput)"
          maxlength="250"
          minlength="3"
          (focus)="onFocus()"
          (blur)="onBlur()"
          (click)="logDropdownToggled()"
        />
      </div>
      <div class="mobile-dropdown">
        <div class="mobile-dropdown-header">
          <div class="mobile-dropdown-header-icon">
            <i class="material-symbols-outlined">category</i>
          </div>
          <div class="mobile-dropdown-header-text">{{ T.control.select_category_to_quick_add_log | translate }}</div>
        </div>
        <div class="mobile-dropdown-category-container custom-scrollbar">
          <ng-container *ngIf="incidentCategories.length">
            <ng-container *ngFor="let object of incidentCategories">
              <app-dropdown-option
              [value]="object"
              [focused]="isFocused(object)"
              [selected]="objectIsSelected(object)"
              (selectionChange)="onObjectSelected(object, quickAddItemTypes.Log)"
              >
                {{ object.filterText}}
              </app-dropdown-option>
            </ng-container>
          </ng-container>
        </div>
        <div class="mobile-dropdown-footer">
          <div class="mobile-footer-left">
            <div class="mobile-assign-me-checkbox mobile-footer-column">
              <app-checkbox-with-label
                (clicked)="toggleLogsOwnerCheckbox()"
                [intialState]="isOwnerSelectedForAddingLog ? 'checked' : 'unchecked'"
                [label]="T.common.assign_me_as_an_owner | translate"
              >
              </app-checkbox-with-label>
            </div>
            <div class="mobile-assign-department-checkbox mobile-footer-column">
              <app-checkbox-with-label
                (clicked)="toggleLogsDepartmentCheckbox()"
                [intialState]="isDepartmentSelectedForAddingLog ? 'checked' : 'unchecked'"
                [label]="
                  T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }
                "
              >
              </app-checkbox-with-label>
            </div>
          </div>
          <div class="mobile-footer-right">
            <div class="mobile-add-more-details mobile-footer-column">
              <div class="mobile-incident-quick-add-desktop-action" (click)="onAddLogDetails()">
                {{ T.common.add_details | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-modal-body>
    <app-modal-footer>
      <app-button-rectangle
        (click)="onEnterPressed()"
        class="button d-md-block"
        [fitToContainer]="true"
        size="large"
        [text]="T.common.save | translate"
        buttonTheme="primary"
      ></app-button-rectangle>
    </app-modal-footer>
  </app-modal>
</ng-container>

<ng-container *ngIf="mobile && mobileAddIncidentModal">
  <app-modal [fullScreen]="true" style="max-height: unset; height: 100%">
    <app-modal-header
      style="z-index: 50"
      [title]="T.control.add_quick_incident.one | translate"
      [icon]="'receipt'"
      [showDelete]="false"
      (closed)="openMobileAddModal(null)"
    >
    </app-modal-header>
    <app-modal-body>
      <div class="mobile-input">
        <input
          #incidentInput
          type="text"
          [style.padding]="incidentInputFormControl.value ? '0 22px' : '0 0 0 22px'"
          [style.width]="'100%'"
          [formControl]="incidentInputFormControl"
          [class.incident-quick-add-invalid-input]="
            isFocused(incidentInput) && incidentInputFormControl.dirty && !incidentInputFormControl.value
          "
          [placeholder]="T.control.type_and_press_to_add_incident | translate"
          [class.incident-quick-add-input-focused]="isFocused(incidentInput)"
          maxlength="120"
          minlength="3"
          (focus)="onFocus()"
          (blur)="onBlur()"
          (click)="incidentDropdownToggled()"
        />
      </div>
      <div class="mobile-dropdown">
        <div class="mobile-dropdown-header">
          <div class="mobile-dropdown-header-icon">
            <i class="material-symbols-outlined">category</i>
          </div>
          <div class="mobile-dropdown-header-text">
            {{ T.control.select_category_to_quick_add_incident | translate }}
          </div>
          <!-- <div class="mobile-selected-count">
            {{ categoryFilters.length }} Selected
          </div> -->
        </div>
        <div class="mobile-dropdown-category-container">
          <ng-container *ngIf="incidentCategories.length">
            <ng-container *ngFor="let object of incidentCategories">
              <app-dropdown-option
              [value]="object"
              [focused]="isFocused(object)"
              [selected]="objectIsSelected(object)"
              (selectionChange)="onObjectSelected(object, quickAddItemTypes.Incident)"
              >
                {{ object.filterText}}
              </app-dropdown-option>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </app-modal-body>
    <app-modal-footer>
      <div class="row">
        <div class="mobile-dropdown-footer mb-3 m-r-3 m-l-3">
          <div class="mobile-footer-left">
            <div class="mobile-assign-me-checkbox mobile-footer-column">
              <app-checkbox-with-label
                (clicked)="toggleIncidentsOwnerCheckbox()"
                [intialState]="isOwnerSelectedForAddingIncident ? 'checked' : 'unchecked'"
                [label]="T.common.assign_me_as_an_owner | translate"
              >
              </app-checkbox-with-label>
            </div>
            <div class="mobile-assign-department-checkbox mobile-footer-column">
              <app-checkbox-with-label
                (clicked)="toggleIncidentsDepartmentCheckbox()"
                [intialState]="isDepartmentSelectedForAddingIncident ? 'checked' : 'unchecked'"
                [label]="
                  T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }
                "
              >
              </app-checkbox-with-label>
            </div>
          </div>
          <div class="mobile-footer-right">
            <div class="mobile-add-more-details mobile-footer-column">
              <div class="mobile-incident-quick-add-desktop-action" (click)="onAddIncidentDetails()">
                {{ T.common.add_details | translate }}
              </div>
            </div>
          </div>
        </div>
        <app-button-rectangle
          (click)="onEnterPressed()"
          class="button"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.save | translate"
          buttonTheme="primary"
        ></app-button-rectangle>
      </div>
    </app-modal-footer>
  </app-modal>
</ng-container>
