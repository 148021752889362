import { ChecklistResponseTypes } from '../../enums/checklists/checklistEnums';
import { ObjectTypes } from '../../enums/objectTypes';

export class ChecklistItemViewModel {
  id: number;
  accountId: number;
  created: string;
  createdBy: number;
  updated: string;
  updatedBy: string;
  title: string;
  description: string;
  viewOrder: number;
  starred: boolean;
  checklistTemplateTitle: string;
  globalObjectId: number;
  globalObjectType: ObjectTypes;
  dueTime: string;
  employeeId: number;
  completed: boolean;
  responseType: ChecklistResponseTypes;
  response: string;
  textResponse: string;
  checklistItemTemplateId: number;
  checklistTemplateId: number;
  important: boolean;
  isNotApplicable: boolean;
  attachmentsCount: number;
}
